import SvgIcon from '@mui/material/SvgIcon';

export default function DeleteIcon(props) {
  return (
    <SvgIcon width="10" height="12" viewBox="0 0 10 12" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.00016 10.6667C1.00016 11.4 1.60016 12 2.3335 12H7.66683C8.40016 12 9.00016 11.4 9.00016 10.6667V2.66667H1.00016V10.6667V10.6667ZM9.66683 0.666667H7.3335L6.66683 0H3.3335L2.66683 0.666667H0.333496V2H9.66683V0.666667V0.666667Z"
        fill="#EF4444"
      />
    </SvgIcon>
  );
}
