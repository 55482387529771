const UploadIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9997 12V2.66669L13.333 5.33335"
        stroke="#436AF3"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 2.66669L18.6667 5.33335"
        stroke="#436AF3"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.64062 17.3333H8.52062C9.02729 17.3333 9.48062 17.6133 9.70729 18.0666L11.2673 21.1866C11.7206 22.0933 12.6406 22.6666 13.654 22.6666H18.3606C19.374 22.6666 20.294 22.0933 20.7473 21.1866L22.3073 18.0666C22.534 17.6133 23.0006 17.3333 23.494 17.3333H29.3073"
        stroke="#436AF3"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33366 6.83984C4.61366 7.53318 2.66699 10.3065 2.66699 15.9998V19.9998C2.66699 26.6665 5.33366 29.3332 12.0003 29.3332H20.0003C26.667 29.3332 29.3337 26.6665 29.3337 19.9998V15.9998C29.3337 10.3065 27.387 7.53318 22.667 6.83984"
        stroke="#436AF3"
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default UploadIcon;
