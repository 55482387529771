import HttpService from '@jibin/common/services/http/HttpService';

export const GapAnalysisApi = {
  GetCategories(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/categories`);
  },
  GetQuestionnaires(userId: string, companyId: string, category: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/categories/${category}/questionnaires`
    );
  },
  PostAnswers(userId: string, companyId: string, questionnaire_id: string, data: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.post(
      `companies/${companyId}/users/${userId}/questionnaires/${questionnaire_id}/answer`,
      data
    );
  },
  PostAnswersEngineer(
    userId: string,
    companyId: string,
    questionnaire_id: string,
    answer_id: string,
    data: any
  ) {
    //return HttpService.getAll("todos/1");
    return HttpService.post(
      `companies/${companyId}/users/${userId}/questionnaires/${questionnaire_id}/answers/${answer_id}/feedback`,
      data
    );
  },
  Submit(
    userId: string,
    companyId: string,    
    data: any
  ) {
    return HttpService.post(
      `companies/${companyId}/users/${userId}/questionnaires/submit`,
      data
    );
  },
  PutAnswers(
    userId: string,
    companyId: string,
    questionnaire_id: string,
    answer_id: string,
    data: any
  ) {
    //return HttpService.getAll("todos/1");
    return HttpService.putAll(
      `companies/${companyId}/users/${userId}/questionnaires/${questionnaire_id}/answers/${answer_id}`,
      data
    );
  },
  GetFile(companyId: string,userId: string, questionId: string, answerId: string, fileId: string) {
    //file_path = file_path.slice(1);
    //console.log(file_path);
    //return HttpService.getDocument(`${file_path}`);
    return HttpService.getDocument(`companies/${companyId}/users/${userId}/questionnaires/${questionId}/answers/${answerId}/files/${fileId}`);
  },
  GetUserData() {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll('users/me');
  },
};
