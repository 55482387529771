import SvgIcon from '@mui/material/SvgIcon';

export default function DownloadIcon(props) {
  return (
    <SvgIcon
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 12H4.5V17H20.5V12H22.5V17C22.5 18.11 21.61 19 20.5 19H4.5C3.4 19 2.5 18.11 2.5 17V12ZM12.5 15L18.05 9.54L16.63 8.13L13.5 11.25V2H11.5V11.25L8.38 8.13L6.96 9.55L12.5 15Z" />
    </SvgIcon>
  );
}
