import HttpService from '@jibin/common/services/http/HttpService';

export const TechinicalInformation = {
  GetLocationList(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/addresses`);
  },

  GetApplicationsList(userId: string, companyId: string, q: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/settings/assessments/applications?keyword=${q}`
    );
  },

  postApplication(userId: string, companyId: string, data: any) {
    return HttpService.post(
      `companies/${companyId}/users/${userId}/settings/assessments/application`,
      data
    );
  },

  putApplication(userId: string, companyId: string, appId: string, data: any) {
    return HttpService.put(
      `companies/${companyId}/users/${userId}/settings/assessments/application`,
      appId,
      data
    );
  },
  PatchApplication(userId: string, companyId: string, appId: string, data: any) {
    return HttpService.patch(
      `companies/${companyId}/users/${userId}/settings/assessments/application`,
      appId,
      data
    );
  },
  deleteApplication(userId: string, companyId: string, appId: string) {
    return HttpService.remove(
      `companies/${companyId}/users/${userId}/settings/assessments/application`,
      appId
    );
  },

  // Env
  postEnv(userId: string, companyId: string, applicationId: string, data: any) {
    return HttpService.post(
      `companies/${companyId}/users/${userId}/settings/assessments/application/${applicationId}/env`,
      data
    );
  },

  putEnv(userId: string, companyId: string, applicationId: string, envId: string, data: any) {
    return HttpService.put(
      `companies/${companyId}/users/${userId}/settings/assessments/application/${applicationId}/env`,
      envId,
      data
    );
  },
  patchEnv(userId: string, companyId: string, applicationId: string, envId: string, data: any) {
    return HttpService.patch(
      `companies/${companyId}/users/${userId}/settings/assessments/application/${applicationId}/env`,
      envId,
      data
    );
  },
  deleteEnv(userId: string, companyId: string, applicationId: string, envId: string) {
    return HttpService.remove(
      `companies/${companyId}/users/${userId}/settings/assessments/application/${applicationId}/env`,
      envId
    );
  },

  // /companies/{company_id}/users/{user_id}/settings/assessments/application/{application_id}/env/{env_id}

  GetIpsList(userId: string, companyId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/assessments/ips`);
  },

  postIps(userId: string, companyId: string, data: any) {
    return HttpService.post(`companies/${companyId}/users/${userId}/settings/assessments/ip`, data);
  },

  putIps(userId: string, companyId: string, ipId: string, data: any) {
    return HttpService.put(
      `companies/${companyId}/users/${userId}/settings/assessments/ip`,
      ipId,
      data
    );
  },
  deleteIps(userId: string, companyId: string, ipId: string) {
    return HttpService.remove(
      `companies/${companyId}/users/${userId}/settings/assessments/ip`,
      ipId
    );
  },
  PatchIps(userId: string, companyId: string, ipId: string, data: any) {
    return HttpService.patch(
      `companies/${companyId}/users/${userId}/settings/assessments/ip`,
      ipId,
      data
    );
  },

  GetFacilitiesList(userId: string, companyId: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/settings/facilities?status=Active`
    );
  },

  GetWirelessAssessmentList(userId: string, companyId: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/settings/assessments/wireless`
    );
  },

  postWirelessAssessment(userId: string, companyId: string, data: any) {
    return HttpService.post(
      `companies/${companyId}/users/${userId}/settings/assessments/wireless`,
      data
    );
  },

  putWirelessAssessment(userId: string, companyId: string, appId: string, data: any) {
    return HttpService.put(
      `companies/${companyId}/users/${userId}/settings/assessments/wireless`,
      appId,
      data
    );
  },
  PatchWirelessAssessment(userId: string, companyId: string, appId: string, data: any) {
    return HttpService.patch(
      `companies/${companyId}/users/${userId}/settings/assessments/wireless`,
      appId,
      data
    );
  },
  deleteWirelessAssessment(userId: string, companyId: string, appId: string) {
    return HttpService.remove(
      `companies/${companyId}/users/${userId}/settings/assessments/wireless`,
      appId
    );
  }
};
