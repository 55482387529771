import HttpService from '@jibin/common/services/http/HttpService';

export const CompanySettingsService = {
  // User section
  GetUserList(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/users`);
  },

  postUser(userId: string, companyId: string, data: any) {
    return HttpService.post(`companies/${companyId}/users/${userId}/settings/users`, data);
  },

  putUser(reqUserId: string, companyId: string, userId: string, data: any) {
    return HttpService.put(
      `companies/${companyId}/users/${reqUserId}/settings/users`,
      userId,
      data
    );
  },
  
  deleteUser(reqUserId: string, companyId: string, userId: string) {
    return HttpService.remove(`companies/${companyId}/users/${reqUserId}/settings/users`, userId);
  },

  // Authorizations
  GetSignatures(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/signatures`);
  },
  // Meetings
  GetCompanyMeetings(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/meetings`);
  },
  GetMeetingsList(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/meetings`);
  },

  //Locations
  GetLocationList(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/addresses`);
  },
  GetLocation(userId: string, companyId: string, locationId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.get(`companies/${companyId}/users/${userId}/settings/address`, locationId);
  },

  postLocation(userId: string, companyId: string, data: any) {
    return HttpService.post(`companies/${companyId}/users/${userId}/settings/address`, data);
  },

  putLocation(userId: string, companyId: string, locationId: string, data: any) {
    return HttpService.put(
      `companies/${companyId}/users/${userId}/settings/address`,
      locationId,
      data
    );
  },
  PatchLocation(userId: string, companyId: string, locationId: string, data: any) {
    return HttpService.patch(
      `companies/${companyId}/users/${userId}/settings/address`,
      locationId,
      data
    );
  },
  deleteLocation(userId: string, companyId: string, locationId: string) {
    return HttpService.remove(
      `companies/${companyId}/users/${userId}/settings/address`,
      locationId
    );
  }
};
