import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserPool from '../../utils/UserPool';
import { Auth, Amplify } from 'aws-amplify';
import { CommonFunctions } from '@jibin/common/utils/Common';
const axios = require('axios');
const axiosApiInstance = axios.create();

export const notifySuccess = (message: any) =>
  toast.success(message, { position: toast.POSITION.TOP_RIGHT });
export const notifyError = (message: any) =>
  toast.error(message, { position: toast.POSITION.TOP_RIGHT });
Amplify.configure({
  Auth: {
    userPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID),
    userPoolWebClientId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_CLIENT_ID)
  }
});
// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config: any) => {
    let idToken = '';
    let accessToken = '';
    try {
      const session = await Auth.currentSession();
      idToken = session.getIdToken().getJwtToken();
      accessToken = session.getAccessToken().getJwtToken();
    } catch {
      idToken = null;
    }

    if (idToken) {
      config.headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${idToken}`,
        Access: `${accessToken}`
      };
    } else {
      config.headers = {
        Accept: 'application/json'
      };
    }
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response: any) => {
    if (
      response.config.method.toLowerCase() == 'post' &&
      response.config.url.includes('settings/users')
    ) {
      notifySuccess('Invite sent successfully');
    }
    if (
      response.config.method.toLowerCase() == 'post' ||
      response.config.method.toLowerCase() == 'put'
    ) {
      if (
        !response.config.url.includes('sf/users') &&
        !response.config.url.includes('settings/users') && 
        !response.config.url.includes('/plan-and-billing') 
      )
        notifySuccess('Data saved successfully');
    } else if (response.config.method.toLowerCase() == 'delete') {
      notifySuccess('Data removed successfully');
    }
    return response;
  },
  async function (error: any) {
    const originalRequest = error.config;

    if (error.response.status === 403) {
      notifyError(error.response.data.error.message);
      //window.location.href = PageRouteConstants.OnBoarding;
    } else if (error.response.status === 401) {
      if (error.response.data.error.message == 'token is invalid') {
        notifyError(error.response.data.error.message);
        localStorage.clear();
        window.location.href = '/login';
      } else {
        notifyError(error.response.data.error.message);
        localStorage.clear();
        window.location.href = '/login';
      }
    } else {
      notifyError(error.response.data.error.message);
    }

    return Promise.reject(error);
  }
);

export default axiosApiInstance;
