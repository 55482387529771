import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FC, useCallback, useEffect, useState } from 'react';
import PasswordChecklist from 'react-password-checklist';
import UserPool from '@jibin/common/utils/UserPool';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './Signup.css';
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardHeader, Skeleton, TextField } from '@mui/material';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { useFormik } from 'formik';

import { SignupService } from './index.api';
import { SignupModel } from '../Signup/signup.models';
import CorrectIcon from '@jibin/common/icons/CorrectIcon/CorrectIcon';
import CrossIcon from '@jibin/common/icons/CrossIcon/CrossIcon';

const notifySuccess = (message: any) =>
  toast.success(message, { position: toast.POSITION.TOP_RIGHT });
const notifyError = (message: any) => toast.error(message, { position: toast.POSITION.TOP_RIGHT });
var debounce = require('lodash.debounce');

export const SignupCreate: FC = (props: any) => {
  const navigate = useNavigate();

  const [isSkeleton, setIsSkeleton] = useState(true);
  const [passwordValidate, setPasswordValidate] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userDetails, setUserDetails] = useState({ compnyId: '', email: '' });
  const location = useLocation();
  const getSignupDetails = useCallback(() => {
    const token = window.location.href.split('/')[4];
    const compnyId = atob(token.split('?')[0]);
    const email = atob(token.split('?')[1]);

    setUserDetails({ ...userDetails, compnyId, email });
  }, []);

  useEffect(() => {
    getSignupDetails();
  }, [getSignupDetails]);
  const handleBlur = (event: any) => {
    const passRegex: RegExp = new RegExp(process.env.REACT_APP_PASSWORD_REGEX);
    if (event.target.value) {
      if (!passRegex.test(event.target.value)) {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }
    }
  };

  const handleChangePhone = (value) => {
    setPhoneNumber(value);
  };
  const capitalizeName = (value: string) => {
    const updatedValue = value.replace(/^-+|-+$/g, '').trim();
    return updatedValue
      .split(/ |-/)
      .map((el, index) => el.charAt(0).toUpperCase() + '' + el.trimEnd().slice(1) + '')
      .join(updatedValue.includes('-') ? '-' : updatedValue.includes(' ') ? ' ' : '');
  };
  const validate = (values: {
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
  }) => {
    const errors = {};

    if (!values.password) {
      errors['password'] = 'This field is Required ';
    }
    if (!values.firstName) {
      errors['firstName'] = 'This field is Required ';
    }
    if (!values.lastName) {
      errors['lastName'] = 'This field is Required ';
    }
    if (!values.confirmPassword) {
      errors['confirmPassword'] = 'This field is Required ';
    } else {
      if (values.confirmPassword && values.password) {
        if (values.password !== values.confirmPassword) {
          errors['confirmPassword'] = 'Confirm password do not match with entered password';
        }
      }
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: ''
    },

    validateOnChange: false,
    validate,
    onSubmit: useCallback(
      debounce(
        (values) => {
          if (passwordError) {
            return;
          }

          var userData = [];
          userData.push(new CognitoUserAttribute({ Name: 'email', Value: userDetails.email }));
          userData.push(
            new CognitoUserAttribute({
              Name: 'custom:first_name',
              Value: capitalizeName(values.firstName)
            })
          );
          userData.push(
            new CognitoUserAttribute({
              Name: 'custom:last_name',
              Value: capitalizeName(values.lastName)
            })
          );
          userData.push(new CognitoUserAttribute({ Name: 'phone_number', Value: phoneNumber }));
          userData.push(
            new CognitoUserAttribute({
              Name: 'custom:external_id',
              Value: ''
            })
          );
          var validationData = [];
          validationData.push(new CognitoUserAttribute({ Name: 'company_name', Value: '' }));
          validationData.push(new CognitoUserAttribute({ Name: 'company_role', Value: '' }));
          validationData.push(
            new CognitoUserAttribute({
              Name: 'industry_type',
              Value: ''
            })
          );
          validationData.push(
            new CognitoUserAttribute({
              Name: 'company_external_id',
              Value: ''
            })
          );
          UserPool.signUp(
            userDetails.email,
            values.password,
            userData,
            validationData,
            (err, data) => {
              if (err) {
                notifyError(err.message);
              } else {
                notifySuccess(data);
                navigate(PageRouteConstants.Login);
              }
            }
          );
        },
        500,
        true
      ),
      [userDetails, phoneNumber]
    )
  });

  return (
    <form onSubmit={formik.handleSubmit} method="post">
      <Box sx={Style.SignUp.SignUpWrapper}>
        <Container maxWidth="xl">
          <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <Box sx={Style.Auth.AuthLeftPart}>
                <Box sx={Style.Auth.TrustLogo}>
                  <img alt="" src="/images/trust-logo-black.svg" />
                </Box>

                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <label htmlFor="firstName">First Name</label>
                  </Box>
                  <TextField
                    placeholder="Enter your first name"
                    id="firstName"
                    variant="outlined"
                    type={'text'}
                    value={formik.values.firstName.trimStart()}
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.errors.firstName = '';
                    }}
                    inputProps={{
                      style: {
                        textTransform: 'capitalize'
                      }
                    }}
                    error={formik.errors.firstName ? true : false}
                    helperText={formik.errors.firstName}
                    aria-describedby="outlined-weight-helper-text"
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <label htmlFor="lastName">Last Name</label>
                  </Box>
                  <TextField
                    placeholder="Enter your last name"
                    id="lastName"
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.errors.lastName = '';
                    }}
                    value={formik.values.lastName.trimStart()}
                    error={formik.errors.lastName ? true : false}
                    helperText={formik.errors.lastName}
                    inputProps={{
                      style: {
                        textTransform: 'capitalize'
                      }
                    }}
                    variant="outlined"
                    type={'text'}
                    aria-describedby="outlined-weight-helper-text"
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <label htmlFor="password">Phone Number for Multi-Factor Authentication</label>
                  </Box>

                  <Box
                    sx={{
                      border: '1px solid #EBEBEB',
                      borderRadius: '4px',
                      px: '14px',
                      '&:hover': {
                        outline: '0.8px solid black'
                      },
                      '&:focus-within': {
                        outline: '1.9px solid #436af3 !important'
                      }
                    }}>
                    <PhoneInput
                      international
                      autoFormat={false}
                      defaultCountry="US"
                      onChange={handleChangePhone}
                    />
                  </Box>
                </FormControl>
                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <label htmlFor="password">Password</label>
                  </Box>
                  <TextField
                    onChange={(e) => {
                      formik.handleChange(e);
                      setPasswordValidate(e.target.value);
                      setPasswordError(false);
                      formik.errors.password = '';
                    }}
                    onBlur={handleBlur}
                    value={formik.values.password}
                    error={formik.errors.password ? true : false || passwordError ? true : false}
                    helperText={
                      formik.errors.password
                        ? formik.errors.password
                        : '' || passwordError
                        ? 'Password must be minimum 12 characters long and contain at least one uppercase letter, one lowercase letter, one digit and one symbol'
                        : ''
                    }
                    placeholder="Enter password"
                    variant="outlined"
                    id="password"
                    type={'password'}
                    aria-describedby="outlined-weight-helper-text"
                  />
                </FormControl>
                <PasswordChecklist
                  rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase']}
                  minLength={12}
                  iconSize={14}
                  style={{
                    fontSize: '12px',
                    marginTop: '8px'
                  }}
                  value={passwordValidate}
                  iconComponents={{
                    ValidIcon: <CorrectIcon />,
                    InvalidIcon: <CrossIcon />
                  }}
                  messages={{
                    minLength: 'Minimum 12 digits',
                    capital: 'Atleast 1 upper case letters (A – Z)',
                    lowercase: 'Atleast 1 Lower case letters (a – z)',
                    number: 'Atleast 1 number (0 – 9)',
                    specialChar: 'Atleast 1 non-alphanumeric symbol (e.g. ‘@$%£!’)'
                  }}
                  onChange={(isValid) => {}}
                />
                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <label htmlFor="confirmPassword">Confirm Password</label>
                  </Box>
                  <TextField
                    placeholder="Enter confirm password"
                    value={formik.values.confirmPassword}
                    error={formik.errors.confirmPassword ? true : false}
                    helperText={formik.errors.confirmPassword}
                    id="confirmPassword"
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.errors.confirmPassword = '';
                    }}
                    variant="outlined"
                    type={'password'}
                    aria-describedby="outlined-weight-helper-text"
                  />
                </FormControl>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ mt: { xs: 3, md: 4, xl: 5 } }}
                  fullWidth>
                  Create your account
                </Button>
                <Typography variant="body2" sx={{ mt: { xs: 2, md: 3 } }}>
                  By clicking the request to sign up, you are creating an [RE]DESIGN Trust Portal
                  account, and you agree our{' '}
                  <Link
                    href="https://www.privacypolicies.com/live/57b461e7-db13-4d84-9590-902e2155dca3"
                    sx={{ textDecoration: 'underline' }}
                    target={'_blank'}>
                    Terms of Use
                  </Link>{' '}
                  and{' '}
                  <Link
                    sx={{ textDecoration: 'underline' }}
                    href="https://www.privacypolicies.com/live/008e0649-83c2-4596-b289-e1763e774ca5"
                    target={'_blank'}>
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={Style.Auth.AuthInfographics}>
                <img alt="" src="/images/elephant-with-men.png" />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </form>
  );
};
