import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import { Box, Button, IconButton, List, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UploadIcon from '@jibin/common/icons/UploadIcon/UploadIcon';
import FileIcon from '@jibin/common/icons/FileIcon/FileIcon';
import PinIcon from '@jibin/common/icons/PinIcon/PinIcon';
import VideoPlay from '@jibin/common/icons/VideoPlay/VideoPlay';
import GallaryIcon from '@jibin/common/icons/GallaryIcon/GallaryIcon';
import shallowEqual from 'react-redux/es/utils/shallowEqual';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import { ServiceReviewApi } from '../ServiceReview.Api';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
export interface ServiceReviewSideBarPopupProp {
  rowData: any;
  isRefresh: boolean;
  closePopup(event: React.KeyboardEvent | React.MouseEvent): void;
}
const ServiceReviewSideBarPopup: FC<ServiceReviewSideBarPopupProp> = (prop) => {
  const [fileData, setFileData] = useState([] as any);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { rowData, closePopup, isRefresh } = prop;
  const [isUploade, setIsUpload] = useState(false);
  const [isUploadButtonLoading, setIsUploadButtonLoading] = useState(false);

  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  function niceBytes(x) {
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const handleRemoveFile = (i) => {
    const upadatedFileData = fileData.filter((el, index) => index != i);
    setFileData(upadatedFileData);
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const formData = new FormData();
    for (let i = 0; i < fileData.length; i++) {
      formData.append('files', fileData[i]);
    }

    try {
      setIsUploadButtonLoading(true);
      setIsUpload(true);
      const res = await ServiceReviewApi.PostDocument(
        rowData.service_review_uuid,
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        formData
      );
      setUploadedFiles(fileData);
      setFileData([]);

      closePopup(e);
    } catch (err) {
      console.log(err);
      setIsUpload(false);
    } finally {
      setTimeout(() => {
        setIsUploadButtonLoading(false);
      }, 1000);
    }
  };

  const handleImageUpload = (e, files) => {
    stopReload(e);

    if (!e) return;

    setFileData([...fileData, ...files]);
  };

  const stopReload = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleBrowseEvent = (e: any) => {
    setFileData([...fileData, ...e.target.files]);
  };

  const handleDragEvent = (e: any) => {
    handleImageUpload(e, e.dataTransfer.files);
  };
  useEffect(() => {
    setUploadedFiles([]);
    setIsUpload(false);
  }, [isRefresh]);

  return (
    <form method="POST" onSubmit={handleSubmit} encType="multipart/form-data">
      <Box sx={{ width: { md: 520, xs: 300 } }} role="presentation">
        <List sx={{ p: 0 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: '#003260',
              px: 2.5,
              alignItems: 'center',
              py: 2
            }}>
            <Box>
              <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', my: 1 }}>
                {rowData.service_name}
              </Typography>
              {/* <Typography variant="body2" sx={{ color: 'white', my: 1 }}>
                {commonDetails.data.first_name + ' ' + commonDetails.data.last_name}
              </Typography> */}
            </Box>
            <Box
              onClick={(event) => {
                closePopup(event);
              }}>
              <CrossCancle
                sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
              />
            </Box>
          </Box>
        </List>
        <List
          sx={{
            p: 3,
            position: 'relative',
            minheight: '87vh',
            height: fileData.length > 5 ? '100vh' : '87vh'
          }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
            Upload Report
          </Typography>
          <Box
            onDragOver={(e) => stopReload(e)}
            onDrop={(e) => handleDragEvent(e)}
            sx={{
              width: '100%',
              backgroundColor: '#FBFBFF',
              py: 4,
              display: 'flex',
              border: '1px dashed #CCCCCC',
              borderRadius: '8px',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2.5
            }}>
            <UploadIcon />
            <Typography variant="body1" sx={{ fontWeight: 600, color: '#464646' }}>
              Drag & Drop or{' '}
              <label style={{ color: '#436AF3', cursor: 'pointer' }}>
                <input
                  multiple
                  hidden
                  onChange={(e) => handleBrowseEvent(e)}
                  style={{
                    backgroundColor: 'transparent',
                    color: 'transparent'
                  }}
                  type={'file'}
                />{' '}
                Choose file{' '}
              </label>
              to upload
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
                width: '100%',
                alignItem: 'center',
                m: 'auto'
              }}>
              {fileData.length == 0 || isUploade ? (
                <Typography textAlign={'center'} variant="body2" sx={{ color: '#787878' }}>
                  doc,img, or video
                </Typography>
              ) : (
                fileData?.map((el, index) => (
                  <Box
                    key={index + 1}
                    sx={{
                      display: 'flex',
                      width: '80%',
                      m: 'auto',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      p: 1.5,
                      borderRadius: '8px',
                      backgroundColor: '#FFFFFF',
                      border: '1px solid #EBEBEB'
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {el.type.split('/')[0] == 'image' ? (
                        <GallaryIcon />
                      ) : el.type.split('/')[0] == 'video' ? (
                        <VideoPlay />
                      ) : (
                        <FileIcon />
                      )}

                      <EllipsisContent
                        sx={{
                          fontSize: 14,
                          color: '#2D2D2D',
                          width: 'max-content',
                          maxWidth: {
                            xxs: 150,
                            md: 150
                          }
                        }}
                        text={el.name}
                      />

                      <Typography variant="body2" sx={{ color: '#787878' }}>
                        {niceBytes(el.size)}
                      </Typography>
                    </Box>
                    <Box onClick={() => handleRemoveFile(index)}>
                      <CrossCancle
                        sx={{
                          background: 'rgba(120, 120, 120,0.1)',
                          width: '20px',
                          height: '20px',
                          color: '#787878',
                          p: '6px',
                          borderRadius: '50%',
                          cursor: 'pointer'
                        }}
                      />
                    </Box>
                  </Box>
                ))
              )}
            </Box>
          </Box>
          {uploadedFiles && isUploade && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
                Files:
              </Typography>

              <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 1.5 }}>
                {uploadedFiles.map((el, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      backgroundColor: '#D9EDFF',
                      py: '5px',
                      px: 1.5,
                      borderRadius: '100px'
                    }}>
                    <PinIcon />
                    <Typography variant="body1" sx={{ color: '#436AF3' }}>
                      {el.name}
                    </Typography>
                    <CrossCancle sx={{ width: '9px', color: '#2D2D2D', cursor: 'pointer' }} />
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          <LoadingButton
            loading={isUploadButtonLoading}
            type="submit"
            disabled={isUploade || !fileData.length}
            sx={{
              position: 'absolute',
              bottom: 0,
              margin: 3,
              right: 0,
              minWidth: 163,
              backgroundColor: '#436AF3',
              color: 'white',
              '&:hover': {
                backgroundColor: '#436AF3'
              },
              '&.Mui-disabled': {
                backgroundColor: '#EBEBEB',
                color: '#9D9D9D'
              }
            }}>
            Submit
          </LoadingButton>
        </List>
      </Box>
    </form>
  );
};
export default ServiceReviewSideBarPopup;
