import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useOutlet } from 'react-router-dom';
import MainContainer from '../../components/Container';
import { PageRouteConstants } from '../constants/PageRouteConstants';
import ProtectedRoute from '../RouteAccessor/ProtectedRoute';
function PrivateRoutes({ children }: { children: React.ReactElement }): React.ReactElement {
  let auth = localStorage.getItem('cognito-token');
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  let location = useLocation();
  if (!auth) {
    return <Navigate to={PageRouteConstants.Login} state={{ from: location }} replace />;
  }
  if (commonDetails?.data?.company?.type == 'engineering') {
    return (
      <ProtectedRoute>
        <MainContainer> {children} </MainContainer>
      </ProtectedRoute>
    );
  }
  //return children;
  return <MainContainer> {children} </MainContainer>;
}

export default PrivateRoutes;
