import { FC, useCallback, useState } from 'react';
import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import UserPool from '@jibin/common/utils/UserPool';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import './index.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import { TextField, Typography } from '@mui/material';
import CorrectIcon from '@jibin/common/icons/CorrectIcon/CorrectIcon';
import CrossIcon from '@jibin/common/icons/CrossIcon/CrossIcon';
import PasswordChecklist from 'react-password-checklist';
import { LoadingButton } from '@mui/lab';
const notifySuccess = (message: any) =>
  toast.success(message, { position: toast.POSITION.TOP_RIGHT });
const notifyInfo = (message: any) => toast.info(message, { position: toast.POSITION.TOP_RIGHT });
const notifyError = (message: any) => toast.error(message, { position: toast.POSITION.TOP_RIGHT });
var debounce = require('lodash.debounce');

export const ForgotPassword: FC = (props: any) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [isRequestMade, setIsRequestMade] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [verificationcode, setVerificationcode] = useState('');
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordValidate, setPasswordValidate] = useState('');
  const [isForgetButtonLoading, setIsForgetButtonLoading] = useState(false);
  const [isCodeSubmitButtonLoading, setIsCodeSubmitButtonLoading] = useState(false);

  const handleBlur = (event: any) => {
    if (event.target.value) {
      const passRegex: RegExp = new RegExp(`${process.env.REACT_APP_PASSWORD_REGEX}`);
      if (!passRegex.test(event.target.value.trim())) {
        setPasswordError(true);
      } else {
        setPasswordError(false);
      }
    }
  };
  const handleSubmitForgotRequest = useCallback(
    debounce(
      (event: any) => {
        try {
          setIsForgetButtonLoading(true);
          event.preventDefault();
          if (!userName) {
            setError('This field is Required ');
            return;
          }
          const userData = {
            Username: userName,
            Pool: UserPool
          };

          const cognitouser = new AmazonCognitoIdentity.CognitoUser(userData);

          cognitouser.forgotPassword({
            onSuccess: (data) => {
              notifyInfo('Check your email address for verification code');
              setIsRequestMade(!isRequestMade);
            },
            onFailure: (err) => {}
          });
        } catch (err) {
        } finally {
          setIsForgetButtonLoading(false);
        }
      },
      500,
      true
    ),
    [userName, UserPool]
  );

  const handleSubmitConfirmPassword = (event: any) => {
    event.preventDefault();

    const userData = {
      Username: userName,
      Pool: UserPool
    };

    const cognitouser = new AmazonCognitoIdentity.CognitoUser(userData);

    cognitouser.confirmPassword(verificationcode, newPassword, {
      onFailure(err) {
        notifyError(err);
      },
      onSuccess(data) {
        notifySuccess('Password updated successfully');
        navigate(PageRouteConstants.Login);
      }
    });
  };

  const validate = (values: { password: string; otp: string }) => {
    const errors = {};
    const passRegex: RegExp = new RegExp(process.env.REACT_APP_PASSWORD_REGEX);
    if (!values.password) {
      errors['password'] = 'This field is Required ';
    } else if (!passRegex.test(values.password.trim())) {
      errors['password'] =
        'Password must be minimum 12 characters long and contain at least one uppercase letter, one lowercase letter, one digit and one symbol';
    }
    if (!values.otp) {
      errors['otp'] = 'This field is Required ';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      otp: ''
    },

    validateOnChange: false,
    validate,
    onSubmit: useCallback(
      debounce(
        (values) => {
          try {
            setIsCodeSubmitButtonLoading(true);
            const userData = {
              Username: userName,
              Pool: UserPool
            };

            const cognitouser = new AmazonCognitoIdentity.CognitoUser(userData);

            cognitouser.confirmPassword(values.otp, values.password, {
              onFailure(err) {
                notifyError(err.message);
              },
              onSuccess(data) {
                notifySuccess('Password updated successfully');
                navigate(PageRouteConstants.Login);
              }
            });
          } catch (error) {
          } finally {
            setTimeout(() => {
              setIsCodeSubmitButtonLoading(false);
            }, 1000);
          }
        },
        500,
        true
      ),
      [passwordError, newPassword, userName]
    )
  });

  return (
    <Box sx={Style.SignUp.SignUpWrapper}>
      <Container maxWidth="xl">
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
            {!isRequestMade && (
              <Box sx={Style.Auth.AuthLeftPart}>
                <Box sx={Style.Auth.TrustLogo}>
                  <img alt="" src="/images/trust-logo-black.svg" />
                </Box>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'text.primary',
                    pt: 2,
                    pb: 3
                  }}
                  variant={'subtitle2'}>
                  Enter your e-mail and we are going to send you a link to reset your password.
                </Typography>
                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <label htmlFor="password">E-mail</label>
                  </Box>
                  <TextField
                    placeholder="Enter your e-mail"
                    variant="outlined"
                    id="email"
                    value={userName}
                    error={error ? true : false}
                    helperText={error}
                    onChange={(e) => {
                      setUserName(e.target.value);
                      setError('');
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'password'
                    }}
                  />
                </FormControl>
                <LoadingButton
                  loading={isForgetButtonLoading}
                  onClick={handleSubmitForgotRequest}
                  variant="contained"
                  sx={{ mt: { xs: 3, md: 4, xl: 5 } }}
                  fullWidth>
                  Reset Password
                </LoadingButton>
              </Box>
            )}
            {isRequestMade && (
              <form onSubmit={formik.handleSubmit} method="post">
                <Box sx={Style.Auth.AuthLeftPart}>
                  <Box sx={Style.Auth.TrustLogo}>
                    <img alt="" src=".trust-logo-black.svg" />
                  </Box>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="password">Verification Code</label>
                    </Box>
                    <TextField
                      placeholder="Verification Code"
                      id="otp"
                      name="otp"
                      error={formik.errors.otp ? true : false}
                      helperText={formik.errors.otp}
                      value={formik.values.otp}
                      onChange={(e) => {
                        formik.handleChange(e);

                        formik.errors.otp = '';
                      }}
                      aria-describedby="outlined-weight-helper-text"
                      variant="outlined"
                      inputProps={{
                        'aria-label': 'password'
                      }}
                    />
                  </FormControl>

                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="password">New Password</label>
                    </Box>
                    <TextField
                      onChange={(e) => {
                        formik.handleChange(e);
                        setPasswordValidate(e.target.value);
                        setPasswordError(false);
                        formik.errors.password = '';
                      }}
                      onBlur={handleBlur}
                      value={formik.values.password}
                      error={formik.errors.password ? true : false || passwordError ? true : false}
                      helperText={
                        formik.errors.password
                          ? formik.errors.password
                          : '' || passwordError
                          ? 'Password must be minimum 12 characters long and contain at least one uppercase letter, one lowercase letter, one digit and one symbol'
                          : ''
                      }
                      placeholder="Password"
                      variant="outlined"
                      id="password"
                      type={'password'}
                      aria-describedby="outlined-weight-helper-text"
                    />
                  </FormControl>
                  <PasswordChecklist
                    rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase']}
                    minLength={12}
                    iconSize={14}
                    style={{
                      fontSize: '12px',
                      marginTop: '8px'
                    }}
                    value={passwordValidate}
                    iconComponents={{
                      ValidIcon: <CorrectIcon />,
                      InvalidIcon: <CrossIcon />
                    }}
                    messages={{
                      minLength: 'Minimum 12 digits',
                      capital: 'At least 1 upper case letters (A – Z)',
                      lowercase: 'At least 1 Lower case letters (a – z)',
                      number: 'At least 1 number (0 – 9)',
                      specialChar: 'At least 1 non-alphanumeric symbol (e.g. ‘@$%£!’)'
                    }}
                    onChange={(isValid) => {}}
                  />
                  <LoadingButton
                    loading={isCodeSubmitButtonLoading}
                    type="submit"
                    variant="contained"
                    sx={{ mt: { xs: 3, md: 4, xl: 5 } }}
                    fullWidth>
                    Submit
                  </LoadingButton>
                </Box>
              </form>
            )}
          </Grid>
          <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
            <Box sx={Style.Auth.AuthInfographics}>
              <img alt="" src="/images/elephant-with-men.png" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
