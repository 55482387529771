import SvgIcon from "@mui/material/SvgIcon";

export default function AlertIcon(props) {
  return (
    <svg
      width="65"
      height="65"
      viewBox="0 0 65 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.2083 37.9167H29.7916V24.375H35.2083M35.2083 48.75H29.7916V43.3333H35.2083M2.70831 56.875H62.2916L32.5 5.41667L2.70831 56.875Z"
        fill="#F96363"
      />
    </svg>
  );
}
