import SvgIcon from '@mui/material/SvgIcon';

export default function SignalCellularIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2988 10.838C15.0297 8.90625 14.0911 3 12.2701 3C11.8321 3 11.4385 3.19772 11.1099 3.47115C10.4607 4.00524 9.55742 4.00524 8.90413 3.47115C8.57497 3.20192 8.18387 3 7.74583 3C5.92329 3 4.98402 8.90625 4.68678 10.838C5.89983 11.262 7.62068 11.6154 9.97982 11.6154C12.339 11.6154 14.113 11.262 15.2988 10.838ZM19.7981 9.58606C19.6064 9.44724 19.3538 9.46793 19.1817 9.6362C19.1504 9.66565 16.0122 12.691 10.0111 12.691C4.03817 12.691 0.868673 9.66245 0.837385 9.63317C0.665299 9.46911 0.408423 9.44781 0.220693 9.58663C0.0290526 9.72545 -0.0479791 9.98677 0.0302416 10.2183C0.0504538 10.2861 2.44779 17 9.97982 17C17.5118 17 19.9357 10.2861 19.9326 10.2188C20.0672 9.98654 19.9889 9.7274 19.7981 9.58606Z"
        fill="white"
      />
    </svg>
  );
}
