import React, { FC, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import Style from '@jibin/common/style/Styles';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import GoldIcon from '@jibin/common/icons/MedalIcon/GoldIcon.svg';
import SilverIcon from '@jibin/common/icons/MedalIcon/SilverIcon.svg';
import BronzeIcon from '@jibin/common/icons/MedalIcon/BronzeIcon.svg';
import PlatniumIcon from '@jibin/common/icons/MedalIcon/PlatniumIcon.svg';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';

const MenuItems = [
  { name: 'Services Review', class: '', route: PageRouteConstants.ServicesReview },
  { name: 'Engineering Consulting', class: '', route: PageRouteConstants.EngineeringConsulting }
];

export const CustomerSuccessTemplate: FC<{ children: React.ReactNode | React.ReactNode[] }> = (
  props
) => {
  const subscriptionPlan = useSelector(
    ({ subscriptionPlan }: any) => subscriptionPlan?.subscriptionPlan?.data,
    shallowEqual
  );

  useEffect(() => { }, [subscriptionPlan]);

  const navigate = useNavigate();

  const loadMedal = (type) => {
    switch (type) {
      case 'gold':
      case 'Gold':
        return <img width={'24px'} src={GoldIcon} />;

      case 'silver':
      case 'Silver':
        return <img width={'24px'} src={SilverIcon} />;
      case 'bronze':
      case 'Bronze':
        return <img width={'24px'} src={BronzeIcon} />;
      case 'platinum':
      case 'Platinum':
        return <img width={'24px'} src={PlatniumIcon} />;
    }
  };

  return (
    <>
      <Box sx={Style.Common.pageHead}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Box>
            <Typography variant="subtitle1" data-testid="lbl_onboard" sx={{ mb: 2 }}>
              Customer Success
            </Typography>
            <Typography variant="subtitle2"> Manage your cybersecurity services</Typography>
          </Box>
          {subscriptionPlan && (
            <Box
              sx={{
                display: location.pathname == PageRouteConstants.ServicesReview ? 'flex' : 'none',
                justifyContent: 'space-between',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 1
              }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {loadMedal(subscriptionPlan?.data?.plan_type)}
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  Subscription ({subscriptionPlan?.data?.plan_type})
                </Typography>

                {subscriptionPlan?.data?.status == 'active' ? (
                  <Box
                    sx={{
                      px: 1,
                      py: '5px',
                      background: 'rgba(16, 185, 129, 0.1)',
                      borderRadius: '100px'
                    }}>
                    <Typography variant="caption" sx={{ fontWeight: 500, color: '#27AE60' }}>
                      Active
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      px: 1,
                      py: '5px',
                      background: 'rgba(239, 68, 68, 0.1)',
                      borderRadius: '100px'
                    }}>
                    <Typography variant="caption" sx={{ fontWeight: 500, color: '#EF4444' }}>
                      Expired
                    </Typography>
                  </Box>
                )}
              </Box>
              <Typography variant="body2">
                {moment(subscriptionPlan?.data?.start_date).format('DD MMM YYYY').toUpperCase()} to{' '}
                {moment(subscriptionPlan?.data?.end_date).format('DD MMM YYYY').toUpperCase()}
              </Typography>
              {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' &&
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 600, color: '#436AF3', cursor: 'pointer' }}>
                  View Pricing
                </Typography>
              }
            </Box>
          )}
        </Box>
      </Box>

      <List sx={Style.CompanySetting.MenuList}>
        {MenuItems.map((currenElement, index) => (
          <ListItem disablePadding key={index} sx={{ width: 'auto' }}>
            <Link
              onClick={() => {
                navigate(currenElement.route);
              }}
              style={{ cursor: 'pointer' }}
              className={window.location.pathname === currenElement.route ? 'active' : ''}
              sx={Style.CompanySetting.MenuListItem}>
              {currenElement.name}
            </Link>
          </ListItem>
        ))}
      </List>
      {props.children}
    </>
  );
};

export default CustomerSuccessTemplate;
