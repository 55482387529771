import { Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CustomerSuccessApi } from '../CustomerSuccess.Api';
import subscriptionPlanSlice from 'src/store/reducers/subscriptionPlanReducer';
import ServiceReviewTable from '../ServicesReview/ServicesReviewTable/ServicesReviewTable';
import { ServiceReviewApi } from './ServiceReview.Api';
const ServicesReview: FC = () => {
  const dispatch = useDispatch();

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [services, setServices] = useState([]);
  const [skeleton, setSkeleton] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  const GetCompanyWebsites = useCallback(() => {
    CustomerSuccessApi.GetSubscriptionPlans(commonDetails.data.company.company_uuid).then(
      async (q: any) => {
        await dispatch(subscriptionPlanSlice.actions.setSubscriptionPlan(q));
      },
      (err: any) => { }
    );
  }, [commonDetails]);
  const GetAllServices = useCallback(() => {
    setSkeleton(true);
    ServiceReviewApi.GetAllServices(
      commonDetails.data.company.company_uuid,
      commonDetails.data.user_uuid
    ).then(
      (q: any) => {
        setServices(q.data.data);
        setSkeleton(false);
      },
      (err: any) => { }
    );
  }, [commonDetails]);

  useEffect(() => {
    GetCompanyWebsites();
    GetAllServices();
  }, [commonDetails,isRefresh]);

  const handleRefresh=()=>{
    setIsRefresh(!isRefresh);
  }

  return (
    <Box sx={{ px: 4, py: 2 }}>
      {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' &&
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
          <Typography variant="body2" sx={{ fontWeight: 600, color: '#436AF3', cursor: 'pointer' }}>
            See historical
          </Typography>
        </Box>
      }
      {skeleton ? (
        <Skeleton sx={{ height: '100px' }} />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {services.map((el, index) => (
            <ServiceReviewTable el={el} index={index} handleRefresh={()=> handleRefresh()} />
          ))}
        </Box>
      )}
    </Box>
  );
};
export default ServicesReview;
