import CalenderIcon from '@jibin/common/icons/CalenderIcon/CalenderIcon';
import StopWatchIcon from '@jibin/common/icons/StopWatchIcon/StopWatchIcon';
import BorderLinearRed from '@jibin/common/components/LinearLoading/BorderLinearRed';
import BorderLinearYellow from '@jibin/common/components/LinearLoading/BorderLinearYellow';
import { Accordion, AccordionSummary, Button, Skeleton, Typography } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { FC, useCallback, useEffect, useState } from 'react';
import BorderLinearProgress from '@jibin/common/components/LinearLoading/BorderLinearGreen';
import { EngineeringConsultingApi } from './EngineeringConsulting.Api';
import moment from 'moment';
import ExpandMoreIcon from '@jibin/common/icons/ExpandMore/ExpandMore';
import EngineeringDataList from './EngineeringDataList/EngineeringDataList';

const EngineeringConsulting: FC = () => {
  const [engineeringConsult, setEngineerConsilt] = useState({} as any);
  const [isSkeleton, setSkeleton] = useState(false);
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const GetCyberSecurityHour = useCallback(() => {
    EngineeringConsultingApi.GetHours(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setEngineerConsilt(q.data);
        setSkeleton(true);
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  useEffect(() => {
    GetCyberSecurityHour();
  }, [GetCyberSecurityHour]);

  return (
    <Box sx={{ px: 4, py: 2 }}>
      {isSkeleton ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {engineeringConsult?.data?.map((el, index) => (
            <>
              <EngineeringDataList el={el} index={index} />
            </>
          ))}
        </Box>
      ) : (
        <Skeleton height={'100px'} />
      )}
    </Box>
  );
};
export default EngineeringConsulting;
