import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';

import CheckIcon from '@jibin/common/icons/CheckIcon/CheckIcon';
import AlertIcon from '@jibin/common/icons/AlertIcon/AlertIcon';
import CalenderIcon from '@jibin/common/icons/CalenderIcon/CalenderIcon';
// import { Graph } from '@jibin/common/icons/Graph/Graph';
import { PieChart, Pie, Sector, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { shallowEqual, useSelector } from 'react-redux';
import { SecurityAwarenessApi } from './SecurityAwareness.Api';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

export const SecurityAwarness: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [trainingData, setTrainingData] = useState({} as any);
  const [phishingData, setPhishingData] = useState({} as any);
  const [isRefresh, setIsRefresh] = useState(false);
  const ref = useRef([]);
  const GetPhishing = useCallback(() => {
    setIsSkeleton(true);
    SecurityAwarenessApi.phishing(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setPhishingData(q.data);
        setIsSkeleton(false);
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  const GetTraining = useCallback(() => {
    SecurityAwarenessApi.training(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setTrainingData(q.data);
        // setChartData([])

        ref.current = [];
        ref.current.push({
          name: 'Completed',
          value: q.data.data.stat.completed,
          fill: 'rgba(16, 185, 129, 1)'
        });
        ref.current.push({
          name: 'In Progress',
          value: q.data.data.stat.in_progress,
          fill: 'rgba(253, 230, 138, 1)'
        });
        ref.current.push({
          name: 'Not Started',
          value: q.data.data.stat.not_started,
          fill: 'rgba(249, 99, 99, 1)'
        });
        // chartData.push({
        //   name: 'Passed',
        //   value: q.data.data.stat.passed,
        //   fill: 'rgba(75, 83, 227,1)'
        // });
        // chartData.push({
        //   name: 'Past Due',
        //   value: q.data.data.stat.past_due,
        //   fill: 'rgba(219, 113, 64,1)'
        // });
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  useEffect(() => {
    GetPhishing();

    GetTraining();
  }, [GetPhishing, GetTraining]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          backgroundColor: '#FFFFFF',
          p: 4,
          gap: 1
        }}>
        <Typography variant={'subtitle1'}>Security Awareness</Typography>
        <Typography variant={'subtitle2'}>
          Employees are the first line of defense against cyber threats
        </Typography>
      </Box>
      {isSkeleton ? (
        <Box sx={{ px: 4, py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : phishingData.data?.campaign.current.start_date == 'Not set' ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', m: 'auto', mt: 5 }}>
          <Box sx={{ textAlign: 'center' }}>
            <img width={'50%'} src="/images/NoDataFound.png" />
            <Typography sx={{ mt: '10px', textAlign: 'center', color: '#696969' }} variant="body2">
              Please provide your employee mailing list
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box sx={{ px: 4, py: 3, width: 1 }}>
          <Box sx={{ color: 'rgba(70, 70, 70, 1)', width: 1 }}>
            <Box>
              <Box
                sx={{
                  border: '1px solid #CCCCCC',
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderRadius: '8px',
                  p: 3,
                  gap: 1,
                  flexWrap: 'wrap',
                  backgroundColor: '#FFFFFF',
                  pr: 15
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1
                  }}>
                  <Typography sx={{ fontSize: '19px', fontWeight: 600 }}>
                    Awareness Campaign Calendar
                  </Typography>
                  <Typography variant="body2">
                    {' '}
                    Automate trainings and phishing campaigns
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1
                  }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CalenderIcon sx={{ color: '#436AF3' }} />
                    <Typography variant="body2">Current Campaign</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      <span style={{ fontWeight: 'bold' }}>
                        {phishingData.data?.campaign.current.name} -{' '}
                      </span>
                      {momentTimezone(phishingData.data?.campaign.current.start_date)
                        .tz('utc')
                        .format('DD MMM YYYY')
                        .toUpperCase()}{' '}
                      to{' '}
                      {momentTimezone(phishingData.data?.campaign.current.end_date)
                        .tz('utc')
                        .format('DD MMM YYYY')
                        .toUpperCase()}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1
                  }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CalenderIcon sx={{ color: '#9D9D9D' }} />
                    <Typography variant="body2">Next Campaign</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      <span style={{ fontWeight: 'bold' }}>
                        {phishingData.data?.campaign.next.name} -{' '}
                      </span>
                      {momentTimezone(phishingData.data?.campaign.next.start_date)
                        .tz('utc')
                        .format('DD MMM YYYY')
                        .toUpperCase()}{' '}
                      to{' '}
                      {momentTimezone(phishingData.data?.campaign.next.end_date)
                        .tz('utc')
                        .format('DD MMM YYYY')
                        .toUpperCase()}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: {
                    xs: 'repeat( 1,1fr)',
                    sm: 'repeat( 2,1fr)',
                    md: 'repeat( 3,1fr)'
                  },
                  width: 1,
                  gap: 3,
                  mt: 3
                }}>
                <Box
                  sx={{
                    border: '1px solid #CCCCCC',
                    width: 1,

                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px'
                  }}>
                  <Box sx={{ p: 3 }}>
                    <Box sx={{ mb: 4 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: 1
                        }}>
                        <Typography sx={{ fontWeight: 600 }}>
                          Security Awareness Training
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Typography variant="caption">
                          {momentTimezone(trainingData.data?.campaign.current.start_date)
                            .tz('utc')
                            .format('DD MMM YYYY')
                            .toUpperCase()}{' '}
                          to{' '}
                          {momentTimezone(trainingData.data?.campaign.current.end_date)
                            .tz('utc')
                            .format('DD MMM YYYY')
                            .toUpperCase()}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ mb: 1, width: 1, display: 'flex', justifyContent: 'center'}}>
                      <ResponsiveContainer className="container" height={250} width="100%">
                        <PieChart>
                          <text
                            fontWeight={700}
                            x="50%"
                            y="45%"
                            textAnchor="middle"
                            dominantBaseline="middle">
                            {Number.isInteger(
                              trainingData.data?.stat.completed +
                                trainingData.data?.stat.in_progress +
                                trainingData.data?.stat.not_started
                            )
                              ? trainingData.data?.stat.completed +
                                trainingData.data?.stat.in_progress +
                                trainingData.data?.stat.not_started
                              : 0}
                          </text>
                          <text x="50%" y="55%" textAnchor="middle" dominantBaseline="middle">
                            Employees
                          </text>
                          <Pie
                            data={ref.current}
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            isAnimationActive={false}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                            label>
                            {ref.current.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.fill} />
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: 0.9,
                        m: 'auto'
                      }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box
                          sx={{
                            width: '14px',
                            height: '14px',
                            backgroundColor: 'rgba(16, 185, 129, 1)',
                            borderRadius: '50%'
                          }}></Box>
                        <Typography sx={{ fontSize: '14px' }}>Completed</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box
                          sx={{
                            width: '14px',
                            height: '14px',
                            backgroundColor: 'rgba(253, 230, 138, 1)',
                            borderRadius: '50%'
                          }}></Box>
                        <Typography sx={{ fontSize: '14px' }}>In Progress</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box
                          sx={{
                            width: '14px',
                            height: '14px',
                            backgroundColor: 'rgba(249, 99, 99, 1)',
                            borderRadius: '50%'
                          }}></Box>
                        <Typography sx={{ fontSize: '14px' }}>Not Started</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: 1,
                    display: 'flex',
                    gap: 2,
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}>
                  <Box
                    sx={{
                      border: '1px solid #CCCCCC',
                      width: 1,

                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px'
                    }}>
                    <Box sx={{ p: 3 }}>
                      <Box sx={{ mb: 4 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 1
                          }}>
                          <Typography sx={{ fontWeight: 600 }}>
                            Failed in Phishing Campaign
                          </Typography>
                          {/* moreIcon */}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                          <Typography variant="caption">
                            {momentTimezone(phishingData.data?.campaign.current.start_date)
                              .tz('utc')
                              .format('DD MMM YYYY')
                              .toUpperCase()}{' '}
                            to{' '}
                            {momentTimezone(phishingData.data?.campaign.current.end_date)
                              .tz('utc')
                              .format('DD MMM YYYY')
                              .toUpperCase()}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: 3
                          }}>
                          <Typography sx={{ fontSize: '40px', fontWeight: 600 }}>
                            {phishingData.data?.stat.failed ? phishingData.data?.stat.failed : 0}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: 600, color: 'rgba(105, 105, 105, 1)' }}
                            variant="body2">
                            {' '}
                            <span style={{ color: ' #F96363' }}>
                              {phishingData.data?.stat.failed
                                ? Math.round(
                                    ((phishingData.data?.stat.failed /
                                      phishingData.data?.stat.total_participant) *
                                      100 +
                                      Number.EPSILON) *
                                      100
                                  ) / 100
                                : 0}
                              %{' '}
                            </span>{' '}
                            of Participants
                          </Typography>
                        </Box>
                        <Box>
                          <AlertIcon />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      border: '1px solid #CCCCCC',
                      width: 1,
                      backgroundColor: '#FFFFFF',
                      height: 1 / 2,
                      borderRadius: '8px'
                    }}>
                    <Box sx={{ p: 3 }}>
                      <Box sx={{ mb: 4 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 1
                          }}>
                          <Typography sx={{ fontWeight: 600 }}>
                            Passed in Phishing Campaign
                          </Typography>
                          {/* moreIcon */}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                          <Typography variant="caption">
                            {momentTimezone(phishingData.data?.campaign.current.start_date)
                              .tz('utc')
                              .format('DD MMM YYYY')
                              .toUpperCase()}{' '}
                            to{' '}
                            {momentTimezone(phishingData.data?.campaign.current.end_date)
                              .tz('utc')
                              .format('DD MMM YYYY')
                              .toUpperCase()}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: 3
                          }}>
                          <Typography sx={{ fontSize: '40px', fontWeight: 600 }}>
                            {phishingData.data?.stat.passed ? phishingData.data?.stat.passed : 0}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: 600, color: 'rgba(105, 105, 105, 1)' }}
                            variant="body2">
                            <span style={{ color: '#059669' }}>
                              {phishingData.data?.stat.passed
                                ? Math.round(
                                    ((phishingData.data?.stat.passed /
                                      phishingData.data?.stat.total_participant) *
                                      100 +
                                      Number.EPSILON) *
                                      100
                                  ) / 100
                                : 0}
                              %{' '}
                            </span>
                            of Participants
                          </Typography>
                        </Box>
                        <Box>
                          <CheckIcon />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    border: '1px solid #CCCCCC',
                    width: 1,
                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px'
                  }}>
                  <Box sx={{ p: 3 }}>
                    <Box sx={{ mb: 4 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: 1
                        }}>
                        <Typography sx={{ fontWeight: 600 }}>Top 10 Clickers</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Typography variant="caption">
                          {momentTimezone(phishingData.data?.campaign.current.start_date)
                            .tz('utc')
                            .format('DD MMM YYYY')
                            .toUpperCase()}{' '}
                          to{' '}
                          {momentTimezone(phishingData.data?.campaign.current.end_date)
                            .tz('utc')
                            .format('DD MMM YYYY')
                            .toUpperCase()}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        maxHeight: '275px',
                        p: 1,
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        '&::-webkit-scrollbar': {
                          width: '6px',
                          height: '0.2em'
                        },
                        '&::-webkit-scrollbar-track': {
                          borderRadius: '10px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: 'rgba(0,0,0,.1)',

                          borderRadius: '10px'
                        }
                      }}>
                      {phishingData.data?.top_clickers ? (
                        phishingData.data?.top_clickers.map((el, index) => (
                          <Box
                            key={index + 1}
                            sx={{
                              border: '1px solid #EAECF0',
                              borderRadius: '8px',
                              width: 1,
                              mb: 1
                            }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                p: 1
                              }}>
                              <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
                                {el.full_name}
                              </Typography>
                              <Typography sx={{ fontSize: '14px' }}>{el.email}</Typography>
                            </Box>
                          </Box>
                        ))
                      ) : (
                        <Box
                          key={1}
                          sx={{
                            border: '1px solid #EAECF0',
                            borderRadius: '8px',
                            width: 1,
                            mb: 1
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              p: 1
                            }}>
                            <Typography sx={{ fontWeight: 600, fontSize: '14px', width: '50%' }}>
                              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            </Typography>
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
