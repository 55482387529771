import HttpService from '@jibin/common/services/http/HttpService';

export const DashboardApi = {  
  // Remediations(userId: string, companyId: string) {
  //   //return HttpService.getAll("todos/1");
  //   return HttpService.getAll(`companies/${companyId}/users/${userId}/remediations?top=10`);
  // },
  VulnerabilityStats(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/vulnerabilities/stats`);
  },
  training(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/training`);
  },
  phishing(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/phishing`);
  },
  policystats(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");    
    return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/policies/stats`);
  },
  frameworkStats(userId: string, companyId: string) {    
    return HttpService.getAll(`companies/${companyId}/users/${userId}/frameworks/stats`);
  },  
  emailProtectionStats(userId: string, companyId: string) {    
    return HttpService.getAll(`companies/${companyId}/users/${userId}/email-protections/stats?period=30`);
  },
}