import HttpService from '@jibin/common/services/http/HttpService';

export const CustomerSuccessApi = {
  // User section
  // PenetrationTests(userId: string, companyId: string) {
  //   //return HttpService.getAll("todos/1");
  //   return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/penetration/tests`);
  // },
  GetSubscriptionPlans(companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/subscriptions/plan`);
  },
  

}