import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Grid, Skeleton, SwipeableDrawer, Typography } from '@mui/material';

import CheckIcon from '@jibin/common/icons/CheckIcon/CheckIcon';
import AlertIcon from '@jibin/common/icons/AlertIcon/AlertIcon';
import ProfileIcon from '@jibin/common/icons/ProfileIcon/ProfileIcon';
import CalenderIcon from '@jibin/common/icons/CalenderIcon/CalenderIcon';
import ChartHalf from '@jibin/common/icons/ChartHalf/ChartHalf';
import { Graph } from '@jibin/common/icons/Graph/Graph';
import { shallowEqual, useSelector } from 'react-redux';
import { DashboardApi } from './Dashboard.Api';
import RemidiationPopUpOld from '@jibin/common/components/RemidiationPopUp-old/index';
import { PieChart, Pie, Sector, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { useNavigate } from 'react-router-dom';
import BorderLinearProgress from '@jibin/common/components/LinearLoading/BorderLinearGreen';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import RemidiationPopUpList from '@jibin/common/components/RemidiationPopUp/RemidiationPopupList';
import { TopRemediations } from '../Common/TopRemediations/TopRemediations';
import UpGuardChart from '@jibin/common/components/UpGaurdChart';
export const DashBoard: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const navigate = useNavigate();
  const [isRefresh, setIsRefresh] = useState(false);

  const [vulnerabilityStats, setVulnerabilityStats] = useState({} as any);
  const [rowData, setRowData] = useState({} as any);
  const [state, setState] = useState({
    right: false
  });
  type Anchor = 'top' | 'left' | 'bottom' | 'right';
  const toggleDrawer = (anchor: Anchor, open: boolean, row: any) => {
    setRowData(row);
    setState({ ...state, [anchor]: open });
  };

  const GetVulnerabilityStats = useCallback(() => {
    DashboardApi.VulnerabilityStats(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setVulnerabilityStats(q.data);
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  const momentTimezone = require('moment-timezone');

  const [chartData, setChartData] = useState([]);
  const [frameworkChartData, setFrameworkChartData] = useState([]);
  const [trainingData, setTrainingData] = useState({} as any);
  const [emailProtectionStats, setEmailProtectionStats] = useState({} as any);
  const [phishingData, setPhishingData] = useState({} as any);
  const [policystat, setPolicystat] = useState({} as any);
  const [frameworkstat, setFrameworkstat] = useState([] as any);
  const ref = useRef([]);
  const emailProtectionRef = useRef([]);
  const GetPhishing = useCallback(() => {
    DashboardApi.phishing(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setPhishingData(q.data);
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  const GetTraining = useCallback(() => {
    DashboardApi.training(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setTrainingData(q.data);

        ref.current = [];
        ref.current.push({
          name: 'Completed',
          value: q.data.data.stat.completed,
          fill: 'rgba(16, 185, 129, 1)'
        });
        ref.current.push({
          name: 'In Progress',
          value: q.data.data.stat.in_progress,
          fill: 'rgba(253, 230, 138, 1)'
        });
        ref.current.push({
          name: 'Not Started',
          value: q.data.data.stat.not_started,
          fill: 'rgba(249, 99, 99, 1)'
        });
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  const GetEmailStats = useCallback(() => {
    DashboardApi.emailProtectionStats(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setEmailProtectionStats(q.data);

        emailProtectionRef.current = [];

        q.data.data.map((c) => {
          let chartArc = { name: c.title, value: c.count, fill: '' };
          switch (c.type) {
            case 'SPAM':
              chartArc.fill = 'rgba(253, 230, 138, 1)';
              break;
            case 'FRAUD':
              chartArc.fill = 'rgba(183, 183, 183, 1)';
              break;
            case 'MALWARE':
              chartArc.fill = 'rgba(245, 158, 11, 1)';
              break;
            case 'CLEAN':
              chartArc.fill = 'rgba(39, 174, 96, 1)';
              break;
            case 'BLOCKED':
              chartArc.fill = 'rgba(248, 113, 113, 1)';
              break;
            default:
              chartArc.fill = 'rgba(39, 174, 96, 1)';
              break;
          }
          emailProtectionRef.current.push(chartArc);
        });
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  const GetPolicyStat = useCallback(() => {
    DashboardApi.policystats(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setPolicystat(q.data);
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  const GetframeworkStats = useCallback(() => {
    DashboardApi.frameworkStats(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        if (q?.data) setFrameworkstat(q?.data?.data);
      },

      (err: any) => {}
    );
  }, [commonDetails]);

  useEffect(() => {
    GetVulnerabilityStats();
    GetPhishing();
    GetTraining();
    GetPolicyStat();
    GetframeworkStats();
    // GetEmailStats();
  }, [
    GetVulnerabilityStats,
    GetPhishing,
    GetTraining,
    GetPolicyStat,
    GetframeworkStats
    // GetEmailStats
  ]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          backgroundColor: '#FFFFFF',
          p: 4,
          gap: 1
        }}>
        <Typography variant={'subtitle1'}>Dashboard</Typography>
        <Typography variant={'subtitle2'}>Real-time view of your cybersecurity program</Typography>
      </Box>
      <Box sx={{ p: 4, backgroundColor: '#FBFBFF', width: 1 }}>
        <Box sx={{ color: 'rgba(70, 70, 70, 1)', width: 1 }}>
          <Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  xs: 'repeat( 1,1fr)',
                  sm: 'repeat( 2,1fr)',
                  md: 'repeat( 3,1fr)'
                },
                width: 1,
                gap: 4
              }}>
              <Box
                sx={{
                  border: '1px solid #CCCCCC',
                  width: 1,

                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px'
                }}>
                <Box sx={{ py: 2, px: 3 }}>
                  <Box sx={{ mb: 1 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          alignItems: 'center'
                        }}>
                        <Typography sx={{ fontWeight: 500, lineHeight: '24px' }}>
                          Gap Analysis Progress
                        </Typography>
                      </Box>
                      <Typography
                        onClick={() => navigate(PageRouteConstants.GapAnalysis)}
                        sx={{ fontWeight: 600, color: ' #436AF3', cursor: 'pointer' }}>
                        See all
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ mb: 2, width: 1, display: 'flex', justifyContent: 'center' }}>
                    <ResponsiveContainer className="container" height={90} width="100%">
                      <Box>
                        {frameworkstat &&
                          frameworkstat.map((el: any, index: any) => (
                            <Box key={index + 1} sx={{ mb: 1 }}>
                              <Typography variant="body2">{el.name}</Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1.5
                                }}>
                                <Box
                                  sx={{
                                    width: 0.75
                                  }}>
                                  <BorderLinearProgress
                                    variant="determinate"
                                    value={(el.completed / el?.total) * 100}
                                    sx={{
                                      [`.${linearProgressClasses.bar}`]: {
                                        borderRadius: 5,
                                        backgroundColor:
                                          (el.completed / el?.total) * 100 >= 1 &&
                                          (el.completed / el?.total) * 100 <= 20
                                            ? '#EF4444'
                                            : (el.completed / el?.total) * 100 >= 21 &&
                                              (el.completed / el?.total) * 100 <= 79
                                            ? '#F59E0B'
                                            : '#A7F3D0'
                                      }
                                    }}
                                  />
                                </Box>

                                <Typography variant="caption" sx={{ color: '#696969' }}>
                                  {Math.round((el.completed / el?.total) * 100)}% <span> </span>
                                  {`(${el.completed}/${el?.total})`}
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                      </Box>
                    </ResponsiveContainer>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  border: '1px solid #CCCCCC',
                  width: 1,

                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px'
                }}>
                <Box sx={{ py: 2, px: 3 }}>
                  <Box sx={{ mb: 4 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 1
                      }}>
                      <Typography sx={{ fontWeight: 600 }}>Failed in Phishing Campaign</Typography>

                      {/* moreIcon */}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography variant="caption">
                        {momentTimezone(phishingData.data?.campaign.current.start_date)
                          .tz('utc')
                          .format('DD MMM YYYY')
                          .toUpperCase()}{' '}
                        to{' '}
                        {momentTimezone(phishingData.data?.campaign.current.end_date)
                          .tz('utc')
                          .format('DD MMM YYYY')
                          .toUpperCase()}{' '}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: 3
                      }}>
                      <Typography sx={{ fontSize: '40px', fontWeight: 600 }}>
                        {phishingData.data?.stat.failed ? phishingData.data?.stat.failed : 0}
                      </Typography>
                      <Typography sx={{ fontWeight: 600 }} variant="body2">
                        {' '}
                        <span style={{ color: ' #F96363' }}>
                          {phishingData.data?.stat.failed
                            ? Math.round(
                                ((phishingData.data?.stat.failed /
                                  phishingData.data?.stat?.total_participant) *
                                  100 +
                                  Number.EPSILON) *
                                  100
                              ) / 100
                            : 0}
                          %{' '}
                        </span>{' '}
                        of Participants
                      </Typography>
                    </Box>
                    <Box>
                      <AlertIcon />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  border: '1px solid #CCCCCC',
                  width: 1,
                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px'
                }}>
                <Box sx={{ py: 2, px: 3 }}>
                  <Box sx={{ mb: 4 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 1
                      }}>
                      <Typography sx={{ fontWeight: 500 }}>Policies & Procedures</Typography>
                      <Typography
                        onClick={() => navigate(PageRouteConstants.PoliciesAndProcedures)}
                        sx={{ fontWeight: 600, color: ' #436AF3', cursor: 'pointer' }}>
                        See all
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', mx: 'auto', justifyContent: 'space-around', mt: 6 }}>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: '28px',
                          fontWeight: 600,
                          lineHeight: '34px',
                          textAlign: 'center'
                        }}>
                        {policystat.data?.approved ? policystat.data?.approved : 0}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 600, color: '#059669' }}>
                        Approved
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: '28px',
                          fontWeight: 600,
                          lineHeight: '34px',
                          textAlign: 'center'
                        }}>
                        {policystat.data?.submitted ? policystat.data?.submitted : 0}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 600, color: '#FBBF24' }}>
                        Submitted
                      </Typography>
                    </Box>

                    <Box>
                      <Typography
                        sx={{
                          fontSize: '28px',
                          fontWeight: 600,
                          lineHeight: '34px',
                          textAlign: 'center'
                        }}>
                        {policystat.data?.rejected ? policystat.data?.rejected : 0}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 600, color: '#F96363' }}>
                        Rejected
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  xs: 'repeat( 1,1fr)',
                  sm: 'repeat( 2,1fr)',
                  md: 'repeat( 3,1fr)'
                },
                width: 1,
                gap: 4,
                mt: 5
              }}>
              <Box
                sx={{
                  border: '1px solid #CCCCCC',
                  width: 1,

                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px'
                }}>
                <Box sx={{ p: 3 }}>
                  <Box sx={{ mb: 4 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 1
                      }}>
                      <Typography sx={{ fontWeight: 600 }}>Security Awareness Training</Typography>
                      <Typography
                        onClick={() => navigate(PageRouteConstants.SecurityAwareness)}
                        sx={{ fontWeight: 600, color: ' #436AF3', cursor: 'pointer' }}>
                        See all
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography variant="caption">
                        {momentTimezone(trainingData.data?.campaign.current.start_date)
                          .tz('utc')
                          .format('DD MMM YYYY')
                          .toUpperCase()}{' '}
                        to{' '}
                        {momentTimezone(trainingData.data?.campaign.current.end_date)
                          .tz('utc')
                          .format('DD MMM YYYY')
                          .toUpperCase()}{' '}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ mb: 1, width: 1, display: 'flex', justifyContent: 'center' }}>
                    <ResponsiveContainer className="container" height={250} width="100%">
                      <PieChart>
                        <text
                          fontWeight={700}
                          x="50%"
                          y="50%"
                          textAnchor="middle"
                          dominantBaseline="middle">
                          {Number.isInteger(
                            trainingData.data?.stat.completed +
                              trainingData.data?.stat.in_progress +
                              trainingData.data?.stat.not_started
                          )
                            ? trainingData.data?.stat.completed +
                              trainingData.data?.stat.in_progress +
                              trainingData.data?.stat.not_started
                            : 0}
                        </text>
                        <text x="50%" y="55%" textAnchor="middle" dominantBaseline="middle">
                          Employees
                        </text>
                        <Pie
                          data={ref.current}
                          cx="50%"
                          cy="50%"
                          innerRadius={60}
                          outerRadius={80}
                          isAnimationActive={false}
                          fill="#8884d8"
                          paddingAngle={5}
                          dataKey="value"
                          label>
                          {ref.current.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.fill} />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: 0.9,
                      m: 'auto'
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '14px',
                          height: '14px',
                          backgroundColor: 'rgba(16, 185, 129, 1)',
                          borderRadius: '50%'
                        }}></Box>
                      <Typography sx={{ fontSize: '14px' }}>Completed</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '14px',
                          height: '14px',
                          backgroundColor: 'rgba(253, 230, 138, 1)',
                          borderRadius: '50%'
                        }}></Box>
                      <Typography sx={{ fontSize: '14px' }}>In Progress</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '14px',
                          height: '14px',
                          backgroundColor: 'rgba(249, 99, 99, 1)',
                          borderRadius: '50%'
                        }}></Box>
                      <Typography sx={{ fontSize: '14px' }}>Not Started</Typography>
                    </Box>
                    {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '14px',
                          height: '14px',
                          backgroundColor: 'rgba(75, 83, 227,1)',
                          borderRadius: '50%'
                        }}></Box>
                      <Typography sx={{ fontSize: '14px' }}>Passed</Typography>
                    </Box> */}
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  border: '1px solid #CCCCCC',
                  width: 1,

                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px'
                }}>
                <Box sx={{ p: 3 }}>
                  <Box sx={{ mb: 4 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 1
                      }}>
                      <Typography sx={{ fontWeight: 600 }}>
                        Vulnerabilities by Criticality
                      </Typography>
                      <Typography
                        onClick={() => navigate(PageRouteConstants.Vulnerability)}
                        sx={{ fontWeight: 600, color: ' #436AF3', cursor: 'pointer' }}>
                        See all
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography variant="caption">
                        From{' '}
                        {vulnerabilityStats.data?.count?.total
                          ? vulnerabilityStats.data?.count?.total
                          : 0}{' '}
                        Vulnerabilities
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        border: '1px solid #EAECF0',
                        borderRadius: '8px',
                        p: '14px'
                      }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box
                          sx={{
                            width: '16px',
                            height: '16px',
                            backgroundColor: '#F87171',
                            borderRadius: '50%'
                          }}></Box>
                        <Typography variant={'body1'} sx={{ fontWeight: 600, color: '#696969' }}>
                          Critical
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                          {vulnerabilityStats.data?.count?.critical
                            ? vulnerabilityStats.data?.count?.critical
                            : 0}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        border: '1px solid #EAECF0',
                        borderRadius: '8px',
                        p: '14px'
                      }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box
                          sx={{
                            width: '16px',
                            height: '16px',
                            backgroundColor: '#F59E0B',
                            borderRadius: '50%'
                          }}></Box>
                        <Typography variant={'body1'} sx={{ fontWeight: 600, color: '#696969' }}>
                          High
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                          {vulnerabilityStats.data?.count?.severe
                            ? vulnerabilityStats.data?.count?.severe
                            : 0}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        border: '1px solid #EAECF0',
                        borderRadius: '8px',
                        p: '14px'
                      }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box
                          sx={{
                            width: '16px',
                            height: '16px',
                            backgroundColor: '#FCD34D',
                            borderRadius: '50%'
                          }}></Box>
                        <Typography variant={'body1'} sx={{ fontWeight: 600, color: '#696969' }}>
                          Medium
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                          {vulnerabilityStats.data?.count?.moderate
                            ? vulnerabilityStats.data?.count?.moderate
                            : 0}
                        </Typography>
                      </Box>
                    </Box>
                    {/* <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        border: '1px solid #EAECF0',
                        borderRadius: '8px',
                        p: '14px'
                      }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box
                          sx={{
                            width: '16px',
                            height: '16px',
                            backgroundColor: '#B3DAFF',
                            borderRadius: '50%'
                          }}></Box>
                        <Typography variant={'body1'} sx={{ fontWeight: 600, color: '#696969' }}>
                          Low
                        </Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                          0
                        </Typography>
                      </Box>
                    </Box> */}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  border: '1px solid #CCCCCC',
                  width: 1,
                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px'
                }}>
                <TopRemediations size={10} source="" title="Top Remediation Tasks" />
              </Box>
            </Box>
            {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' && (
              <Grid
                container
                spacing={4}
                sx={{
                  mt: 5
                }}>
                <Grid item xs={12} md={4}>
                  <Box
                    sx={{
                      border: '1px solid #CCCCCC',
                      width: 1,
                      height: 1,
                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px'
                    }}>
                    <Box sx={{ p: 3 }}>
                      <Box sx={{ mb: 4 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 1
                          }}>
                          <Typography sx={{ fontWeight: 600 }}>Email Threat Overview</Typography>
                          <Typography
                            onClick={() => navigate(PageRouteConstants.EmailProtection)}
                            sx={{ fontWeight: 600, color: ' #436AF3', cursor: 'pointer' }}>
                            See all
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                          <Typography variant="caption">Last 30 Days </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ mb: 1, width: 1, display: 'flex', justifyContent: 'center' }}>
                        <ResponsiveContainer className="container" height={250} width="100%">
                          <PieChart>
                            <text
                              fontWeight={700}
                              x="50%"
                              y="50%"
                              textAnchor="middle"
                              dominantBaseline="middle">
                              {emailProtectionStats?.data?.reduce(
                                (a, b) => a + (Number.isInteger(b.count) ? b.count : 0),
                                0
                              )}
                            </text>
                            <text x="50%" y="55%" textAnchor="middle" dominantBaseline="middle">
                              Total
                            </text>
                            <Pie
                              data={emailProtectionRef.current}
                              cx="50%"
                              cy="50%"
                              innerRadius={60}
                              outerRadius={80}
                              isAnimationActive={false}
                              fill="#8884d8"
                              paddingAngle={5}
                              dataKey="value"
                              label>
                              {emailProtectionRef.current.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.fill} />
                              ))}
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: 0.9,
                          m: 'auto'
                        }}>
                        {emailProtectionRef.current.map(
                          (entry, index) =>
                            index < 3 && (
                              <Box
                                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                                key={entry.name}>
                                <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: entry.fill,
                                    borderRadius: '50%'
                                  }}></Box>
                                <Typography sx={{ fontSize: '14px' }}>{entry.name}</Typography>
                              </Box>
                            )
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          gap: 2,
                          pt: 1,
                          width: 0.9,
                          m: 'auto'
                        }}>
                        {emailProtectionRef.current.map(
                          (entry, index) =>
                            index >= 3 && (
                              <Box
                                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                                key={entry.name}>
                                <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: entry.fill,
                                    borderRadius: '50%'
                                  }}></Box>
                                <Typography sx={{ fontSize: '14px' }}>{entry.name}</Typography>
                              </Box>
                            )
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <UpGuardChart />
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
