const LinkIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12588_112228)">
        <path
          d="M6.66602 8.66672C6.95232 9.04948 7.31759 9.36618 7.73705 9.59535C8.15651 9.82452 8.62035 9.9608 9.09712 9.99495C9.57388 10.0291 10.0524 9.9603 10.5002 9.79325C10.9481 9.62619 11.3548 9.36477 11.6927 9.02672L13.6927 7.02672C14.2999 6.39805 14.6359 5.55604 14.6283 4.68205C14.6207 3.80806 14.2701 2.97202 13.6521 2.354C13.0341 1.73597 12.198 1.38541 11.324 1.37781C10.45 1.37022 9.60802 1.7062 8.97935 2.31339L7.83268 3.45339"
          stroke="#436AF3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33347 7.33332C9.04716 6.95057 8.68189 6.63387 8.26243 6.40469C7.84297 6.17552 7.37913 6.03924 6.90237 6.0051C6.4256 5.97095 5.94707 6.03974 5.49924 6.2068C5.0514 6.37386 4.64472 6.63527 4.3068 6.97332L2.3068 8.97332C1.69961 9.60199 1.36363 10.444 1.37122 11.318C1.37881 12.192 1.72938 13.028 2.3474 13.646C2.96543 14.2641 3.80147 14.6146 4.67546 14.6222C5.54945 14.6298 6.39146 14.2938 7.02013 13.6867L8.16013 12.5467"
          stroke="#436AF3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12588_112228">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default LinkIcon