import SvgIcon from '@mui/material/SvgIcon';

export default function SpaceDashboardIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10809_31583)">
        <path
          d="M8.33341 16.6667C8.33341 17.5871 9.07961 18.3333 10.0001 18.3333C10.9206 18.3333 11.6667 17.5871 11.6667 16.6667C11.6667 15.7462 10.9206 15 10.0001 15C9.07961 15 8.33341 15.7462 8.33341 16.6667Z"
          stroke="white"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 17C15 18.1046 15.8954 19 17 19C18.1046 19 19 18.1046 19 17C19 15.8954 18.1046 15 17 15C15.8954 15 15 15.8954 15 17Z"
          stroke="white"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 17C1 18.1046 1.89543 19 3 19C4.10457 19 5 18.1046 5 17C5 15.8954 4.10457 15 3 15C1.89543 15 1 15.8954 1 17Z"
          stroke="white"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 3.33337C7.5 4.71409 8.61929 5.83337 10 5.83337C11.3807 5.83337 12.5 4.71409 12.5 3.33337C12.5 1.95266 11.3807 0.833374 10 0.833374C8.61929 0.833374 7.5 1.95266 7.5 3.33337Z"
          stroke="white"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 5.83337L10 15"
          stroke="white"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 15L17 11.5714C17 9.85714 16.125 9 14.375 9L5.625 9C3.875 9 3 9.85714 3 11.5714L3 15"
          stroke="white"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10809_31583">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
