import BorderLinearProgress from '@jibin/common/components/LinearLoading/BorderLinearGreen';
import BorderLinearRed from '@jibin/common/components/LinearLoading/BorderLinearRed';
import BorderLinearYellow from '@jibin/common/components/LinearLoading/BorderLinearYellow';
import CalenderIcon from '@jibin/common/icons/CalenderIcon/CalenderIcon';
import CheckCircleIcon from '@jibin/common/icons/CheckCircle/CheckCircle';
import ExpandMoreIcon from '@jibin/common/icons/ExpandMore/ExpandMore';
import PdfIcon from '@jibin/common/icons/PdfIcon/PdfIcon';
import StopWatchIcon from '@jibin/common/icons/StopWatchIcon/StopWatchIcon';
import { Style } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import moment from 'moment';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { EngineeringConsultingApi } from '../EngineeringConsulting.Api';
const momentTimezone = require('moment-timezone');
const EngineeringDataList: FC<{ el: any; index: number }> = (prop) => {
  const { el, index } = prop;
  const [expand, setExpand] = useState(false);
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };
  const [consumedHour, setConsumedHour] = useState([]);
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const totalConsumedHour = (data) => {
    let finalduration = moment.duration();
    data.forEach((element) => {
      let duration = moment.duration(
        'PT' +
          element.time_consumed
            .replace(/hr/gi, 'h') // replace "hr" with "h"
            .replace(/\s/g, '') // remove the spaces
            .toUpperCase()
      );
      finalduration.add(duration);
    });

    const hours = Math.floor(finalduration.asHours());
    const minutes = finalduration.minutes();

    if (hours == 0) {
      return `${minutes} min`;
    } else {
      return `${hours} hr ${minutes} min`;
    }
  };

  const formateTime = (data: any) => {
    let duration = moment.duration('PT' + data.toUpperCase());

    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    if (hours == 0) {
      return `${minutes} min`;
    } else {
      return `${hours} hr ${minutes} min`;
    }
  };
  const GetConsumedHour = useCallback(async () => {
    const res = await EngineeringConsultingApi.GetConsumedHours(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    );
    setConsumedHour(res.data.data);
  }, [commonDetails]);
  useEffect(() => {
    GetConsumedHour();
  }, [GetConsumedHour]);
  const renderBar = (bar: any) => {
    let percent = (bar.hours_consumed / bar.hours_total) * 100;

    if (percent < 40) {
      //green
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: 1,
            gap: 1
          }}>
          <Box
            sx={{
              width: 0.7,
              p: 0.5,
              borderRadius: '10px',
              border: '0.8px solid #B3DAFF',
              backgroundColor: '#F2F9FF'
            }}>
            <BorderLinearProgress
              variant="determinate"
              value={percent}
              sx={{
                '.MuiLinearProgress-bar': {
                  borderRadius: 5,
                  backgroundColor: '#059669'
                }
              }}
            />
          </Box>
          <Typography variant="body2">{`${bar.hours_consumed}/${bar.hours_total}`} hr</Typography>
        </Box>
      );
    } else if (percent < 60) {
      //yellow
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: 1,
            gap: 1
          }}>
          <Box
            sx={{
              width: 0.7,

              p: 0.5,
              borderRadius: '10px',
              border: '0.8px solid #B3DAFF',
              backgroundColor: '#F2F9FF'
            }}>
            <BorderLinearYellow
              variant="determinate"
              value={(bar.hours_consumed / bar.hours_total) * 100}
            />
          </Box>
          <Typography variant="body2">{`${bar.hours_consumed}/${bar.hours_total}`} hr</Typography>
        </Box>
      );
    } else {
      //red
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: 1,
            gap: 1
          }}>
          <Box
            sx={{
              width: 0.7,

              p: 0.5,
              borderRadius: '10px',
              border: '0.8px solid #B3DAFF',
              backgroundColor: '#F2F9FF'
            }}>
            <BorderLinearRed
              variant="determinate"
              value={(bar.hours_consumed / bar.hours_total) * 100}
            />
          </Box>
          <Typography variant="body2">{`${bar.hours_consumed}/${bar.hours_total} hr`}</Typography>
        </Box>
      );
    }
  };
  return (
    <Box>
      <Accordion
        expanded={expand}
        sx={{
          borderRadius: '8px',

          borderTopLeftRadius: '8px',
          '&.MuiPaper-root': {
            borderRadius: '8px',
            border: '1px solid #CCCCCC',
            boxShadow: 'none'
          },

          '&.MuiPaper-root:before': {
            display: 'none'
          }
        }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={toggleAcordion} />}
          aria-controls={`panel${index + 1}a-content`}
          sx={{
            p: 3,
            '.MuiAccordionSummary-content': {
              m: 0
            },
            '.MuiAccordionSummary-content.Mui-expanded': {
              my: 0.5
            }
          }}
          id={`panel${index + 1}a-content`}>
          <Box
            key={index + 1}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: '#FFFFFF',

              width: '100%',
              borderRadius: 2,
              mr: 1
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                minWidth: '25%',
                width: '25%',
                mr: 4,
                gap: 1
              }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                Engineering Consulting
              </Typography>

              <Button
                onClick={toggleAcordion}
                sx={{ fontWeight: 500, fontSize: '14px', p: '0px', color: '#436AF3' }}>
                Track consumed hours
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                minWidth: '25%',
                width: '25%',
                gap: 1
              }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 1
                }}>
                <CalenderIcon sx={{ color: '#436AF3' }} />
                <Typography variant="body2" sx={{ color: ' #464646' }}>
                  Subscription
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" sx={{ color: ' #464646' }}>
                  <span>{moment(el.from).format('DD MMM YYYY').toUpperCase()} </span> to{' '}
                  <span>{moment(el.to).format('DD MMM YYYY').toUpperCase()} </span>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                minWidth: '20%',
                width: '20%',
                gap: 1
              }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 0.5
                }}>
                <StopWatchIcon />
                <Typography variant="body2" sx={{ color: ' #464646' }}>
                  Time consumed
                </Typography>
              </Box>

              {renderBar(el)}
            </Box>
            <Box
              sx={{
                display: 'flex',

                alignItems: 'center',

                gap: 1
              }}>
              {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' && (
                <Button
                  sx={{
                    px: 3,
                    py: 0.5,
                    fontSize: '14px',
                    backgroundColor: '#436AF3',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#436AF3'
                    }
                  }}>
                  Buy Hours
                </Button>
              )}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Divider />
          <TableContainer component={Paper} sx={{ maxHeight: '400px' }}>
            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ py: 1, backgroundColor: '#FBFBFF' }} align="left">
                    Ticket Number
                  </TableCell>
                  <TableCell sx={{ py: 1, backgroundColor: '#FBFBFF' }} align="left">
                    Type of Work
                  </TableCell>
                  <TableCell sx={{ py: 1, backgroundColor: '#FBFBFF' }} align="left">
                    Assigned to
                  </TableCell>
                  <TableCell sx={{ py: 1, backgroundColor: '#FBFBFF' }} align="left">
                    Closed Date
                  </TableCell>
                  <TableCell
                    sx={{
                      py: 1,
                      backgroundColor: '#FBFBFF',
                      width: '150px',
                      pr: 3,
                      whiteSpace: 'nowrap'
                    }}
                    align="right">
                    Time Consumed
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {consumedHour.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell sx={{ py: '8px' }} component="th" scope="row">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="body2">{row.ticket_number}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ py: '8px' }} align="left">
                      <Box
                        sx={{
                          display: 'flex',
                          cursor: 'pointer',
                          alignItems: 'center',
                          gap: 0.5
                        }}>
                        <Typography variant="body2">{row.type}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ py: '8px' }} align="left">
                      <Typography variant="body2">{row.assigned_to}</Typography>
                    </TableCell>
                    <TableCell sx={{ py: '8px' }} align="left">
                      <Typography variant="body2">
                        {' '}
                        {moment(row.close_date).isValid()
                          ? momentTimezone(row?.close_date)
                              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                              .format('DD MMM YYYY')
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ py: '8px', pr: 2 }} align="center">
                      {formateTime(row?.time_consumed)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter sx={{ position: 'sticky', bottom: 0 }}>
                <TableRow sx={{ background: '#FBFBFF', p: 2.5 }}>
                  <TableCell
                    sx={{ fontWeight: 600, fontSize: '14px', color: '#464646' }}
                    align="left">
                    Total Time Consumed:{' '}
                  </TableCell>

                  <TableCell sx={{ py: 1 }} align="left"></TableCell>
                  <TableCell sx={{ py: 1 }} align="left"></TableCell>
                  <TableCell sx={{ py: 1 }} align="left"></TableCell>
                  <TableCell
                    sx={{ fontWeight: 600, fontSize: '14px', color: '#464646', width: '150px' }}
                    align="center">
                    {totalConsumedHour(consumedHour)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
export default EngineeringDataList;
