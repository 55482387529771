import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FC, useState,  } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CompanyWebsiteProp } from './Models/CompanyWebsiteProp';
import AddLocationModel from './AddLocationModel';
import LocationsTable from './LocationsTable';
import { Location } from "./Models/CompanyProfile.Model";


export const CompanyProfile: FC = (props: any) => {

	const [isRefreshList, setIsRefreshList] = useState(Boolean);

	const commonDetails = useSelector(
		({ commonDetails }: any) => commonDetails.commonDetails?.data,
		shallowEqual
	);
	const data:CompanyWebsiteProp = {
		LocationProp : new Location(),
		IsEdit : false,
		handleSubmit(){},
		handleClose(){}
	}
	const handleRefresh=()=>{		
		setIsRefreshList(!isRefreshList)
	}

	return (
		<>
			<Box sx={Style.CompanySettingPage.CompanySettingPageWrapper}>
				
				 {/* <SiteListTable isRefresh={isRefreshList}/> */}
				 <LocationsTable isRefresh={isRefreshList}/>
				
			</Box>


		</>
	);
};
