const NotificationIcon = () => {
  return (
    <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.001 24.5C11.9017 24.5 13.4921 23.0255 13.8573 21.0714H6.14307C6.50983 23.0255 8.09874 24.5 10.001 24.5Z"
        fill="#B7B7B7"
      />
      <path
        d="M17.4664 14.7883C17.4411 14.7883 17.4162 14.7926 17.391 14.7926C13.0428 14.7926 9.50614 11.2545 9.50614 6.90777C9.50614 5.20007 10.0577 3.62123 10.9846 2.32899V1.48614C10.9846 0.940733 10.5439 0.5 9.99994 0.5C9.45597 0.5 9.01379 0.940733 9.01379 1.48614V2.55062C5.67527 3.03106 3.09981 5.9025 3.09981 9.37097V12.1193C3.09981 14.0696 2.24542 15.9105 0.745988 17.1789C0.363008 17.507 0.142822 17.9846 0.142822 18.4888C0.142822 19.4396 0.916 20.2142 1.86821 20.2142H18.1317C19.0824 20.2142 19.8571 19.441 19.8571 18.4888C19.8571 17.9846 19.6369 17.507 19.2449 17.1713C18.4659 16.5137 17.8671 15.6961 17.4664 14.7883Z"
        fill="#B7B7B7"
      />
      <path
        d="M9.99903 24.5C8.09834 24.5 6.50789 23.0255 6.14267 21.0714H13.8569C13.4902 23.0255 11.9013 24.5 9.99903 24.5Z"
        fill="#B7B7B7"
      />
      <path
        d="M2.53359 14.7883C2.55886 14.7883 2.58377 14.7926 2.60903 14.7926C6.95716 14.7926 7.70414 12.043 7.70414 7.6963C7.70414 5.9886 9.94231 3.62123 9.01536 2.32899V1.48614C9.01536 0.940733 9.45609 0.5 10.0001 0.5C10.544 0.5 10.9862 0.940733 10.9862 1.48614V2.55062C14.3247 3.03106 16.9002 5.9025 16.9002 9.37097V12.1193C16.9002 14.0696 17.7546 15.9105 19.254 17.1789C19.637 17.507 19.8572 17.9846 19.8572 18.4888C19.8572 19.4396 19.084 20.2142 18.1318 20.2142H1.86834C0.91757 20.2142 0.142946 19.4411 0.142946 18.4888C0.142946 17.9846 0.363132 17.507 0.755136 17.1713C1.53409 16.5137 2.13292 15.6961 2.53359 14.7883Z"
        fill="#B7B7B7"
      />
    </svg>
  );
};
export default NotificationIcon;
