import CloseIcon from '@jibin/common/icons/Close/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { TechinicalInformation } from '../../TechinicalInformation.Api';
import { IpsProp } from '../Models/IpsProp';
import EditIcon from '@jibin/common/icons/Edit/Edit';
import SearchIcon from '@jibin/common/icons/Search/Search';
import { Facility, Ips } from '../Models/Ips.Model';
import { useFormik } from 'formik';
import { FormHelperText, InputAdornment } from '@mui/material';
import './index.css';
import { LoadingButton } from '@mui/lab';
var debounce = require('lodash.debounce');

export const AddExternalAssessmentModel: FC<IpsProp> = (prop) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const handleClose = () => {
    formik.resetForm();
    prop.closePill();
    setOpen(false);
    setIpError('');
    setIsFormSubmitted(false);
  };
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [isSkeleton, setIsSkeleton] = useState(true);
  const [locationListData, setLocationListData] = useState({} as any);
  const [facilityList, setfacilityList] = useState({} as any);
  const [ipError, setIpError] = useState('');
  const [isIPRangeButtonLoading, setIsIPRangeButtonLoading] = useState(false);
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const getFacilities = useCallback(() => {
    TechinicalInformation.GetFacilitiesList(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setfacilityList(q.data);
        setIsSkeleton(false);
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  useEffect(() => {
    //getLocations();
    getFacilities();
  }, [getFacilities]);
  const handleOnBlure = (event: any) => {
    const ipReg = new RegExp(
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
    );

    if (!ipReg.test(event.target.value)) {
      setIpError('Enter valid IP Address');
    } else if (event.target.value > 21) {
      setIpError('IP Range must be less than 21 characters long');
    } else {
      setIpError('');
    }
  };
  const ref = useRef(null);
  const handleChangeData = (e: any, value: any) => {
    if (value) {
      formik.setFieldValue(e, value.company_facility_uuid);
    } else {
      formik.setFieldValue(e, '');
    }
  };

  const validate = (values: Ips) => {
    const errors = {};

    const ipReg = new RegExp(
      /^((([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5]))$/
    );
    if (!values.ip_address) {
      errors['ip_address'] = 'This field is Required';
    } else if (
      !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        values.ip_address
      )
    ) {
      errors['ip_address'] = 'Enter valid IP Address';
    } else if (values.ip_address.length > 21) {
      errors['ip_address'] = 'IP Range must be less than 21 characters long';
    }

    if (!values.prefix) {
      errors['prefix'] = 'This field is Required';
    } else if (!/^[0-9]*$/i.test(values.prefix.toString())) {
      errors['prefix'] = 'Please enter a valid number';
    }
    if (!values.is_external.toString()) {
      errors['is_external'] = 'This field is Required';
    }

    if (!values.company_facility_uuid) {
      errors['company_facility_uuid'] = 'This field is Required';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: new Ips(prop.IpsModel),
    validateOnChange: isFormSubmitted,
    validate,
    onSubmit: useCallback(
      debounce(
        (values) => {
          try {
            setIsIPRangeButtonLoading(true);
            let data = { ...values };

            data['company_facility_uuid'] =
              values.company_facility_uuid || values.facility.company_facility_uuid;

            delete data.facility;
            delete data.prefix;
            data['prefix'] = parseInt(values.prefix.toString());

            if (prop.IpsModel.tech_info_ip_range_uuid) {
              data['company_uuid'] = prop.IpsModel.company_uuid;

              TechinicalInformation.putIps(
                commonDetails.data.user_uuid,
                commonDetails.data.company.company_uuid,
                prop.IpsModel.tech_info_ip_range_uuid,
                data
              ).then(
                (q: any) => {
                  handleClose();
                  prop.handleSubmit();
                  formik.resetForm();
                },
                (err) => {}
              );
            } else {
              delete data.tech_info_ip_range_uuid;
              delete data.company_uuid;

              TechinicalInformation.postIps(
                commonDetails.data.user_uuid,
                commonDetails.data.company.company_uuid,
                data
              ).then(
                (q: any) => {
                  handleClose();
                  prop.handleSubmit();
                  formik.resetForm();
                },
                (err) => {}
              );
            }
          } catch (error) {
          } finally {
            setTimeout(() => {
              setIsIPRangeButtonLoading(false);
            }, 1000);
          }
        },
        500,
        true
      ),
      [open]
    )
  });

  return (
    <>
      {!prop.IsEdit && (
        <Box>
          <MenuItem onClick={handleClickOpen}>
            <EditIcon sx={{ mr: 4, color: 'primary.main' }} /> Edit
          </MenuItem>
        </Box>
      )}
      {prop.IsEdit && (
        <Button variant="contained" onClick={handleClickOpen}>
          Add IP Range
        </Button>
      )}
      {!isSkeleton && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle
            id="alert-dialog-title"
            sx={{ display: 'flex', justifyContent: 'space-between', p: { xs: 1, md: 2 } }}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: '600',
                maxWidth: 'calc(100% - 40px)',
                p: 1,
                color: 'text.primary'
              }}>
              IP Range
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={formik.handleSubmit} method="post" noValidate>
            <DialogContent sx={{ px: { xs: 2, md: 3 }, pt: 0 }}>
              <Box id="alert-dialog-description">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Url">Facility </label>
                    </Box>
                    <Autocomplete
                      freeSolo
                      id="facility"
                      className="search"
                      onChange={(e, value) => handleChangeData('company_facility_uuid', value)}
                      options={
                        facilityList?.data.sort((a: any, b: any) => {
                          return a?.name > b?.name ? 1 : -1;
                        }) || []
                      }
                      getOptionLabel={(option: any) => {
                        if (option.constructor.name == 'Array') {
                          return option[0]?.name ? `${option[0]?.name} :${option[0]?.address}` : '';
                        }

                        return option?.name ? `${option?.name} :${option?.address}` : '';
                      }}
                      defaultValue={!prop.IsEdit && [formik.values.facility]}
                      renderOption={(props, option) => (
                        <Box
                          {...props}
                          key={option?.company_facility_uuid + 'a'}
                          component="li"
                          sx={{ pr: 1 }}>
                          <p key={option?.company_facility_uuid + 'b'}>
                            <strong>{option?.name}</strong>&nbsp;-&nbsp;{option?.address}
                          </p>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          ref={ref}
                          error={formik.errors.company_facility_uuid ? true : false}
                          InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            )
                          }}
                          value={formik.values.company_facility_uuid}
                          helperText={formik.errors.company_facility_uuid}
                          placeholder="Data Center, Head Office, etc...."
                          size="small"
                          onChange={formik.handleChange}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                      <Box sx={{ mb: 1 }}>
                        <label htmlFor="Url">External / Internal? </label>
                      </Box>
                      <Select
                        size="small"
                        displayEmpty
                        id="is_external"
                        name="is_external"
                        value={formik.values.is_external}
                        error={formik.errors.is_external ? true : false}
                        onChange={(e) => {
                          formik.handleChange(e);
                          //   //formik.errors.is_external = '';
                        }}>
                        <MenuItem disabled selected sx={{ display: 'none' }}>
                          Select
                        </MenuItem>
                        <MenuItem value={true as any}>External</MenuItem>
                        <MenuItem value={false as any}>Internal</MenuItem>
                      </Select>
                      <FormHelperText sx={{ color: '#d50000' }}>
                        {' '}
                        {formik.errors.is_external}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                      <Box sx={{ mb: 1 }}>
                        <label htmlFor="Email">IP Address</label>
                      </Box>
                      <TextField
                        size="small"
                        placeholder="Enter valid IP Address"
                        id="ip_address"
                        name="ip_address"
                        value={formik.values.ip_address}
                        error={formik.errors.ip_address ? true : false}
                        helperText={formik.errors.ip_address ? formik.errors.ip_address : ''}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        //onBlur={()=>{ formik.validateField('ip_address')}}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                      <Box sx={{ mb: 1 }}>
                        <label htmlFor="Email">Prefix</label>
                      </Box>
                      <TextField
                        size="small"
                        placeholder="Ex.: 24"
                        type={'text'}
                        id="prefix"
                        name="prefix"
                        value={formik.values.prefix}
                        error={formik.errors.prefix ? true : false}
                        helperText={formik.errors.prefix}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.errors.prefix = '';
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions
              sx={{ px: { xs: 2, md: 3 }, pb: { xs: 2, md: 3 }, pt: 0, flexWrap: 'wrap' }}>
              <Button variant="outlined" sx={{ minWidth: 100 }} color="error" onClick={handleClose}>
                Cancel
              </Button>
              <LoadingButton
                loading={isIPRangeButtonLoading}
                variant="contained"
                sx={{ minWidth: 100 }}
                onClick={() => {
                  setIsFormSubmitted(true);
                  formik.submitForm();
                }}>
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  );
};

export default AddExternalAssessmentModel;
