import { FC, useCallback, useEffect, useState } from 'react';
import { Skeleton, Typography } from '@mui/material';

import CalenderIcon from '@jibin/common/icons/CalenderIcon/CalenderIcon';
import Box from '@mui/material/Box';
import { PanetrationApi } from './Panetration.Api';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TopRemediations } from '../Common/TopRemediations/TopRemediations';


export const PanetrationOld: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const navigate = useNavigate();
  const [isSkeleton, setIsSkeleton] = useState(false);

  const [penetrationTests, setPenetrationTests] = useState({} as any);
  const [penetrationStats, setPenetrationStats] = useState({} as any);

  // const GetPenetrationTests = useCallback(() => {
  //   PanetrationApi.PenetrationTests(
  //     commonDetails.data.user_uuid,
  //     commonDetails.data.company.company_uuid
  //   ).then(
  //     (q: any) => {
  //       setPenetrationTests(q.data);
  //     },
  //     (err: any) => { }
  //   );
  // }, [commonDetails]);

  const GetPenetrationStats = useCallback(() => {
    setIsSkeleton(true);
    PanetrationApi.PenetrationStats(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setPenetrationStats(q.data);
        setIsSkeleton(false);
      },
      (err: any) => { }
    );
  }, [commonDetails]);

  const momentTimezone = require('moment-timezone');

  useEffect(() => {
    GetPenetrationStats();
    
  }, [GetPenetrationStats, ]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          backgroundColor: '#FFFFFF',
          p: 4,
          gap: 1
        }}>
        <Typography variant={'subtitle1'}>Penetration Testing</Typography>
        <Typography variant={'subtitle2'}>
          Simulated cyberattack to proactively test for vulnerabilities
        </Typography>
      </Box>
      {isSkeleton ? (
        <Box sx={{ px: 4, py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : penetrationStats.data?.scan.current == 'Not set' ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            m: 'auto',
            mt: 5,
            mb: 5
          }}>
          <Box sx={{ textAlign: 'center' }}>
            <img width={'50%'} src="/images/NoDataFound.png" />
            <Typography variant="h2" sx={{ fontWeight: 600 }}>
              No Data Available
            </Typography>
            <Typography sx={{ mt: '10px', textAlign: 'center', color: '#696969' }} variant="body2">
              Go to Technical Information and Setup your Environment
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box sx={{ px: 4, py: 3, width: 1 }}>
          <Box sx={{ color: 'rgba(70, 70, 70, 1)', width: 1 }}>
            <Box>
              <Box
                sx={{
                  border: '1px solid #CCCCCC',
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderRadius: '8px',
                  p: 3,
                  gap: 1,
                  flexWrap: 'wrap',
                  backgroundColor: '#FFFFFF',
                  pr: 15
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1
                  }}>
                  <Typography sx={{ fontSize: '19px', fontWeight: 600 }}>
                    Penetration Testing
                  </Typography>
                  <Typography variant="body2">
                    Simulating Cyber Attacks to Enhance Security
                  </Typography>
                </Box>

                {momentTimezone(penetrationStats.data?.scan.current).isValid() &&
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: 1
                    }}>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <CalenderIcon sx={{ color: '#436AF3' }} />
                      <Typography variant="body2">Last Penetration Test</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2">
                        {momentTimezone(penetrationStats.data?.scan.current).isValid()
                          ? momentTimezone(penetrationStats.data?.scan.current)
                            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                            .format('DD MMM YYYY')
                            .toUpperCase() +
                          ' at ' +
                          momentTimezone(penetrationStats.data?.scan.current)
                            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                            .format('hh:mm A zz')
                          : 'Not set'}{' '}
                      </Typography>
                    </Box>
                  </Box>
                }

                {momentTimezone(penetrationStats.data?.scan.next).isValid() &&
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: 1
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <CalenderIcon sx={{ color: '#9D9D9D' }} />
                      <Typography variant="body2">Next Penetration Test</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2">
                        <Typography variant="body2">
                          {' '}
                          {momentTimezone(penetrationStats.data?.scan.next).isValid()
                            ? momentTimezone(penetrationStats.data?.scan.next)
                              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                              .format('DD MMM YYYY')
                              .toUpperCase() +
                            ' at ' +
                            momentTimezone(penetrationStats.data?.scan.next)
                              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                              .format('hh:mm A zz')
                            : 'Not set'}{' '}
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                }
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: {
                    xs: 'repeat( 1,1fr)',
                    sm: 'repeat( 2,1fr)',
                    md: 'repeat( 3,1fr)'
                  },
                  width: 1,
                  gap: 2,
                  mt: 3
                }}>
                <Box
                  sx={{
                    border: '1px solid #CCCCCC',
                    width: 1,

                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px'
                  }}>
                  <Box sx={{ p: 3 }}>
                    <Box sx={{ mb: 4 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: 1
                        }}>
                        <Typography sx={{ fontWeight: 600 }}>Finding by Criticality</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Typography variant="caption">Source: Penetration Test </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          border: '1px solid #EAECF0',
                          borderRadius: '8px',
                          p: '14px'
                        }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Box
                            sx={{
                              width: '16px',
                              height: '16px',
                              backgroundColor: '#F87171',
                              borderRadius: '50%'
                            }}></Box>
                          <Typography variant={'body1'} sx={{ fontWeight: 600, color: '#696969' }}>
                            Critical
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                            {penetrationStats.data?.count.critical
                              ? penetrationStats.data?.count.critical
                              : 0}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          border: '1px solid #EAECF0',
                          borderRadius: '8px',
                          p: '14px'
                        }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Box
                            sx={{
                              width: '16px',
                              height: '16px',
                              backgroundColor: '#F59E0B',
                              borderRadius: '50%'
                            }}></Box>
                          <Typography variant={'body1'} sx={{ fontWeight: 600, color: '#696969' }}>
                            High
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                            {penetrationStats.data?.count.severe
                              ? penetrationStats.data?.count.severe
                              : 0}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          border: '1px solid #EAECF0',
                          borderRadius: '8px',
                          p: '14px'
                        }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Box
                            sx={{
                              width: '16px',
                              height: '16px',
                              backgroundColor: '#FCD34D',
                              borderRadius: '50%'
                            }}></Box>
                          <Typography variant={'body1'} sx={{ fontWeight: 600, color: '#696969' }}>
                            Medium
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                            {penetrationStats.data?.count.moderate
                              ? penetrationStats.data?.count.moderate
                              : 0}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    border: '1px solid #CCCCCC',

                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px'
                  }}>
                  <TopRemediations
                    size={10}
                    source='pen'
                    title='Top Remediation Tasks'
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
