import HttpService from '@jibin/common/services/http/HttpService';

export const RemediationApi = {
  Remediations(userId: string, companyId: string, params: any) {
    //return HttpService.getAll("todos/1");
    // /companies/9940c9d2-8ef0-42b9-8626-5be545d1e63d/users/64d1802e-1fa4-4732-b182-0719199108a8/remediation/list
    return HttpService.getAll(`companies/${companyId}/users/${userId}/remediation/list?` + params);
  },
  GetRemediatios(userId: string, companyId: string, remediation_id: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/remediation/${remediation_id}`
    );
  },
  GetRemediatiosHistory(userId: string, companyId: string, remediation_id: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/remediation/${remediation_id}/history`
    );
  },
  PutRemediationData(userId: string, companyId: string, remediation_id: string, data: any) {
    return HttpService.putAll(
      `companies/${companyId}/users/${userId}/remediation/${remediation_id}`,
      data
    );
  },
  GetAssignees(userId: string, companyId: string) {
    return HttpService.getAll(`companies/${companyId}/users/${userId}/assignees`);
  },

  patchAssignee(userId: string, companyId: string, remediation_id: string, data) {
    return HttpService.patchAll(
      `companies/${companyId}/users/${userId}/remediation/${remediation_id}/assign`,
      data
    );
  },
  GetFiles(userId: string, companyId: string, remediation_id: string, file_name: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/remediation/${remediation_id}/files/${file_name}`
    );
  },
  PostActionData(userId: string, companyId: string, remediation_id: string, data: any) {
    return HttpService.post(
      `companies/${companyId}/users/${userId}/remediation/${remediation_id}/actions-taken`,
      data
    );
  }
};
