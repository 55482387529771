
import React, { FC, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import Style from '@jibin/common/style/Styles';
import { PageRouteConstants } from '../utils/constants/PageRouteConstants';
import { useNavigate } from 'react-router-dom';


const MenuItems = [
  {name :'Applications', class:'',route: PageRouteConstants.Applications}, 
  {name :'IP Ranges', class:'',route: PageRouteConstants.ExternalInfrastructureAssessment },
   {name :'Wireless Networks', class:'',route: PageRouteConstants.WirelessAssessment}]


export const TechnicalInformation: FC<{ children: React.ReactNode | React.ReactNode[] }> = (props) => {
const navigate = useNavigate();

  return (    
    <>
          <Box >
            <Typography variant="h2" sx={Style.TechnicalInformation.PageHeadTitle}>Technical Information</Typography>
          </Box>
          <List sx={Style.TechnicalInformation.MenuList}>
            
              {
                MenuItems.map((currenElement, index) => (
                  <ListItem disablePadding key={index} sx={{width:'auto'}}>
                  <Link onClick={()=>{navigate(currenElement.route)}} style={{cursor: 'pointer'}}  className={window.location.pathname === currenElement.route ? 'active':'' } sx={Style.TechnicalInformation.MenuListItem}>{currenElement.name}</Link>
                  </ListItem>
                  ))
              }
           
          </List>
          {props.children}
        
    </>
    
  );
};

export default TechnicalInformation;
