import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { PlanAndBillingApi } from './PlanAndBilling.Api';

export const PlanAndBilling: FC = (props: any) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const handleContactUs = async () => {
    try {
      const res = PlanAndBillingApi.ContactUs(        
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        ''
      ).then(q=> {
        window.open('https://www.redesign-group.com/contact-us', '_blank')
      });
    } catch (error) {}
  };
  return (
    <>
      <Box sx={Style.CompanySettingPage.CompanySettingPageWrapper}>
        <Box sx={{ ...Style.CompanySettingPage.AddButton, textAlign: 'right', mt: 2 }}>
          <Button
            variant="contained"
            onClick={() => {
              handleContactUs();
            }}
            sx={{ minWidth: 150 }}>
            Contact Us
          </Button>
        </Box>
      </Box>
    </>
  );
};
