import HttpService from '@jibin/common/services/http/HttpService';

export const MyAccountApi = {
  GetUserData() {
    return HttpService.getAll(`users/me`);
  },
  PutUserData(data: any) {
    return HttpService.putAll(`users/me`, data);
  },
  PutProfilePhoto(user_id: any, data: any) {
    return HttpService.putAll(`users/${user_id}/profile-pic`, data);
  }
};
