import HttpService from '@jibin/common/services/http/HttpService';
export const PlanAndBillingApi = {
  ContactUs(user_id: string, company_id: string, data: any) {
    //return HttpService.getAll("todos/1");

    return HttpService.putAll(
      `companies/${company_id}/users/${user_id}/settings/plan-and-billing`,
      data
    );
  }
};
