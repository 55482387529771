import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import CrossCancle from '../../icons/CrossCancle/CrossCancle';
import List from '@mui/material/List';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {
  Autocomplete,
  Divider,
  FormControl,
  InputAdornment,
  ListSubheader,
  ListSubheaderProps,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';
import Shareicon from '@jibin/common/icons/Shareicon/Shareicon';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import { shallowEqual, useSelector } from 'react-redux';
import Style from '@jibin/common/style/Styles';
import { fontSize } from '@mui/system';
import PinIcon from '@jibin/common/icons/PinIcon/PinIcon';
import FileIcon from '@jibin/common/icons/FileIcon/FileIcon';
import GallaryIcon from '@jibin/common/icons/GallaryIcon/GallaryIcon';
import UploadIcon from '@jibin/common/icons/UploadIcon/UploadIcon';
import VideoPlay from '@jibin/common/icons/VideoPlay/VideoPlay';
import { EllipsisContent } from '../EllipsisContent';
import { SuccessConfirmationUpadated } from '@jibin/common/components/ConformationModel/SuccessConfirmationUpadated';
import { DeleteTypesEnum } from '@jibin/common/utils/enums/deleteTypeEnums';
import { notifySuccess } from '@jibin/common/services/http/http-common';
import { RemediationApi } from 'src/Pages/Remediation/Remediation.Api';
import moment from 'moment';
import { SourceTypeEnums } from '@jibin/common/utils/enums/SourceTypeEnums';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { LoadingButton } from '@mui/lab';
type Anchor = 'top' | 'left' | 'bottom' | 'right';
var debounce = require('lodash.debounce');

export interface RemidiationPopUpProp {
  remediation_uuid: any;
  el: any;
  closePopup(event: React.KeyboardEvent | React.MouseEvent): void;
  isEngineer: boolean;
  setIsRefresh: any;
  isRefresh: any;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const RemidiationPopUpList: FC<RemidiationPopUpProp> = (prop) => {
  const [edit, setEdit] = useState(false);
  const [remediationEditData, setRemediationEditData] = useState({
    issue_name: prop.el?.issue_name,
    description: prop.el?.description,
    recommendation: prop.el?.recommendation,
    severity: prop.el?.severity,
    status: prop.el?.status
  });

  //const [engineer, setEngineer] = useState(false);
  const [fileData, setFileData] = useState([] as any);
  const validURL = (str) => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  };

  const [value, setValue] = React.useState(0);
  const [resolve, setResolve] = useState(false);
  const [assign, setAssign] = useState(null);
  const [remediationHistory, setRemediationHistory] = useState([]);
  const [severty, setSeverty] = useState('medium');
  const [filelist, setFilesList] = useState([]);
  const [actionTaken, setActionTaken] = useState('');
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [skelton, setSkeleton] = useState(false);
  const [dataPosted, setDataPosted] = useState(false);
  const [isAssignButtonLoading, setIsAssignButtonLoading] = useState(false);
  const [isEditButtonLoading, setIsEditButtonLoading] = useState(false);

  const handlePostData = useCallback(
    debounce(
      async (e: any) => {
        const formData = new FormData();

        for (let i = 0; i < fileData.length; i++) {
          formData.append('files', fileData[i]);
        }
        let data = { description: actionTaken };
        formData.append('data', JSON.stringify(data));

        try {
          const res = await RemediationApi.PostActionData(
            commonDetails.data.user_uuid,
            commonDetails.data.company.company_uuid,
            prop.el.remediation_uuid,
            formData
          );
          setResolve(false);
          prop.closePopup(e);
          prop.setIsRefresh(!prop.isRefresh);
          setFileData([]);
        } catch (err) {
          console.log(err);
        }
      },
      500,
      true
    ),
    [resolve, actionTaken, fileData, open]
  );

  function MyListSubheader(props: ListSubheaderProps) {
    return <ListSubheader {...props} />;
  }

  MyListSubheader.muiSkipListHighlight = true;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (newValue == 2) {
      GetRemediationHistory();
    }
  };

  const handleChangeSeverty = (e: any) => {
    setSeverty(e.target.value);
  };
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  function niceBytes(x) {
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }

  const handleImageUpload = (e, files) => {
    stopReload(e);

    if (!e) return;

    setFileData([...fileData, ...files]);
  };
  const handleAssige = (event: any, value: any) => {
    setAssign(value);
  };

  const [rowData, setRowData] = useState({} as any);

  const GetRemidiationDetails = useCallback(async () => {
    setSkeleton(true);
    try {
      const res = await RemediationApi.GetRemediatios(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        ref.current
      );

      if (res.data?.data?.owner.user_uuid) {
        setAssign({
          first_name: res.data?.data?.owner.first_name,
          last_name: res.data?.data?.owner.last_name,
          user_uuid: res.data?.data?.owner.user_uuid,
          ico: res.data?.data?.owner.ico
        });
      }
      setSkeleton(false);
      setRowData(res.data?.data);
    } catch (err) {
      console.log(err);
      setSkeleton(false);
    }
  }, [commonDetails]);

  const ref = useRef('');
  const [assignees, setAssignees] = useState({} as any);
  const GetAssignees = useCallback(async () => {
    try {
      const res = await RemediationApi.GetAssignees(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );
      renderOption(res.data);
    } catch (err) {
      console.log(err);
    }
  }, [commonDetails]);
  const [option, setOption] = useState([]);
  useEffect(() => {
    setActionTaken('');
    setEdit(false);
    setResolve(false);
    ref.current = prop.el.remediation_uuid;

    if (ref.current) {
      setValue(0);

      GetRemidiationDetails();
      GetAssignees();
    }

    setRemediationEditData({
      issue_name: prop.el?.issue_name,
      description: prop.el?.description,
      recommendation: prop.el?.recommendation,
      severity: prop.el?.severity,
      status: prop.el?.status
    });
    return () => {
      setAssign(null);
      setFileData([]);
    };
  }, [GetRemidiationDetails, GetAssignees, prop]);

  const stopReload = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleBrowseEvent = (e: any) => {
    setFileData([...fileData, ...e.target.files]);
  };

  const handleDragEvent = (e: any) => {
    handleImageUpload(e, e.dataTransfer.files);
  };

  const handleRemoveFile = (i) => {
    const upadatedFileData = fileData.filter((el, index) => index != i);
    setFileData(upadatedFileData);
  };

  const renderColor = (severity: any) => {
    var color = '#F87171';
    if (severity == 'critical') {
      color = '#F87171';
    } else if (severity == 'high') {
      color = '#F87171';
    } else if (severity == 'medium') {
      color = '#FCD34D';
    } else if (severity == 'low') {
      color = '#436AF3';
    }
    return color;
  };
  const handleChangeEditData = (e: any) => {
    setRemediationEditData({ ...remediationEditData, [e.target.name]: e.target.value });
  };
  const handleChangeAcctionTaken = (e: any) => {
    setActionTaken(e.target.value);
  };
  const renderOption = (assignees) => {
    const options = assignees.data.map((option) => {
      const firstLetter = option.company_name.toUpperCase();
      const company_uuid = option.company_uuid;

      const data = option?.users.map((el: any) => {
        return { firstLetter: firstLetter, company_uuid: company_uuid, ...el };
      });
      return data;
    });

    const optionarray = options.concat.apply([], options).flat();
    setOption(optionarray);
  };
  const GetRemediationHistory = async () => {
    try {
      const res = await RemediationApi.GetRemediatiosHistory(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        prop.el.remediation_uuid || ''
      );

      setRemediationHistory(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getFile = async (element: any) => {
    const res = await RemediationApi.GetFiles(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      prop.el.remediation_uuid,
      element.file_name
    );

    const blob = new Blob([res.data], { type: res.headers['content-type'] });

    const urlName = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = urlName;
    //a.download = `downloadfile.${res.headers['content-type'].split('/')[1]}`;
    a.download = `${element.file_name}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const handleSubmitEditData = async () => {
    if (edit) {
      try {
        setIsEditButtonLoading(true);
        const res = await RemediationApi.PutRemediationData(
          commonDetails.data.user_uuid,
          commonDetails.data.company.company_uuid,
          prop.el.remediation_uuid || '',
          remediationEditData
        );
        setEdit(false);
        prop.setIsRefresh(!prop.isRefresh);
      } catch (err) {
        console.log(err);
      } finally {
        setTimeout(() => {
          setIsEditButtonLoading(false);
        }, 1000);
      }
    } else {
      setResolve(true);
    }
  };

  const renderColorBackGround = (severity: any = 'critical') => {
    var color = '#FFE6E2';
    if (severity == 'critical') {
      color = '#FFE6E2';
    } else if (severity == 'high') {
      color = '#FFE6E2';
    } else if (severity == 'medium') {
      color = '#FEF3C7';
    } else if (severity == 'low') {
      color = '#D0DAFC';
    }
    return color;
  };

  const renderSelectGroup = (company) => {
    const items = company?.users?.map((p, index) => {
      return (
        <MenuItem key={index} value={p.user_uuid}>
          <Box>
            <Typography
              sx={{
                ...Style.versionsCard.infoRound,
                width: '24px',
                height: '24px',
                fontSize: '14px'
              }}>
              {' '}
              {p.first_name.charAt(0) + '' + p.last_name.charAt(0)}
            </Typography>
          </Box>
          <Typography variant="body2">{p.first_name + ' ' + p.last_name}</Typography>
        </MenuItem>
      );
    });
    return [
      <ListSubheader>
        <Box>
          <Typography variant="body2" sx={{ fontWeight: '600', px: 2, py: 1 }}>
            {' '}
            {company.company_name}
          </Typography>
        </Box>
      </ListSubheader>,
      items
    ];
  };

  const assignUser = async () => {
    try {
      setIsAssignButtonLoading(true);
      let data = {
        owner_company_uuid: assign.company_uuid,
        owner_user_uuid: assign.user_uuid
      };
      const res = await RemediationApi.patchAssignee(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        prop.el.remediation_uuid || '',
        data
      );
      notifySuccess('User Assigned');
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        setIsAssignButtonLoading(false);
      }, 1000);
    }
  };

  const renderstatusColor = (status) => {
    var color = '#F87171';

    if (status == 'in_progress') {
      color = '#FEF3C7';
    } else if (status == 'not_started') {
      color = '#FFE6E2';
    } else {
      color = '#A7F3D0';
    }
    return color;
  };
  const getSource = (source: any) => {
    let sourceLabel = '';
    switch (source) {
      case SourceTypeEnums.gap:
        sourceLabel = 'Gap Analysis';
        break;
      case SourceTypeEnums.vuln:
        sourceLabel = 'Vulnerability';
        break;
      case SourceTypeEnums.pen:
        sourceLabel = 'Penetration Test';
        break;
      case SourceTypeEnums.endpoint:
        sourceLabel = 'Endpoint';
        break;
    }
    return sourceLabel;
  };
  if (skelton) {
    return <Skeleton height={100} width={520} />;
  }
  return (
    <>
      {rowData?.status && (
        <Box
          sx={{
            width: { md: 520, xs: 300 },
            height: value == 1 || value == 2 ? '100vh' : 'auto',
            position: 'relative'
          }}
          role="presentation">
          <List sx={{ p: 0 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 1,
                justifyContent: 'space-between',
                backgroundColor: '#003260',
                px: 2.5,
                alignItems: 'center',
                py: 1
              }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',

                  width: 1,
                  alignItems: 'center'
                }}>
                <Typography variant="body1" sx={{ fontWeight: 600, color: 'white', my: 1.25 }}>
                  Source : {getSource(rowData?.source)}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${window.location.hostname}${PageRouteConstants.Remediation}${window.location.search}`
                      );
                      // window.location.href

                      notifySuccess('Copied');
                    }}>
                    <Shareicon />
                  </Box>

                  <Box
                    onClick={(event) => {
                      prop.closePopup(event);
                    }}>
                    <CrossCancle
                      sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 1 }}>
                <Typography variant="body2" sx={{ color: '#FFFFFF' }}>
                  Discovered: {moment(rowData?.created_at).format('DD MMM YYYY').toUpperCase()}
                </Typography>
                <Box
                  sx={{
                    backgroundColor: renderstatusColor(rowData?.status),
                    py: '6px',
                    px: '30px',
                    fontSize: '12px',
                    borderRadius: '100px'
                  }}>
                  <Typography variant="caption">
                    {' '}
                    {rowData?.status == 'in_progress' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {/* <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: '#FBBF24',
                                    borderRadius: '50%'
                                  }}></Box> */}
                        <Typography variant="caption">In Progress</Typography>
                      </Box>
                    ) : rowData?.status == 'not_started' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {/* <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: '#EF4444',
                                    borderRadius: '50%'
                                  }}></Box> */}
                        <Typography variant="caption">Not Started</Typography>
                      </Box>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {/* <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: '#10B981',
                                    borderRadius: '50%'
                                  }}></Box> */}
                        <Typography variant="caption">
                          {rowData?.status.charAt(0).toUpperCase() + '' + rowData?.status.slice(1)}
                        </Typography>
                      </Box>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </List>

          <Box>
            <Box sx={{ p: 3 }}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderColor: 'divider' }}>
                  <Tabs
                    value={value}
                    sx={{ '.Mui-selected': { textDecoration: 'underline' }, minHeight: '0px' }}
                    TabIndicatorProps={{ sx: { display: 'none' } }}
                    onChange={handleChange}
                    aria-label="basic tabs example">
                    <Tab
                      sx={{ justifyContent: 'flex-end', padding: '0px', textTransform: 'none' }}
                      label="Remediation"
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="Files"
                      sx={{ justifyContent: 'flex-end', padding: '0px', textTransform: 'none' }}
                      {...a11yProps(1)}
                    />
                    <Tab
                      label="History"
                      sx={{ justifyContent: 'flex-end', padding: '0px', textTransform: 'none' }}
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  {edit ? (
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}>
                        <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                          Severity Level:
                        </Typography>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            value={remediationEditData.severity || 'critical'}
                            name="severity"
                            defaultValue={remediationEditData.severity}
                            onChange={handleChangeEditData}
                            displayEmpty
                            sx={{
                              '.MuiSelect-select': {
                                py: 1,
                                display: 'flex',
                                alignItems: 'center'
                              },
                              '&.MuiInputBase-root': {
                                backgroundColor: renderColorBackGround(remediationEditData.severity)
                              },
                              borderRadius: '100px'
                            }}
                            inputProps={{ 'aria-label': 'Without label' }}>
                            <MenuItem sx={{ backgroundColor: '#FFE6E2' }} value="critical">
                              <Box>
                                <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F73939' }} />
                              </Box>
                              <Typography variant="body2">Critical</Typography>
                            </MenuItem>
                            <MenuItem sx={{ backgroundColor: '#FFE6E2' }} value="high">
                              <Box>
                                <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F73939' }} />
                              </Box>
                              <Typography variant="body2">High</Typography>
                            </MenuItem>
                            <MenuItem sx={{ backgroundColor: '#FEF3C7' }} value={'medium'}>
                              <Box>
                                <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#FBBF24' }} />
                              </Box>
                              <Typography variant="body2">Medium</Typography>
                            </MenuItem>

                            <MenuItem sx={{ backgroundColor: '#D0DAFC' }} value={'low'}>
                              {' '}
                              <Box>
                                <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#436AF3' }} />
                              </Box>
                              <Typography variant="body2">Low</Typography>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box sx={{ my: 1 }}>
                        <Box>
                          <List>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 1
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '14px', fontWeight: 600 }}>
                                Issue Name
                              </Typography>
                              <Box sx={{ width: 1 }}>
                                <TextField
                                  size="small"
                                  onChange={handleChangeEditData}
                                  fullWidth
                                  name="issue_name"
                                  value={remediationEditData.issue_name}
                                  inputProps={{ sx: { fontSize: '14px' } }}
                                  variant="outlined"
                                  sx={{ fontSize: '14px' }}
                                  defaultValue={rowData?.issue_name}
                                />
                              </Box>
                            </Box>
                          </List>
                          <List>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 1
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '14px', fontWeight: 600 }}>
                                Remediation Description
                              </Typography>
                              <Box sx={{ width: 1 }}>
                                <TextField
                                  size="small"
                                  name="description"
                                  fullWidth
                                  onChange={handleChangeEditData}
                                  value={remediationEditData.description}
                                  inputProps={{ sx: { fontSize: '14px' } }}
                                  variant="outlined"
                                  rows={7}
                                  multiline
                                  sx={{ fontSize: '14px' }}
                                  defaultValue={rowData?.description}
                                />
                              </Box>
                            </Box>
                          </List>
                          <List>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 1
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '14px', fontWeight: 600 }}>
                                Recommendation
                              </Typography>
                              <Box sx={{ width: 1 }}>
                                <TextField
                                  size="small"
                                  name="recommendation"
                                  fullWidth
                                  onChange={handleChangeEditData}
                                  value={remediationEditData.recommendation}
                                  inputProps={{ sx: { fontSize: '14px' } }}
                                  variant="outlined"
                                  rows={7}
                                  multiline
                                  sx={{ fontSize: '14px' }}
                                  defaultValue={rowData?.recommendation}
                                />
                              </Box>
                            </Box>
                          </List>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}>
                        <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                          Severity Level:
                        </Typography>
                        <FormControl sx={{ m: 1 }}>
                          <Box
                            sx={{
                              py: 1,
                              px: 2,

                              backgroundColor: renderColorBackGround(rowData?.severity),

                              borderRadius: '100px'
                            }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <FlagIcon
                                sx={{
                                  fontSize: '12px',
                                  mr: 1,
                                  color: renderColor(rowData?.severity)
                                }}
                              />

                              <Typography variant="body2"> {rowData?.severity}</Typography>
                            </Box>
                          </Box>
                        </FormControl>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: '#F2F9FF',
                          padding: 2.5,
                          borderRadius: '8px',
                          my: 1
                        }}>
                        <Box>
                          <List>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 1
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '19px', fontWeight: 600 }}>
                                Issue Name
                              </Typography>
                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: '#9D9D9D',
                                    fontWeight: 600,
                                    wordBreak: 'break-all',
                                    wordWrap: 'break-word'
                                  }}>
                                  {rowData?.issue_name}
                                </Typography>
                              </Box>
                            </Box>
                          </List>
                          <List>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 1
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px', fontWeight: 600 }}>
                                Description
                              </Typography>
                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: ' #696969',
                                    wordBreak: 'break-word',
                                    wordWrap: 'break-word'
                                  }}>
                                  {rowData?.description}
                                </Typography>
                              </Box>
                            </Box>
                          </List>
                          <List>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                gap: 1
                              }}>
                              <Typography
                                variant="subtitle2"
                                sx={{ fontSize: '16px', fontWeight: 600 }}>
                                Recommendation
                              </Typography>

                              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: ' #696969',
                                    wordBreak: 'break-word',
                                    wordWrap: 'break-word'
                                  }}>
                                  {rowData?.recommendation}
                                </Typography>
                              </Box>
                            </Box>
                          </List>
                        </Box>
                      </Box>
                      {!resolve && rowData.status != 'resolved' ? (
                        <Box>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 600, color: '#696969', my: 2 }}>
                              Remediation Owner
                            </Typography>

                            {!prop.isEngineer && (
                              <Typography
                                variant="body2"
                                onClick={() =>
                                  setAssign({
                                    first_name: commonDetails.data.first_name,
                                    last_name: commonDetails.data.last_name,
                                    user_uuid: commonDetails.data.user_uuid,
                                    ico: commonDetails.data.ico,
                                    company_uuid: commonDetails.data.company.company_uuid
                                  })
                                }
                                sx={{
                                  fontWeight: 600,
                                  color: '#436AF3',
                                  my: 2,
                                  cursor: 'pointer'
                                }}>
                                {commonDetails.data.group != 'csc' && 'Assign to me'}
                              </Typography>
                            )}
                          </Box>
                          <Box sx={{ display: 'flex', gap: 1.5 }}>
                            <FormControl sx={{ width: '100%' }}>
                              <Autocomplete
                                id="grouped-demo"
                                size="small"
                                value={assign}
                                fullWidth={true}
                                options={option}
                                onChange={(e, value) => handleAssige(e, value)}
                                groupBy={(option: any) => option.firstLetter}
                                getOptionLabel={(option: any) => {
                                  return `${option?.first_name} ${option?.last_name}`;
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    sx={{ fontSize: '14px' }}
                                    {...params}
                                    placeholder="Assign to"
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment:
                                        assign != null ? (
                                          <InputAdornment position="start">
                                            <Box>
                                              {assign.ico ? (
                                                <Box
                                                  sx={{
                                                    ...Style.versionsCard.infoRound,
                                                    width: '24px',
                                                    height: '24px',
                                                    fontSize: '14px'
                                                  }}>
                                                  <img
                                                    width={'100%'}
                                                    height="100%"
                                                    style={{
                                                      objectFit: 'cover',
                                                      borderRadius: '50%'
                                                    }}
                                                    src={`${process.env.REACT_APP_API_HOST}/${assign.ico}`}
                                                    alt=""
                                                  />
                                                </Box>
                                              ) : (
                                                <Typography
                                                  sx={{
                                                    ...Style.versionsCard.infoRound,
                                                    width: '24px',
                                                    height: '24px',
                                                    fontSize: '14px'
                                                  }}>
                                                  {assign?.first_name?.charAt(0) +
                                                    '' +
                                                    assign?.last_name?.charAt(0)}
                                                </Typography>
                                              )}
                                            </Box>
                                          </InputAdornment>
                                        ) : null
                                    }}
                                  />
                                )}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}>
                                    {option.ico ? (
                                      <Box
                                        sx={{
                                          ...Style.versionsCard.infoRound,
                                          width: '24px',
                                          height: '24px',
                                          fontSize: '14px'
                                        }}>
                                        <img
                                          width={'100%'}
                                          height="100%"
                                          style={{
                                            objectFit: 'cover',
                                            borderRadius: '50%'
                                          }}
                                          src={`${process.env.REACT_APP_API_HOST}/${option.ico}`}
                                          alt=""
                                        />
                                      </Box>
                                    ) : (
                                      <Typography
                                        sx={{
                                          ...Style.versionsCard.infoRound,
                                          width: '24px',
                                          height: '24px',
                                          fontSize: '14px'
                                        }}>
                                        {option?.first_name?.charAt(0) +
                                          '' +
                                          option?.last_name?.charAt(0)}
                                      </Typography>
                                    )}

                                    <Typography variant="body2">
                                      {' '}
                                      {option.first_name} {option.last_name}
                                    </Typography>
                                  </Box>
                                )}
                              />
                            </FormControl>
                            <LoadingButton
                              loading={isAssignButtonLoading}
                              onClick={() => assignUser()}
                              sx={{
                                border: '1px solid #436AF3',
                                '&.Mui-disabled': {
                                  backgroundColor: '#EBEBEB',
                                  color: isAssignButtonLoading ? '#EBEBEB' : '#9D9D9D',
                                  border: '1px solid #DADADA'
                                }
                              }}>
                              Assign
                            </LoadingButton>
                          </Box>
                        </Box>
                      ) : (
                        <Box></Box>
                      )}
                      {rowData?.actions_taken?.map(
                        (el, index) =>
                          index == rowData?.actions_taken.length - 1 && (
                            <Box>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600, color: '#696969', my: 2 }}>
                                Remediation Actions Taken
                              </Typography>
                              <Box
                                sx={{
                                  backgroundColor: '#F2F9FF',
                                  padding: 2.5,
                                  borderRadius: '8px',
                                  my: 1
                                }}>
                                <Typography variant="body2">{el.description}</Typography>
                              </Box>

                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  mt: 2,
                                  justifyContent: 'space-between'
                                }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Box>
                                    {el.created.ico ? (
                                      <Box
                                        sx={{
                                          ...Style.versionsCard.infoRound,
                                          width: '24px',
                                          height: '24px',
                                          fontSize: '14px'
                                        }}>
                                        <img
                                          width={'100%'}
                                          height="100%"
                                          style={{
                                            objectFit: 'cover',
                                            borderRadius: '50%'
                                          }}
                                          src={`${process.env.REACT_APP_API_HOST}/${el.created.ico}`}
                                          alt=""
                                        />
                                      </Box>
                                    ) : (
                                      <Typography
                                        sx={{
                                          ...Style.versionsCard.infoRound,
                                          width: '24px',
                                          height: '24px',
                                          fontSize: '14px'
                                        }}>
                                        {' '}
                                        {el.created.first_name.charAt(0) +
                                          '' +
                                          el.created.last_name.charAt(0)}
                                      </Typography>
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'space-between'
                                    }}>
                                    <Typography variant="body2" sx={{ fontWeight: 400 }}>
                                      {el.created.first_name} {el.created.last_name}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box>
                                  <Typography
                                    variant="body2"
                                    sx={{ color: '#475467', fontSize: '12px' }}>
                                    {moment(el?.created_at).format('dddd')}{' '}
                                    {moment(el?.created_at).format('hh:mm A')}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          )
                      )}
                      {resolve && !dataPosted && (
                        <Box>
                          {' '}
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 600, color: '#696969', my: 2 }}>
                            Remediation Actions Taken
                          </Typography>
                          <TextField
                            size="small"
                            fullWidth
                            value={actionTaken}
                            onChange={handleChangeAcctionTaken}
                            inputProps={{ sx: { fontSize: '14px' } }}
                            variant="outlined"
                            rows={6}
                            multiline
                            placeholder="Type here..."
                            sx={{ fontSize: '14px' }}
                          />
                          <Box>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 600, color: '#696969', my: 2 }}>
                              Upload Evidence
                            </Typography>
                            <Box
                              onDragOver={(e) => stopReload(e)}
                              onDrop={(e) => handleDragEvent(e)}
                              sx={{
                                width: '100%',
                                backgroundColor: '#FBFBFF',
                                py: 4,
                                display: 'flex',
                                border: '1px dashed #CCCCCC',
                                borderRadius: '8px',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 2.5
                              }}>
                              <UploadIcon />
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 600, color: '#464646' }}>
                                Drag & Drop or{' '}
                                <label style={{ color: '#436AF3', cursor: 'pointer' }}>
                                  <input
                                    multiple
                                    hidden
                                    onChange={(e) => handleBrowseEvent(e)}
                                    style={{
                                      backgroundColor: 'transparent',
                                      color: 'transparent'
                                    }}
                                    type={'file'}
                                  />{' '}
                                  Choose file{' '}
                                </label>
                                to upload
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 1.5,
                                  width: '100%',
                                  alignItem: 'center',
                                  m: 'auto'
                                }}>
                                {fileData.length == 0 ? (
                                  <Typography
                                    textAlign={'center'}
                                    variant="body2"
                                    sx={{ color: '#787878' }}>
                                    doc,img, or video
                                  </Typography>
                                ) : (
                                  fileData?.map((el, index) => (
                                    <Box
                                      key={index + 1}
                                      sx={{
                                        display: 'flex',
                                        width: '80%',
                                        m: 'auto',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        p: 1.5,
                                        borderRadius: '8px',
                                        backgroundColor: '#FFFFFF',
                                        border: '1px solid #EBEBEB'
                                      }}>
                                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        {el.type.split('/')[0] == 'image' ? (
                                          <GallaryIcon />
                                        ) : el.type.split('/')[0] == 'video' ? (
                                          <VideoPlay />
                                        ) : (
                                          <FileIcon />
                                        )}

                                        <EllipsisContent
                                          sx={{
                                            fontSize: 14,
                                            color: '#2D2D2D',
                                            width: 'max-content',
                                            maxWidth: {
                                              xxs: 150,
                                              md: 150
                                            }
                                          }}
                                          text={el.name}
                                        />

                                        <Typography variant="body2" sx={{ color: '#787878' }}>
                                          {niceBytes(el.size)}
                                        </Typography>
                                      </Box>
                                      <Box onClick={() => handleRemoveFile(index)}>
                                        <CrossCancle
                                          sx={{
                                            background: 'rgba(120, 120, 120,0.1)',
                                            width: '20px',
                                            height: '20px',
                                            color: '#787878',
                                            p: '6px',
                                            borderRadius: '50%',
                                            cursor: 'pointer'
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  ))
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Divider />
                  <Box sx={{ display: 'inline-block' }}>
                    {rowData?.files.map((element, index) => (
                      <Box
                        onClick={() => getFile(element)}
                        sx={{
                          display: 'flex',
                          gap: 1,
                          mt: 2,

                          alignItems: 'center',
                          backgroundColor: '#D9EDFF',
                          py: '5px',
                          px: 1.5,
                          borderRadius: '100px',
                          cursor: 'pointer'
                        }}>
                        <PinIcon />
                        <Typography variant="body1" sx={{ color: '#436AF3' }}>
                          {element.file_name}
                        </Typography>
                      </Box>
                    ))}
                    {rowData?.actions_taken?.map(
                      (el, index) =>
                        index == rowData?.actions_taken?.length - 1 &&
                        el?.files.map((element) => (
                          <Box
                            onClick={() => getFile(element)}
                            sx={{
                              display: 'flex',
                              gap: 1,
                              mt: 2,

                              alignItems: 'center',
                              backgroundColor: '#D9EDFF',
                              py: '5px',
                              px: 1.5,
                              borderRadius: '100px',
                              cursor: 'pointer'
                            }}>
                            <PinIcon />
                            <Typography variant="body1" sx={{ color: '#436AF3' }}>
                              {element.file_name}
                            </Typography>
                          </Box>
                        ))
                    )}
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Divider />
                  <Box sx={{ display: 'inline-block' }}>
                    {remediationHistory.map((element: any, index) => (
                      <Typography variant="body2" sx={{ color: '#696969', mt: 2 }}>
                        {`At ${moment(element.created_at)
                          .format('DD MMM YYYY')
                          .toUpperCase()} as ${moment(element.created_at)
                          .format('hh:mm A zz')
                          .toUpperCase()} ${
                          element.owner.first_name + ' ' + element.owner.last_name
                        } ${element.action}`}
                      </Typography>
                    ))}
                  </Box>
                </TabPanel>
              </Box>
            </Box>
          </Box>

          {dataPosted && !prop.isEngineer ? (
            <Box></Box>
          ) : (
            <Box
              sx={{
                px: 3,
                py: 1,
                textAlign: 'end',
                bottom: 0,
                right: 0,
                position: value == 1 || value == 2 ? 'absolute' : 'relative'
              }}>
              {prop.isEngineer && (
                <Button
                  onClick={() => {
                    if (resolve) {
                      setResolve(false);

                      setEdit(false);
                    } else {
                      setEdit(!edit);
                    }
                  }}
                  sx={{
                    position: 'relative',
                    mr: 2,
                    fontWeight: 700,
                    justifySelf: 'flex-end',
                    minWidth: 150,
                    border: '1px solid #436AF3',

                    '&.Mui-disabled': {
                      backgroundColor: '#EBEBEB',
                      color: '#9D9D9D'
                    }
                  }}>
                  {edit || resolve ? 'Cancel' : 'Edit'}
                </Button>
              )}
              {!prop.isEngineer && resolve && (
                <Button
                  onClick={() => {
                    setResolve(false);
                  }}
                  sx={{
                    position: 'relative',
                    mr: 2,
                    fontWeight: 700,
                    justifySelf: 'flex-end',
                    minWidth: 150,
                    border: '1px solid #436AF3',

                    '&.Mui-disabled': {
                      backgroundColor: '#EBEBEB',
                      color: '#9D9D9D'
                    }
                  }}>
                  Cancel
                </Button>
              )}
              {prop.isEngineer ? (
                resolve ? (
                  <SuccessConfirmationUpadated
                    handleConfirm={(e) => {
                      handlePostData(e);
                    }}
                    title="Confirm Action"
                    type={DeleteTypesEnum.Button}
                    asking={'Are you sure you want to “Resolve” this Remediation?'}
                    returnData={'Submitted'}
                    mainBtnTitle={'Submit'}
                    mainBtnType={'primary'}
                    cancelButtonText="Cancel"
                    conformButtonText="Confirm"
                  />
                ) : rowData.status != 'resolved' ? (
                  <LoadingButton
                    loading={isEditButtonLoading}
                    type="submit"
                    onClick={() => {
                      handleSubmitEditData();
                    }}
                    sx={{
                      position: 'relative',
                      fontWeight: 700,
                      justifySelf: 'flex-end',
                      minWidth: 150,
                      backgroundColor: '#436AF3',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#436AF3'
                      },
                      '&.Mui-disabled': {
                        backgroundColor: '#EBEBEB',
                        color: isEditButtonLoading ? '#EBEBEB' : '#9D9D9D'
                      }
                    }}>
                    {edit ? 'Save' : 'Resolve'}
                  </LoadingButton>
                ) : rowData.status == 'resolved' && edit ? (
                  <LoadingButton
                    loading={isEditButtonLoading}
                    type="submit"
                    onClick={() => {
                      handleSubmitEditData();
                    }}
                    sx={{
                      position: 'relative',
                      fontWeight: 700,
                      justifySelf: 'flex-end',
                      minWidth: 150,
                      backgroundColor: '#436AF3',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#436AF3'
                      },
                      '&.Mui-disabled': {
                        backgroundColor: '#EBEBEB',
                        color: isEditButtonLoading ? '#EBEBEB' : '#9D9D9D'
                      }
                    }}>
                    {edit ? 'Save' : 'Resolve'}
                  </LoadingButton>
                ) : (
                  <Box></Box>
                )
              ) : resolve ? (
                <SuccessConfirmationUpadated
                  handleConfirm={(e) => {
                    handlePostData(e);
                  }}
                  title="Confirm Action"
                  type={DeleteTypesEnum.Button}
                  asking={'Are you sure you want to “Resolve” this Remediation?'}
                  returnData={'Submitted'}
                  mainBtnTitle={'Submit'}
                  mainBtnType={'primary'}
                  cancelButtonText="Cancel"
                  conformButtonText="Confirm"
                />
              ) : (
                rowData.status != 'resolved' && (
                  <Button
                    type="submit"
                    onClick={() => {
                      setResolve(true);
                    }}
                    sx={{
                      position: 'relative',
                      fontWeight: 700,
                      justifySelf: 'flex-end',
                      minWidth: 150,
                      backgroundColor: '#436AF3',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#436AF3'
                      },
                      '&.Mui-disabled': {
                        backgroundColor: '#EBEBEB',
                        color: '#9D9D9D'
                      }
                    }}>
                    Resolve
                  </Button>
                )
              )}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default RemidiationPopUpList;
