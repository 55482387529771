import styled from '@emotion/styled';
import {
  Button,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useCallback, useEffect, useState } from 'react';
// import BorderLinearProgress from '@jibin/common/components/LinearLoading';
import BorderLinearProgress from '@jibin/common/components/LinearLoading/BorderLinearGreen';
import CheckCircle from '@jibin/common/icons/CheckCircle/CheckCircle';
import GapAnalysisForm from './GapAnalysisForm';
import SearchIcon from '@jibin/common/icons/Search/Search';
import { shallowEqual, useSelector } from 'react-redux';
import { GapAnalysisApi } from './GapAnalysis.Api';
import { useLocation, useSearchParams } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

const GapAnalysis: FC = () => {
  const [securityValue, setSecurityValue] = useState(40);
  const [value, setValue] = useState(0);
  const [useParams, setUseParam] = useSearchParams();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const selectCategory = (index, el) => {
    
    setParams(el?.category);

    setValue(index);
    setCategory(el);
  };
  const setParams = (index) => {
    const params = {
      q: index
    };
    setUseParam(params);
  };

  const [completedScore, setCompletedScore] = useState(0);
  const [isRefresh, setIsRefresh] = useState(true);
  const [category, setCategory] = useState({} as any);
  const [categories, setCategories] = useState({} as any);
  const [filteredCategories, setFilteredCategories] = useState([] as any);
  const [searchText, setSearchText] = useState('');
  const [isAllComplete, setIsAllComplete] = useState(false);
  const handleRefresh = () => {
    setIsRefresh(!isRefresh);
  };

  const location = useLocation();

  const handleSearch = (text) => {
    let data = { ...categories };

    let filteredCategor = data.data.filter((q) =>
      q.category.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredCategories(filteredCategor);
  };

  const GetCategories = () => {
    GapAnalysisApi.GetCategories(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setCategories(q.data);

        setFilteredCategories(q.data.data);
        if (useParams.get('q')) {
          const filterdData = q.data.data.filter((el, index) => el.category == useParams.get('q'));
          const findeIndex = q.data.data.findIndex(
            (el, index) => el.category == useParams.get('q')
          );
          if (findeIndex != -1) {
            selectCategory(findeIndex, filterdData[0]);
          } else {
            selectCategory(0, q.data.data[0]);
          }
        } else {
          selectCategory(0, q.data.data[0]);
        }
        let total = 0;
        let completed = 0;

        q.data.data.forEach((element) => {
          completed += element.stats.completed;
          total += element.stats.total;
        });
        let isAllComplete = q.data.data.filter((q) => q.stats.total != q.stats.completed).length;
        if (isAllComplete < 1) {
          setIsAllComplete(true);
        } else {
          setIsAllComplete(false);
        }
        setCompletedScore(Math.round((completed / total) * 100));
      },
      (err: any) => {}
    );
  };
  const [engineer, setEngineer] = useState(null);

  useEffect(() => {
    GetCategories();
    if (engineer == null) {
      if (commonDetails.data.group == 'engineer') {
        setEngineer(true);
      } else {
        setEngineer(false);
      }
    }
  }, [commonDetails]);

  const handleRadioChange = (e: any) => {
    
    if (e.target.value == 'true') {
      setEngineer(true);
    } else {
      setEngineer(false);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          backgroundColor: '#FFFFFF',
          p: 4,
          gap: 1
        }}>
        <Typography variant={'subtitle1'}>Gap Analysis</Typography>
        <Typography variant={'subtitle2'}>
          Assessment of your cybersecurity program against a leading framework
        </Typography>
        {(commonDetails.data.group == 'superadmin' || commonDetails.data.group == 'csc') && (
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="user"
            name="radio-buttons-group"
            onChange={(e) => {
              handleRadioChange(e);
            }}
            value={engineer}
            row>
            <FormControlLabel value={true as any} control={<Radio />} label="Engineer" />
            <FormControlLabel value={false as any} control={<Radio />} label="User" />
          </RadioGroup>
        )}
      </Box>
      <Box sx={{ p: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3.25}>
            <Box
              sx={{
                border: '1px solid #D5D5D5',
                // height: '73vh',
                minHeight: '60vh',
                maxHeight: '72vh',
                overflowY: 'hidden',
                borderRadius: 2,
                backgroundColor: '#FFFFFF'
              }}>
              <Box sx={{ p: 2 }}>
                <Box sx={{ mb: 2 }}>
                  <Typography sx={{ fontWeight: 600, color: '#696969' }} variant="body2">
                    To complete your security journey{' '}
                  </Typography>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box
                      sx={{
                        width: 0.8,

                        p: 0.5,
                        borderRadius: '10px',
                        backgroundColor: '#F2F9FF'
                      }}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={completedScore}
                        sx={{
                          '.MuiLinearProgress-bar': {
                            borderRadius: 5,
                            backgroundColor: '#059669'
                          }
                        }}
                      />
                    </Box>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      {Math.round((completedScore + Number.EPSILON) * 10) / 10}%
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <TextField
                    id="name"
                    name="name"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      handleSearch(e.target.value);
                    }}
                    InputProps={{
                      sx: { fontSize: '14px', height: '36px' },
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                    sx={{ width: 1, minWidth: 0 }}
                    placeholder="Search Category"
                  />
                </Box>
                {filteredCategories?.length == 0 && searchText && (
                  <Typography variant="caption" sx={{ color: '#EF4444' }}>
                    No Search Results
                  </Typography>
                )}

                <Box
                  sx={{
                    flexGrow: 1,
                    mt: 2,
                    display: 'flex',
                    minHeight: '45vh',
                    maxHeight: '55vh'
                  }}>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    TabIndicatorProps={{
                      style: {
                        width: 0
                      }
                    }}
                    scrollButtons={false}
                    onChange={handleChange}
                    aria-label="Vertical tabs example">
                    {filteredCategories?.map((el, index) => (
                      <Tab
                        icon={<CheckCircle sx={{ color: value != index && '#27AE60' }} />}
                        iconPosition="start"
                        sx={{
                          textJustify: 'start',
                          display: 'flex',
                          p: 0,
                          mb: 2,
                          minHeight: 0,
                          minWidth: 0,
                          justifyContent: 'flex-start',
                          textAlign: 'start',

                          textTransform: 'none',

                          '&.Mui-selected': {
                            color: '#436AF3'
                          }
                        }}
                        component={() => (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mb: 2,
                              gap: 1,
                              cursor: 'pointer',
                              wordWrap: 'break-word'
                            }}>
                            <CheckCircle
                              sx={{
                                color:
                                  value != index
                                    ? el.stats.total == el.stats.completed
                                      ? '#27AE60'
                                      : 'grey'
                                    : '#436AF3'
                              }}
                            />{' '}
                            <Typography
                              sx={{
                                whiteSpace: 'normal',
                                color: value != index ? '#464646' : '#436AF3'
                              }}
                              variant={'body2'}
                              onClick={() => selectCategory(index, el)}>
                              {el.category}
                              <span style={{ color: value != index ? 'grey' : '#436AF3' }}>
                                ({el.stats.completed}/{el.stats.total})
                              </span>
                            </Typography>
                          </Box>
                        )}
                        label={`${el.category}(${el.stats.completed}/${el.stats.total} )`}
                        {...a11yProps(index)}
                      />
                    ))}
                  </Tabs>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={8.75}>
            {filteredCategories?.length == 0 && searchText ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  m: 'auto',
                  mt: 15
                }}>
                <Box sx={{ textAlign: 'center' }}>
                  <img width={'50%'} src="/images/NoSearchFound.png" />
                  <Typography variant="h2" sx={{ fontWeight: 600 }}>
                    No Search Results
                  </Typography>
                  <Typography sx={{ mt: 2, textAlign: 'center', color: '#696969' }} variant="body2">
                    Try adjusting your search or filter to find what you are looking for
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  maxHeight: '72vh',
                  minHeight: '60vh'
                }}>
                {filteredCategories?.map((el, index) => (
                  <TabPanel value={value} index={index}>
                    <GapAnalysisForm
                      el={el}
                      GetCategories={() => GetCategories()}
                      isEngineer={engineer}
                      isAllComplete={isAllComplete}
                    />
                  </TabPanel>
                ))}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default GapAnalysis;
