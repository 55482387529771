import ConformationModel from '@jibin/common/components/ConformationModel';
import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Table, { TableProps } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CompanySettingsService } from '../CompanySettings.Api';
import { ListTableProps } from './Models/ListTableProps';
import { ApiResponse, Users } from './Models/UserList.model';
import { DeleteTypesEnum } from '@jibin/common/utils/enums/deleteTypeEnums';
import InviteUserModel from './InviteUserModel';
import { notifySuccess } from '@jibin/common/services/http/http-common';
import { UserModel } from './Models/User.Model';
import { UserProp } from './Models/UserProp';
import { Divider, IconButton, Menu, Skeleton, Typography } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
const label = { inputProps: { 'aria-label': 'Switch demo' } };
var debounce = require('lodash.debounce');
const RowUser = ({ row, index, handleRefresh, handleConfirm }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: any, row: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow
      id={row.user_uuid}
      key={row?.first_name + index}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component="th" scope="row">
        {row.first_name && row?.first_name + ' ' + row?.last_name}
      </TableCell>
      <TableCell>{row.email}</TableCell>
      <TableCell>{`${row.role.charAt(0).toUpperCase()}${row.role.substring(1)}`}</TableCell>
      <TableCell>
        <Box>
          {row.status === 'ACTIVE' ? (
            <Box
              sx={{
                ...Style.SiteListTable.StatusActive,
                bgcolor: 'common.green'
              }}></Box>
          ) : row.status === 'PENDING' ? (
            <Box
              sx={{
                ...Style.SiteListTable.StatusActive,
                bgcolor: '#FBBF24'
              }}></Box>
          ) : (
            <Box
              sx={{
                ...Style.SiteListTable.StatusActive,
                bgcolor: '#EF4444'
              }}></Box>
          )}

          {row.status.charAt(0).toUpperCase() + row.status.substr(1).toLowerCase()}
        </Box>
      </TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="Example"
          onClick={(e) => {
            handleClick(e, row);
          }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}>
          <MoreHorizIcon color="primary" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id={'account-menu'}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          <InviteUserModel
            key={row.user_uuid + row.role}
            UserModel={{ ...row }}
            IsEdit={true}
            handleSubmit={() => {
              handleRefresh();
              handleClose();
            }}
          />
          <Divider />

          <ConformationModel
            handleConfirm={() => {
              handleConfirm(row);
              handleClose();
            }}
            title="Confirm Action"
            data={row}
            handleClose={() => {}}
            label={{ ...label }}
            type={DeleteTypesEnum.SwitchAndLabel}
            description={
              row?.status.toUpperCase() == 'ACTIVE'
                ? `Are you sure you want to inactivate this  "${row.first_name}  ${row.last_name}" ?`
                : row?.status.toUpperCase() == 'INACTIVE' && row?.first_name
                ? `Are you sure you want to activate this  "${row.first_name}  ${row.last_name}" ?`
                : row?.status.toUpperCase() == 'PENDING'
                ? `Are you sure you want to inactivate this  "${row.email}" ?`
                : `Are you sure you want to activate this  "${row.email}" ?`
            }
            cancelButtonText="Cancel"
            conformButtonText="Confirm"
          />
        </Menu>
      </TableCell>
    </TableRow>
  );
};
export const UsersListTable: FC<ListTableProps> = (props) => {
  const [UserListData, setUserListData] = useState(new ApiResponse());
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const data: UserProp = {
    UserModel: new UserModel(),
    IsEdit: false,
    handleSubmit() {}
  };
  const location = useLocation();
  const getUsers = useCallback(() => {
    CompanySettingsService.GetUserList(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setUserListData(q.data);
        setIsSkeleton(false);
        if (searchParams.get('user_uuid')) {
          const filterdData = q.data.data.filter(
            (el, index) => el.user_uuid == searchParams.get('user_uuid')
          );

          setTimeout(() => {
            const element = document.getElementById(filterdData[0].user_uuid);
            if (element) {
              var headerOffset = 90;
              var elementPosition = element.getBoundingClientRect().top;
              var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
              });
              element.classList.add('flash');
              setTimeout(() => {
                element.classList.remove('flash');
              }, 1000);
            }
          }, 1);
          searchParams.delete('user_uuid');
          setSearchParams(searchParams);
        }
      },
      (err: any) => {}
    );
  }, [commonDetails, location.search]);

  useEffect(() => {
    getUsers();
  }, [getUsers, isRefresh]);

  const handleRefresh = () => {
    setIsRefresh(!isRefresh);
  };

  const handleConfirm = useCallback(
    debounce(
      (row: Users) => {
        let status;

        row.status.toUpperCase() == 'ACTIVE'
          ? (status = 'INACTIVE')
          : row?.status.toUpperCase() == 'INACTIVE' && row?.first_name
          ? (status = 'ACTIVE')
          : row?.status.toUpperCase() == 'PENDING'
          ? (status = 'INACTIVE')
          : (status = 'PENDING');
        row = { ...row, status: status };
        CompanySettingsService.putUser(
          commonDetails.data.user_uuid,
          commonDetails.data.company.company_uuid,
          row.user_uuid,
          row
        ).then(
          (q: any) => {
            setIsRefresh(!isRefresh);
            if (q.data.data.status.toUpperCase() == 'INACTIVE') {
              notifySuccess('User is deactivated successfully.');
            } else if (q.data.data.status.toUpperCase() == 'ACTIVE') {
              notifySuccess('User is activated successfully.');
            } else if (q.data.data.status.toUpperCase() == 'PENDING') {
              notifySuccess('Status changed successfully');
            }
          },
          (err: any) => {}
        );
      },
      500,
      true
    ),
    [UserListData]
  );

  if (isSkeleton) {
    return (
      <Box>
        <Skeleton sx={{ p: 5 }} />
      </Box>
    );
  } else if (UserListData?.data?.length == 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', m: 'auto' }}>
        <Box sx={{ textAlign: 'center' }}>
          <img width={'50%'} src="/images/NoDataFound.png" />
          <Typography sx={{ mt: '10px', textAlign: 'center', color: '#696969' }} variant="body2">
            Please click the button below to invite other users to the Trust Portal.
          </Typography>
          <Box sx={{ ...Style.CompanySettingPage.AddButton, textAlign: 'center', mt: 2 }}>
            <InviteUserModel
              {...data}
              handleSubmit={() => {
                handleRefresh();
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box sx={Style.SiteListTable.SiteListTableWrapper}>
        <Box sx={Style.CompanySettingPage.AddButton}>
          <InviteUserModel
            {...data}
            handleSubmit={() => {
              handleRefresh();
            }}
          />
        </Box>

        <Table sx={{ minWidth: 650 }} aria-label="site list table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {UserListData?.data?.map((row, index) => (
              <>
                {row.user_uuid != commonDetails.data.user_uuid && (
                  <>
                    <Box key={row?.first_name + index + index} sx={Style.Common.Divider}></Box>
                    <RowUser
                      row={row}
                      index={index}
                      handleConfirm={(row) => handleConfirm(row)}
                      handleRefresh={() => handleRefresh()}
                    />
                  </>
                )}
              </>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default UsersListTable;
