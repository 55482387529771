import CloseIcon from '@jibin/common/icons/Close/Close';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import { useState } from 'react';

const ChipBox = (prop) => {
  const { option, getTagProps, index, setValue, tag, values, value } = prop;
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteOption, setDeleteOption] = useState('');

  const handleClickOpenDelete = (option, value) => {
    console.log(option);
    setDeleteOption(option);
    const item = values;

    item[tag] = value;
    setValue(item);

    setOpenDelete(true);
  };
  const handleConfirmDelete = (deleteFacility) => {
    const updatedFacility = value.filter((el, index) => el !== deleteFacility);
    console.log(updatedFacility);
    const item = values;

    item[tag] = updatedFacility;
    console.log(item);
    setValue({ ...value, ...item });

    setOpenDelete(false);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  return (
    <Box>
      <Chip
        variant="outlined"
        label={option}
        {...getTagProps({ index })}
        onDelete={() => handleClickOpenDelete(option, value)}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            px: { xs: 1, md: 2 },
            py: { xs: 0.5, md: 1 },
            bgcolor: 'error.red100',
            mb: { xs: 2, md: 3 }
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'error.main',
                fontWeight: '600',
                maxWidth: 'calc(100% - 40px)',
                p: 1
              }}>
              <WarningIcon sx={{ mr: 1 }} />
              Confirm Deletion
            </Typography>
            <IconButton
              sx={{ color: 'text.primary' }}
              aria-label="close"
              onClick={handleCloseDelete}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: 'text.primary', fontWeight: 700, mb: 1 }}>
            Are you sure do you want to delete this {tag}?
          </DialogContentText>

          <DialogContentText id="alert-dialog-description" sx={{ color: 'text.primary' }}>
            {deleteOption}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0 }}>
          <Button variant="outlined" sx={{ minWidth: 100 }} onClick={handleCloseDelete}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ minWidth: 100 }}
            color="error"
            onClick={() => handleConfirmDelete(deleteOption)}>
            {' '}
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default ChipBox;
