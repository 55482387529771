export const NotificationSourceTypeConstants = {
    GapAnalysis: 'Gap Analysis',
    Remediation: "Remediation",
    RemediationTracker: "Remediation Tracker",


    Vulnerability: "Vulnerability Management (Rapid7)",
    Penetration: "Penetration Test",
    Endpoint: "Endpoint Protection",
    CustomerSuccess: "Customer Success Services Review",
    MyAccount: "My Account",
    CompanyInformation: "Company Information",
    ManageUsers: "Manage Users",
    Meetings: "Meetings",
    Locations: "Company Information - Locations page",
    Authorizations: "Authorizations",
    PlanandBilling: "Plan and Billing",
    TechnicalInformation: "Technical Information",
    TechnicalInformationApplications: "Technical Information - Applications page",
    TechnicalInformationIPRanges: "Technical Information - IP Ranges page",
    TechnicalInformationWireless: "Technical Information - Wireless Networks page",
    //Technical Information - Applications page
    //Technical Information - Applications page
}