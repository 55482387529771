import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC, useCallback, useEffect, useState } from 'react';
import { CompanySettingsService } from '../CompanySettings.Api';
import { shallowEqual, useSelector } from 'react-redux';
import ScheduleMeetingsModel from './ScheduleMeetingsModel';
import moment from 'moment';
import { Typography } from '@mui/material';
const momentTimezone = require('moment-timezone');
export const MeetingsTable: FC<{ isRefresh: any }> = (prop) => {
  const [meetingListData, setMeetingListData] = useState({});
  const [isSkeleton, setIsSkeleton] = useState(true);

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const GetCompanyWebsites = useCallback(() => {
    CompanySettingsService.GetMeetingsList(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setMeetingListData(q.data);
        setIsSkeleton(false);
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  useEffect(() => {
    GetCompanyWebsites();
  }, [GetCompanyWebsites, prop]);
  if (meetingListData['data']?.length == 0) {
    return <></>;
  }
  return (
    <>
      {' '}
      {!isSkeleton && (
        <>
          <Typography variant="subtitle2" sx={{ fontWeight: '600', mt: { xs: 3, sm: 5 } }}>
            My Meetings
          </Typography>
          <Box sx={Style.SiteListTable.SiteListTableWrapper}>
            <Table sx={{ minWidth: 650 }} aria-label="site list table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ minWidth: 200 }}>Meetings</TableCell>
                  <TableCell sx={{ minWidth: 150 }}>Date</TableCell>
                  <TableCell sx={{ minWidth: 200 }}>Time</TableCell>
                  <TableCell sx={{ minWidth: 150 }}>Host</TableCell>
                  <TableCell sx={{ minWidth: 300 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {meetingListData['data'] &&
                  meetingListData['data']
                    .sort((a: any, b: any) => (a.start_at > b.start_at ? 1 : -1))
                    .map((row) => (
                      <>
                        <Box sx={Style.Common.Divider}></Box>
                        <TableRow
                          key={row.company_meetings_uuid}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell>
                            {moment(row.start_at).isValid() &&
                              moment(row.start_at).format('DD MMM YYYY').toUpperCase()}
                          </TableCell>
                          {moment(row.start_at).isValid() ? (
                            <TableCell>
                              {momentTimezone(row.start_at)
                                .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                                .format('hh:mm A')}{' '}
                              -{' '}
                              {momentTimezone(row.start_at)
                                .add(
                                  momentTimezone(row.duration, 'hh:mm:ss').diff(
                                    momentTimezone().startOf('day'),
                                    'seconds'
                                  ),
                                  'seconds'
                                )
                                .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                                .format('hh:mm A zz')}{' '}
                            </TableCell>
                          ) : (
                            <TableCell></TableCell>
                          )}

                          <TableCell>{row.host}</TableCell>
                          <TableCell align="right">
                            {/* <Button variant="outlined" sx={{ minWidth: 100, }} >Reschedule</Button> */}
                            <Box sx={Style.SiteListTable.ActionIcons}>
                              {/* <ScheduleMeetingsModel Meeting={row}  isReschedule={true} /> */}
                              <Button
                                onClick={() => {
                                  window.open(row.data?.reschedule_url, '_blank');
                                }}
                                variant="outlined"
                                sx={{ minWidth: 100, ml: 1 }}>
                                Reschedule
                              </Button>
                              <Button
                                onClick={() => {
                                  window.open(row.data?.location, '_blank');
                                }}
                                variant="contained"
                                sx={{ minWidth: 100, ml: 1 }}>
                                Join Meeting
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
              </TableBody>
            </Table>
          </Box>
        </>
      )}
    </>
  );
};

export default MeetingsTable;
