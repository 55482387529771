
import { AuthContainer } from "@jibin/common/components/AuthContainer";
import React from "react"
import { Navigate, Outlet, useLocation, useOutlet } from "react-router-dom"
import { PageRouteConstants } from "../constants/PageRouteConstants";

function PublicRoutes({children}:{children : React.ReactElement}): React.ReactElement
{    
    let auth = localStorage.getItem("cognito-token");
    let location = useLocation()
    if(auth){
        return <Navigate to={PageRouteConstants.OnBoarding} state={{from: location}} replace />;
    }

    //return children;
    return (
        <AuthContainer> {children}</AuthContainer>
             )
}

export default PublicRoutes;