import SvgIcon from '@mui/material/SvgIcon';

export default function EndpointProtection(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.31874 17.7224L5.31875 17.7224L5.31874 17.7224ZM5.31874 17.7224L5.16813 17.3563L5.31874 17.7224ZM1.53104 13.4771H18.469L17.0035 14.4423C17.0034 14.4423 17.0034 14.4423 17.0034 14.4423H12.16H11.76V14.8423V17.3265V17.7182H8.24V17.3265V14.8423V14.4423H7.84H2.99683C2.99679 14.4423 2.99674 14.4423 2.9967 14.4423C2.60546 14.4422 2.2279 14.2984 1.93571 14.0382C1.76004 13.8818 1.62258 13.6898 1.53104 13.4771ZM14.6812 17.7182C14.6813 17.7182 14.6813 17.7182 14.6813 17.7182H14.6812ZM14.6813 17.7265C14.6813 17.7265 14.6813 17.7265 14.6812 17.7265H14.6813ZM5.31873 17.7182C5.31874 17.7182 5.31875 17.7182 5.31876 17.7182H5.31873ZM5.31876 17.7265C5.31875 17.7265 5.31874 17.7265 5.31873 17.7265H5.31876Z" stroke="white" strokeWidth="0.8"/>
<path d="M11.6927 10.5454L11.6927 10.5454C12.2746 10.2272 12.7601 9.75822 13.0983 9.18776C13.4365 8.6173 13.615 7.96634 13.615 7.30315V4.877V4.603L13.354 4.51974L10.114 3.48627L10 3.44992L9.88604 3.48627L6.64604 4.51975L6.385 4.60302V4.87702V7.30314C6.38501 7.96636 6.56351 8.61735 6.90177 9.18781C7.24004 9.75828 7.72559 10.2272 8.3075 10.5454L9.82007 11.3726L9.99999 11.4709L10.1799 11.3726L11.6927 10.5454ZM17.624 2.38028L17.7675 2.03383L17.624 2.38028C17.8207 2.46178 17.9995 2.58123 18.1501 2.7318C18.3007 2.88238 18.4201 3.06115 18.5016 3.25788C18.5831 3.45463 18.625 3.66549 18.625 3.87844V11.9103H1.375V3.87846C1.375 3.87846 1.375 3.87845 1.375 3.87844C1.37503 3.44836 1.5459 3.03591 1.85002 2.7318C2.15414 2.42769 2.56661 2.25684 2.9967 2.25684C2.9967 2.25684 2.9967 2.25684 2.9967 2.25684H17.0035C17.2164 2.25684 17.4273 2.29879 17.624 2.38028Z" stroke="white" strokeWidth="0.75"/>
<path d="M8.60464 7.31221C8.56792 7.27533 8.52428 7.24607 8.47624 7.22608C8.42819 7.20609 8.37667 7.19577 8.32463 7.19572C8.27259 7.19567 8.22105 7.20588 8.17296 7.22577C8.12487 7.24566 8.08118 7.27484 8.04438 7.31164C8.00758 7.34843 7.97841 7.39213 7.95852 7.44022C7.93862 7.4883 7.92841 7.53984 7.92847 7.59188C7.92852 7.64392 7.93884 7.69544 7.95882 7.74349C7.97881 7.79154 8.00808 7.83517 8.04495 7.87189L9.06273 8.88968C9.0995 8.92646 9.14315 8.95564 9.1912 8.97555C9.23924 8.99545 9.29074 9.0057 9.34275 9.0057C9.39475 9.0057 9.44625 8.99545 9.4943 8.97555C9.54234 8.95564 9.586 8.92646 9.62276 8.88968L11.9554 6.55671C11.9921 6.51992 12.0213 6.47624 12.0411 6.42818C12.061 6.38013 12.0712 6.32862 12.0712 6.27662C12.0712 6.22461 12.0609 6.17312 12.041 6.12509C12.021 6.07706 11.9918 6.03342 11.955 5.99667C11.9182 5.95992 11.8746 5.93078 11.8265 5.91091C11.7784 5.89104 11.7269 5.88083 11.6749 5.88086C11.6229 5.88089 11.5714 5.89117 11.5234 5.9111C11.4754 5.93103 11.4317 5.96023 11.395 5.99703L9.34258 8.0498L8.60464 7.31221Z" fill="white"/>
</svg>
  );
}
