import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import FileIcon from '@jibin/common/icons/FileIcon/FileIcon';
import GallaryIcon from '@jibin/common/icons/GallaryIcon/GallaryIcon';
import PinIcon from '@jibin/common/icons/PinIcon/PinIcon';
import UploadIcon from '@jibin/common/icons/UploadIcon/UploadIcon';
import VideoPlay from '@jibin/common/icons/VideoPlay/VideoPlay';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import Shareicon from '@jibin/common/icons/Shareicon/Shareicon';
import {
  Box,
  List,
  Typography,
  Button,
  TextField,
  ListItem,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  Select
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { GapAnalysisApi } from '../../GapAnalysis.Api';
import { notifySuccess } from '@jibin/common/services/http/http-common';
import { useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
var debounce = require('lodash.debounce');
export interface GapAnalysisSideBarProp {
  rowData: any;
  index: any;

  isEngineer: boolean;
  closePopup(event: React.KeyboardEvent | React.MouseEvent): void;
}
const GapAnalysisSideBar: FC<GapAnalysisSideBarProp> = (prop) => {
  const [fileData, setFileData] = useState([] as any);

  const [error, setError] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([{ name: 'xyz' }]);
  const { rowData, closePopup, index, isEngineer } = prop;
  const [postData, setPostData] = useState({ answer: '' });
  const [isUploade, setIsUpload] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [postDataEngineer, setPostDataEngineer] = useState({
    status: 'pending',
    severity: 'high',
    title: '',
    recommendation: ''
  });
  const [values, setValues] = useState('high');
  const [isAnswerButtonLoading, setIsAnswerButtonLoading] = useState(false);
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  function niceBytes(x) {
    let l = 0,
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
  }
  const handleChangeEngineer = (e: any) => {
    if (e.target.name == 'recommendation') {
      if (e.target.value.length < 1000) {
        setPostDataEngineer({ ...postDataEngineer, [e.target.name]: e.target.value });
      } else {
        setPostDataEngineer({
          ...postDataEngineer,
          [e.target.name]: postDataEngineer.recommendation
        });
      }
    } else {
      setPostDataEngineer({ ...postDataEngineer, [e.target.name]: e.target.value });
    }
  };
  const handleChange = (event: any) => {
    setError(false);
    if (event.target.value.length < 1000) {
      setPostData({ ...postData, answer: event.target.value });
    } else {
      setPostData({ ...postData, answer: postData.answer });
    }
  };
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [engineer, setEngineer] = useState(false);
  const handleChangeDropdown = (e: any) => {
    setValues(e.target.value);
  };

  const handleRemoveFile = (i) => {
    const upadatedFileData = fileData.filter((el, index) => index != i);
    setFileData(upadatedFileData);
  };

  const handleSubmit = useCallback(
    debounce(
      async (e: any) => {
        e.preventDefault();
        if (postData.answer == '') {
          setError(true);
          return;
        }
        try {
          setIsAnswerButtonLoading(true);
          if (engineer) {
            const res = await GapAnalysisApi.PostAnswersEngineer(
              commonDetails.data.user_uuid,
              commonDetails.data.company.company_uuid,
              rowData.questionnaires_uuid,
              rowData.answer.questionnaire_answers_uuid,
              postDataEngineer
            );
            closePopup(e);
          } else if (rowData?.answer.questionnaire_answers_uuid) {
            const formData = new FormData();

            for (let i = 0; i < fileData.length; i++) {
              formData.append('files', fileData[i]);
            }
            let data = { comment: postData.answer };
            formData.append('data', JSON.stringify(data));
            const res = await GapAnalysisApi.PutAnswers(
              commonDetails.data.user_uuid,
              commonDetails.data.company.company_uuid,
              rowData.questionnaires_uuid,
              rowData.answer.questionnaire_answers_uuid,
              formData
            );
            closePopup(e);
          } else {
            const formData = new FormData();
            for (let i = 0; i < fileData.length; i++) {
              formData.append('files', fileData[i]);
            }
            let data = { comment: postData.answer };
            formData.append('data', JSON.stringify(data));

            const res = await GapAnalysisApi.PostAnswers(
              commonDetails.data.user_uuid,
              commonDetails.data.company.company_uuid,
              rowData.questionnaires_uuid,
              formData
            );
            closePopup(e);
          }
        } catch (err) {
        } finally {
          setTimeout(() => {
            setIsAnswerButtonLoading(false);
          }, 1000);
        }
      },
      500,
      true
    ),
    [postData, fileData]
  );

  const handleImageUpload = (e, files) => {
    stopReload(e);

    if (!e) return;

    setFileData([...fileData, ...files]);
  };

  const stopReload = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleBrowseEvent = (e: any) => {
    setFileData([...fileData, ...e.target.files]);
  };

  const handleDragEvent = (e: any) => {
    handleImageUpload(e, e.dataTransfer.files);
  };
  const getFile = async (el) => {
    const res = await GapAnalysisApi.GetFile(
      commonDetails.data.company.company_uuid,
      commonDetails.data.user_uuid,
      rowData.questionnaires_uuid,
      rowData.answer.questionnaire_answers_uuid,
      el.id
    );
    //const res = await GapAnalysisApi.GetFile(el.s3key);

    const blob = new Blob([res?.data], { type: res.headers['content-type'] });

    const urlName = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = urlName;
    //a.download = `downloadfile.${res.headers['content-type'].split('/')[1]}`;
    a.download = `${el.file_name}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    setPostData({ ...postData, answer: rowData?.answer?.comment });
    // setPostDataEngineer({ ...postDataEngineer, ...rowData?.answer?.feedback });
    if (rowData?.answer?.feedback?.status) {
      setPostDataEngineer({ ...postDataEngineer, ...rowData?.answer?.feedback });
    }
    if (isEngineer) {
      setEngineer(true);
    } else {
      setEngineer(false);
    }

    setUploadedFiles([]);
    setIsUpload(false);
    return () => {
      setPostDataEngineer({
        status: 'pending',
        severity: 'high',
        title: '',
        recommendation: ''
      });
    };
  }, [commonDetails, prop]);
  return (
    <>
      <Box
        sx={{
          width: { md: 520, xs: 300 },

          position: 'relative',
          minheight: '87vh',
          height: fileData.length > 5 ? '100vh' : '87vh'
        }}
        role="presentation">
        <List sx={{ p: 0 }}>
          <Box sx={{ backgroundColor: '#003260', px: 2.5, py: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 1,
                alignItems: 'center'
              }}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 600, fontSize: '19px', color: 'white' }}>
                  Question Category
                  {/* {engineer ? 'Source: Gap Analysis' : 'Question Category'} */}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    notifySuccess('Copied');
                  }}>
                  <Shareicon />
                </Box>
                <Box
                  onClick={(event) => {
                    closePopup(event);
                  }}>
                  <CrossCancle
                    sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                  />
                </Box>
              </Box>
            </Box>
            {/* {engineer && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2" sx={{ color: '#FFFFFF' }}>
                  Discovered: 12 JAN 2023
                </Typography>
                <Box
                  sx={{
                    backgroundColor: '#FEF3C7',
                    py: '6px',
                    px: '30px',
                    fontSize: '12px',
                    borderRadius: '100px'
                  }}>
                  In Progress
                </Box>
              </Box>
            )} */}
          </Box>
        </List>
        <List sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, mb: 2 }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: 600, mb: 1.25, color: '#2D2D2D', fontSize: '19px' }}>
              <span>{index + 1}.</span>
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 600,
                mb: 1.25,
                color: '#2D2D2D',
                fontSize: '19px',
                textAlign: 'justify'
              }}>
              {rowData.question}
            </Typography>
          </Box>
          {commonDetails.data.company.gap_status != 'draft' ? (
            <Box sx={{ backgroundColor: '#F2F9FF', padding: 2.5, borderRadius: '8px', my: 1 }}>
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: ' #696969',
                    wordBreak: 'break-word',
                    wordWrap: 'break-word'
                  }}>
                  {postData.answer}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box sx={{ position: 'relative' }}>
              <TextField
                autoComplete={'none'}
                error={error}
                helperText={error && 'This field is Required'}
                value={postData.answer}
                // defaultValue={rowData?.answer?.comment}
                onChange={handleChange}
                multiline
                rows={7}
                sx={{ fontSize: '14px', textAlign: 'justify', width: 1 }}
                placeholder="Type your answer here..."
              />

              <Typography
                variant="body2"
                sx={{
                  color: '#B7B7B7',
                  textAlign: 'end',
                  position: 'absolute',
                  right: 8,
                  bottom: 6
                }}>
                {`${postData?.answer?.length ? postData?.answer?.length : 0}/999`}
              </Typography>
            </Box>
          )}

          <Box></Box>
        </List>
        <List
          sx={{
            p: 3
          }}>
          {commonDetails.data.company.gap_status != 'draft' ? (
            <Box></Box>
          ) : (
            <Box>
              <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
                Upload Evidence
              </Typography>
              <Box
                onDragOver={(e) => stopReload(e)}
                onDrop={(e) => handleDragEvent(e)}
                sx={{
                  width: '100%',
                  backgroundColor: '#FBFBFF',
                  py: 4,
                  display: 'flex',
                  border: '1px dashed #CCCCCC',
                  borderRadius: '8px',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2.5
                }}>
                <UploadIcon />
                <Typography variant="body1" sx={{ fontWeight: 600, color: '#464646' }}>
                  Drag & Drop or{' '}
                  <label style={{ color: '#436AF3', cursor: 'pointer' }}>
                    <input
                      multiple
                      hidden
                      onChange={(e) => handleBrowseEvent(e)}
                      style={{
                        backgroundColor: 'transparent',
                        color: 'transparent'
                      }}
                      type={'file'}
                    />{' '}
                    Choose file{' '}
                  </label>
                  to upload
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                    width: '100%',
                    alignItem: 'center',
                    m: 'auto'
                  }}>
                  {fileData.length == 0 || isUploade ? (
                    <Typography textAlign={'center'} variant="body2" sx={{ color: '#787878' }}>
                      doc,img, or video
                    </Typography>
                  ) : (
                    fileData?.map((el, index) => (
                      <Box
                        key={index + 1}
                        sx={{
                          display: 'flex',
                          width: '80%',
                          m: 'auto',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          p: 1.5,
                          borderRadius: '8px',
                          backgroundColor: '#FFFFFF',
                          border: '1px solid #EBEBEB'
                        }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {el.type.split('/')[0] == 'image' ? (
                            <GallaryIcon />
                          ) : el.type.split('/')[0] == 'video' ? (
                            <VideoPlay />
                          ) : (
                            <FileIcon />
                          )}

                          <EllipsisContent
                            sx={{
                              fontSize: 14,
                              color: '#2D2D2D',
                              width: 'max-content',
                              maxWidth: {
                                xxs: 150,
                                md: 150
                              }
                            }}
                            text={el.name}
                          />

                          <Typography variant="body2" sx={{ color: '#787878' }}>
                            {niceBytes(el.size)}
                          </Typography>
                        </Box>
                        <Box onClick={() => handleRemoveFile(index)}>
                          <CrossCancle
                            sx={{
                              background: 'rgba(120, 120, 120,0.1)',
                              width: '20px',
                              height: '20px',
                              color: '#787878',
                              p: '6px',
                              borderRadius: '50%',
                              cursor: 'pointer'
                            }}
                          />
                        </Box>
                      </Box>
                    ))
                  )}
                </Box>
              </Box>
            </Box>
          )}
          {rowData?.answer?.files?.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 600, color: '#696969', mb: 2 }}>
                Files:
              </Typography>

              <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 1.5 }}>
                {rowData?.answer?.files?.map((el, index) => (
                  <Box
                    onClick={() => getFile(el)}
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      backgroundColor: '#D9EDFF',
                      py: '5px',
                      px: 1.5,
                      borderRadius: '100px',
                      cursor: 'pointer'
                    }}>
                    <PinIcon />
                    <Typography variant="body1" sx={{ color: '#436AF3' }}>
                      {el.file_name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </List>
        {/* {engineer && (
          <Box>
            <Divider sx={{ mx: 3 }} />
            <Box sx={{ p: 3 }}>
              <Typography variant="body1" sx={{ fontWeight: 600, mb: 1, color: '#2D2D2D' }}>
                Does evidence meet the control?
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                  Control Status:
                </Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    value={postDataEngineer.status || 'pending'}
                    name="status"
                    onChange={handleChangeEngineer}
                    displayEmpty
                    sx={{
                      '.MuiSelect-select': {
                        py: 1,
                        display: 'flex',
                        alignItems: 'center'
                      },
                      borderRadius: '100px'
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="does_not_meet">
                      <Box
                        sx={{
                          height: '12px',
                          width: '12px',
                          backgroundColor: '#EF4444',
                          borderRadius: '50%',
                          mr: 1
                        }}></Box>
                      <Typography variant="body2">Does Not Meet</Typography>
                    </MenuItem>
                    <MenuItem value={'pending'}>
                      {' '}
                      <Box
                        sx={{
                          height: '12px',
                          width: '12px',
                          backgroundColor: '#787878',
                          borderRadius: '50%',
                          mr: 1
                        }}></Box>
                      <Typography variant="body2">Pending</Typography>
                    </MenuItem>
                    <MenuItem value={'partially_meets'}>
                      {' '}
                      <Box
                        sx={{
                          height: '12px',
                          width: '12px',
                          backgroundColor: '#FBBF24',
                          borderRadius: '50%',
                          mr: 1
                        }}></Box>
                      <Typography variant="body2">Partially Meets</Typography>
                    </MenuItem>
                    <MenuItem value={'fully_meets'}>
                      {' '}
                      <Box
                        sx={{
                          height: '12px',
                          width: '12px',
                          backgroundColor: '#27AE60',
                          borderRadius: '50%',
                          mr: 1
                        }}></Box>
                      <Typography variant="body2">Fully Meets</Typography>
                    </MenuItem>
                    <MenuItem value={'not_applicable'}>
                      {' '}
                      <Box
                        sx={{
                          height: '12px',
                          width: '12px',
                          backgroundColor: '#D9E1FD',
                          borderRadius: '50%',
                          mr: 1
                        }}></Box>
                      <Typography variant="body2">Not Applicable</Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Divider sx={{ mx: 3 }} />
            <Box sx={{ p: 3 }}>
              <Typography variant="body1" sx={{ fontWeight: 600, mb: 1, color: '#2D2D2D' }}>
                Create a Remediation Action
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                  Severity Level:
                </Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    value={postDataEngineer.severity || 'high'}
                    name="severity"
                    onChange={handleChangeEngineer}
                    displayEmpty
                    sx={{
                      '.MuiSelect-select': {
                        py: 1,
                        display: 'flex',
                        alignItems: 'center'
                      },
                      '&.MuiInputBase-root': {
                        backgroundColor:
                          postDataEngineer.severity.toUpperCase() == 'HIGH'
                            ? '#FFE6E2'
                            : postDataEngineer.severity.toUpperCase() == 'MEDIUM'
                            ? '#FEF3C7'
                            : postDataEngineer.severity.toUpperCase() == 'LOW'
                            ? '#D0DAFC'
                            : '#EBEBEB'
                      },
                      borderRadius: '100px'
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem sx={{ backgroundColor: '#FFE6E2' }} value="high">
                      <Box>
                        <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F73939' }} />
                      </Box>
                      <Typography variant="body2">High</Typography>
                    </MenuItem>
                    <MenuItem sx={{ backgroundColor: '#FEF3C7' }} value={'medium'}>
                      <Box>
                        <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#FBBF24' }} />
                      </Box>
                      <Typography variant="body2">Medium</Typography>
                    </MenuItem>

                    <MenuItem sx={{ backgroundColor: '#D0DAFC' }} value={'low'}>
                      {' '}
                      <Box>
                        <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#436AF3' }} />
                      </Box>
                      <Typography variant="body2">Low</Typography>
                    </MenuItem>
                    <MenuItem sx={{ backgroundColor: '#EBEBEB' }} value={'status'}>
                      {' '}
                      <Box>
                        <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#787878' }} />
                      </Box>
                      <Typography variant="body2">Status</Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box sx={{ px: 3 }}>
              <Box sx={{ p: 1, background: '#F2F9FF', borderRadius: '8px' }}>
                <TextField
                  autoComplete={'none'}
                  value={postDataEngineer.title}
                  name="title"
                  onChange={handleChangeEngineer}
                  defaultValue={'Website does don implement X-frame Option Best Practices'}
                  sx={{
                    fontSize: '14px',
                    textAlign: 'justify',
                    width: 1,
                    mb: 1.5,
                    backgroundColor: 'white',

                    '.MuiInputBase-input': {
                      py: '14.5px'
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CCCCCC'
                    }
                  }}
                  placeholder="Remediation Issue Name"
                />{' '}
                <Box sx={{ position: 'relative' }}>
                  <TextField
                    autoComplete={'none'}
                    multiline
                    value={postDataEngineer.recommendation}
                    name="recommendation"
                    onChange={handleChangeEngineer}
                    rows={7}
                    sx={{
                      fontSize: '14px',

                      border: 'none',
                      textAlign: 'justify',
                      width: 1,
                      backgroundColor: 'white',
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CCCCCC'
                      }
                    }}
                    placeholder="Remediation Description"
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#B7B7B7',
                      textAlign: 'end',
                      position: 'absolute',
                      right: 8,
                      bottom: 6
                    }}>
                    {`${
                      postDataEngineer?.recommendation?.length
                        ? postDataEngineer?.recommendation?.length
                        : 0
                    }/999`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )} */}
        {rowData?.answer?.feedback?.status && (
          <Box>
            <Divider sx={{ mx: 3 }} />
            <Box sx={{ p: 3 }}>
              <Typography variant="body1" sx={{ fontWeight: 600, mb: 1, color: '#2D2D2D' }}>
                Does evidence meet the control?
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                  Control Status:
                </Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    value={postDataEngineer.status || 'pending'}
                    name="status"
                    displayEmpty
                    disabled={true}
                    sx={{
                      '.MuiSelect-select': {
                        py: 1,
                        display: 'flex',
                        alignItems: 'center'
                      },
                      borderRadius: '100px'
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="does_not_meet">
                      <Box
                        sx={{
                          height: '12px',
                          width: '12px',
                          backgroundColor: '#EF4444',
                          borderRadius: '50%',
                          mr: 1
                        }}></Box>
                      <Typography variant="body2">Does Not Meet</Typography>
                    </MenuItem>
                    <MenuItem value={'pending'}>
                      {' '}
                      <Box
                        sx={{
                          height: '12px',
                          width: '12px',
                          backgroundColor: '#787878',
                          borderRadius: '50%',
                          mr: 1
                        }}></Box>
                      <Typography variant="body2">Pending</Typography>
                    </MenuItem>
                    <MenuItem value={'partially_meets'}>
                      {' '}
                      <Box
                        sx={{
                          height: '12px',
                          width: '12px',
                          backgroundColor: '#FBBF24',
                          borderRadius: '50%',
                          mr: 1
                        }}></Box>
                      <Typography variant="body2">Partially Meets</Typography>
                    </MenuItem>
                    <MenuItem value={'fully_meets'}>
                      {' '}
                      <Box
                        sx={{
                          height: '12px',
                          width: '12px',
                          backgroundColor: '#27AE60',
                          borderRadius: '50%',
                          mr: 1
                        }}></Box>
                      <Typography variant="body2">Fully Meets</Typography>
                    </MenuItem>
                    <MenuItem value={'not_applicable'}>
                      {' '}
                      <Box
                        sx={{
                          height: '12px',
                          width: '12px',
                          backgroundColor: '#D9E1FD',
                          borderRadius: '50%',
                          mr: 1
                        }}></Box>
                      <Typography variant="body2">Not Applicable</Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Divider sx={{ mx: 3 }} />
            {postDataEngineer?.status == 'fully_meets' ||
            postDataEngineer?.status == 'not_applicable' ? (
              <Box></Box>
            ) : (
              <Box>
                <Box sx={{ p: 3 }}>
                  <Typography variant="body1" sx={{ fontWeight: 600, mb: 1, color: '#2D2D2D' }}>
                    Create a Remediation Action
                  </Typography>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969' }}>
                      Severity Level:
                    </Typography>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        value={postDataEngineer.severity || 'high'}
                        name="severity"
                        disabled={true}
                        onChange={handleChangeEngineer}
                        displayEmpty
                        sx={{
                          '.MuiSelect-select': {
                            py: 1,
                            display: 'flex',
                            alignItems: 'center'
                          },
                          '&.MuiInputBase-root': {
                            backgroundColor:
                              postDataEngineer.severity.toUpperCase() == 'HIGH'
                                ? '#FFE6E2'
                                : postDataEngineer.severity.toUpperCase() == 'MEDIUM'
                                ? '#FEF3C7'
                                : postDataEngineer.severity.toUpperCase() == 'LOW'
                                ? '#D0DAFC'
                                : '#EBEBEB'
                          },
                          borderRadius: '100px'
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}>
                        <MenuItem sx={{ backgroundColor: '#FFE6E2' }} value="high">
                          <Box>
                            <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#F73939' }} />
                          </Box>
                          <Typography variant="body2">High</Typography>
                        </MenuItem>
                        <MenuItem sx={{ backgroundColor: '#FEF3C7' }} value={'medium'}>
                          <Box>
                            <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#FBBF24' }} />
                          </Box>
                          <Typography variant="body2">Medium</Typography>
                        </MenuItem>

                        <MenuItem sx={{ backgroundColor: '#D0DAFC' }} value={'low'}>
                          {' '}
                          <Box>
                            <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#436AF3' }} />
                          </Box>
                          <Typography variant="body2">Low</Typography>
                        </MenuItem>
                        <MenuItem sx={{ backgroundColor: '#EBEBEB' }} value={'status'}>
                          {' '}
                          <Box>
                            <FlagIcon sx={{ fontSize: '12px', mr: 1, color: '#787878' }} />
                          </Box>
                          <Typography variant="body2">Status</Typography>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box sx={{ px: 3 }}>
                  <Box
                    sx={{ backgroundColor: '#F2F9FF', padding: 2.5, borderRadius: '8px', my: 1 }}>
                    <Box>
                      <List>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: 1
                          }}>
                          <Typography
                            variant="subtitle2"
                            sx={{ fontSize: '19px', fontWeight: 600 }}>
                            {postDataEngineer.title}
                          </Typography>
                          <Box>
                            <Typography
                              variant="body2"
                              sx={{
                                color: '#9D9D9D',
                                fontWeight: 600,
                                wordBreak: 'break-all',
                                wordWrap: 'break-word'
                              }}>
                              {postDataEngineer.recommendation}
                            </Typography>
                          </Box>
                        </Box>
                      </List>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
        <Box sx={{ textAlign: 'end' }}>
          <LoadingButton
            loading={isAnswerButtonLoading}
            onClick={handleSubmit}
            disabled={commonDetails.data.company.gap_status != 'draft'}
            sx={{
              position: 'relative',
              bottom: 0,
              margin: 3,
              right: 0,
              justifySelf: 'flex-end',
              minWidth: 163,
              backgroundColor: '#436AF3',
              color: 'white',
              '&:hover': {
                backgroundColor: '#436AF3'
              },
              '&.Mui-disabled': {
                backgroundColor: '#EBEBEB',
                color: '#9D9D9D'
              }
            }}>
            Save
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};
export default GapAnalysisSideBar;
