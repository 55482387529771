import CloseIcon from '@jibin/common/icons/Close/Close';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';

import { Box } from '@mui/system';
import { FC, useCallback, useState } from 'react';
import { Auth, Amplify } from 'aws-amplify';
import { CommonFunctions } from '@jibin/common/utils/Common';
import ChangePasswordModel from '../Models/ChangePassword.model';
import { toast } from 'react-toastify';
import { shallowEqual, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';

var debounce = require('lodash.debounce');
const notifyError = (message: any) => toast.error(message, { position: toast.POSITION.TOP_RIGHT });
const notifySuccess = (message: any) =>
  toast.success(message, { position: toast.POSITION.TOP_RIGHT });

const ChangePasswordPopup: FC = () => {
  Amplify.configure({
    Auth: {
      userPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID),
      userPoolWebClientId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_CLIENT_ID)
    }
  });
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const updatePassword = async () => {};

  const [open, setOpen] = useState(false);
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [isPasswordButtonLoading, setIsPasswordButtonLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const validate = (values: ChangePasswordModel) => {
    const errors = {};
    const passRegex: RegExp = new RegExp(process.env.REACT_APP_PASSWORD_REGEX);
    if (!values.CurrentPassword) {
      errors['CurrentPassword'] = 'This field is Required';
    }
    if (!values.NewPassword) {
      errors['NewPassword'] = 'This field is Required';
    } else if (!passRegex.test(values.NewPassword)) {
      errors['NewPassword'] =
        'Password must be minimum 12 characters long and contain at least one uppercase letter, one lowercase letter, one digit and one symbol';
    }
    if (values.NewPassword != values.ConfirmPassword) {
      errors['ConfirmPassword'] = 'Confirm Password does not match New Password';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: new ChangePasswordModel(),
    validateOnChange: validateAfterSubmit,
    validate,
    onSubmit: useCallback(
      debounce(
        (values) => {
          try {
            setIsPasswordButtonLoading(true);
            Auth.currentAuthenticatedUser().then((user) => {
              Auth.changePassword(user, values.CurrentPassword, values.NewPassword)
                .then((q) => {
                  notifySuccess('Password Updated Successfully');
                  handleClose();
                })
                .catch((err) => {
                  notifyError(err.message);
                });

              return true;
            });
          } catch (error) {
          } finally {
            setTimeout(() => {
              setIsPasswordButtonLoading(false);
            }, 1000);
          }
        },
        500,
        true
      ),
      []
    )
  });

  return (
    <Box>
      <Box onClick={handleOpen} sx={{ color: '#436AF3', cursor: 'pointer', fontWeight: 600 }}></Box>
      <Box sx={{ color: '#436AF3', cursor: 'pointer' }}>
        <Button onClick={handleOpen} sx={{ fontWeight: 600, p: 0, minWidth: 0, minHeight: 0 }}>
          Change Password
        </Button>
      </Box>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="subtitle2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'text.primary',
                fontWeight: '600',
                maxWidth: 'calc(100% - 40px)',
                p: 1
              }}>
              Change Password
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} sx={{ color: 'text.primary' }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />

        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ px: { xs: 2, md: 3 }, pt: 0 }}>
            <DialogContentText id="alert-dialog-description">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="CurrentPassword">Current Password </label>
                    </Box>

                    <TextField
                      type={'password'}
                      size="small"
                      id="CurrentPassword"
                      name="CurrentPassword"
                      placeholder="Current Password"
                      value={formik.values.CurrentPassword}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      error={formik.errors.CurrentPassword ? true : false}
                      helperText={formik.errors.CurrentPassword}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'CurrentPassword'
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}></FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="NewPassword">New Password</label>
                    </Box>
                    <TextField
                      type={'password'}
                      size="small"
                      placeholder="New Password"
                      id="NewPassword"
                      name="NewPassword"
                      value={formik.values.NewPassword}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      error={formik.errors.NewPassword ? true : false}
                      helperText={formik.errors.NewPassword}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'NewPassword'
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="ConfirmNewPassword"> Confirm New Password</label>
                    </Box>
                    <TextField
                      type={'password'}
                      size="small"
                      placeholder="Confirm New Password"
                      id="ConfirmPassword"
                      name="ConfirmPassword"
                      value={formik.values.ConfirmPassword}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      error={formik.errors.ConfirmPassword ? true : false}
                      helperText={formik.errors.ConfirmPassword}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'ConfirmPassword'
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: 3, pt: 0 }}>
            <LoadingButton
              loading={isPasswordButtonLoading}
              variant="contained"
              sx={{ minWidth: 100 }}
              onClick={(e) => {
                setValidateAfterSubmit(true);
                formik.handleSubmit();
              }}
              type="button">
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};
export default ChangePasswordPopup;
