import { Env } from "./Env.model";

    export class Error {
        code: number;
        message: string;
    }

    export class ApplicationData {
        tech_info_application_uuid: string;
        company_uuid: string;
        name: string;
        type: string;
        status: string;
        envs: Env[];
        created_at: Date;
        updated_at: string;
        created_by: string;
        updated_by: string;

       
    }

    export class ApiResponse {
        error: Error;
        data: ApplicationData[];
    }



