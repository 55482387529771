const PinIcon = () => {
  return (
    <svg width="21" height="11" viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 5.5C0.5 2.46 2.96 0 6 0H16.5C18.71 0 20.5 1.79 20.5 4C20.5 6.21 18.71 8 16.5 8H8C6.62 8 5.5 6.88 5.5 5.5C5.5 4.12 6.62 3 8 3H15.5V5H7.91C7.36 5 7.36 6 7.91 6H16.5C17.6 6 18.5 5.1 18.5 4C18.5 2.9 17.6 2 16.5 2H6C4.07 2 2.5 3.57 2.5 5.5C2.5 7.43 4.07 9 6 9H15.5V11H6C2.96 11 0.5 8.54 0.5 5.5Z"
        fill="#436AF3"
      />
    </svg>
  );
};
export default PinIcon;
