import { Box } from '@mui/system';
import { FC } from 'react';
import CompanyManagementContactTable from '../../CompanyManagementContactTable';

const CompanyManagementContacts: FC = () => {
  return (
    <Box>
      <CompanyManagementContactTable />
    </Box>
  );
};
export default CompanyManagementContacts;
