import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import { FC, useState } from 'react';
import AddExternalAssessmentModel from './AddExternalAssessmentModel';
import ExternalInfrastructureAssessmentTable from './ExternalInfrastructureAssessmentTable';
import { Ips } from './Models/Ips.Model';



export const ExternalInfrastructureAssessment: FC = (props: any) => {
	const [isRefreshList, setIsRefreshList] = useState(Boolean);

	const handleRefresh=()=>{		
		setIsRefreshList(!isRefreshList)
	}

	
	return (
		<>
			<Box sx={Style.Applications.ApplicationsWrapper}>
				
				<ExternalInfrastructureAssessmentTable isRefresh={isRefreshList}/>
			</Box>
		</>
	);
};
