import Style from '@jibin/common/style/Styles';
import { ExpandMore } from '@mui/icons-material';
import {
  Box,
  Typography,
  Button,
  Menu,
  OutlinedInput,
  InputAdornment,
  IconButton
} from '@mui/material';
import { CompanyOptionComponent } from './CompanyOptionComponent';
import Search from '@jibin/common/icons/Search/Search';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SidebarService } from './Sidebar.api';
import commonDetailSlice from 'src/store/reducers/commonReducer';

export const SidebarCompanySearchComponent = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const dispatch = useDispatch();
  const [anchorElSidebar, setAnchorElSidebar] = React.useState(null);
  const [company_uuid, setCompany_uuid] = React.useState('');

  const [comapnyName, setComapny] = React.useState({ name: '', role: '', logo_url: '' });
  const { name, role, logo_url } = comapnyName;

  const [companies, setCompanies] = useState([] as any);
  const [filterCompanies, setFilterComapanies] = useState([] as any);

  const handleSelect = async (company_uuid: string) => {
    let company = companies?.data?.find((q: any) => q.company_uuid == company_uuid);

    setComapny({
      ...comapnyName,
      name: company?.name,
      role: company?.role,
      logo_url: company?.logo_url
    });

    SidebarService.PatchUserData(commonDetails.data.user_uuid, company_uuid).then((q: any) => {
      handleClose();
      GetUserDetails();
      setSearchWord('');
    });
  };

  const GetUserDetails = () => {
    SidebarService.GetUserData().then(
      async (q: any) => {
        await dispatch(commonDetailSlice.actions.setcommonDetails(q));
      },
      (err: any) => {}
    );
  };

  const open = Boolean(anchorElSidebar);
  const handleClick = (event: any) => {
    setAnchorElSidebar(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElSidebar(null);
  };
  const [searchWord, setSearchWord] = useState('');

  const handleChange = (e: any) => {
    if (e.target.value) {
      setSearchWord(e.target.value);
    } else {
      setSearchWord('');
    }
  };

  useEffect(() => {
    GetcompanyList();
    if (commonDetails?.data) {
      setCompany_uuid(commonDetails?.data?.company?.company_uuid);
      let company = commonDetails?.data?.companies?.find(
        (q: any) => q.company_uuid == commonDetails.data.company.company_uuid
      );
      setComapny({
        ...comapnyName,
        name: company?.name,
        role: company?.user_role,
        logo_url: company?.logo_url
      });
    }
  }, [commonDetails]);

  const GetcompanyList = () => {
    SidebarService.GetUserCompanyData(commonDetails?.data.user_uuid, '').then(
      async (q: any) => {
        setCompanies(q.data.data);
      },
      (err: any) => {}
    );
  };

  return (
    <Box sx={Style.Sidebar.CompanyDropDown}>
      {logo_url ? (
        <Box sx={Style.UserNameRound}>
          <img style={{ borderRadius: '50%' }} src={logo_url} />
        </Box>
      ) : (
        <Typography variant="caption" sx={Style.UserNameRound}>
          {name && name?.charAt(0) + name?.charAt(1)}
        </Typography>
      )}
      <Button
        disableRipple
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<ExpandMore />}
        sx={Style.Sidebar.CompanyButton}>
        <Box sx={{ textAlign: 'left' }}>
          {name}
          <Typography variant="caption" sx={{ display: 'block' }}>
            {role}
          </Typography>
        </Box>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorElSidebar}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '.MuiList-root': {
            p: 0
          }
        }}>
        <Box sx={{ p: 1 }}>
          <OutlinedInput
            sx={{ fontSize: '14px' }}
            size="small"
            fullWidth
            onChange={handleChange}
            placeholder="Search Company Name"
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end">
                  <Search sx={{ color: '#B7B7B7' }} />
                </IconButton>
              </InputAdornment>
            }
          />
        </Box>
        {companies &&
          companies
            .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
            .filter((el) => el.name.toLowerCase().includes(searchWord))
            ?.map((details: any, index: any) => (
              <CompanyOptionComponent
                details={details}
                index={index}
                handleSelect={() => handleSelect(details.company_uuid)}
              />
            ))}
      </Menu>
    </Box>
  );
};
