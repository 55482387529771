import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import theme from '@jibin/common/style/core/config/theme';
import { LoginContextProvider } from '@jibin/common/Context/LoginContext';

import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import store from './store/store';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const persistor = persistStore(store);

root.render(
  <React.Suspense>
    <Provider store={store}>
      <LoginContextProvider>
        <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
          <ThemeProvider theme={theme}>
            <ToastContainer />
            <App />
          </ThemeProvider>
        </PersistGate>
      </LoginContextProvider>
    </Provider>
  </React.Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
