import ConformationModel from '@jibin/common/components/ConformationModel';
import EditIcon from '@jibin/common/icons/Edit/Edit';
import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
import Style from '@jibin/common/style/Styles';
import { DeleteTypesEnum } from '@jibin/common/utils/enums/deleteTypeEnums';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { TechinicalInformation } from '../TechinicalInformation.Api';
import AddTechEnvModel from './AddTechEnvModel/AddTechEnvModel';
import { ApplicationTable } from './Models/ApplicationsTable.model';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const Row: FC<{ row: any; index: any; props: any; refreshTable(): void }> = (rowProp) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [rowdata, setRowdata] = useState({} as any);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any, data: any) => {
    setRowdata(data);
    setAnchorEl(event.currentTarget);
  };
  const handleRefresh = () => {
    handleClose();
    rowProp.refreshTable();
  };
  const handleClose = () => {
    setRowdata(null);
    setAnchorEl(null);
  };

  const handleConfirm = (data: any) => {
    let status;
    data.status == 'Active' ? (status = 'Inactive') : (status = 'Active');
    //data = { ...data, status: status };
    let statusData = { status: status };
    TechinicalInformation.patchEnv(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      rowProp.props.applicationData.tech_info_application_uuid,
      rowdata.application_env_uuid,
      statusData
    ).then(
      (q: any) => {
        handleRefresh();
        handleClose();
      },
      (err: any) => {}
    );
  };

  return (
    <TableRow
      key={rowProp.row.application_env_uuid + rowProp.index}
      id={rowProp.row.application_env_uuid}
      sx={{ borderLeft: '1px solid #eeeeee', borderRight: '1px solid #eeeeee' }}>
      <TableCell
        component="th"
        scope="row"
        sx={{
          color:
            (rowProp.props.applicationData.status == 'Inactive' && '#B7B7B7') ||
            (rowProp.row.status == 'Inactive' && '#B7B7B7')
        }}>
        {rowProp.row.name}
      </TableCell>
      <TableCell
        sx={{
          color:
            (rowProp.props.applicationData.status == 'Inactive' && '#B7B7B7') ||
            (rowProp.row.status == 'Inactive' && '#B7B7B7')
        }}>
        {rowProp.row.type}
      </TableCell>
      <TableCell
        sx={{
          color:
            (rowProp.props.applicationData.status == 'Inactive' && '#B7B7B7') ||
            (rowProp.row.status == 'Inactive' && '#B7B7B7')
        }}>
        {rowProp.row.url}
      </TableCell>
      <TableCell
        sx={{
          color:
            (rowProp.props.applicationData.status == 'Inactive' && '#B7B7B7') ||
            (rowProp.row.status == 'Inactive' && '#B7B7B7')
        }}>
        {rowProp.row.hosting_provider}
      </TableCell>
      <TableCell
        sx={{
          color:
            (rowProp.props.applicationData.status == 'Inactive' && '#B7B7B7') ||
            (rowProp.row.status == 'Inactive' && '#B7B7B7')
        }}>
        {rowProp.row.mfa}
      </TableCell>
      <TableCell
        sx={{
          color:
            (rowProp.props.applicationData.status == 'Inactive' && '#B7B7B7') ||
            (rowProp.row.status == 'Inactive' && '#B7B7B7')
        }}>
        {rowProp.row.ids_ips}
      </TableCell>
      <TableCell align="right">
        {/* {JSON.stringify(props.applicationData)} */}
        <IconButton
          disabled={rowProp.props.applicationData.status != 'Active'}
          aria-label="Example"
          onClick={(e) => {
            handleClick(e, rowProp.row);
          }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}>
          <MoreHorizIcon
            color={rowProp.props.applicationData.status == 'Active' ? 'primary' : '#B7B7B7'}
          />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          <AddTechEnvModel
            handleSubmit={() => {
              handleRefresh();
            }}
            envData={rowdata}
            applicationId={rowProp.props.applicationData.tech_info_application_uuid}
            applicationStatus={rowProp.props.applicationData.status}
            type={3}
            handleClose={() => handleClose()}
            envType={rowdata?.type}
          />
          <Divider />
          <ConformationModel
            handleConfirm={() => {
              handleConfirm(rowdata);
            }}
            handleClose={() => handleClose()}
            title="Confirm Action"
            data={rowdata}
            label={{ ...label }}
            type={DeleteTypesEnum.SwitchAndLabel}
            asking={
              rowdata?.status == 'Active'
                ? 'Are you sure you want to deactivate this Environment?'
                : 'Are you sure you want to activate this Environment?'
            }
            description={rowdata?.name}
            cancelButtonText="Cancel"
            conformButtonText="Confirm"
          />
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export const ApplicationsTable: FC<ApplicationTable> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleRefresh = () => {
    props.handleRefresh();
  };
  const location = useLocation();
  useEffect(() => {
    if (searchParams.get('application_env_uuid')) {
      const filterdData = props.applicationData.envs.filter(
        (el, index) => el.application_env_uuid == searchParams.get('application_env_uuid')
      );

      setTimeout(() => {
        const element = document.getElementById(filterdData[0]?.application_env_uuid);
        if (element) {
          var headerOffset = 90;
          var elementPosition = element.getBoundingClientRect().top;
          var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
          element.classList.add('flash');
          setTimeout(() => {
            element.classList.remove('flash');
          }, 1000);
        }
      }, 1);
      searchParams.delete('tech_info_application_uuid');
      searchParams.delete('application_env_uuid');
      setSearchParams(searchParams);
    }
  }, [location.search]);
  return (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <Table
        sx={{
          minWidth: 650,
          '.MuiTableCell-root': {
            p: 1
          }
        }}
        aria-label="site list table">
        <TableHead
          sx={{ bgcolor: props.applicationData.status == 'Inactive' ? ' #F9F8F6' : 'grey.200' }}>
          <TableRow sx={{ borderLeft: '1px solid #eeeeee', borderRight: '1px solid #eeeeee' }}>
            <TableCell sx={{ color: props.applicationData.status == 'Inactive' && '#B7B7B7' }}>
              Name
            </TableCell>
            <TableCell sx={{ color: props.applicationData.status == 'Inactive' && '#B7B7B7' }}>
              Env. Type
            </TableCell>
            <TableCell sx={{ color: props.applicationData.status == 'Inactive' && '#B7B7B7' }}>
              URL
            </TableCell>
            <TableCell sx={{ color: props.applicationData.status == 'Inactive' && '#B7B7B7' }}>
              Host Provider
            </TableCell>
            <TableCell sx={{ color: props.applicationData.status == 'Inactive' && '#B7B7B7' }}>
              MFA
            </TableCell>
            <TableCell sx={{ color: props.applicationData.status == 'Inactive' && '#B7B7B7' }}>
              IDS/IPS
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props &&
            props.applicationData.envs.map((row, index) => (
              <Row
                index={index}
                row={row}
                refreshTable={() => handleRefresh()}
                props={props}
                key={index}
              />
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ApplicationsTable;
