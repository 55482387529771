import CloseIcon from '@jibin/common/icons/Close/Close';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useState, useRef, useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import DeletaionBox from '../DeletaionBox';
import { CommonFunctions } from '@jibin/common/utils/Common';
import { Amplify, Auth, AWSCloudWatchProvider } from 'aws-amplify';

import { useFormik } from 'formik';
import { notifySuccess } from '@jibin/common/services/http/http-common';
import { MyAccountApi } from '../MyAccount.Api';
import commonDetailSlice from 'src/store/reducers/commonReducer';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { QRCodeCanvas } from 'qrcode.react';

const MFAPopupApp: FC<{ userDetails: any }> = (prop) => {
  const [open, setOpen] = useState(false);
  const [displayPhone, setDisplayPhone] = useState('none');
  const [displayAuth, setDisplayAuth] = useState('none');
  const [codeSent, setCodeSent] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [qrCode, setQrcode] = useState('');
  const [userDetails, setUserDetails] = useState({} as any);
  const [openDeletaion, setOpenDeletaion] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const handleOpen = () => {
    if (prop.userDetails.isMfaAppEnabled) {
      setOpenDeletaion(true);
    } else {
      setOpen(true);
    }
  };
  const handleVerfication = async () => {
    try {
      const res = await Auth.verifyTotpToken(userDetails, otpCode);
      const noMFA = await Auth.setPreferredMFA(userDetails, 'NOMFA');
      const res2 = await Auth.setPreferredMFA(userDetails, 'TOTP');
      const data = { ...prop.userDetails, isMfaAppEnabled: true };
      data.isMfaSmsEnabled = false;
      const updateUserdetals = await MyAccountApi.PutUserData(data);
      const user = await MyAccountApi.GetUserData();

      dispatch(commonDetailSlice.actions.setcommonDetails(user));

      handleClose();
      setIsChecked(true);
    } catch (error) {
      setErrorMessage('Please Enter Correct OTP');
    }
  };
  const handleClose = () => {
    if (prop.userDetails.isMfaAppEnabled) {
      setOpenDeletaion(false);
    } else {
      setOpen(false);
      setOtpCode('');

      setDisplayAuth('none');
      setDisplayPhone('none');
      setCodeSent(false);
    }
  };
  Amplify.configure({
    Auth: {
      userPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID),
      userPoolWebClientId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_CLIENT_ID)
    }
  });
  async function getUserInfo() {
    const user = await Auth.currentAuthenticatedUser();

    const code = await Auth.setupTOTP(user);
    setUserDetails(user);

    const str =
      'otpauth://totp/Redesign%20Trust%20Portal:' +
      user.attributes.email +
      '?secret=' +
      code +
      '&issuer=Redesign%20Trust%20Portal';
    setQrcode(str);
  }

  useEffect(() => {
    setIsChecked(prop.userDetails.isMfaAppEnabled);
    getUserInfo();
  }, [commonDetails]);
  return (
    <Box>
      <Box onClick={handleOpen} sx={{ color: '#436AF3', cursor: 'pointer', fontWeight: 600 }}>
        <Switch
          checked={isChecked}
          sx={{
            ml: 2,

            '	.MuiSwitch-switchBase': { color: 'rgba(0, 0, 0, 0.25)' },
            '	.MuiSwitch-track': {
              backgroundColor: 'transparent',
              border: '1px solid #ADB5BD',
              borderRadius: '45px'
            },
            '.css-jsexje-MuiSwitch-thumb': {
              boxShadow: 'none'
            },
            '	.Mui-disabled': {
              cursor: 'not-allowed'
            }
          }}
        />
      </Box>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '50%'
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="subtitle2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'text.primary',
                fontWeight: '600',
                maxWidth: 'calc(100% - 40px)',

                py: 1,
                px: 2.5
              }}>
              Set Up Multi-Factor Authentication
            </Typography>
            <IconButton aria-label="close" onClick={handleClose} sx={{ color: 'text.primary' }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <form>
          <DialogContent sx={{ px: { xs: 2, md: 3 }, pt: 0 }}>
            <DialogContentText id="alert-dialog-description">
              <Box>
                <Typography sx={{ p: 2.5 }} variant="body1">
                  To use these verification method, you must set up the Authenticator app
                </Typography>
              </Box>

              <Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', px: 2.5, py: 1 }}>
                  <Typography sx={{ py: 1 }} variant="body1">
                    <span style={{ fontWeight: 600 }}>Step 1: </span>
                    Open your Authentication App
                  </Typography>
                  <Typography sx={{ py: 1 }} variant="body1">
                    <span style={{ fontWeight: 600 }}>Step 2: </span>
                    Add an account within the app, and scan the QR below
                  </Typography>
                  <QRCodeCanvas value={qrCode} />
                  <Typography sx={{ py: 1 }} variant="body1">
                    <span style={{ fontWeight: 600 }}>Step 3: </span>
                    Enter the 6-digit code generated by the app
                  </Typography>

                  <TextField
                    size="small"
                    value={otpCode}
                    error={errorMessage ? true : false}
                    helperText={errorMessage}
                    onChange={(e) => setOtpCode(e.target.value)}
                    sx={{
                      py: 0,
                      width: 0.6,
                      '.MuiInputBase-input': {
                        py: 1
                      }
                    }}
                    placeholder="Please enter the 6-digit generated code"
                  />
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: 3, pt: 0 }}>
            <Button
              onClick={() => {
                handleVerfication();
                // setIsChecked(true);
                // handleClose();
              }}
              variant="contained"
              sx={{ minWidth: 100 }}>
              Verify
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <DeletaionBox
        openDeletaion={openDeletaion}
        handleOpen={handleOpen}
        flag={'APP'}
        userDetails={prop.userDetails}
        handleClose={handleClose}
        titleText={'Disable Authentication App'}
        discription={
          'A verification code won’t be sent to your Authenticathion App when you log in '
        }
      />
    </Box>
  );
};
export default MFAPopupApp;
