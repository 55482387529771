import CloseIcon from '@jibin/common/icons/Close/Close';
import EditIcon from '@jibin/common/icons/Edit/Edit';
import { notifySuccess } from '@jibin/common/services/http/http-common';
import { FormHelperText, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { Formik, useFormik } from 'formik';
import React, { FC, useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CompanySettingsService } from '../../CompanySettings.Api';
import { UserModel } from '../Models/User.Model';
import { UserProp } from '../Models/UserProp';
import { LoadingButton } from '@mui/lab';
var debounce = require('lodash.debounce');

export const InviteUserModel: FC<UserProp> = (prop) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [setState, setSetState] = React.useState('');
  const [isInvitesent, setIsInvitesent] = React.useState(false);
  const handleChangesetSetState = (event: any) => {
    setSetState(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [isInviteButtonLoading, setIsInviteButtonLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formik.resetForm();
    setValidateAfterSubmit(false);
    setIsInvitesent(false);
    setOpen(false);
  };

  const [invitePending, setInvitePending] = useState(true);

  const [country, setCountry] = React.useState('');
  const handleChangeCountry = (event: any) => {
    setCountry(event.target.value);
  };
  const capitalizeName = (value: string) => {
    const updatedValue = value.replace(/^-+|-+$/g, '').trim();
    return updatedValue
      .split(/ |-/)
      .map((el, index) => el.charAt(0).toUpperCase() + '' + el.trimEnd().slice(1) + '')
      .join(updatedValue.includes('-') ? '-' : updatedValue.includes(' ') ? ' ' : '');
  };
  const validate = (values: UserModel) => {
    const errors = {};

    if (!values.email) {
      errors['email'] = 'This field is Required';
    } else if (
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
        values.email
      )
    ) {
      errors['email'] = 'Please enter valid email address';
    }
    if (!values.role) {
      errors['role'] = 'This field is Required';
    }
    if (!values.first_name) {
      errors['first_name'] = 'This field is Required';
    }
    if (!values.last_name) {
      errors['last_name'] = 'This field is Required';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: new UserModel(prop.UserModel),
    validateOnChange: validateAfterSubmit,
    validate,
    onSubmit: useCallback(
      debounce(
        (values) => {
          try {
            setIsInviteButtonLoading(true);

            values = {
              ...values,
              first_name: capitalizeName(values.first_name),
              last_name: capitalizeName(values.last_name)
            };

            if (prop.UserModel?.status?.toUpperCase() == 'PENDING' || !prop.UserModel.user_uuid) {
              delete values.user_uuid;

              CompanySettingsService.postUser(
                commonDetails.data.user_uuid,
                commonDetails.data.company.company_uuid,
                values
              ).then(
                (q: any) => {
                  setIsInvitesent(true);
                  handleClose();
                  prop.handleSubmit();
                  formik.resetForm();
                },
                (err) => {}
              );
            } else if (prop.UserModel.user_uuid) {
              CompanySettingsService.putUser(
                commonDetails.data.user_uuid,
                commonDetails.data.company.company_uuid,
                prop.UserModel.user_uuid,
                values
              ).then(
                (q: any) => {
                  handleClose();
                  prop.handleSubmit();
                  formik.resetForm();
                  notifySuccess('Data saved successfully');
                },
                (err) => {}
              );
            }
          } catch (error) {
          } finally {
            setTimeout(() => {
              setIsInviteButtonLoading(false);
            }, 1000);
          }
        },
        500,
        true
      ),
      [open, prop, commonDetails]
    )
  });

  const handleClick = (e: any) => {
    if (e.target.checked) {
      formik.setFieldValue('status', 'INACTIVE');
    } else {
      formik.setFieldValue('status', 'PENDING');
    }
  };

  return (
    <>
      {prop.IsEdit && (
        <Box>
          <MenuItem onClick={handleClickOpen}>
            <EditIcon sx={{ mr: 4, color: 'primary.main' }} /> Edit
          </MenuItem>
        </Box>
      )}
      {!prop.IsEdit && (
        <Button variant="contained" onClick={handleClickOpen} sx={{ minWidth: 160 }}>
          Invite User
        </Button>
      )}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{ px: { xs: 1, md: 2 }, py: { xs: 0.5, md: 1 }, bgcolor: 'error.red100' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="subtitle2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'text.primary',
                fontWeight: '600',
                maxWidth: 'calc(100% - 40px)',
                p: 1
              }}>
              {prop.IsEdit ? 'Manage User' : 'Invite User'}
            </Typography>
            <IconButton sx={{ color: 'text.primary' }} aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ px: { xs: 2, md: 3 } }}>
            {prop.UserModel.user_uuid && (
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: '600' }}>
                  {prop.UserModel.first_name} {prop.UserModel.last_name}
                </Typography>
                <Typography variant="body2" sx={{ mt: 0.5 }}>
                  {prop.UserModel.email}
                </Typography>
              </Box>
            )}
            {!prop.IsEdit && (
              <>
                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <label htmlFor="first_name">First Name</label>
                  </Box>
                  <TextField
                    size="small"
                    placeholder="Enter your first name"
                    id="first_name"
                    variant="outlined"
                    type={'text'}
                    value={formik.values?.first_name?.trimStart()}
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.errors.first_name = '';
                    }}
                    inputProps={{
                      style: {
                        textTransform: 'capitalize'
                      }
                    }}
                    error={formik.errors.first_name ? true : false}
                    helperText={formik.errors.first_name}
                    aria-describedby="outlined-weight-helper-text"
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <label htmlFor="last_name">Last Name</label>
                  </Box>
                  <TextField
                    size="small"
                    placeholder="Enter your last name"
                    id="last_name"
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.errors.last_name = '';
                    }}
                    value={formik.values?.last_name?.trimStart()}
                    error={formik.errors.last_name ? true : false}
                    helperText={formik.errors.last_name}
                    inputProps={{
                      style: {
                        textTransform: 'capitalize'
                      }
                    }}
                    variant="outlined"
                    type={'text'}
                    aria-describedby="outlined-weight-helper-text"
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <label htmlFor="Email">Email</label>
                  </Box>
                  <TextField
                    size="small"
                    id="email"
                    name="email"
                    placeholder="Enter email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.errors.email ? true : false}
                    helperText={formik.errors.email}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'Email'
                    }}
                  />
                </FormControl>
              </>
            )}
            {formik.values.status != 'PENDING' && (
              <FormControl variant="outlined" fullWidth sx={{ mt: 2, maxWidth: '50%' }}>
                <Box sx={{ mb: 1 }}>
                  <label htmlFor="Email">Role Type </label>
                </Box>
                {commonDetails?.data?.company?.type == 'engineering' ? (
                  <Select
                    size="small"
                    id="role"
                    name="role"
                    value={formik.values.role || ''}
                    onChange={formik.handleChange}
                    error={formik.errors.role ? true : false}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>

                    <MenuItem value="superadmin">Super Admin</MenuItem>
                    <MenuItem value="csc">Customer Success Coordinator (CSC)</MenuItem>
                    <MenuItem value="engineer">Engineer</MenuItem>
                  </Select>
                ) : (
                  <Select
                    size="small"
                    id="role"
                    name="role"
                    value={formik.values.role || ''}
                    onChange={formik.handleChange}
                    error={formik.errors.role ? true : false}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>

                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="user">User</MenuItem>
                  </Select>
                )}

                <FormHelperText sx={{ color: '#d50000' }}> {formik.errors.role}</FormHelperText>
              </FormControl>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-end',
              alignItems: 'center',
              px: { xs: 2, md: 3 },
              pb: 2,
              pt: 0,
              ml: 1.5
            }}>
            {/* {!invitePending &&
              <Typography variant="body2" sx={{ color: "error.main", display: 'flex', alignItems: 'center' }}><ErrorIcon sx={{ mr: 1 }} /> Invite Pendind</Typography>
            } */}

            {/* <FormControlLabel
              control={
                <Switch
                  key="status"
                  name="status"
                  disabled={formik.values.status == 'PENDING'}
                  checked={formik.values.status == 'INACTIVE'}
                  onClick={(e: any) => {
                    !e.target.checked
                      ? formik.setFieldValue('status', 'ACTIVE')
                      : formik.setFieldValue('status', 'INACTIVE');
                  }}
                  disableRipple
                  sx={{ mr: 1 }}
                />
              }
              label="Inactivate user"
            /> */}

            <Box>
              {!prop.IsEdit && (
                <LoadingButton
                  loading={isInviteButtonLoading}
                  variant="contained"
                  sx={{ minWidth: { xs: '100%', sm: 150 }, mt: { xs: 1, sm: 'auto' } }}
                  type="button"
                  onClick={() => {
                    setValidateAfterSubmit(true);
                    formik.handleSubmit();
                  }}>
                  Invite
                </LoadingButton>
              )}
              {prop.IsEdit && (
                <LoadingButton
                  loading={isInviteButtonLoading}
                  variant="contained"
                  sx={{ minWidth: { xs: '100%', sm: 150 }, mt: { xs: 1, sm: 'auto' } }}
                  type="button"
                  onClick={() => {
                    setIsInviteButtonLoading(true);
                    setValidateAfterSubmit(true);
                    formik.handleSubmit();
                  }}>
                  {prop.UserModel.status == 'PENDING' ||
                  (prop.UserModel.status == 'INACTIVE' && !prop.UserModel.first_name)
                    ? 'Resend Invite'
                    : 'Save new role'}
                </LoadingButton>
              )}
              {isInvitesent && (
                <Typography
                  variant="body2"
                  sx={{ color: 'common.green', mt: 1, textAlign: 'right' }}>
                  Invite sent!
                </Typography>
              )}
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default InviteUserModel;
