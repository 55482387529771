import SvgIcon from "@mui/material/SvgIcon";

export default function CheckIcon(props) {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.0433 6.23334L68 18.19L23.9133 62.3333L0 38.3917L11.9567 26.435L23.9133 38.3917L56.0433 6.23334ZM56.0433 14.1667L23.9133 46.3533L11.9567 34.5383L7.96167 38.3917L23.9133 54.315L60.0383 18.19L56.0433 14.1667Z"
        fill="#059669"
      />
    </svg>
  );
}
