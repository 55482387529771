import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import { FC } from 'react';
import AuthorizationsTable from './AuthorizationsTable';

export const Authorizations: FC = (props: any) => {

	return (
		<>
			<Box sx={{  px: 3}}>
				<AuthorizationsTable />
			</Box>
		</>
	);
};
