export const PageRouteConstants = {
  Home: '/',
  Login: '/login',
  OnBoarding: '/onboarding',
  Welcome: '/welcome',
  Mfa: '/two-factor-authentication',
  Signup: '/signup/:id',
  SignupCreate: '/user-signup/:id',
  ForgotPassword: '/forgot-password',
  Notification: '/notification',
  CompanyProfile: '/company/information/locations',
  ManageUsers: '/company/information/manage-users',
  Meetings: '/company/information/meetings',
  CompanyManagement: '/company-management',
  CompanyManagementAccount: '/company-management/account',
  CompanyManagementContact: '/company-management/contacts',
  Authorizations: '/company/information/authorizations',
  PlanAndBilling: '/company/information/plan-and-billing',
  UpGuard: '/up-guard',
  Applications: '/company/information/applications',
  ExternalInfrastructureAssessment: '/company/information/ip-ranges',
  WirelessAssessment: '/company/information/wireless-network',

  PoliciesAndProcedures: '/policies-procedures',
  PoliciesAndProceduresTemplate: '/policies-procedures/document/',

  SecurityAwareness: '/security-awareness',
  Vulnerability: '/vulnerability-management',

  Remediation: '/remediation-tracker',

  Penetration: '/penetration-testing',
  EndpointProtection: '/endpoint-protection',
  Frameworks: '/frameworks/',
  FrameworkControl: '/frameworks/control/',
  // FrameworksCIS: '/frameworks/CIS/',

  GapAnalysis: '/gap-analysis',
  DashBoard: '/dashboard',

  ServicesReview: '/customer-success/service-review',
  EngineeringConsulting: '/customer-success/engineering-consulting',
  EmailProtection: '/email-protection',
  MyAccount: '/myAccount',

  Any: '*'
};
