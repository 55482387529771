import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import React, { FC, useCallback, useEffect } from 'react';
import CloseIcon from '@jibin/common/icons/Close/Close';
import { useFormik } from 'formik';
import { ApplicationModel } from '../Models/ApplicationModel';
import { TechinicalInformation } from '../../TechinicalInformation.Api';
import { shallowEqual, useSelector } from 'react-redux';
import { ApplicationsProp } from '../Models/ApplicationsProp';
import EditIcon from '@jibin/common/icons/Edit/Edit';
import { LoadingButton } from '@mui/lab';

var debounce = require('lodash.debounce');
const applicationtypes = [
  { key: 'Website', value: 'Website' },
  { key: 'Saas', value: 'SaaS' },
  { key: 'Desktop / On-Prem', value: 'Desktop' }
];

export const AddApplicationModel: FC<ApplicationsProp> = (prop) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [open, setOpen] = React.useState(false);
  const [mfa, setMfa] = React.useState('');
  const [websiteList, setWebsiteList] = React.useState([]);
  const [validationCheck, setValidationCheck] = React.useState({ type: false, name: false });
  const [isApplicationButtonLoading, setIsApplicationButtonLoading] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    formik.resetForm();
    setValidationCheck({ name: false, type: false });
    setOpen(false);
    prop.handleClose();
  };

  useEffect(() => {}, [prop, commonDetails]);

  const validate = (values: ApplicationModel) => {
    if (values.name == '') {
      setValidationCheck({ ...validationCheck, name: true });
    }
    if (values.type == '-1') {
      setValidationCheck({ ...validationCheck, type: true });
    }
    if (values.name == '' && values.type == '-1') {
      setValidationCheck({ ...validationCheck, name: true, type: true });
    }
  };

  const formik = useFormik({
    initialValues: new ApplicationModel(prop.ApplicationModel),

    onSubmit: useCallback(
      debounce(
        (values) => {
          try {
            setIsApplicationButtonLoading(true);

            if (values.name == '' || values.type == '-1') {
              validate(values);
            } else if (prop.ApplicationModel.tech_info_application_uuid) {
              TechinicalInformation.putApplication(
                commonDetails.data.user_uuid,
                commonDetails.data.company.company_uuid,
                prop.ApplicationModel.tech_info_application_uuid,
                values
              ).then(
                (q: any) => {
                  handleClose();
                  prop.handleSubmit();
                  formik.resetForm();
                },
                (err) => {}
              );
            } else {
              delete values.tech_info_application_uuid;
              TechinicalInformation.postApplication(
                commonDetails.data.user_uuid,
                commonDetails.data.company.company_uuid,
                values
              ).then(
                (q: any) => {
                  handleClose();
                  prop.handleSubmit();
                  formik.resetForm();
                },
                (err) => {}
              );
            }
          } catch (error) {
          } finally {
            setTimeout(() => {
              setIsApplicationButtonLoading(false);
            }, 1000);
          }
        },
        500,
        true
      ),
      [open, prop]
    )
  });

  return (
    <>
      {prop.IsEdit && (
        <Box>
          <MenuItem onClick={handleClickOpen}>
            <EditIcon sx={{ mr: 4, color: 'primary.main' }} /> Edit
          </MenuItem>
        </Box>
      )}
      {!prop.IsEdit && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}>
          {/* <TextField
            size="small"
            id="search"
            name="search"
            aria-describedby="Search"
            placeholder="Search"
            sx={{ mr: 2 }}
          /> */}
          <Button variant="contained" onClick={handleClickOpen} sx={{ minWidth: 160 }}>
            Add Application
          </Button>
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{ display: 'flex', justifyContent: 'space-between', p: { xs: 1, md: 2 } }}>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: '600',
              maxWidth: 'calc(100% - 40px)',
              p: 1,
              color: 'text.primary'
            }}>
            Application
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit} method="post" noValidate>
          <DialogContent sx={{ px: { xs: 2, md: 3 }, pt: 0 }}>
            <Box id="alert-dialog-description">
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Url">Application Type </label>
                    </Box>
                    <TextField
                      size="small"
                      select
                      id="type"
                      name="type"
                      sx={{ color: 'gray' }}
                      value={formik.values.type}
                      error={validationCheck.type}
                      helperText={validationCheck.type && 'This field is Required'}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setValidationCheck({ ...validationCheck, type: false });
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}>
                      <MenuItem sx={{ display: 'none' }} key="-1" value="-1" disabled>
                        Select
                      </MenuItem>
                      {applicationtypes
                        .sort((a: any, b: any) => {
                          return a.key > b.key ? 1 : -1;
                        })
                        .map((currItem, index) => (
                          <MenuItem key={index} value={currItem.value}>
                            {currItem.key}
                          </MenuItem>
                        ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Email">Application Name</label>
                    </Box>
                    <TextField
                      size="small"
                      id="name"
                      name="name"
                      placeholder="Ex: Amazon website"
                      error={validationCheck.name}
                      value={formik.values.name}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setValidationCheck({ ...validationCheck, name: false });
                      }}
                      helperText={validationCheck.name && 'This field is Required'}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'ApplicationName'
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ px: { xs: 2, md: 3 }, pb: { xs: 2, md: 3 }, pt: 0, flexWrap: 'wrap' }}>
            <Button variant="outlined" sx={{ minWidth: 100 }} color="error" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              loading={isApplicationButtonLoading}
              variant="contained"
              sx={{ minWidth: 100 }}
              type="submit">
              Save
            </LoadingButton>
            {/* <Typography variant="caption" component="div" sx={{ width: 1, textAlign: "right", mt: 1.5, color: "common.green" }}>Aplication activated!</Typography> */}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default AddApplicationModel;
