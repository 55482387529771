import React, { FC, useState } from 'react';

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import List from '@mui/material/List';

import { Typography } from '@mui/material';
type Anchor = 'top' | 'left' | 'bottom' | 'right';

export interface RemidiationPopUpProp {
  el: any;
  closePopup(event: React.KeyboardEvent | React.MouseEvent): void;
}

const FrameWorksListPopup: FC<RemidiationPopUpProp> = (prop) => {
  return (
    <Box sx={{ width: { md: 520, xs: 300 } }} role="presentation">
      <List sx={{ p: 0 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#003260',
            px: 2.5,
            alignItems: 'flex-start',
            py: 2
          }}>
          <Box>
            {' '}
            <Typography variant="body1" sx={{ fontWeight: 600, color: 'white' }}>
              {prop.el.name ? prop.el.name : prop.el.topic}
            </Typography>
            <Typography variant="body2" sx={{ color: 'white' }}>
              {prop.el.domain}{' '}
            </Typography>
          </Box>

          <Box
            onClick={(event) => {
              prop.closePopup(event);
            }}>
            <CrossCancle sx={{ color: 'white', height: '16px', width: '16px',cursor:'pointer' }} />
          </Box>
        </Box>
      </List>
      <Box
        sx={{
          maxHeight: '600px',

          overflowY: 'scroll',
          mr: 1,
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '0.2em'
          },
          '&::-webkit-scrollbar-track': {
            borderRadius: '10px',
            backgroundColor: '#F9F8F6'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#CCCCCC',

            borderRadius: '10px'
          }
        }}>
        <List sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
            <Typography variant="subtitle2" sx={{ fontSize: '19px', fontWeight: 600 }}>
              Best Practice
            </Typography>
            <Box>
              <Typography
                variant="body2"
                sx={{ color: '#696969', whiteSpace: 'pre-line', justifyContent: 'space-evenly' }}>
                {prop.el.best_practices}
              </Typography>
            </Box>
          </Box>
        </List>
        <List sx={{ px: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
            <Typography variant="subtitle2" sx={{ fontSize: '19px', fontWeight: 600 }}>
              Implementation Guidance
            </Typography>
            <Box>
              <Typography
                variant="body2"
                sx={{ color: ' #696969', whiteSpace: 'pre-line', justifyContent: 'space-evenly' }}>
                {prop.el.solution}
              </Typography>
            </Box>
          </Box>
        </List>
      </Box>
      {/* <List sx={{ display: 'flex', justifyContent: 'flex-end', px: 3 }}>
        <Typography variant="body2" sx={{ color: '#436AF3', textDecoration: 'underline' }}>
          Need help?
        </Typography>
      </List> */}
    </Box>
  );
};

export default FrameWorksListPopup;
