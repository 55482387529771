import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import { FC, useState, useEffect, useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { TechinicalInformation } from '../TechinicalInformation.Api';
import AddWirelessAssessmentModel from './AddWirelessAssessmentModel';
import { Wireless } from './Models/wireless';
import WirelessAssessmentTable from './WirelessAssessmentTable';

export const WirelessAssessment: FC = (props: any) => {
  const [isRefreshList, setIsRefreshList] = useState(Boolean);
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [facilityList, setfacilityList] = useState({} as any);
  const handleRefresh = () => {
    setIsRefreshList(!isRefreshList);
  };

  useEffect(() => {
    //getLocations();
  }, []);

  return (
    <>
      <Box sx={Style.Applications.ApplicationsWrapper}>
        <WirelessAssessmentTable isRefresh={isRefreshList} />
      </Box>
    </>
  );
};
