export default function StopWatchIcon() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0833 13.25C21.0833 18.08 17.1633 22 12.3333 22C7.50325 22 3.58325 18.08 3.58325 13.25C3.58325 8.42 7.50325 4.5 12.3333 4.5C17.1633 4.5 21.0833 8.42 21.0833 13.25Z"
        stroke="#436AF3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3333 8V13"
        stroke="#436AF3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33325 2H15.3333"
        stroke="#436AF3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
