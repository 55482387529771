export class ApplicationModel {
  constructor(application?: ApplicationModel) {
    let properties = ['name', 'type', 'tech_info_application_uuid'];
    for (let property of properties)
      if (application && (application[property] || application[property] === 0))
        this[property] = application[property];
  }
  tech_info_application_uuid: string;
  name: string = '';
  type: string = '-1';
}
