import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { shallowEqual, useSelector } from 'react-redux';

export const Welcome: FC = (props: any) => {
  const navigate = useNavigate();
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const navigateToOnboarding = () => {
    if (commonDetails?.data?.company?.type == 'engineering') {
      navigate(PageRouteConstants.MyAccount);
    } else {
      navigate(PageRouteConstants.OnBoarding);
    }
  };

  return (
    <Box sx={Style.Welcome.WelcomeWrapper}>
      <Container maxWidth="xl">
        <Box sx={{ m: 'auto', textAlign: 'center' }}>
          <Typography variant="subtitle2" sx={{ display: 'block', mb: { xs: 2, md: 3 } }}>
            Welcome to the
          </Typography>
          <Box
            sx={{
              mx: 'auto',
              width: 1,
              maxWidth: 330,
              mb: 3,
              img: {
                width: 1
              }
            }}>
            <img alt="" src="/images/trust-logo-black.svg" />
          </Box>
          <Button onClick={navigateToOnboarding} variant="contained" sx={{ minWidth: 160 }}>
            Onboarding
          </Button>
        </Box>
      </Container>
    </Box>
  );
};
