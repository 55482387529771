import { createContext, useState } from 'react';
export const LoginContext = createContext();

export const LoginContextProvider = ({ children }) => {
  const [userData, setUserData] = useState();
  const [values, setValues] = useState({
    password: '',
    Email: ''
  });
  return (
    <LoginContext.Provider value={{ userData, values, setValues, setUserData }}>
      {children}
    </LoginContext.Provider>
  );
};
