import SvgIcon from '@mui/material/SvgIcon';

export default function VideoPlay(props) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.5 15V9C22.5 4 20.5 2 15.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15Z"
        stroke="#436AF3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokLinejoin="round"
      />
      <path
        d="M3.01953 7.11H21.9795"
        stroke="#436AF3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokLinejoin="round"
      />
      <path
        d="M9.01953 2.11V6.97"
        stroke="#436AF3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokLinejoin="round"
      />
      <path
        d="M15.9805 2.11V6.52"
        stroke="#436AF3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokLinejoin="round"
      />
      <path
        d="M10.25 14.45V13.25C10.25 11.71 11.34 11.08 12.67 11.85L13.71 12.45L14.75 13.05C16.08 13.82 16.08 15.08 14.75 15.85L13.71 16.45L12.67 17.05C11.34 17.82 10.25 17.19 10.25 15.65V14.45V14.45Z"
        stroke="#436AF3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
