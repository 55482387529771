import HttpService from '@jibin/common/services/http/HttpService';
export const EngineeringConsultingApi = {
  GetHours(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");

    return HttpService.getAll(`companies/${companyId}/users/${userId}/consulting/hours`);
  },
  GetConsumedHours(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");

    return HttpService.getAll(`companies/${companyId}/users/${userId}/consulting/consumed-hour`);
  }
};
