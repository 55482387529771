import HttpService from '@jibin/common/services/http/HttpService';

export const FrameworksApi = {  
  // Remediations(userId: string, companyId: string) {
  //   //return HttpService.getAll("todos/1");
  //   return HttpService.getAll(`companies/${companyId}/users/${userId}/remediations`);
  // },
  FrameworksList(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/frameworks`);
  },
  FrameworksControls(userId: string, companyId: string,frameworkId:string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/frameworks/${frameworkId}/controls`);
  },

}