import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import GoldIcon from '@jibin/common/icons/MedalIcon/GoldIcon.svg';
import SilverIcon from '@jibin/common/icons/MedalIcon/SilverIcon.svg';
import BronzeIcon from '@jibin/common/icons/MedalIcon/BronzeIcon.svg';
import PlatniumIcon from '@jibin/common/icons/MedalIcon/PlatniumIcon.svg';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { Skeleton } from '@mui/material';
import { CompanyManagementApi } from './CompanyManagement.Api';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const CompanyManagementTable: FC<{
  isRefresh: boolean;
  isSkeleton: boolean;
  search: string;
}> = (prop) => {
  const { isRefresh, search } = prop;
  const [companyData, setCompanyData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const open = Boolean(anchorEl);

  const nevigate = useNavigate();
  const renderColorBackGround = (severity: any = 'active') => {
    // Active = Green
    // Bypass = Red
    // Deregistered = Yellow
    // Errors = Red
    // Inactive = Red
    // Pending Install = Red
    // Pending Update = Yellow
    // Quarantine = Red
    // Sensor Out of Date = Red
    var color = '#27AE60';
    switch (severity.toLowerCase()) {
      case 'active':
        color = '#27AE60';
        break;

      case 'bypass':
      case 'errors':
      case 'inactive':
      case 'pending install':
      case 'quarantine':
      case 'sensor out of date':
        color = '#EF4444';
        break;

      case 'deregistered':
      case 'pending':
        color = '#FCD34D';
        break;
    }

    return color;
  };

  const loadMedal = (type) => {
    switch (type) {
      case 'gold':
      case 'Gold':
        return <img width={'16px'} src={GoldIcon} />;

      case 'silver':
      case 'Silver':
        return <img width={'16px'} src={SilverIcon} />;
      case 'bronze':
      case 'Bronze':
        return <img width={'16px'} src={BronzeIcon} />;
      case 'platinum':
      case 'Platinum':
        return <img width={'16px'} src={PlatniumIcon} />;
    }
  };
  const GetAllCompany = useCallback(async () => {
    try {
      const res = await CompanyManagementApi.GetCompany(
        commonDetails.data.company.company_uuid,
        commonDetails.data.user_uuid
      );
      setCompanyData(res?.data?.data);
    } catch (error) {}
  }, [commonDetails]);
  useEffect(() => {
    GetAllCompany();
  }, [GetAllCompany]);
  return (
    <Box sx={Style.PoliciesAndProcedures.PoliciesTableWrapper}>
      {!prop.isSkeleton ? (
        <Table sx={{ minWidth: 650 }} aria-label="site list table">
          <TableHead>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell>Website</TableCell>
              <TableCell>Industry</TableCell>
              <TableCell>Plan</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyData?.map((el: any, index) => (
              <>
                <Box sx={Style.Common.Divider}></Box>
                <TableRow
                  key={index + 1}
                  onClick={() => nevigate(PageRouteConstants.CompanyManagementAccount)}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',

                    borderColor: '#CCCCCC'
                  }}>
                  <TableCell component="th" scope="row" sx={{ minWidth: 200 }}>
                    <Typography variant="caption"> {el?.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption"> {el?.website}</Typography>{' '}
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption"> {el?.industry}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      {loadMedal(el?.plan.toLowerCase())}
                      {el?.plan}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: '14px',
                          height: '14px',
                          backgroundColor: renderColorBackGround(el?.status),
                          borderRadius: '50%'
                        }}></Box>
                      <Typography variant="caption"> {el?.status}</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table
          sx={{ minWidth: 650, borderSpacing: '0px 10px', borderCollapse: 'separate' }}
          aria-label="site list table">
          <TableHead>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell>Website</TableCell>
              <TableCell>Industry</TableCell>
              <TableCell>Plan</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row" sx={{ minWidth: 200 }}>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell>
                {' '}
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                </Typography>
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default CompanyManagementTable;
