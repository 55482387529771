import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import AddApplicationModel from './AddApplicationModel/AddApplicationModel';
import Typography from '@mui/material/Typography';
import ApplicationsTable from './ApplicationsTable';
import { ApplicationModel } from './Models/ApplicationModel';
import AddCircleIcon from '@jibin/common/icons/AddCircle/AddCircle';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import CloudIcon from '@jibin/common/icons/Cloud/Cloud';
import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
import LanguageIcon from '@jibin/common/icons/Language/Language';
import DesktopWindowIcon from '@jibin/common/icons/DesktopWindow/DesktopWindow';

import { shallowEqual, useSelector } from 'react-redux';
import { TechinicalInformation } from '../TechinicalInformation.Api';
import { ApiResponse, ApplicationData } from './Models/ApiResponse';
import ConformationModel from '@jibin/common/components/ConformationModel';
import { DeleteTypesEnum } from '@jibin/common/utils/enums/deleteTypeEnums';
import { Env } from './Models/Env.model';
import AddTechEnvModel from './AddTechEnvModel/AddTechEnvModel';
import { Data } from 'src/Pages/Signup/signup.models';
import { InputAdornment, Skeleton, TextField } from '@mui/material';
import SearchIcon from '@jibin/common/icons/Search/Search';
import { useLocation, useSearchParams } from 'react-router-dom';
var debouce = require('lodash.debounce');
const label = { inputProps: { 'aria-label': 'Switch demo' } };
export const Row: FC<{ row: any; index: any; props: any; refreshTable(): void }> = (rowProp) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [rowData, setRowData] = useState({} as any);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any, data: any) => {
    setRowData(data);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setRowData(null);
    setAnchorEl(null);
  };
  const handleConfirm = (data: any) => {
    let status;
    data.status == 'Active' ? (status = 'Inactive') : (status = 'Active');
    data = { ...data, status: status };

    TechinicalInformation.PatchApplication(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      data.tech_info_application_uuid,
      data
    ).then(
      (q: any) => {
        handleClose();
        rowProp.refreshTable();
      },
      (err: any) => {}
    );
  };
  return (
    <Box key={'b1' + rowProp.index}>
      <Box
        id={rowProp.row.tech_info_application_uuid}
        sx={{
          p: 1,
          border: '1px solid',
          borderColor: 'grey.200',
          bgcolor: 'common.white',
          display: 'flex',
          alignItems: 'center',
          mt: 3
        }}>
        {rowProp.row.type == 'SaaS' && (
          <CloudIcon
            sx={{ color: rowProp.row.status == 'Active' ? 'primary.main' : '#B7B7B7', mr: 1 }}
          />
        )}
        {rowProp.row.type == 'Desktop' && (
          <DesktopWindowIcon
            sx={{ color: rowProp.row.status == 'Active' ? 'primary.main' : '#B7B7B7', mr: 1 }}
          />
        )}
        {rowProp.row.type == 'Website' && (
          <LanguageIcon
            sx={{ color: rowProp.row.status == 'Active' ? 'primary.main' : '#B7B7B7', mr: 1 }}
          />
        )}
        <Typography
          variant="caption"
          sx={{
            flex: '1 1 auto',
            fontWeight: '600',
            color: rowProp.row.status == 'Active' ? 'primary.main' : '#B7B7B7'
          }}>
          {rowProp.row.name}
        </Typography>
        <IconButton
          aria-label="Example"
          onClick={(e) => {
            handleClick(e, rowProp.row);
          }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}>
          <MoreHorizIcon color="primary" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          <AddApplicationModel
            IsEdit={true}
            ApplicationModel={rowData}
            handleClose={handleClose}
            handleSubmit={() => {
              rowProp.refreshTable();
            }}
          />

          <Divider />
          <ConformationModel
            handleConfirm={() => {
              handleConfirm(rowData);
            }}
            title="Confirm Action"
            data={rowData}
            handleClose={() => handleClose()}
            label={{ ...label }}
            type={DeleteTypesEnum.SwitchAndLabel}
            asking={
              rowData?.status == 'Active'
                ? 'Are you sure you want to deactivate this application?'
                : 'Are you sure you want to activate this application?'
            }
            description={rowData?.name}
            cancelButtonText="Cancel"
            conformButtonText="Confirm"
          />

          {/* <MenuItem >
								<Switch {...label} defaultChecked sx={{ mr: 2 }} /> Deactivate
							</MenuItem> */}
        </Menu>
      </Box>
      <ApplicationsTable
        {...rowProp.row}
        applicationData={rowProp.row}
        handleRefresh={() => {
          rowProp.refreshTable();
        }}
      />
      <Box
        key={'b2' + rowProp.index}
        sx={{
          p: 1,
          border: '1px solid',
          borderColor: 'grey.200',
          borderTop: 0,
          bgcolor: 'common.white',
          display: 'flex',
          alignItems: 'center'
        }}>
        <Box sx={{ flex: '1 1 auto' }}>
          <AddTechEnvModel
            handleSubmit={() => {
              rowProp.refreshTable();
            }}
            envData={new Env()}
            handleClose={() => handleClose()}
            applicationId={rowProp.row.tech_info_application_uuid}
            applicationStatus={rowProp.row.status}
            type={1}
            envType={rowProp.row.type}
          />
        </Box>
        <IconButton
          aria-label="add"
          disabled={rowProp.row.status == 'Inactive'}
          sx={{ color: 'primary.main' }}>
          <AddTechEnvModel
            handleSubmit={() => {
              rowProp.refreshTable();
            }}
            envData={new Env()}
            handleClose={() => handleClose()}
            applicationId={rowProp.row.tech_info_application_uuid}
            applicationStatus={rowProp.row.status}
            type={2}
            envType={rowProp.row.type}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export const Applications: FC = (props: any) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [search, setSearch] = useState('');
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isSkeletonSearch, setIsSkeletonSearch] = useState(true);
  const [isRefresh, setIsRefresh] = useState(true);
  const [application, setApplication] = useState(new ApiResponse());
  const [applicationSearch, setApplicationSearch] = useState(new ApiResponse());
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const handleChange = (event: any) => {
    TechinicalInformation.GetApplicationsList(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      event.target.value
    ).then(
      (q: any) => {
        setApplicationSearch(q.data);
        setSearch(event.target.value);

        //setWirelessAssessmentListData(q.data);
        setIsSkeletonSearch(false);
      },
      (err: any) => {}
    );
  };

  const debouncedResults = useMemo(() => {
    return debouce(handleChange, 300);
  }, []);
  const GetWirelessAssessments = useCallback(() => {
    TechinicalInformation.GetApplicationsList(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      ''
    ).then((q: any) => {
      setApplication(q.data);
      //setWirelessAssessmentListData(q.data);
      setIsSkeleton(false);

      if (
        searchParams.get('tech_info_application_uuid') &&
        !searchParams.get('application_env_uuid')
      ) {
        const filterdData = q.data.data.filter(
          (el, index) =>
            el.tech_info_application_uuid == searchParams.get('tech_info_application_uuid')
        );

        setTimeout(() => {
          const element = document.getElementById(filterdData[0].tech_info_application_uuid);
          if (element) {
            var headerOffset = 90;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
            element.classList.add('flash');
            setTimeout(() => {
              element.classList.remove('flash');
            }, 1000);
          }
        }, 1);
      }
    });
  }, [commonDetails, location.search]);

  useEffect(() => {
    GetWirelessAssessments();
  }, [GetWirelessAssessments, props, isRefresh]);

  const handleRefresh = () => {
    setIsRefresh(!isRefresh);
  };
  if (isSkeleton) {
    return (
      <Box sx={{ px: 3 }}>
        <Skeleton sx={{ p: 5 }} />
      </Box>
    );
  } else if (application?.data?.length == 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', m: 'auto', mt: 5 }}>
        <Box sx={{ textAlign: 'center' }}>
          <img width={'50%'} src="/images/NoDataFound.png" />
          <Typography sx={{ mt: '10px', textAlign: 'center', color: '#696969' }} variant="body2">
            Please provide your company's technical information to enable your security services.
          </Typography>
          <Box sx={{ ...Style.CompanySettingPage.AddButton, textAlign: 'center', mt: 2 }}>
            <AddApplicationModel
              IsEdit={false}
              handleClose={() => {}}
              ApplicationModel={new ApplicationModel()}
              handleSubmit={() => {
                handleRefresh();
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box sx={Style.Applications.ApplicationsWrapper}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <TextField
            placeholder="Search"
            hiddenLabel
            onChange={debouncedResults}
            sx={{ mr: 2 }}
            inputProps={{ sx: { py: '8px' } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <Box sx={Style.Applications.AddButton}>
            <AddApplicationModel
              IsEdit={false}
              handleClose={() => {}}
              ApplicationModel={new ApplicationModel()}
              handleSubmit={() => {
                handleRefresh();
              }}
            />
          </Box>
        </Box>

        {applicationSearch?.data?.length != 0 && search ? (
          applicationSearch?.data?.map((row, index) => (
            <Row
              index={index}
              props={props}
              row={row}
              refreshTable={() => handleRefresh()}
              key={index}
            />
          ))
        ) : applicationSearch?.data?.length == 0 && search ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', m: 'auto', my: 5 }}>
            <Box sx={{ textAlign: 'center' }}>
              <img width={'50%'} src="/images/NoSearchFound.png" />
              <Typography variant="h2" sx={{ fontWeight: 600 }}>
                No Search Results
              </Typography>
              <Typography sx={{ mt: 2, textAlign: 'center', color: '#696969' }} variant="body2">
                Try adjusting your search or filter to find what you are looking for
              </Typography>
            </Box>
          </Box>
        ) : (
          application?.data?.map((row, index) => (
            <Row
              index={index}
              props={props}
              row={row}
              refreshTable={() => handleRefresh()}
              key={index}
            />
          ))
        )}
      </Box>
    </>
  );
};
