import Style from '@jibin/common/style/Styles';
import { Box, Button, Skeleton, SwipeableDrawer, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import FrameWorksListPopup from '../FrameWorkListPopup';
import { FrameworksApi } from '../Frameworks.Api';
import { useLocation, useSearchParams } from 'react-router-dom';

type Anchor = 'top' | 'left' | 'bottom' | 'right';
const FrameworkControl: FC<{ el: any }> = (prop) => {
  const [state, setState] = useState({
    right: false
  });
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [framework, setFramework] = useState({} as any);
  const [useParams, setUseParam] = useSearchParams();

  const location = useLocation();

  const toggleDrawer = (anchor: Anchor, open: boolean, ce: any) => {
    if (!open) {
      useParams.delete('framework_control_uuid');
      setUseParam(useParams);
    } else {
      setParams(ce.framework_control_uuid);
    }

    setFramework(ce);

    setState({ ...state, [anchor]: open });
  };
  const setParams = (index) => {
    const params = {
      frameworks_uuid: useParams.get('frameworks_uuid'),
      framework_control_uuid: index
    };
    setUseParam(params);
  };

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [frameworkControlList, setFrameworkControlList] = useState({} as any);

  const getFrameworkList = () => {
    FrameworksApi.FrameworksControls(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      prop.el?.frameworks_uuid
    ).then(
      (q: any) => {
        if (useParams.get('framework_control_uuid')) {
          //location.search.split('=')[1]
          const filterdData = q.data.data.filter(
            (el, index) => el.framework_control_uuid == useParams.get('framework_control_uuid')
          );
          if (filterdData.length > 0) {
            setTimeout(() => {
              const element = document.getElementById(filterdData[0]?.framework_control_uuid);

              if (element) {
                //element.scrollIntoView({ behavior: 'smooth'});
                var headerOffset = 90;
                var elementPosition = element.getBoundingClientRect().top;
                var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                window.scrollTo({
                  top: offsetPosition,
                  behavior: 'smooth'
                });
                element.classList.add('flash');
              }
            }, 1);

            toggleDrawer('right', true, filterdData[0]);
            setFramework(filterdData[0]);
          }
        }
        setFrameworkControlList(q.data);
        setIsSkeleton(false);
      },
      (err: any) => {}
    );
  };

  useEffect(() => {
    setIsSkeleton(true);
    getFrameworkList();
  }, [prop.el?.frameworks_uuid]);

  return (
    <>
      {prop.el && frameworkControlList.data ? (
        <Box sx={Style.Farmework.FrameworkWrapper}>
          <Box
            sx={{
              backgroundColor: '#FFFFFF',
              display: 'flex',
              mx: 1,
              flexDirection: 'column',
              justifyContent: 'flex-start',
              px: 3,
              py: 2.5,
              mb: 3,
              mt: 2,
              border: '1px solid #D5D5D5',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              borderRadius: 2
            }}>
            {prop.el.name == 'MPA' ? (
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, mb: 6.25 }}>
                <img width={'60px'} height="60px" src="/images/mpa.png" />
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                      MPA Best Practices Controls
                    </Typography>
                    <Box
                      sx={{
                        borderRadius: '100px',
                        fontSize: '12px',
                        backgroundColor: '#D9EDFF',
                        color: '#787878',
                        fontWeight: 600,
                        px: '8px',
                        py: '5px'
                      }}>
                      Version 5.1
                    </Box>
                  </Box>
                  <Box>
                    <Typography sx={{ color: '#787878' }} variant="body1">
                      To meet the industry’s evolving security needs, address industry-specific
                      challenges and provide clear information for all stakeholders involved in
                      Content Security.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, mb: 6.25 }}>
                <img width={'60px'} height="60px" src="/images/cis.png" />
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                      CIS Controls
                    </Typography>
                    <Box
                      sx={{
                        borderRadius: '100px',
                        fontSize: '12px',
                        backgroundColor: '#D9EDFF',
                        color: '#787878',
                        fontWeight: 600,
                        px: '8px',
                        py: '5px'
                      }}>
                      Version 8
                    </Box>
                  </Box>
                  <Box>
                    <Typography sx={{ color: '#787878' }} variant="body1">
                      Is a recommended set of actions for cyber defense that provide specific and
                      actionable ways to thwart the most pervasive attacks.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          {!isSkeleton && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, px: 1 }}>
              {frameworkControlList.data?.map((ce, index) => (
                <Box
                  id={ce.framework_control_uuid}
                  sx={{
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    p: 3,
                    border: '1px solid #D5D5D5',
                    borderRadius: 2
                  }}>
                  <Box sx={{ mb: 2.5 }}>
                    <Typography sx={{ fontWeight: 600, color: '#436AF3' }} variant="body1">
                      {ce.name ? ce.name : ce.topic}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#787878' }}>
                      {ce.domain}
                    </Typography>
                  </Box>
                  <Typography sx={{ fontWeight: 600, mb: 2.5 }} variant="body2">
                    Best Practice
                  </Typography>
                  <Typography sx={{ mb: 2.5, whiteSpace: 'pre-line' }} variant="body2">
                    {ce.best_practices}
                  </Typography>
                  <Typography
                    onClick={() => toggleDrawer('right', true, ce)}
                    sx={{
                      fontWeight: 600,
                      color: '#436AF3',
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}
                    variant="body2">
                    View More
                  </Typography>
                </Box>
              ))}
              <SwipeableDrawer
                BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
                sx={{
                  '.MuiDrawer-paper': {
                    boxShadow: 'none'
                  }
                }}
                anchor={'right'}
                open={state['right']}
                onClose={() => toggleDrawer('right', false, {})}
                onOpen={() => toggleDrawer('right', true, framework)}>
                <FrameWorksListPopup
                  el={framework}
                  closePopup={() => toggleDrawer('right', false, {})}
                />
              </SwipeableDrawer>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={Style.Farmework.FrameworkWrapper}>
          <Box sx={{ py: 2 }}>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 600, color: '#696969', fontSize: '19px', px: 1 }}>
              <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, px: 1 }}>
            <Box
              sx={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                p: 3,
                border: '1px solid #D5D5D5',
                borderRadius: 2
              }}>
              <Box sx={{ mb: 2.5 }}>
                <Typography sx={{ fontWeight: 600, color: '#436AF3' }} variant="body1">
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                </Typography>
                <Typography variant="body2" sx={{ color: '#787878' }}>
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                </Typography>
              </Box>
              <Typography sx={{ fontWeight: 600, mb: 2.5 }} variant="body2">
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </Typography>
              <Typography sx={{ mb: 2.5 }} variant="body2">
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  color: '#436AF3'
                }}
                variant="body2">
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default FrameworkControl;
