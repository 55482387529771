import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { ApiResponse } from './Models/ApiResponse';
import { PoliciesAndProceduresApi } from './Policys.Api';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { Skeleton } from '@mui/material';
const momentTimezone = require('moment-timezone');
const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const PoliciesTable: FC<{ policyList: any; isRefresh: boolean; isSkeleton: boolean }> = (
  prop
) => {
  const { policyList, isRefresh } = prop;
  const [anchorEl, setAnchorEl] = useState(null);
  const [updatedPolicy, setPolicyUpdated] = useState([]);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const nevigate = useNavigate();

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  useEffect(() => {}, [isRefresh]);

  return (
    <Box sx={Style.PoliciesAndProcedures.PoliciesTableWrapper}>
      {!prop.isSkeleton ? (
        <Table
          sx={{ minWidth: 650, borderSpacing: '0px 10px', borderCollapse: 'separate' }}
          aria-label="site list table">
          <TableHead>
            <TableRow>
              <TableCell>Policy Name</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Last Draft</TableCell>
              <TableCell>Created by</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {policyList?.data?.map((row) => (
              // <Box sx={Style.Common.Divider}></Box>
              <TableRow
                key={row.name}
                sx={{
                  cursor: 'pointer',
                  '.MuiTableCell-root': {
                    backgroundColor: '#FFFFFF ',

                    borderBottom: '1px solid  #CCCCCC',
                    borderTopRadius: '4px',
                    borderTop: '1px solid  #CCCCCC',
                    '&:last-child': {
                      borderRight: '1px solid  #CCCCCC',
                      borderTopRightRadius: '4px',
                      borderBottomRightRadius: '4px'
                    },
                    '&:first-child': {
                      borderLeft: '1px solid  #CCCCCC',
                      borderTopLeftRadius: '4px',
                      borderBottomLeftRadius: '4px'
                    }
                  }
                }}>
                <TableCell component="th" scope="row" sx={{ minWidth: 200 }}>
                  {row.name}
                </TableCell>
                <TableCell>{row.version}</TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  {moment(row.last_draft_date).isValid()
                    ? momentTimezone(row.last_draft_date)
                        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                        .format('DD MMM YYYY')
                        .toUpperCase()
                    : ''}
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {row.owner.first_name + ' ' + row.owner.last_name}
                    <Box component="span" sx={{ display: 'block' }}>
                      {' '}
                      {row.owner.email}
                    </Box>{' '}
                  </Typography>
                </TableCell>
                <TableCell sx={{ minWidth: 150 }}>
                  <Box>
                    {row.status.toUpperCase() == 'APPROVED' && (
                      <Box
                        sx={{ ...Style.SiteListTable.StatusActive, bgcolor: 'common.green' }}></Box>
                    )}
                    {row.status.toUpperCase() == 'DRAFT' && (
                      <Box sx={{ ...Style.SiteListTable.StatusActive, bgcolor: 'grey.500' }}></Box>
                    )}
                    {row.status.toUpperCase() == 'ARCHIVED' && (
                      <Box sx={{ ...Style.SiteListTable.StatusActive, bgcolor: 'black' }}></Box>
                    )}
                    {row.status.toUpperCase() == 'SUBMITTED' && (
                      <Box
                        sx={{
                          ...Style.SiteListTable.StatusActive,
                          bgcolor: 'common.orange'
                        }}></Box>
                    )}
                    {row.status.toUpperCase() == 'REJECTED' && (
                      <Box
                        sx={{ ...Style.SiteListTable.StatusActive, bgcolor: 'error.main' }}></Box>
                    )}
                    {row.status}
                  </Box>
                </TableCell>
                <TableCell align="right" sx={{ minWidth: 200 }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      nevigate(PageRouteConstants.PoliciesAndProceduresTemplate + row.policy_uuid);
                    }}>
                    View Document
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table
          sx={{ minWidth: 650, borderSpacing: '0px 10px', borderCollapse: 'separate' }}
          aria-label="site list table">
          <TableHead>
            <TableRow>
              <TableCell>Policy Name</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Last Draft</TableCell>
              <TableCell>Created by</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                cursor: 'pointer',
                '.MuiTableCell-root': {
                  backgroundColor: '#FFFFFF ',

                  borderBottom: '1px solid  #CCCCCC',
                  borderTopRadius: '4px',
                  borderTop: '1px solid  #CCCCCC',
                  '&:last-child': {
                    borderRight: '1px solid  #CCCCCC',
                    borderTopRightRadius: '4px',
                    borderBottomRightRadius: '4px'
                  },
                  '&:first-child': {
                    borderLeft: '1px solid  #CCCCCC',
                    borderTopLeftRadius: '4px',
                    borderBottomLeftRadius: '4px'
                  }
                }
              }}>
              <TableCell component="th" scope="row" sx={{ minWidth: 200 }}>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell>
                {' '}
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell sx={{ minWidth: 150 }}>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                </Typography>
              </TableCell>
              <TableCell sx={{ minWidth: 150 }}>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell align="right" sx={{ minWidth: 200 }}>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default PoliciesTable;
