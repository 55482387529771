import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import { FC, useCallback, useEffect, useState } from 'react';
import ScheduleMeetingsModel from './ScheduleMeetingsModel';
import Typography from '@mui/material/Typography';
import ScheduleIcon from '@jibin/common/icons/Schedule/Schedule';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MeetingsTable from './MeetingsTable';
import { shallowEqual, useSelector } from 'react-redux';
import { CompanySettingsService } from '../CompanySettings.Api';
import moment from 'moment';

export const Meetings: FC = (props: any) => {
  const [meetingListData, setMeetingListData] = useState({});
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isRefresh, setIsRefresh] = useState(true);

  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const handleRefresh = () => {
    setIsRefresh(!isRefresh);
  };

  const GetCompanyWebsites = useCallback(() => {
    CompanySettingsService.GetCompanyMeetings(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setMeetingListData(q.data);
        setIsSkeleton(false);
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  useEffect(() => {
    GetCompanyWebsites();
  }, [GetCompanyWebsites]);

  const getdurationstrin = (duration: any) => {
    let stringTime = moment(duration, 'HH:mm').format('H [Hours] mm [mins]');
    stringTime = stringTime.replace('0 Hours', '');
    stringTime = stringTime.replace('1 Hours', '1 Hour');
    stringTime = stringTime.replace('00 mins', '');

    return stringTime.trim();
  };

  return (
    <>
      <Box sx={Style.CompanySettingPage.CompanySettingPageWrapper}>
        {!isSkeleton && (
          <Grid container spacing={2}>
            {meetingListData['data'].map((currentElement, index) => (
              <Grid item xs={12} sm={6} lg={3} key={index}>
                <Box sx={Style.MeetingCard}>
                  <Typography variant="body1" sx={{ fontWeight: '600', mb: 1.5 }}>
                    {currentElement.name}{' '}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1.5, minHeight: 100, maxHeight: 100 }}>
                    {currentElement.description}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mb: 1.5, fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                    <ScheduleIcon sx={{ mr: 1 }} />{' '}
                    <Box component="span">{getdurationstrin(currentElement.duration)}</Box>
                  </Typography>
                  {/* <Button variant="contained" sx={{ minWidth: 140 }}>{currentElement.duration}</Button> */}
                  <ScheduleMeetingsModel
                    handleRefresh={() => handleRefresh()}
                    Meeting={currentElement}
                    isReschedule={false}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
        
        <MeetingsTable isRefresh={isRefresh} />
      </Box>
    </>
  );
};
