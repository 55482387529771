export class UsereDetailsModel {

    constructor(ud?: UsereDetailsModel )  {
        let properties = [ "first_name", "last_name", "phone", "email", "isMfaAppEnabled","isMfaSmsEnabled","company", "job",'company_uuid'];
        for (let property of properties)
            if (ud && (ud[property] || ud[property] === 0))
                this[property] = ud[property];
    }

    first_name : string = '';
    last_name  : string = '';
    phone : string = '';
    email: string = '';
    isMfaAppEnabled: boolean;
    isMfaSmsEnabled: boolean;
    company : string = '';
    job  : string = '';
    company_uuid : string = '';
}