import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import Style from '@jibin/common/style/Styles';
import { PageRouteConstants } from '../utils/constants/PageRouteConstants';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

// const MenuItems = [
//   'Company Profile', 'Manage Users', 'Meetings', 'Authorizations', 'Plan and Billing'
// ]

const MenuItems = [
  { name: 'Manage Users', class: '', route: PageRouteConstants.ManageUsers },
  { name: 'Meetings', class: '', route: PageRouteConstants.Meetings },
  { name: 'Locations', class: '', route: PageRouteConstants.CompanyProfile },
  { name: 'Authorizations', class: '', route: PageRouteConstants.Authorizations },
  { name: 'Plan and Billing', class: '', route: PageRouteConstants.PlanAndBilling }
];

export const CompanySettings: FC<{ children: React.ReactNode | React.ReactNode[] }> = (props) => {
  const navigate = useNavigate();
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  return (
    <>
      <Box>
        <Typography variant="h2" sx={Style.CompanySetting.PageHeadTitle}>
          {commonDetails?.data?.company?.type == 'engineering'
            ? 'Manage User'
            : 'Company Information'}
        </Typography>
      </Box>
      {commonDetails?.data?.company?.type == 'engineering' ? (
        <Box></Box>
      ) : (
        <List sx={Style.CompanySetting.MenuList}>
          {MenuItems.map((currenElement, index) => (
            <ListItem disablePadding key={index} sx={{ width: 'auto' }}>
              <Link
                onClick={() => {
                  navigate(currenElement.route);
                }}
                style={{ cursor: 'pointer' }}
                className={window.location.pathname === currenElement.route ? 'active' : ''}
                sx={Style.CompanySetting.MenuListItem}>
                {currenElement.name}
              </Link>
            </ListItem>
          ))}
        </List>
      )}
      {props.children}
    </>
  );
};

export default CompanySettings;
