
export class ApiResponse {
    error: Error
    data: Locations[]
  }
  
  export class Error {
    code: number
    message: string
  }
  
  export class Facility {
    company_facility_uuid: string;
    name: string;
    type: string;
    status: string;
}

export class Locations {
    company_address_uuid: string;
    company_uuid: string;
    zip: string;
    country: string;
    state: string;
    city: string;
    address1: string;
    address2: string;
    status: string;
    facilities: Facility[];
    created_at: Date;
    updated_at: any;
    created_by: string;
    updated_by: string;
}
