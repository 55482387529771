
export class ApiResponse {
    error: Error
    data: Users[]
  }
  
  export class Error {
    code: number
    message: string
  }
  
  export class Users {
    user_uuid: string
    first_name: string=''
    last_name: string=''
    email: string
    phone: string
    created_at: string
    role: string
    status: string    
  }
  
