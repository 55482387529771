import HttpService from '@jibin/common/services/http/HttpService';

export const PoliciesAndProceduresApi = {
  // User section
  GetPolicyList(userId: string, companyId: string, q: string = '') {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/settings/policies?keyword=${q}`
    );
  },

  GetPolicyById(userId: string, companyId: string, policyId: string, version: number = 0) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/settings/policy/${policyId}?version=${version}`
    );
  },

  GetPolicyHistoryById(userId: string, companyId: string, policyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/settings/policy/${policyId}/history`
    );
  },

  CreatePolicy(userId: string, companyId: string, data: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.post(`companies/${companyId}/users/${userId}/settings/policy`, data);
  },

  CreatePolicyDocument(userId: string, companyId: string, policyId: string, data: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.post(
      `companies/${companyId}/users/${userId}/settings/policy/${policyId}/document`,
      data
    );
  },
  SubmitPolicyDocument(userId: string, companyId: string, policyId: string, data: any) {
    //return HttpService.getAll("todos/1");
    return HttpService.patch(
      `companies/${companyId}/users/${userId}/settings/policy`,
      policyId,
      data
    );
  },
  GetTemplates(userId: string, companyId: string) {
    return HttpService.getAll(
      `companies/${companyId}/users/${userId}/policies/templates`
    );
  },
  CreateNewPolicyFromTemplate(userId: string, companyId: string,templateId:any) {
    return HttpService.post(
      `companies/${companyId}/users/${userId}/settings/policy/${templateId}`,{}
    );
  },


};
