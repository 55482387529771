export class Location{
    
    constructor(companySite?: Location )  {
        let properties = [
             "country", "address1", "address2", "zip", "state", "city",
        "company_address_uuid","company_uuid","status","facilities","address"];
        for (let property of properties)
            if (companySite && (companySite[property] || companySite[property] === 0))
                this[property] = companySite[property];
    }
    country:string ='';    
    address1:string='';
    address2:string='';
    zip:string='';
    state:string='';
    city:string='';  
    address:string='';     
    status:string ="Active"
    company_address_uuid: string='';
    company_uuid: string='';
    facilities: Facility[];

}

export class Facility {
    company_facility_uuid: string;
    name: string;
    type: string;
    status: string;
}