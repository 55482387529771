export default function UpArrow() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99967 15.8335L9.99967 4.16683M9.99967 4.16683L4.16634 10.0002M9.99967 4.16683L15.833 10.0002"
        stroke="#F96363"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
