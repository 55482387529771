import SearchIcon from '@jibin/common/icons/Search/Search';
import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Skeleton,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  SwipeableDrawer,
  IconButton,
  Menu
} from '@mui/material';

import { Box } from '@mui/system';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import { RemediationApi } from '../Remediation/Remediation.Api';
import { EmailProtectionApi } from './EmailProtection.Api';
import EndPointProtectionSideBarEngineer from './EmailProtectionSideBar/EmailProtectionSideBarEngineer';
import EndPointProtectionSideBarUser from './EmailProtectionSideBar/EmailProtectionSideBarUser';
import EndpointProtectionRow from './EmailProtectionRow';
type Anchor = 'top' | 'left' | 'bottom' | 'right';
const momentTimezone = require('moment-timezone');

const EmailProtection: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [useParams, setUseParam] = useSearchParams();
  const [rowData, setRowData] = useState({} as any);
  const toggleDrawer = (anchor: Anchor, open: boolean, el: any) => {
    setclick(!click);
    if (!open) {
      useParams.delete('device_id');
      setUseParam(useParams);
    } else {
      setParams(el.device_id);
    }
    setRowData(el);
    setState({ ...state, [anchor]: open });
  };
  const setParams = (index) => {
    const params = {
      device_id: index
    };
    setUseParam(params);
  };
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [assignees, setAssignees] = useState({} as any);
  const [serach, setSearch] = useState({ keyword: '', status: '' });
  const [state, setState] = useState({
    right: false
  });
  const menuItems = [
    {
      value: 'ACTIVE',
      lable: 'Active'
    },
    {
      value: 'PENDING',
      lable: 'Pending'
    },
    {
      value: 'INACTIVE',
      lable: 'Inactive'
    },
    {
      value: 'SENSOR_OUTOFDATE',
      lable: 'Sensor out of date'
    },
    {
      value: 'BYPASS',
      lable: 'Bypass'
    },
    {
      value: 'DEREGISTERED',
      lable: 'Deregistered'
    },
    {
      value: 'QUARANTINE',
      lable: 'Quarantine'
    },
    {
      value: 'ERROR',
      lable: 'Error'
    },
    {
      value: 'SENSOR_PENDING_UPDATE',
      lable: 'Sensor pending update'
    }
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [engineer, setEngineer] = useState(null);
  const [endpoint, setEndPoints] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [click, setclick] = useState(false);
  const [isRemediation, setIsRemediation] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const location = useLocation();
  const GetAssignees = useCallback(async () => {
    setIsSkeleton(true);
    try {
      const res = await RemediationApi.GetAssignees(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );
      setIsSkeleton(false);
      setAssignees(res.data);
    } catch (err) {
      setIsSkeleton(false);
      console.log(err);
    }
  }, [commonDetails]);
  useEffect(() => {
    GetAssignees();
  }, []);

  const GetEndPoint = useCallback(async () => {
    try {
      const res = await EmailProtectionApi.GetAllEndPoint(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        new URLSearchParams(serach).toString()
      );
      if (searchParams.get('device_id')) {
        const filterdData = res.data.data.filter(
          (el, index) => el.device_id == searchParams.get('device_id')
        );

        setTimeout(() => {
          const element = document.getElementById(filterdData[0].device_id);
          if (element) {
            //element.scrollIntoView({ behavior: 'smooth'});
            var headerOffset = 90;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
            element.classList.add('flash');
            setTimeout(() => {
              element.classList.remove('flash');
            }, 1000);
          }
        }, 1);

        toggleDrawer('right', true, filterdData[0]);
        //setRowData(filterdData[0]);
      }
      setEndPoints(res.data.data);
    } catch (error) {}
  }, [commonDetails, serach, location.search]);

  useEffect(() => {
    if (!click) {
      GetEndPoint();
    }
    if (engineer == null) {
      if (commonDetails.data.group == 'engineer') {
        setEngineer(true);
      } else {
        setEngineer(false);
      }
    }
  }, [GetEndPoint]);
  const handleSearch = (e) => {
    setSearch({ ...serach, [e.target.name]: e.target.value });
  };
  const handleRadioChange = (e: any) => {
    if (e.target.value == 'engineer') {
      setEngineer(true);
    } else {
      setEngineer(false);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          backgroundColor: '#FFFFFF',
          p: 4,
          gap: 1
        }}>
        <Typography variant={'subtitle1'}>Email Protection</Typography>
        <Typography variant={'subtitle2'}>
          Advanced email security to protect against phishing attacks
        </Typography>
        {commonDetails.data.group == 'superadmin' && (
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="user"
            onChange={handleRadioChange}
            name="radio-buttons-group"
            row>
            <FormControlLabel value="engineer" control={<Radio />} label="Engineer" />
            <FormControlLabel value="user" control={<Radio />} label="User" />
          </RadioGroup>
        )}
      </Box>
      {isSkeleton ? (
        <Box sx={{ px: 4, py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : (
        <Box sx={{ px: 4, py: 1, backgroundColor: '#FBFBFF', width: 1 }}>
          <Typography variant="subtitle2" sx={{ fontSize: '19px', fontWeight: 600, mt: 2 }}>
            Email Threats{' '}
          </Typography>

          <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}>
            <Table
              sx={{
                minWidth: 650,
                borderCollapse: 'separate',
                backgroundColor: '#FBFBFF',
                boxShadow: 'none',
                borderSpacing: '0px 16px',
                border: 'none'
              }}
              aria-label="site list table">
              <TableHead>
                <TableRow sx={{ '.MuiTableCell-root': { border: 'none' } }}>
                  <TableCell>
                    <Typography variant="body2">Threat</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">Received</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2"> Subject</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2"> Size</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2"> From</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2"> To</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2"> Type</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2"> Status</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">Actions </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {endpoint.map((el: any, index: any) => (
                  <EndpointProtectionRow
                    onClick={(e) => {
                      setIsRemediation(e);
                      toggleDrawer('right', true, el);
                    }}
                    engineer={engineer}
                    assignees={assignees}
                    el={el}
                  />
                ))}
              </TableBody>
            </Table>
            <SwipeableDrawer
              BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
              sx={{
                '.MuiDrawer-paper': {
                  boxShadow: 'none'
                }
              }}
              anchor={'right'}
              open={state['right']}
              onClose={() => toggleDrawer('right', false, {})}
              onOpen={() => toggleDrawer('right', true, rowData)}>
              {engineer ? (
                <EndPointProtectionSideBarEngineer
                  rowData={rowData}
                  assignees={assignees}
                  isCreateRemediation={isRemediation}
                  onClose={() => toggleDrawer('right', false, {})}
                />
              ) : (
                <EndPointProtectionSideBarUser
                  rowData={rowData}
                  isCreateRemediation={isRemediation}
                  assignees={assignees}
                  onClose={() => toggleDrawer('right', false, {})}
                />
              )}
            </SwipeableDrawer>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default EmailProtection;
