import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
import { TickMarkBlue } from '@jibin/common/icons/Tickmark/Tickmark';
import { Box, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import momentTimezone from 'moment-timezone';
import { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

const EndpointProtectionRow = ({ el, assignees, engineer, onClick }) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [rowData, setRowData] = useState({} as any);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setRowData(null);
    setAnchorEl(null);
  };

  const renderColorBackGround = (severity: any = 'active') => {
    // Active = Green
    // Bypass = Red
    // Deregistered = Yellow
    // Errors = Red
    // Inactive = Red
    // Pending Install = Red
    // Pending Update = Yellow
    // Quarantine = Red
    // Sensor Out of Date = Red
    var color = '#27AE60';
    switch (severity.toLowerCase()) {
      case 'active':
        color = '#27AE60';
        break;

      case 'bypass':
      case 'errors':
      case 'inactive':
      case 'pending install':
      case 'quarantine':
      case 'sensor out of date':
        color = '#EF4444';
        break;

      case 'deregistered':
      case 'pending update':
        color = '#FCD34D';
        break;
    }

    return color;
  };

  return (
    <TableRow
      id={el.device_id}
      sx={{
        cursor: 'pointer',
        '.MuiTableCell-root': {
          //backgroundColor: '#FFFFFF ',
          px: '20px',

          borderBottom: '1px solid  #CCCCCC',
          borderTopRadius: '4px',
          borderTop: '1px solid  #CCCCCC',
          '&:last-child': {
            borderRight: '1px solid  #CCCCCC',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px'
          },
          '&:first-child': {
            borderLeft: '1px solid  #CCCCCC',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px'
          }
        }
      }}>
      <TableCell
        onClick={() => {
          onClick(false);
          handleClose();
        }}
        align="left">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              width: '14px',
              height: '14px',
              backgroundColor: renderColorBackGround(el.status),
              borderRadius: '50%'
            }}></Box>
          <Typography variant="caption"> {el.status}</Typography>
        </Box>
      </TableCell>
      <TableCell
        onClick={() => {
          onClick(false);
          handleClose();
        }}
        component="th"
        scope="row">
        <Typography variant="caption">
          {momentTimezone(el?.last_check_in).tz('utc').format('DD MMM YYYY hh:mm A').toUpperCase()}
        </Typography>
      </TableCell>      
      <TableCell
        onClick={() => {
          onClick(false);
          handleClose();
        }}
        align="left">
        <Typography variant="caption">{el.user_name} </Typography>
      </TableCell>
      <TableCell
        onClick={() => {
          onClick(false);
          handleClose();
        }}
        align="left">
        <Typography variant="caption">{el.device_name}</Typography>
      </TableCell>
      <TableCell
        onClick={() => {
          onClick(false);
          handleClose();
        }}
        sx={{ maxWidth: 250 }}
        align="left">
        <Typography variant="caption">{el.os} </Typography>
      </TableCell>
      <TableCell
        onClick={() => {
          onClick(false);
          handleClose();
        }}
        align="left">
        <Typography variant="caption"> {el.sensor} </Typography>
      </TableCell>

      <TableCell sx={{ maxWidth: 250 }} align="left">
        <IconButton
          onClick={handleClick}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}>
          <MoreHorizIcon color="primary" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id={'account-menu'}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          <MenuItem
            onClick={() => {
              onClick(true);
              handleClose();
            }}>
            <TickMarkBlue />
            <Typography sx={{ ml: 1 }} variant="caption">
              {' '}
              Create Remediation
            </Typography>
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default EndpointProtectionRow;
