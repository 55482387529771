import { Box, Typography } from '@mui/material';
import { FC } from 'react';

export const NotFound: FC = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 13 }}>
      <Box sx={{ textAlign: 'center' }}>
        <img width={'50%'} src="/images/NoFound.png" />
        <Typography variant="body2" sx={{ fontWeight: 600, color: '#436AF3', mt: '10px' }}>
          404 error
        </Typography>
        <Typography variant="h1" sx={{ fontSize: '40px', color: '#787878', mt: '12px' }}>
          Page not found
        </Typography>
        <Typography
          sx={{ mt: '12px', textAlign: 'center', fontWeight: 600, color: '#9D9D9D' }}
          variant="subtitle2">
          Sorry, the page you are looking for doesn't exist.
        </Typography>
        <Typography
          sx={{ mt: '10px', textAlign: 'center', fontWeight: 600, color: '#9D9D9D' }}
          variant="subtitle2">
          Our team is working to fix that.
        </Typography>
      </Box>
    </Box>
  );
};
