import HttpService from '@jibin/common/services/http/HttpService';

export const OnboardingApi = {
  GetUserData() {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll('users/me');
  },
  GetSecurityAwareness(company_id: string, user_id: string) {
    return HttpService.getAll(`companies/${company_id}/users/${user_id}/security/awareness/users`);
  },
  PostDocument(company_id: string, user_id: string, data: any) {
    return HttpService.post(
      `companies/${company_id}/users/${user_id}/security/awareness/users`,
      data
    );
  }
};
