export class SignupModel{
    error: Error = new Error();
    data:  Data = new Data();
}
export class Data {
    user:    User = new User();
    company: Company[] = [];
}

export class Company {
    name:          string='';
    industry_type: string[] = [];
    role:          string='';
    external_id:   string='';
}

export class User {
    username:   string='';
    first_name: string='';
    last_name:  string='';
    email:      string='';
    phone:      string='';
    external_id: string="";
}

export class Error {
    code:    number=0;
    message: string='';
}