import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React, { FC } from 'react';
import CloseIcon from '../../icons/Close/Close';
import WarningIcon from '../../icons/Warning/Warning';
import DeleteIcon from '@jibin/common/icons/Delete/Delete';
import { DeleteTypesEnum } from '../../utils/enums/deleteTypeEnums';
import { Divider, MenuItem, Switch } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { shallowEqual, useSelector } from 'react-redux';

export interface ConformationModel {
  title?: string;
  description?: string;
  asking?: string;
  cancelButtonText?: string;
  conformButtonText?: string;
  type?: number;
  label?: any;
  data?: any;
  mainBtnTitle: any;
  mainBtnType: any;
  returnData: any;
  handleConfirm(returnData: any): void;
}

export const SuccessConfirmationUpadated: FC<ConformationModel> = (props) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    setOpen(false);
    props.handleConfirm(props.returnData);
  };
  const handleChange = (e: any) => {
    if (!e.target.checked) {
      handleClickOpen();
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{ minWidth: 150 }}
        color={props.mainBtnType}>
        {props.mainBtnTitle}
      </Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        sx={{ maxWidth: '450px', m: 'auto' }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            px: { xs: 1, md: 2 },
            py: { xs: 0.5, md: 1 },
            bgcolor: 'primary.main100'
          }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'primary.main',
                fontWeight: '600',
                maxWidth: 'calc(100% - 40px)',
                p: 1
              }}>
              <CheckCircle sx={{ mr: 1 }} />
              {props.title}
            </Typography>
            <IconButton sx={{ color: 'text.primary' }} aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
          {props.asking && (
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: 'text.primary', fontWeight: 400, mb: 1 }}>
              {props.asking}
            </DialogContentText>
          )}
          <DialogContentText id="alert-dialog-description" sx={{ color: 'text.primary' }}>
            {props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0 }}>
          <Button
            variant="outlined"
            sx={{ minWidth: 120, color: '#9D9D9D', borderColor: '#9D9D9D', py: 0.4 }}
            onClick={handleClose}>
            {props.cancelButtonText}
          </Button>
          <Button
            variant="contained"
            sx={{ minWidth: 120, py: 0.4 }}
            color="primary"
            onClick={handleConfirm}>
            {' '}
            {props.conformButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConformationModel;
