import { useState, useEffect, useContext } from 'react';
import { Box } from '@mui/system';
import { FC } from 'react';
import Style from '@jibin/common/style/Styles';
import { Button, Container, Grid, TextField, Typography } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';

import { LoginContext } from '@jibin/common/Context/LoginContext';
import { Amplify, Auth } from 'aws-amplify';
import { LoginService } from '../Login/index.api';
import commonDetailSlice from 'src/store/reducers/commonReducer';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CommonFunctions } from '@jibin/common/utils/Common';
export const Mfa: FC = () => {
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const { userData, setUserData, values } = useContext(LoginContext);
  const handleChange = (newValue) => {
    setOtp(newValue);
    setButtonDisable(true);
    setOtpError(false);
  };

  const navigate = useNavigate();
  const handleComplate = () => {
    setButtonDisable(false);
  };
  Amplify.configure({
    Auth: {
      userPoolId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_USERPOOL_ID),
      userPoolWebClientId: CommonFunctions.getDefaultString(process.env.REACT_APP_COGNITO_CLIENT_ID)
    }
  });
  const varifyUser = async () => {
    if (userData.challengeName === 'SMS_MFA' || userData.challengeName === 'SOFTWARE_TOKEN_MFA') {
      // You need to get the code from the UI inputs
      // and then trigger the following function with a button click
      const code = otp;
      // If MFA is enabled, sign-in should be confirmed with the confirmation code
      try {
        if (userData.challengeName === 'SMS_MFA') {
          const loggedUser = await Auth.confirmSignIn(
            userData, // Return object from Auth.signIn()
            code, // Confirmation code
            'SMS_MFA' // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
          );
        } else {
          const loggedUser = await Auth.confirmSignIn(
            userData, // Return object from Auth.signIn()
            code, // Confirmation code
            'SOFTWARE_TOKEN_MFA' // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
          );
        }

        localStorage.setItem('cognito-token', JSON.stringify(userData.signInUserSession));
        sessionStorage.setItem('IsSessionActive', 'true');
        await getEntries();
      } catch (err) {
        setOtpError(true);
      }
    } else {
      // The user directly signs in
    }
  };

  const dispatch = useDispatch();
  async function getEntries() {
    await dispatch(commonDetailSlice.actions.removecommonDetails());
    LoginService.GetUserData().then(
      async (t: any) => {
        await dispatch(commonDetailSlice.actions.setcommonDetails(t));
        if (t.data.data.is_first_login) {
          navigate(PageRouteConstants.Welcome);
        } else {
          if (t?.data?.data?.company?.type == 'engineering') {
            navigate(PageRouteConstants.MyAccount);
          } else {
            navigate(PageRouteConstants.OnBoarding);
          }
        }
      },
      async (err: any) => {}
    );
  }
  useEffect(() => {
    if (!userData) {
      navigate(PageRouteConstants.Login);
    }
  }, []);
  const handleResend = async () => {
    if (userData && userData.challengeName === 'SMS_MFA') {
      const userRes = await Auth.signIn(values.Email, values.password);
      // Update user object to confirm sign in with newly received code.

      setUserData(userRes);
    }
  };
  return (
    <Box sx={{ mt: 10 }}>
      <Container maxWidth={'xl'}>
        <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
            <Box
              sx={{
                ...Style.Auth.AuthLeftPart,
                display: 'flex',
                flexDirection: 'column',
                gap: 4
              }}>
              <Box>
                <Typography sx={{ fontWeight: 600, textAlign: 'justify' }} variant="subtitle2">
                  {' '}
                  Two-factor authentication
                </Typography>
              </Box>
              <Typography sx={{ textAlign: 'justify' }} variant="body1">
                Enter the six digit authorization code sent to your phone{' '}
                <>{userData?.challengeParam.CODE_DELIVERY_DESTINATION}</>
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <MuiOtpInput
                    value={otp}
                    onComplete={handleComplate}
                    length={6}
                    onChange={handleChange}
                    TextFieldsProps={{
                      error: otpError,
                      autoComplete: 'off'
                    }}
                  />
                  {otpError && (
                    <Typography sx={{ color: '#EF4444', fontSize: '14px' }}>
                      Invalid code
                    </Typography>
                  )}
                </Box>
                <Typography
                  variant="body1"
                  sx={{ fontSize: '14px', textAlign: 'justify', letterSpacing: 0 }}>
                  It may take a minute to receive your code. Haven't received it?
                  <span onClick={handleResend} style={{ color: '#436bf2', cursor: 'pointer' }}>
                    {' '}
                    Resend a new code.
                  </span>
                </Typography>
                <Button onClick={varifyUser} disabled={buttonDisable} variant="contained" fullWidth>
                  Verify
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
            <Box sx={Style.Auth.AuthInfographics}>
              <img alt="" src="/images/elephant-with-men.png" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
