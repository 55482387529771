import SvgIcon from '@mui/material/SvgIcon';

export default function PictureAsPdfIcon(props) {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        d="M12 1H4C2.89 1 2 1.81 2 2.8V17.2C2 18.199 2.89 19 4 19H16C17.11 19 18 18.199 18 17.2V6.4L12 1ZM16 17.2H4V2.8H11V7.3H16V17.2ZM13.2 8.92C12.7 8.47 12.1 8.2 10.8 8.2H7V16.3H8.6V13.33H10.8C12.1 13.33 12.6 13.15 13.2 12.7C13.8 12.25 14 11.62 14 10.81C14 10 13.8 9.46 13.2 8.92ZM11.9 11.8C11.6 12.07 11.3 12.25 10.7 12.25H8.6V9.37H10.6C11.2 9.37 11.6 9.55 11.9 9.82C12.2 10.09 12.3 10.36 12.3 10.81C12.3 11.26 12.2 11.53 11.9 11.8Z"
        fill="white"
      />
    </SvgIcon>
  );
}
