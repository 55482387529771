import Style from '@jibin/common/style/Styles';
import { Button, Link, List, ListItem, Menu, Skeleton, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import SearchIcon from '@jibin/common/icons/Search/Search';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FC, useState, useContext, useCallback, useEffect, useMemo } from 'react';

import DropDown from '@jibin/common/icons/Dropdown/Dropdown';
import { useNavigate } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';

import { shallowEqual, useSelector } from 'react-redux';
import ArrowBackIcon from '@jibin/common/icons/ArrowBack/ArrowBack';

var debouce = require('lodash.debounce');
export const ComapnyManagementTemplate: FC<{ children: React.ReactNode | React.ReactNode[] }> = (
  props
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const nevigate = useNavigate();
  const [isSkeleton, setIsSkeleton] = useState(false);

  const [isRefresh, setIsRefresh] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const MenuItems = [
    { name: 'Account', class: '', route: PageRouteConstants.CompanyManagementAccount },
    { name: 'Contacts', class: '', route: PageRouteConstants.CompanyManagementContact }
  ];
  const navigate = useNavigate();
  return (
    <>
      <Box sx={Style.PoliciesAndProcedures.PageHeadTitle}>
        <Typography
          variant="caption"
          sx={{ color: 'primary.main', display: 'flex', alignItem: 'center', mb: 1 }}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            nevigate(PageRouteConstants.CompanyManagement);
          }}>
          <ArrowBackIcon fontSize="small" sx={{ mr: 0.2 }} />
          Back
        </Typography>
        <Typography variant="h2" sx={{ mb: { xs: 1, lg: 2 } }}>
          Company Management
        </Typography>
        <Typography variant="subtitle2">Manage easily your companies in one place</Typography>
      </Box>

      <Box>
        <>
          <List sx={Style.CompanySetting.MenuList}>
            {MenuItems.map((currenElement, index) => (
              <ListItem disablePadding key={index} sx={{ width: 'auto' }}>
                <Link
                  onClick={() => {
                    navigate(currenElement.route);
                  }}
                  style={{ cursor: 'pointer' }}
                  className={window.location.pathname === currenElement.route ? 'active' : ''}
                  sx={Style.CompanySetting.MenuListItem}>
                  {currenElement.name}
                </Link>
              </ListItem>
            ))}
          </List>
          <Box sx={{ px: 4}}>{props.children}</Box>
        </>
      </Box>
    </>
  );
};

export default ComapnyManagementTemplate;
