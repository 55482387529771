import SvgIcon from '@mui/material/SvgIcon';

export default function GapAnalysisIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.125 17.8982C7.19607 17.8982 8.875 16.2193 8.875 14.1482C8.875 12.0771 7.19607 10.3982 5.125 10.3982C3.05393 10.3982 1.375 12.0771 1.375 14.1482C1.375 16.2193 3.05393 17.8982 5.125 17.8982Z"
        stroke="white"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.875 5.52325V2.52325C8.56335 2.25122 8.16368 2.10132 7.75 2.10132C7.33632 2.10132 6.93665 2.25122 6.625 2.52325L5.5 5.52325L4.375 6.64825L2.125 11.8983"
        stroke="white"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.875 17.8982C16.9461 17.8982 18.625 16.2193 18.625 14.1482C18.625 12.0771 16.9461 10.3982 14.875 10.3982C12.8039 10.3982 11.125 12.0771 11.125 14.1482C11.125 16.2193 12.8039 17.8982 14.875 17.8982Z"
        stroke="white"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.125 5.52325V2.5705C11.4367 2.29846 11.8363 2.14856 12.25 2.14856C12.6637 2.14856 13.0633 2.29846 13.375 2.5705L14.5 5.52325L15.625 6.64825L17.875 11.8982"
        stroke="white"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 8.52319C7 7.69819 8.34325 7.02319 10 7.02319C11.6568 7.02319 13 7.69819 13 8.52319"
        stroke="white"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.875 14.1482V7.13269"
        stroke="white"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.125 14.1482V7.13269"
        stroke="white"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
