const Style = {
  Layout: {
    MainLayout: {
      display: 'flex'
    },
    MainContent: {
      marginTop: 10,
      marginLeft: '280px',
      flex: '1'
    }
  },
  Common: {
    pageHead: {
      mb: 1,
      p: { xs: 2, md: 4 },
      bgcolor: 'common.white'
    },
    Divider: {
      height: 15
    },
    fillSelect: {
      bgcolor: 'primary.main',
      color: 'common.white',
      svg: {
        color: 'common.white'
      }
    },
    stepperCustomization: {
      mt: 2,

      '.MuiStepIcon-root': {
        height: 20,
        width: 20
      },
      '.MuiStepIcon-text': {
        display: 'none'
      },
      '.MuiStepContent-root,.MuiStepConnector-root': {
        ml: 1.25
      },
      '.MuiStepConnector-root': {
        ml: 1.25
      },

      '.MuiStepConnector-line': {
        minHeight: 14
      }
    }
  },
  MenuIcon: {
    mr: 1
  },
  UserNameRound: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    width: 28,
    height: 28,
    m: 1,
    bgcolor: 'common.grey',
    borderRadius: '50%'
  },
  UserNameRoundBig: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    width: 44,
    height: 44,
    m: 1,
    bgcolor: 'common.grey',
    borderRadius: '50%',
    fontSize: '16px'
  },
  Header: {
    HeaderWrapper: {
      position: 'fixed',
      left: 0,
      right: 0,
      zIndex: 2,
      p: 2,
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      bgcolor: 'common.white',
      borderBottom: '1px solid',
      borderColor: 'common.borderColor'
    },
    HeaderLogo: {},
    NotificationNumber: {
      bgcolor: '#EF4444',
      height: '18px',
      width: '18px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    UserProfile: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      borderRadius: 1,
      border: '0.8px solid',
      borderColor: 'common.borderColor'
    },
    UserForm: {
      minWidth: 120,
      '.MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    },
    ProfileButton: {
      color: 'text.primary',
      textTransform: 'capitalize',
      p: 1.5,
      minWidth: 150,
      justifyContent: 'space-between'
    }
  },
  Sidebar: {
    Title: {
      fontSize: '14px',
      fontWeight: 600
    },
    SidebarMain: {
      py: 3,
      px: 2,
      position: 'fixed',
      left: 0,
      top: 82,
      maxHeight: '100vh',
      height: '100vh',
      overflow: 'auto',
      zIndex: 2,
      bottom: 0,
      bgcolor: 'common.darkBlue',
      width: 280,
      minHeight: 'calc( 100vh - 83.1px)',
      display: { xs: 'none', md: 'flex' },
      flexDirection: 'column',
      '&::-webkit-scrollbar': { display: 'none' }

      //   -webkit-scrollbar {
      //     display: none;
      // }
    },
    SidebarHead: {
      width: 1,
      mb: 3
    },
    SidebarBottom: {
      px: 1,
      py: 4,
      marginBottom: 5,
      marginTop: 'auto',
      width: 1
    },
    CompanyDropDown: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 1,
      border: '0.8px solid',
      bgcolor: 'common.white',
      borderColor: 'common.borderColor',
      mb: 1
    },
    CompanyButton: {
      color: 'text.primary',
      textTransform: 'capitalize',
      width: 'calc(100% - 36px)',
      justifyContent: 'space-between'
    },
    CompanyDropDownMenuItem: {
      minWidth: 248,
      display: 'flex',
      alignItems: 'center'
    },
    OnBoardingButton: {
      width: 1,
      py: 1,
      px: 2,
      justifyContent: 'flex-start',
      mb: 2
    },
    sidebarMenuWrapper: {
      p: 0,
      width: '100%',
      maxWidth: 360,
      color: 'common.white'
    },
    sidebarMenuIcon: {
      // minWidth: 36,
      color: 'common.white'
    },
    sidebarMenuButton: {
      mt: 1,
      p: 1,
      borderRadius: 1,
      '&:hover': {
        bgcolor: '#0053A0'
      },
      '&.Mui-selected': {
        bgcolor: 'primary.main',
        '&:hover': {
          bgcolor: 'primary.main'
        }
      },
      '& span': {
        fontSize: '14px !important'
      }
    }
  },
  OnBoarding: {
    ListWrapper: {
      px: { xs: 2, md: 4 },
      pb: { xs: 2, md: 4 }
    },
    ListItem: {
      bgcolor: 'common.white',
      mt: 3,
      p: 1.5,
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'common.borderColor',
      justifyContent: 'space-between'
    },
    ListContent: {
      display: 'flex',
      pr: 2
    },
    TaskButton: {
      minWidth: 130
    },
    CompleteTaskButton: {
      minWidth: 130
    }
  },
  Auth: {
    AuthLeftPart: {
      width: 1,
      maxWidth: 400,
      m: 'auto'
    },
    AuthRightPart: {
      width: 1,
      maxWidth: 400,
      m: 'auto'
    },
    AuthInfographics: {
      maxWidth: 600,
      m: 'auto'
    },
    TrustLogo: {
      width: 1,
      maxWidth: 330,
      mb: { xs: 1, md: 2, xl: 3 },
      img: {
        width: 1
      }
    }
  },
  SignUp: {
    SignUpWrapper: {
      py: { xs: 4, xl: 5 },
      minHeight: 'calc(100vh - 71px)',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center'
    },

    AuthThumb: {
      height: 36,
      width: 36,
      bgcolor: 'common.grey',
      borderRadius: '50%',
      textTransform: 'capitalize',
      mr: 1.5,
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center'
    },
    ContactUsButton: {
      mt: 2,
      justifyContent: 'flex-start',
      p: { xs: 1, md: 2, xl: 3 },
      color: 'text.primary',
      borderColor: 'common.borderColor'
    }
  },
  Login: {
    ActionBox: {
      mt: 1,
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  },
  Welcome: {
    WelcomeWrapper: {
      py: { xs: 4, xl: 5 },
      minHeight: 'calc(100vh - 71px)',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  CompanySetting: {
    CompanySettingWrapper: {
      minHeight: '100vh',
      bgcolor: 'common.pageBackground'
    },
    PageHeadTitle: {
      px: { xs: 3, md: 4 },
      py: { xs: 3, md: 4, lg: 6 },
      bgcolor: 'common.white'
    },
    MenuList: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      px: { xs: 3, md: 4 },
      py: { xs: 1, md: 2 }
    },
    MenuListItem: {
      color: 'grey.600',
      fontWeight: '600',
      p: 1,
      fontSize: '14px',
      '&:hover': {
        color: 'primary.main',
        textDecoration: 'underline'
      },
      '&.active': {
        color: 'primary.main',
        textDecoration: 'underline'
      }
    }
  },
  TechnicalInformation: {
    TechnicalInformationWrapper: {
      minHeight: '100vh',
      bgcolor: 'common.pageBackground'
    },
    PageHeadTitle: {
      px: { xs: 3, md: 4 },
      py: { xs: 3, md: 4, lg: 6 },
      bgcolor: 'common.white'
    },
    MenuList: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      px: { xs: 3, md: 4 },
      py: { xs: 1, md: 2 }
    },
    MenuListItem: {
      color: 'grey.600',
      fontWeight: '600',
      p: 1,
      '&:hover': {
        color: 'primary.main',
        textDecoration: 'underline'
      },
      '&.active': {
        color: 'primary.main',
        textDecoration: 'underline'
      }
    }
  },
  CompanySettingPage: {
    CompanySettingPageWrapper: {
      px: 4,
      py: { xs: 4, md: 5 }
    },
    AddButton: {
      textAlign: 'right',
      mb: 2
    },
    ManageUsersCard: {
      display: 'flex',
      alignItems: 'center',
      py: 1.5,
      px: { xs: 2, md: 2.5 },
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'common.borderColor',
      bgcolor: 'common.white'
    },
    ManageUsersThumbnail: {
      p: 0.1,
      border: '1px solid',
      borderColor: 'common.borderColor',
      borderRadius: 20,
      width: { xs: 40, md: 58 },
      height: { xs: 40, md: 58 },
      mr: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'grey.600'
    },
    ManageUsersContent: {
      maxWidth: { xs: 'calc(100% - 56px)', md: 'calc(100% - 74px)' }
    }
  },
  Applications: {
    ApplicationsWrapper: {
      px: 4,
      py: { xs: 4, md: 5 }
    },
    AddButton: {
      textAlign: 'right'
    }
  },
  SiteListTable: {
    SiteListTableWrapper: {
      width: 1,
      overflowX: 'auto',
      mt: { xs: 2, md: 3 },
      '.MuiTableBody-root': {
        '.MuiTableRow-root': {
          border: '1px solid',
          bgcolor: 'common.white',
          borderColor: 'common.borderColor'
        }
      },

      '.MuiTableCell-root': {
        border: 0
      }
    },
    ActionIcons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '.MuiSvgIcon-root': {
        cursor: 'pointer',
        '&:hover': {
          color: 'primary.main'
        }
      }
    },
    StatusActive: {
      height: 16,
      width: 16,
      mr: 1,
      borderRadius: 20,
      display: 'inline-block',
      verticalAlign: 'middle'
    }
  },
  ApplicationsTable: {
    ApplicationsTableWrapper: {
      width: 1,
      overflowX: 'auto',
      mt: { xs: 2, md: 3 },
      '.MuiTableBody-root': {
        '.MuiTableRow-root': {
          border: '1px solid',
          bgcolor: 'common.white',
          borderColor: 'common.borderColor'
        }
      },
      '.MuiTableCell-root': {
        border: 0
      }
    },
    ImageWithUrl: {
      display: 'flex',
      alignItems: 'center',
      img: {
        maxWidth: 60
      }
    },
    ButtonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  MeetingCard: {
    bgcolor: 'common.white',
    p: 2.5,
    border: '1px solid',
    borderColor: 'common.borderColor',
    borderRadius: 1
  },
  PoliciesAndProcedures: {
    PageHeadTitle: {
      px: { xs: 3, md: 4 },
      py: { xs: 3, md: 4, lg: 6 },
      bgcolor: 'common.white'
    },
    PoliciesTableWrapper: {
      width: 1,
      overflowX: 'auto',
      mt: { xs: 2, md: 3 },
      '.MuiTableBody-root': {
        '.MuiTableRow-root': {
          border: '1px solid',
          bgcolor: 'common.white',
          borderColor: 'common.borderColor'
        }
      },

      '.MuiTableCell-root': {
        border: 0
      }
    }
  },

  EndPointProtection: {
    EndPointDataTable: { p: 1, display: 'flex', flexDirection: 'column', gap: 1 }
  },
  versionsCard: {
    cardWrapper: {
      border: '1px solid',
      borderColor: 'common.borderColor',
      borderRadius: 1
    },
    userInfo: {
      bgcolor: 'common.white',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      p: 2,
      borderBottom: '1px solid',
      borderColor: 'common.borderColor'
    },
    infoRound: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'uppercase',
      width: 40,
      height: 40,
      mr: 1,
      bgcolor: 'common.grey',
      borderRadius: '50%'
    },
    infoText: {
      maxWidth: 'calc(100% - 48px)'
    },
    versionInfo: {
      bgcolor: 'common.white',

      p: 2,
      borderBottom: '1px solid',
      borderColor: 'common.borderColor'
    },
    versionStatus: {
      display: 'flex',
      mt: 0.5
    },
    versionStatusIcon: {
      display: 'inline-block',
      verticalAlign: 'middle',
      height: 14,
      width: 14,
      borderRadius: '50%',
      mr: 0.5
    },
    versionStepper: {
      bgcolor: 'common.white',
      p: 2
    }
  },
  Farmework: {
    FrameworkWrapper: {
      px: 3,
      py: { xs: 0 }
    }
  },
  Upguard: {
    Rating: {
      display: 'flex'
    },
    RatingBox: {
      display: 'flex',
      width: '50%',
      gap: 1.5,
      flexDirection: 'column'
    },
    Grade: {
      background: ' rgba(167, 243, 208, 0.2)',
      display: 'inline-block',
      p: 0.1,
      borderRadius: '100%',

      border: ' 1px solid #27AE60'
    },
    Arating: {
      fontSize: '48px',
      fontWeight: 600,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      lineHeight: '57px',
      width: '57px',

      color: '#27AE60'
    }
  },
  CompanyManagement: {
    CommonBoxes: { bgcolor: '#FFFFFF', p: 3, borderRadius: '8px', mb: 2 }
  }
};

export default Style;
