import ConformationModel from '@jibin/common/components/ConformationModel';
import EditIcon from '@jibin/common/icons/Edit/Edit';
import MoreHorizIcon from '@jibin/common/icons/MoreHoriz/MoreHoriz';
import Style from '@jibin/common/style/Styles';
import { DeleteTypesEnum } from '@jibin/common/utils/enums/deleteTypeEnums';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { CompanySettingsService } from '../CompanySettings.Api';
import AddLocationModel from './AddLocationModel';
import { Location } from './Models/CompanyProfile.Model';
import { CompanyWebsiteProp } from './Models/CompanyWebsiteProp';
import { SiteListTableProps } from './Models/SiteListTableProps';
import { ApiResponse } from './Models/WebsiteList.model';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const Row: FC<{ row: any; index: any; props: any; refreshTable(): void }> = (rowProp) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const handleRefresh = () => {
    handleClose();
    rowProp.refreshTable();
  };

  const handleConfirm = (row: any) => {
    CompanySettingsService.PatchLocation(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      row.company_address_uuid,
      {
        status: row.status == 'Active' ? 'Inactive' : 'Active'
      }
    ).then(
      (q: any) => {
        handleClose();
        rowProp.refreshTable();
      },
      (err: any) => {}
    );
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState({} as any);
  const open = Boolean(anchorEl);
  const handleClick = (event: any, row: any) => {
    setRowData(row);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setRowData(null);
    setAnchorEl(null);
  };

  const getMenuDetails = (row: any, index: any) => {
    return;
  };

  const getAddressString = (row: any) => {
    let address = '';
    if (row.address1) address += row.address1 + ', ';
    if (row.address2) address += row.address2 + ', ';
    if (row.state) address += row.state + ', ';
    if (row.city) address += row.city + ', ';
    if (row.zip) address += row.zip + ', ';

    address = address.replace(/,\s*$/, '');
    //address = address.replace(', , ', ', ');
    return address;
  };
  return (
    <>
      <Box sx={Style.Common.Divider}></Box>
      <TableRow
        id={rowProp.row.company_address_uuid}
        key={rowProp.row.company_address_uuid}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell component="th" scope="row">
          {getAddressString(rowProp.row)}
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {rowProp.row.facilities.map((data, i) => (
              <>
                {i < 2 && <Chip label={data.name} sx={{ mr: 1, bgcolor: 'common.lightBlue' }} />}
                {i == 2 && (
                  <Typography component="span" sx={{ ml: 1, color: 'primary.main' }}>
                    +{rowProp.row.facilities.length - 2}{' '}
                  </Typography>
                )}
              </>
            ))}
          </Box>
        </TableCell>
        <TableCell>
          <Box>
            {rowProp.row.status.toUpperCase() == 'ACTIVE' && (
              <Box
                sx={{
                  ...Style.SiteListTable.StatusActive,
                  bgcolor: 'common.green'
                }}></Box>
            )}
            {rowProp.row.status.toUpperCase() == 'PENDING' && (
              <Box
                sx={{
                  ...Style.SiteListTable.StatusActive,
                  bgcolor: 'common.orange'
                }}></Box>
            )}
            {rowProp.row.status.toUpperCase() == 'INACTIVE' && (
              <Box
                sx={{
                  ...Style.SiteListTable.StatusActive,
                  bgcolor: '#9D9D9D'
                }}></Box>
            )}
            {rowProp.row.status}
          </Box>
        </TableCell>
        {/* <TableCell > <AddLocationModel LocationProp={new Location(row)} IsEdit={true} handleSubmit={()=>{handleRefresh()}}  /></TableCell> */}

        <TableCell align="right">
          <IconButton
            key={rowProp.row.company_address_uuid + 'MC'}
            aria-label="Example"
            onClick={(e) => {
              handleClick(e, rowProp.row);
            }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            <MoreHorizIcon color="primary" />
          </IconButton>
          <Menu
            key={rowProp.row.company_address_uuid + 'M'}
            anchorEl={anchorEl}
            id={'account-menu'}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5
              }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <AddLocationModel
              key={'Me' + rowProp.row.company_address_uuid}
              LocationProp={rowData}
              IsEdit={true}
              handleSubmit={() => {
                handleRefresh();
              }}
              handleClose={() => handleClose()}
            />
            <Divider />
            <ConformationModel
              handleConfirm={() => {
                handleConfirm(rowData);
              }}
              title={rowData?.status == 'Active' ? 'Confirm deactivation' : 'Confirm activation'}
              data={rowData}
              handleClose={() => handleClose()}
              label={{ ...label }}
              type={DeleteTypesEnum.SwitchAndLabel}
              description={rowData?.address1 + ' ' + rowData?.address2}
              asking={
                rowData?.status == 'Active'
                  ? 'Are you sure you want to deactivate this Address?'
                  : 'Are you sure you want to activate this Address?'
              }
              cancelButtonText="Cancel"
              conformButtonText="Confirm"
            />
          </Menu>
        </TableCell>
      </TableRow>
    </>
  );
};

export const LocationsTable: FC<SiteListTableProps> = (props: any) => {
  const [locationListData, setLocationListData] = useState(new ApiResponse());
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [isRefresh, setIsRefresh] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const location = useLocation();
  const getLocations = useCallback(() => {
    CompanySettingsService.GetLocationList(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setLocationListData(q.data);
        setIsSkeleton(false);
        if (searchParams.get('company_address_uuid')) {
          const filterdData = q.data.data.filter(
            (el, index) => el.company_address_uuid == searchParams.get('company_address_uuid')
          );

          setTimeout(() => {
            const element = document.getElementById(filterdData[0].company_address_uuid);
            if (element) {
              var headerOffset = 90;
              var elementPosition = element.getBoundingClientRect().top;
              var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
              });
              element.classList.add('flash');
              setTimeout(() => {
                element.classList.remove('flash');
              }, 1000);
            }
          }, 1);
          searchParams.delete('company_address_uuid');
          setSearchParams(searchParams);
        }
      },
      (err: any) => {}
    );
  }, [commonDetails, location.search]);

  useEffect(() => {
    getLocations();
  }, [getLocations, isRefresh]);

  const handleRefresh = () => {
    setIsRefresh(!isRefresh);
  };
  const data: CompanyWebsiteProp = {
    LocationProp: new Location(),
    IsEdit: false,
    handleSubmit() {},
    handleClose() {}
  };
  if (isSkeleton) {
    return (
      <Box>
        <Skeleton sx={{ p: 5 }} />
      </Box>
    );
  } else if (locationListData?.data?.length == 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', m: 'auto' }}>
        <Box sx={{ textAlign: 'center' }}>
          <img width={'50%'} src="/images/NoDataFound.png" />
          <Typography sx={{ mt: '10px', textAlign: 'center', color: '#696969' }} variant="body2">
            Please click the button below to provide your company's locations.
          </Typography>
          <Box sx={{ ...Style.CompanySettingPage.AddButton, textAlign: 'center', mt: 2 }}>
            <AddLocationModel
              {...data}
              handleSubmit={() => {
                handleRefresh();
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={Style.SiteListTable.SiteListTableWrapper}>
      <Box sx={Style.CompanySettingPage.AddButton}>
        {/* <ConformationModel title="Confirm Deletion" description='Are you sure you want to delete this "Site Name”?' cancelButtonText="Cancel" conformButtonText="Confirm"/> */}
        {/* <AddSiteModel {...data} handleSubmit={()=>{handleRefresh()}} /> */}
        <AddLocationModel
          {...data}
          handleSubmit={() => {
            handleRefresh();
          }}
        />
      </Box>

      <Table
        sx={{
          minWidth: 650
        }}
        aria-label="site list table">
        <TableHead>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>Facilities</TableCell>
            <TableCell>Status</TableCell>
            {/* <TableCell >Status1</TableCell> */}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {locationListData?.data?.map((row, index) => (
            <Row
              index={index}
              props={props}
              row={row}
              refreshTable={() => handleRefresh()}
              key={index}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default LocationsTable;
