import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FC, useCallback, useEffect, useState } from 'react';
import { SignupService } from './index.api';
import { SignupModel } from './signup.models';
import UserPool from '@jibin/common/utils/UserPool';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardHeader, Skeleton, TextField } from '@mui/material';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { useFormik } from 'formik';
import PasswordChecklist from 'react-password-checklist';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './Signup.css';
import CorrectIcon from '@jibin/common/icons/CorrectIcon/CorrectIcon';
import CrossIcon from '@jibin/common/icons/CrossIcon/CrossIcon';
const notifySuccess = (message: any) =>
  toast.success(message, { position: toast.POSITION.TOP_RIGHT });
const notifyError = (message: any) => toast.error(message, { position: toast.POSITION.TOP_RIGHT });
var debounce = require('lodash.debounce');

export const Signup: FC = (props: any) => {
  const navigate = useNavigate();

  // const [values, setValues] = useState({
  //   password: '',
  //   errorpassword: '',
  //   ConfirmPassword: '',
  //   errorConfirmPassword: ''
  // });
  var signupModel = new SignupModel();

  const [apiData, setApiData] = useState(signupModel);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [passwordError, setPasswordError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [passwordValidate, setPasswordValidate] = useState('');
  const getSignupDetails = useCallback(() => {
    let data = { token: window.location.href.split('/')[4] };
    SignupService.GetSignUpDate(data).then(
      (q: any) => {
        setApiData(q.data);
        setIsSkeleton(!isSkeleton);
      },
      (err: any) => {}
    );
  }, []);

  useEffect(() => {
    getSignupDetails();
  }, [getSignupDetails]);

  // const handleBlur = (event: any) => {
  //
  //   const passRegex: RegExp = new RegExp(process.env.REACT_APP_PASSWORD_REGEX);
  //   if (event.target.value) {
  //     if (
  //       !passRegex.test(
  //         event.target.value
  //       )
  //     ) {
  //       setPasswordError(true);
  //     } else {
  //       setPasswordError(false);
  //     }
  //   }
  // };
  const handleChangePhone = (value) => {
    setPhoneNumber(value);
  };
  const validate = (values: { password: string; confirmPassword: string }) => {
    const errors = {};
    const passRegex: RegExp = new RegExp(process.env.REACT_APP_PASSWORD_REGEX);
    if (!values.password) {
      errors['password'] = 'This field is Required ';
    } else if (!passRegex.test(values.password)) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }

    if (!values.confirmPassword) {
      errors['confirmPassword'] = 'This field is Required ';
    } else {
      if (values.confirmPassword && values.password) {
        if (values.password !== values.confirmPassword) {
          errors['confirmPassword'] = 'Confirm password do not match with entered password';
        }
      }
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },

    validateOnChange: false,
    validate,
    onSubmit: useCallback(
      debounce(
        (values) => {
          if (passwordError) {
            return;
          }
          var userData = [];
          userData.push(
            new CognitoUserAttribute({ Name: 'email', Value: apiData.data.user.email })
          );
          userData.push(
            new CognitoUserAttribute({
              Name: 'custom:first_name',
              Value: apiData.data.user.first_name
            })
          );
          userData.push(
            new CognitoUserAttribute({
              Name: 'custom:last_name',
              Value: apiData.data.user.last_name
            })
          );
          userData.push(new CognitoUserAttribute({ Name: 'phone_number', Value: phoneNumber }));
          userData.push(
            new CognitoUserAttribute({
              Name: 'custom:external_id',
              Value: apiData.data.user.external_id
            })
          );

          var validationData = [];
          validationData.push(
            new CognitoUserAttribute({ Name: 'company_name', Value: apiData.data.company[0].name })
          );
          validationData.push(
            new CognitoUserAttribute({ Name: 'company_role', Value: apiData.data.company[0].role })
          );
          validationData.push(
            new CognitoUserAttribute({
              Name: 'industry_type',
              Value: apiData.data.company[0].industry_type[0]
            })
          );
          validationData.push(
            new CognitoUserAttribute({
              Name: 'company_external_id',
              Value: apiData.data.company[0].external_id
            })
          );

          UserPool.signUp(
            apiData.data.user.email,
            values.password,
            userData,
            validationData,
            (err, data) => {
              if (err) {
                notifyError(err.message);
              } else {
                notifySuccess(data);

                navigate(PageRouteConstants.Login);
              }
            }
          );
        },
        500,
        true
      ),
      [phoneNumber, apiData]
    )
  });
  // const handleSubmit = (event: any) => {
  //   event.preventDefault();

  //   var userData = [];
  //   userData.push(new CognitoUserAttribute({ Name: 'email', Value: apiData.data.user.email }));
  //   userData.push(
  //     new CognitoUserAttribute({ Name: 'custom:first_name', Value: apiData.data.user.first_name })
  //   );
  //   userData.push(
  //     new CognitoUserAttribute({ Name: 'custom:last_name', Value: apiData.data.user.last_name })
  //   );
  //   userData.push(
  //     new CognitoUserAttribute({ Name: 'custom:external_id', Value: apiData.data.user.external_id })
  //   );

  //   var validationData = [];
  //   validationData.push(
  //     new CognitoUserAttribute({ Name: 'company_name', Value: apiData.data.company[0].name })
  //   );
  //   validationData.push(
  //     new CognitoUserAttribute({ Name: 'company_role', Value: apiData.data.company[0].role })
  //   );
  //   validationData.push(
  //     new CognitoUserAttribute({
  //       Name: 'industry_type',
  //       Value: apiData.data.company[0].industry_type[0]
  //     })
  //   );
  //   validationData.push(
  //     new CognitoUserAttribute({
  //       Name: 'company_external_id',
  //       Value: apiData.data.company[0].external_id
  //     })
  //   );

  //   UserPool.signUp(
  //     apiData.data.user.email,
  //     values.password,
  //     userData,
  //     validationData,
  //     (err, data) => {
  //       if (err) {
  //         notifyError(err.message);
  //       } else {
  //         notifySuccess(data);

  //         navigate(PageRouteConstants.Login);
  //       }
  //     }
  //   );
  // };
  return (
    <form onSubmit={formik.handleSubmit} method="post">
      <Box sx={Style.SignUp.SignUpWrapper}>
        <Container maxWidth="xl">
          <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <Box sx={Style.Auth.AuthLeftPart}>
                <Box sx={Style.Auth.TrustLogo}>
                  <img alt="" src="/images/trust-logo-black.svg" />
                </Box>
                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <label htmlFor="password">Phone Number</label>
                  </Box>
                  <Box
                    sx={{
                      border: '1px solid #EBEBEB',
                      borderRadius: '4px',
                      px: '14px',
                      '&:hover': {
                        outline: '0.8px solid black'
                      },
                      '&:focus-within': {
                        outline: '1.9px solid #436af3 !important'
                      }
                    }}>
                    <PhoneInput
                      international
                      autoFormat={false}
                      defaultCountry="US"
                      onChange={handleChangePhone}
                    />
                  </Box>
                </FormControl>
                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <label htmlFor="password">Password</label>
                  </Box>
                  <TextField
                    onChange={(e) => {
                      formik.handleChange(e);
                      setPasswordValidate(e.target.value);
                      setPasswordError(false);

                      formik.errors.password = '';
                    }}
                    //onBlur={handleBlur}
                    value={formik.values.password}
                    error={formik.errors.password ? true : false || passwordError ? true : false}
                    helperText={
                      formik.errors.password
                        ? formik.errors.password
                        : '' || passwordError
                        ? 'Password must be minimum 12 characters long and contain at least one uppercase letter, one lowercase letter, one digit and one symbol'
                        : ''
                    }
                    placeholder="Enter password"
                    variant="outlined"
                    id="password"
                    type={'password'}
                    aria-describedby="outlined-weight-helper-text"
                  />
                </FormControl>
                <PasswordChecklist
                  rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase']}
                  minLength={12}
                  iconSize={14}
                  style={{
                    fontSize: '12px',
                    marginTop: '8px'
                  }}
                  value={passwordValidate}
                  iconComponents={{
                    ValidIcon: <CorrectIcon />,
                    InvalidIcon: <CrossIcon />
                  }}
                  messages={{
                    minLength: 'Minimum 12 digits',
                    capital: 'At least 1 upper case letters (A – Z)',
                    lowercase: 'At least 1 Lower case letters (a – z)',
                    number: 'At least 1 number (0 – 9)',
                    specialChar: 'At least 1 non-alphanumeric symbol (e.g. ‘@$%£!’)'
                  }}
                  onChange={(isValid) => {}}
                />
                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <Box sx={{ mb: 1 }}>
                    <label htmlFor="confirmPassword">Confirm Password</label>
                  </Box>
                  <TextField
                    placeholder="Enter confirm password"
                    value={formik.values.confirmPassword}
                    error={formik.errors.confirmPassword ? true : false}
                    helperText={formik.errors.confirmPassword}
                    id="confirmPassword"
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.errors.confirmPassword = '';
                    }}
                    variant="outlined"
                    type={'password'}
                    aria-describedby="outlined-weight-helper-text"
                  />
                </FormControl>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ mt: { xs: 3, md: 4, xl: 5 } }}
                  fullWidth>
                  Create your account
                </Button>
                <Typography variant="body2" sx={{ mt: { xs: 2, md: 3 } }}>
                  By clicking the request to sign up, you are creating an [RE]DESIGN Trust Portal
                  account, and you agree our{' '}
                  <Link
                    href="https://www.privacypolicies.com/live/57b461e7-db13-4d84-9590-902e2155dca3"
                    sx={{}}
                    target={'_blank'}>
                    Terms of Use
                  </Link>{' '}
                  and{' '}
                  <Link
                    href="https://www.privacypolicies.com/live/008e0649-83c2-4596-b289-e1763e774ca5"
                    target={'_blank'}>
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={Style.Auth.AuthRightPart}>
                <Typography>
                  If information about you is incorrect <Link href="#">Contact us</Link>.
                </Typography>
                {!isSkeleton ? (
                  apiData.data.company.map((ob, index: any) => (
                    <Button
                      variant="outlined"
                      key={index}
                      sx={Style.SignUp.ContactUsButton}
                      fullWidth>
                      <Box component="span" sx={Style.SignUp.AuthThumb}>
                        {apiData.data.user.first_name.charAt(0) +
                          apiData.data.user.last_name.charAt(0)}{' '}
                      </Box>
                      <Box sx={{ textAlign: 'left' }}>
                        {apiData.data.user.first_name + ' ' + apiData.data.user.last_name}
                        <Typography variant="caption" sx={{ display: 'block' }}>
                          {apiData.data.user.email}
                        </Typography>
                      </Box>
                    </Button>
                  ))
                ) : (
                  <Card sx={Style.SignUp.ContactUsButton}>
                    <CardHeader
                      avatar={
                        <Skeleton animation="wave" variant="circular" width={40} height={40} />
                      }
                      title={
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="80%"
                          style={{ marginBottom: 6 }}
                        />
                      }
                      subheader={<Skeleton animation="wave" height={10} width="40%" />}
                    />
                  </Card>
                )}

                {!isSkeleton ? (
                  apiData.data.company.map((ob, index: any) => (
                    <Button
                      variant="outlined"
                      key={index}
                      sx={Style.SignUp.ContactUsButton}
                      fullWidth>
                      <Box component="span" sx={Style.SignUp.AuthThumb}>
                        {ob.name.charAt(0) + ob.name.charAt(1)}{' '}
                      </Box>
                      <Box sx={{ textAlign: 'left' }}>
                        {ob.name}
                        <Typography variant="caption" sx={{ display: 'block' }}>
                          {ob.role}
                        </Typography>
                      </Box>
                    </Button>
                  ))
                ) : (
                  <Card sx={Style.SignUp.ContactUsButton}>
                    <CardHeader
                      avatar={
                        <Skeleton animation="wave" variant="circular" width={40} height={40} />
                      }
                      title={
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="80%"
                          style={{ marginBottom: 6 }}
                        />
                      }
                      subheader={<Skeleton animation="wave" height={10} width="40%" />}
                    />
                  </Card>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </form>
  );
};
