import Style from '@jibin/common/style/Styles';
import { Button, Menu, Skeleton, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import SearchIcon from '@jibin/common/icons/Search/Search';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FC, useState, useContext, useCallback, useEffect, useMemo } from 'react';
import PoliciesTable from './PoliciesTable';
import DropDown from '@jibin/common/icons/Dropdown/Dropdown';
import { useNavigate } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import CreateTemplateModel from './CreatetemplateModel/CreateTemplateModel';

import { PoliciesAndProceduresApi } from './Policys.Api';
import { shallowEqual, useSelector } from 'react-redux';
import { ApiResponse } from './Models/ApiResponse';
var debouce = require('lodash.debounce');
export const PoliciesAndProcedures: FC = (props: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const nevigate = useNavigate();
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [policyList, setPolicyList] = useState(new ApiResponse());
  const [policyListSearch, setPolicyListSearch] = useState(new ApiResponse());
  const [isRefresh, setIsRefresh] = useState(false);
  const [search, setSearch] = useState('');
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: any) => {
    PoliciesAndProceduresApi.GetPolicyList(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      event.target.value
    ).then(
      (q: any) => {
        setIsRefresh(!isRefresh);
        setPolicyListSearch(q.data);
        setSearch(event.target.value);

        handleClose();
      },
      (err: any) => {}
    );
  };
  const debouncedResults = useMemo(() => {
    return debouce(handleChange, 300);
  }, []);

  const openPopup = () => {};
  const getPolicy = useCallback(() => {
    PoliciesAndProceduresApi.GetPolicyList(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setIsRefresh(!isRefresh);
        setPolicyList(q.data);

        setIsSkeleton(false);
        handleClose();
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  useEffect(() => {
    getPolicy();
  }, [getPolicy]);

  return (
    <>
      <Box sx={Style.PoliciesAndProcedures.PageHeadTitle}>
        <Typography variant="h2" sx={{ mb: { xs: 1, lg: 2 } }}>
          Policies and Procedures
        </Typography>
        <Typography variant="subtitle2">
          Save time and gain agility by selecting the templates you need.
        </Typography>
      </Box>

      {isSkeleton ? (
        <Box sx={{ px: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : policyList?.data?.length == 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', m: 'auto', my: 5 }}>
          <Box sx={{ textAlign: 'center' }}>
            <img width={'50%'} src="/images/NoDataFound.png" />
            <Typography sx={{ mt: '10px', textAlign: 'center', color: '#696969' }} variant="body2">
              Please create new policy
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', mt: 2 }}>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                sx={{ display: 'flex', alignItems: 'center' }}
                variant="contained"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>
                Create new Policy{' '}
                <Box sx={{ display: 'flex' }}>
                  <DropDown />
                </Box>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}>
                <CreateTemplateModel />

                <MenuItem
                  value={30}
                  onClick={() => {
                    nevigate(
                      PageRouteConstants.PoliciesAndProceduresTemplate +
                        '00000000-0000-0000-0000-000000000000'
                    );
                  }}>
                  New Document{' '}
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ px: 4, py: { xs: 4, md: 5 } }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <TextField
              placeholder="Search"
              hiddenLabel
              onChange={debouncedResults}
              sx={{ mr: 2 }}
              inputProps={{ sx: { py: '8px' } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />

            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              sx={{ display: 'flex', alignItems: 'center' }}
              variant="contained"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}>
              Create new Policy{' '}
              <Box sx={{ display: 'flex' }}>
                <DropDown />
              </Box>
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}>
              <CreateTemplateModel />

              <MenuItem
                value={30}
                onClick={() => {
                  nevigate(
                    PageRouteConstants.PoliciesAndProceduresTemplate +
                      '00000000-0000-0000-0000-000000000000'
                  );
                }}>
                New Document{' '}
              </MenuItem>
            </Menu>
          </Box>
          {policyListSearch?.data?.length != 0 && search ? (
            <PoliciesTable
              isSkeleton={isSkeleton}
              isRefresh={isRefresh}
              policyList={policyListSearch}
            />
          ) : policyListSearch?.data?.length == 0 && search ? (
            <Box
              sx={{ display: 'flex', justifyContent: 'center', width: '100%', m: 'auto', my: 5 }}>
              <Box sx={{ textAlign: 'center' }}>
                <img width={'50%'} src="/images/NoSearchFound.png" />
                <Typography variant="h2" sx={{ fontWeight: 600 }}>
                  No Search Results
                </Typography>
                <Typography sx={{ mt: 2, textAlign: 'center', color: '#696969' }} variant="body2">
                  Try adjusting your search or filter to find what you are looking for
                </Typography>
              </Box>
            </Box>
          ) : (
            policyList?.data && (
              <PoliciesTable
                isSkeleton={isSkeleton}
                isRefresh={isRefresh}
                policyList={policyList}
              />
            )
          )}
        </Box>
      )}
    </>
  );
};

export default PoliciesAndProcedures;
