import CalenderIcon from '@jibin/common/icons/CalenderIcon/CalenderIcon';
import CheckCircleIcon from '@jibin/common/icons/CheckCircle/CheckCircle';
import DownloadIcon from '@jibin/common/icons/DownloadIcon/DownloadIcon';
import DropDown from '@jibin/common/icons/Dropdown/Dropdown';
import ExpandMoreIcon from '@jibin/common/icons/ExpandMore/ExpandMore';
import GreenCircleCheck from '@jibin/common/icons/GreenCircleCheck/GreenCircleCheck';
import PdfIcon from '@jibin/common/icons/PdfIcon/PdfIcon';
import WarningIconYellow from '@jibin/common/icons/WarningYellow/WarningYellow';
import Style from '@jibin/common/style/Styles';
import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  Button,
  AccordionDetails,
  Divider,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  SwipeableDrawer,
  MenuItem,
  Menu
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ServiceReviewApi } from '../ServiceReview.Api';
import ServiceReviewSideBarPopup from '../ServiceReviewSideBarPopup/ServiceReviewSideBarPopup';
const momentTimezone = require('moment-timezone');
type Anchor = 'top' | 'left' | 'bottom' | 'right';
const DataModel = {
  created_at: '',
  data: [],
  'evidence_uuid ': '',
  status: '',
  updated_at: '',
  updated_by: ''
};
const ServiceReviewTable: FC<{
  el: any;
  index: any;
  handleRefresh(): void;
}> = (prop) => {
  const { el, index } = prop;
  const [url, setUrl] = useState({ urlname: '', name: '' });
  const [expand, setExpand] = useState(false);
  const [data, setData] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [downloadData, setDownloadData] = useState({} as any);
  const open = Boolean(anchorEl);
  const [rowData, setRowData] = useState({} as any);
  const [serachParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState({
    right: false
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const handleClick = (event: any, el: any) => {
    setAnchorEl(event.currentTarget);

    setDownloadData(el);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setParams = (index) => {
    const params = {
      service_review_uuid: index
    };
    setSearchParams(params);
  };
  const toggleAcordion = (id: any) => {
    if (expand) {
      serachParams.delete('service_review_uuid');
      setSearchParams(serachParams);
    } else {
      setParams(id);
    }

    setExpand((prev) => !prev);
  };
  const toggleDrawer =
    (anchor: Anchor, open: boolean, row: any) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
      setRowData(row);
      setIsRefresh(!isRefresh);
      if (!open) prop.handleRefresh();
    };
  const handleApprovel = async (el, index) => {
    if (commonDetails?.data.group != 'engineer') {
      try {
        setIsSubmitting(true);
        const res = await ServiceReviewApi.PatchApprovel(
          commonDetails.data.company.company_uuid,
          commonDetails.data.user_uuid,
          el['evidence_uuid'],
          { status: 'ACKNOWLEDGED' }
        );
        const upadatedData = [...data];
        upadatedData[index] = res.data.data;
        setData(upadatedData);
      } catch (e) {
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const getAllFiles = async (downloadData) => {
    downloadData?.data.forEach(async (element) => {
      await getFile(element, element.file_path);
    });
  };
  const location = useLocation();
  //This function will be use when we will impliment the accual api
  const getFile = async (downloadData, file_path) => {
    const res = await ServiceReviewApi.GetFile(
      downloadData.file_name,
      commonDetails.data.company.company_uuid,
      file_path
    );
    const blob = new Blob([res.data], { type: res.headers['content-type'] });

    const urlName = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = urlName;

    a.download = downloadData.file_name;
    //a.download = `downloadfile.${res.headers['content-type'].split('/')[1]}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  useEffect(() => {
    setData(el?.evidences);

    if (serachParams.get('evidence_uuid')) {
      const filterdData = el?.evidences.filter(
        (element, index) => element.evidence_uuid == serachParams.get('evidence_uuid')
      );
      setTimeout(() => {
        const element = document.getElementById(filterdData[0].evidence_uuid);

        if (element) {
          var headerOffset = 90;
          var elementPosition = element.getBoundingClientRect().top;
          var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
          element.classList.add('flash');
          setTimeout(() => {
            element.classList.remove('flash');
          }, 1000);
        }
      }, 1);
    }
  }, [isRefresh, location.search]);
  return (
    <Box>
      <Accordion
        expanded={expand || el.service_review_uuid == serachParams.get('service_review_uuid')}
        sx={{
          borderRadius: '8px',
          borderTopLeftRadius: '8px',
          '&.MuiPaper-root': {
            borderRadius: '8px',
            border: '1px solid #CCCCCC',
            boxShadow: 'none'
          },
          '&.Mui-expanded': {
            m: 0
          },
          '&.MuiPaper-root:before': {
            display: 'none'
          }
        }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => toggleAcordion(el.service_review_uuid)}
          aria-controls={`panel${el.service_review_uuid}a-content`}
          id={el.service_review_uuid}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                {el.service_name}
              </Typography>
              <Button sx={{ borderRadius: '100px', fontSize: '12px', fontWeight: 600 }}>
                {el.subscription_type}
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',

                alignItems: 'center',
                mr: 1,
                gap: 1
              }}>
              {el.status.toUpperCase() == 'ACKNOWLEDGED' ? (
                <GreenCircleCheck />
              ) : (
                el.status && <WarningIconYellow />
              )}
              {/* <WarningIconYellow /> */}

              <Box>
                {el.status.toUpperCase() == 'ACKNOWLEDGED' ? (
                  <Typography variant="body2" sx={{ fontWeight: 500, color: '#10B981' }}>
                    {' '}
                    Acknowledged
                  </Typography>
                ) : (
                  el.status && (
                    <Typography variant="body2" sx={{ fontWeight: 500, color: '#F59E0B' }}>
                      {' '}
                      Action Required
                    </Typography>
                  )
                )}
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Divider />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ backgroundColor: '#FBFBFF' }}>
                  <TableCell sx={{ py: 1 }} align="left">
                    Completed on
                  </TableCell>
                  <TableCell sx={{ py: 1 }} align="left">
                    Evidence
                  </TableCell>
                  <TableCell sx={{ py: 1 }} align="left">
                    Acknowledged by
                  </TableCell>
                  <TableCell sx={{ py: 1 }} align="left">
                    Acknowledged on
                  </TableCell>
                  <TableCell sx={{ py: 1, width: 0 }} align="left">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {commonDetails?.data.group != 'customer' && (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="body2" sx={{ color: '#464646' }}></Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">
                      <Button
                        onClick={toggleDrawer('right', true, el)}
                        sx={{
                          minWidth: 163,
                          backgroundColor: '#436AF3',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#436AF3'
                          }
                        }}>
                        Upload Report
                      </Button>
                    </TableCell>
                  </TableRow>
                )}

                {data?.map((row, index) => (
                  <TableRow
                    id={row.evidence_uuid}
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CalenderIcon sx={{ color: '#9D9D9D' }} />
                        <Typography variant="body2" sx={{ color: '#464646' }}>
                          {momentTimezone(row.completed_on)
                            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                            .format('DD MMM YYYY hh:mm A')
                            .toUpperCase()}{' '}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box sx={{}}>
                        <Button
                          id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={(e) => handleClick(e, row)}
                          sx={{
                            fontWeight: 500,
                            color: '#436AF3',
                            display: 'flex',

                            alignItems: 'center',
                            gap: 0.5
                          }}>
                          <PdfIcon />
                          Download Report
                          {row?.data?.length > 0 && (
                            <Box
                              sx={{
                                backgroundColor: '#FBFBFF',
                                borderRadius: '100px',
                                py: '5px',
                                px: 1,
                                fontSize: '12px',
                                color: '#787878'
                              }}>
                              +{row?.data?.length}
                            </Box>
                          )}
                        </Button>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box
                        sx={{
                          display: 'flex',

                          alignItems: 'center',
                          gap: 0.5
                        }}>
                        {row.ico ? (
                          <Box sx={Style.versionsCard.infoRound}>
                            <img
                              width={'100%'}
                              height="100%"
                              style={{ objectFit: 'cover', borderRadius: '50%' }}
                              src={`${process.env.REACT_APP_API_HOST}/${row.ico}`}
                              alt=""
                            />
                          </Box>
                        ) : (
                          <Typography sx={Style.versionsCard.infoRound}>
                            {row.acknowledged_by
                              ? row?.acknowledged_by.split(' ')[0].charAt(0) +
                                row?.acknowledged_by.split(' ')[1].charAt(0)
                              : '-'}
                          </Typography>
                        )}

                        <Box>
                          <Typography variant="body2" sx={{ color: '#464646', fontWeight: 500 }}>
                            {row?.acknowledged_by}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2" sx={{ color: ' #464646' }}>
                        {row?.status.toUpperCase() == 'ACKNOWLEDGED'
                          ? momentTimezone(row.acknowledged_at)
                              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                              .format('DD MMM YYYY hh:mm A')
                              .toUpperCase()
                          : ' - '}{' '}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {row?.status.toUpperCase() == 'ACKNOWLEDGED' ? (
                        <Button
                          startIcon={<CheckCircleIcon sx={{ color: '#27AE60' }} />}
                          sx={{
                            minWidth: 163,
                            backgroundColor: '#D9E1FD',
                            color: '#436AF3',
                            '&:hover': {
                              backgroundColor: '#D9E1FD'
                            }
                          }}>
                          Acknowledged
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleApprovel(row, index)}
                          disabled={isSubmitting}
                          sx={{
                            minWidth: 163,

                            color: '#436AF3',
                            border: '1px solid #436AF3',

                            '&:hover': {
                              backgroundColor: 'white'
                            }
                          }}>
                          Pending Approval
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <SwipeableDrawer
                  anchor={'right'}
                  open={state['right']}
                  onClose={toggleDrawer('right', false, {})}
                  onOpen={toggleDrawer('right', true, rowData)}>
                  <ServiceReviewSideBarPopup
                    rowData={rowData}
                    isRefresh={isRefresh}
                    closePopup={toggleDrawer('right', false, {})}
                  />
                </SwipeableDrawer>
              </TableBody>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}>
                <MenuItem sx={{ gap: 1 }} onClick={() => getAllFiles(downloadData)}>
                  <DownloadIcon sx={{ color: '#436AF3' }} />
                  <Typography variant="body2" sx={{ color: '#436AF3' }}>
                    Download all files
                  </Typography>
                </MenuItem>
                <Divider sx={{ '&.MuiDivider-root': { my: 0 } }} />

                {downloadData?.data?.map((el, index) => (
                  <Box key={index + 1}>
                    <MenuItem>
                      <PdfIcon sx={{ color: '#787878', mr: 1 }} />
                      <a
                        href={el.file_url}
                        style={{ textDecoration: 'none' }}
                        target="_blank"
                        onClick={() => getFile(el, el.file_path)}>
                        <Typography variant="body2" sx={{ color: '#787878' }}>
                          {el.file_name}
                        </Typography>
                      </a>
                    </MenuItem>
                    {index != downloadData.data.length - 1 && (
                      <hr style={{ margin: 0, border: '0.5px solid #DADADA' }} />
                    )}
                  </Box>
                ))}
              </Menu>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
export default ServiceReviewTable;
