import { createSlice } from '@reduxjs/toolkit';

const initialStateObj = {
  subscriptionPlan: {}
};

export const subscriptionPlanSlice = createSlice({
  name: 'subscriptionPlan',
  initialState: initialStateObj,
  reducers: {
    setSubscriptionPlan: (state, { payload }) => {
      state.subscriptionPlan = payload;
    },
    removeSubscriptionPlan: (state) => {
      state.subscriptionPlan = {};
    }
  }
});


export default subscriptionPlanSlice;
