import RemidiationPopUpList from '@jibin/common/components/RemidiationPopUp/RemidiationPopupList';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { Box, Skeleton, SwipeableDrawer, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TopRemediationsApi } from './TopRemediations.Api';
import RemidiationPopUpListOld from '@jibin/common/components/RemidiationPopUp-old/RemidiationPopupList';
import CalenderIcon from '@jibin/common/icons/CalenderIcon/CalenderIcon';
import moment from 'moment';
export interface TopRemediationProp {
  source: string;
  size: any;
  title: any;
}
export const TopRemediations: FC<TopRemediationProp> = (prop) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const [isRefresh, setIsRefresh] = useState(false);
  const [rowData, setRowData] = useState({} as any);
  const [state, setState] = useState({
    right: false
  });
  type Anchor = 'top' | 'left' | 'bottom' | 'right';
  const [useParams, setUseParam] = useSearchParams();
  const toggleDrawer = (anchor: Anchor, open: boolean, row: any) => {
    if (!open) {
      useParams.delete('remediation_uuid');
      setUseParam(useParams);
    } else {
      setParams(row.remediation_uuid);
    }
    setState({ ...state, [anchor]: open });
    setRowData(row);
    if (!open) {
      setRowData({});
    }
  };
  const setParams = (index) => {
    const params = {
      remediation_uuid: index
    };
    setUseParam(params);
  };
  const [remediations, setRemediations] = useState({} as any);
  let data = { source: prop.source, size: prop.size };
  const navigate = useNavigate();
  const GetRemediations = useCallback(() => {
    TopRemediationsApi.RemediationsList(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      new URLSearchParams(data).toString()
    ).then(
      (q: any) => {
        // if (useParams.get('remediation_uuid')) {
        //   const filterdData = q.data.data.filter(
        //     (el, index) => el.remediation_uuid == useParams.get('remediation_uuid')
        //   );

        //   setTimeout(() => {
        //     const element = document.getElementById(filterdData[0].remediation_uuid);
        //     if (element) {
        //       scroller.scrollTo(filterdData[0].remediation_uuid, {
        //         duration: 500,
        //         delay: 100,
        //         smooth: true,
        //         containerId: 'remediation_container',
        //         offset: 0 // Scrolls to element + 50 pixels down the page
        //       });
        //       element.classList.add('flash');
        //     }
        //   }, 1);

        //   toggleDrawer('right', true, filterdData[0]);
        //   setRowData(filterdData[0]);
        // }
        setRemediations(q.data);
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  useEffect(() => {
    GetRemediations();
  }, [GetRemediations]);

  return (
    <Box sx={{ px: 3, pt: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ mb: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 1
            }}>
            <Typography sx={{ fontWeight: 600 }}>{prop?.title}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Typography variant="caption">Top {prop?.size} </Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            onClick={() => navigate(PageRouteConstants.Remediation)}
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              color: ' #436AF3',
              cursor: 'pointer'
            }}>
            See all
          </Typography>
        </Box>
      </Box>

      <Box
        id={'remediation_container'}
        sx={{
          maxHeight: '290px',
          p: 1,
          overflowY: 'scroll',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '0.2em'
          },
          '&::-webkit-scrollbar-track': {
            borderRadius: '10px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',

            borderRadius: '10px'
          }
        }}>
        {remediations.data ? (
          remediations.data?.map((el, index) => (            
              <Box
                id={el.remediation_uuid}
                onClick={() => {
                  toggleDrawer('right', true, el);
                }}
                key={index}
                sx={{
                  border: '1px solid #EAECF0',
                  borderRadius: '8px',
                  width: 1,
                  mb: 1,
                  cursor: 'pointer'
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: 1,
                    p: 1.5
                  }}>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      color: '#696969',
                      whiteSpace: 'nowrap',
                      width: '20em',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}>
                    {el.title ? el.title : el.issue_name}
                  </Typography>
                  <Typography
                    sx={{ display: 'flex', alignItems: 'center', gap: 0.5, color: '#787878' }}
                    variant="caption">
                    Finding date:{' '}
                    <CalenderIcon
                      width="14px"
                      height="14px"
                      sx={{ color: '#436AF3', fontSize: '14px' }}
                    />{' '}
                    <span style={{ color: '#464646' }}>
                      {moment(el.created_at).format('DD MMM YYYY')}
                    </span>
                  </Typography>
                </Box>
              </Box>            
          ))
        ) : (
          <Box
            sx={{
              border: '1px solid #EAECF0',
              borderRadius: '8px',
              width: 1,
              mb: 1,
              cursor: 'pointer'
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: 1,
                p: 1.5
              }}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '14px',
                  color: '#696969',
                  whiteSpace: 'nowrap',
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ fontSize: '1rem', width: '100%' }}
                />
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      <SwipeableDrawer
        BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
        sx={{
          '.MuiDrawer-paper': {
            boxShadow: 'none'
          }
        }}
        anchor={'right'}
        open={state['right']}
        onClose={() => toggleDrawer('right', false, {})}
        onOpen={() => toggleDrawer('right', true, rowData)}>
        <RemidiationPopUpList
          el={rowData}
          remediation_uuid={rowData.remediation_uuid}
          closePopup={() => toggleDrawer('right', false, rowData)}
          isEngineer={commonDetails.data.group == 'engineer'}
          setIsRefresh={setIsRefresh}
          isRefresh={isRefresh}
        />
      </SwipeableDrawer>
    </Box>
  );
};
