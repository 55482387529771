import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import Box from '@mui/material/Box';
import Settings from '@jibin/common/icons/Settings/Settings';
import Power from '@jibin/common/icons/Power/Power';
import VerifiedUser from '@jibin/common/icons/VerifiedUser/VerifiedUser';
import SignalCellular from '@jibin/common/icons/SignalCellular/SignalCellular';

import PictureAsPdf from '@jibin/common/icons/PictureAsPdf/PictureAsPdf';
import dashboard from '@jibin/common/icons/PieChart/dashboard.png';
import SpaceDashboard from '@jibin/common/icons/SpaceDashboard/SpaceDashboard';
import ExpandMore from '@jibin/common/icons/ExpandMore/ExpandMore';
import ExpandLess from '@jibin/common/icons/ExpandLess/ExpandLess';
import EndpointProtection from '@jibin/common/icons/EndpointProtection/EndpointProtection';
import TickMark from '@jibin/common/icons/Tickmark/Tickmark';
import AttckSurafaceIcon from '@jibin/common/icons/AttckSurafaceIcon/AttckSurafaceIcon';
import HumanHead from '@jibin/common/icons/HumanHead/HumanHead';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Style from '@jibin/common/style/Styles';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import CustomerSuccess from '@jibin/common/icons/CustomerSuccess/CustomerSuccess';
import EmailProtection from '@jibin/common/icons/EmailProtection/EmailProtection';
import GapAnalysisIcon from '@jibin/common/icons/GapAnalysisIcon/GapAnalysisIcon';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
// import { commonDetailSlice } from '../../store/reducers/commonReducer';
import { commonDetailSlice } from '../../../store/reducers/commonReducer';

import { SidebarService } from './Sidebar.api';
import { SidebarCompanySearchComponent } from './SidebarCompanySearchComponent';

export const Sidebar: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(location.pathname);

  useEffect(() => {
    if (location.pathname.includes('company/information')) {
      if (
        location.pathname.includes(PageRouteConstants.Applications) ||
        location.pathname.includes(PageRouteConstants.ExternalInfrastructureAssessment) ||
        location.pathname.includes(PageRouteConstants.WirelessAssessment)
      )
        setSelectedItem(PageRouteConstants.Applications);
      else setSelectedItem(PageRouteConstants.ManageUsers);

      if (!menuOpen) setMenuOpen(true);
    } else {
      setSelectedItem(location.pathname);
    }
  }, [location.pathname]);

  const menuClick = () => {
    setMenuOpen(!menuOpen);
  };
  const routeChange = (path: string) => {
    setSelectedItem(path);
    navigate(path);
  };

  if (commonDetails?.data?.company?.type == 'engineering') {
    return (
      <Box sx={Style.Sidebar.SidebarMain}>
        <Box sx={Style.Sidebar.SidebarHead}>
          <SidebarCompanySearchComponent />
          <MenuList sx={Style.Sidebar.sidebarMenuWrapper}>
            <MenuItem onClick={menuClick} sx={Style.Sidebar.sidebarMenuButton}>
              <ListItemIcon sx={Style.Sidebar.sidebarMenuIcon}>
                <Settings />
              </ListItemIcon>
              <ListItemText>Settings</ListItemText>
              {menuOpen ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
            <Collapse in={menuOpen} timeout="auto" unmountOnExit>
              <MenuList disablePadding sx={{ pl: '37px' }}>
                <MenuItem
                  onClick={() => routeChange(PageRouteConstants.MyAccount)}
                  selected={selectedItem === PageRouteConstants.MyAccount}
                  sx={Style.Sidebar.sidebarMenuButton}>
                  <ListItemText>My Account</ListItemText>
                </MenuItem>
                {commonDetails?.data?.group.toUpperCase() == 'SUPERADMIN' && (
                  <>
                    <MenuItem
                      onClick={() => routeChange(PageRouteConstants.ManageUsers)}
                      selected={selectedItem === PageRouteConstants.ManageUsers}
                      sx={Style.Sidebar.sidebarMenuButton}>
                      <ListItemText>Manage Users</ListItemText>
                    </MenuItem>
                  </>
                )}

                <MenuItem
                  onClick={() => routeChange(PageRouteConstants.CompanyManagement)}
                  selected={selectedItem.includes(PageRouteConstants.CompanyManagement)}
                  sx={Style.Sidebar.sidebarMenuButton}>
                  <ListItemText>Company Management</ListItemText>
                </MenuItem>
              </MenuList>
            </Collapse>
          </MenuList>
        </Box>
      </Box>
    );
  }
  return (
    <Box sx={Style.Sidebar.SidebarMain}>
      <Box sx={Style.Sidebar.SidebarHead}>
        <SidebarCompanySearchComponent />
        <MenuList sx={Style.Sidebar.sidebarMenuWrapper}>
          {commonDetails?.data?.company.onboarding.filter(
            (q: { status: string }) => q.status == 'DRAFT'
          ).length != 0 && (
            <MenuItem
              onClick={() => routeChange(PageRouteConstants.OnBoarding)}
              sx={Style.Sidebar.sidebarMenuButton}
              selected={selectedItem === PageRouteConstants.OnBoarding}>
              <ListItemIcon sx={Style.Sidebar.sidebarMenuIcon}>
                <Power />
              </ListItemIcon>
              <ListItemText>Onboarding</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            sx={Style.Sidebar.sidebarMenuButton}
            onClick={() => routeChange(PageRouteConstants.DashBoard)}
            selected={selectedItem === PageRouteConstants.DashBoard}>
            <ListItemIcon sx={Style.Sidebar.sidebarMenuIcon}>
              <img width={'20px'} src={dashboard} alt="" />
            </ListItemIcon>
            <ListItemText>Dashboard</ListItemText>
          </MenuItem>
          <MenuItem
            sx={Style.Sidebar.sidebarMenuButton}
            onClick={() => routeChange(PageRouteConstants.Frameworks)}
            selected={selectedItem === PageRouteConstants.Frameworks}>
            <ListItemIcon sx={Style.Sidebar.sidebarMenuIcon}>
              <SpaceDashboard />
            </ListItemIcon>
            <ListItemText>Frameworks</ListItemText>
          </MenuItem>
          <MenuItem
            sx={Style.Sidebar.sidebarMenuButton}
            onClick={() => routeChange(PageRouteConstants.GapAnalysis)}
            selected={selectedItem === PageRouteConstants.GapAnalysis}>
            <ListItemIcon sx={Style.Sidebar.sidebarMenuIcon}>
              <GapAnalysisIcon />
            </ListItemIcon>
            <ListItemText>Gap Analysis</ListItemText>
          </MenuItem>
          <MenuItem
            sx={Style.Sidebar.sidebarMenuButton}
            onClick={() => routeChange(PageRouteConstants.PoliciesAndProcedures)}
            selected={selectedItem === PageRouteConstants.PoliciesAndProcedures}>
            <ListItemIcon sx={Style.Sidebar.sidebarMenuIcon}>
              <PictureAsPdf />
            </ListItemIcon>
            <ListItemText>Policies & Procedures</ListItemText>
          </MenuItem>
          <MenuItem
            sx={Style.Sidebar.sidebarMenuButton}
            onClick={() => routeChange(PageRouteConstants.SecurityAwareness)}
            selected={selectedItem === PageRouteConstants.SecurityAwareness}>
            <ListItemIcon sx={Style.Sidebar.sidebarMenuIcon}>
              <HumanHead />
            </ListItemIcon>
            <ListItemText>Security Awareness</ListItemText>
          </MenuItem>
          <MenuItem
            sx={Style.Sidebar.sidebarMenuButton}
            onClick={() => routeChange(PageRouteConstants.Vulnerability)}
            selected={selectedItem === PageRouteConstants.Vulnerability}>
            <ListItemIcon sx={Style.Sidebar.sidebarMenuIcon}>
              <VerifiedUser />
            </ListItemIcon>
            <ListItemText>Vulnerability Management</ListItemText>
          </MenuItem>
          <MenuItem
            sx={Style.Sidebar.sidebarMenuButton}
            onClick={() => routeChange(PageRouteConstants.Penetration)}
            selected={selectedItem === PageRouteConstants.Penetration}>
            <ListItemIcon sx={Style.Sidebar.sidebarMenuIcon}>
              <SignalCellular />
            </ListItemIcon>
            <ListItemText>Penetration Testing</ListItemText>
          </MenuItem>
          <MenuItem
            sx={Style.Sidebar.sidebarMenuButton}
            onClick={() => routeChange(PageRouteConstants.EndpointProtection)}
            selected={selectedItem === PageRouteConstants.EndpointProtection}>
            <ListItemIcon sx={Style.Sidebar.sidebarMenuIcon}>
              <EndpointProtection />
            </ListItemIcon>
            <ListItemText>Endpoint Protection</ListItemText>
          </MenuItem>
          {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' && (
            <MenuItem
              sx={Style.Sidebar.sidebarMenuButton}
              onClick={() => routeChange(PageRouteConstants.EmailProtection)}
              selected={selectedItem === PageRouteConstants.EmailProtection}>
              <ListItemIcon sx={Style.Sidebar.sidebarMenuIcon}>
                <EmailProtection />
              </ListItemIcon>
              <ListItemText>Email Protection</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            sx={Style.Sidebar.sidebarMenuButton}
            onClick={() => routeChange(PageRouteConstants.Remediation)}
            selected={selectedItem === PageRouteConstants.Remediation}>
            <ListItemIcon sx={Style.Sidebar.sidebarMenuIcon}>
              <TickMark />
            </ListItemIcon>
            <ListItemText>Remediation Tracker</ListItemText>
          </MenuItem>

          <MenuItem
            sx={Style.Sidebar.sidebarMenuButton}
            onClick={() => routeChange(PageRouteConstants.ServicesReview)}
            selected={selectedItem === PageRouteConstants.ServicesReview}>
            <ListItemIcon sx={Style.Sidebar.sidebarMenuIcon}>
              <CustomerSuccess />
            </ListItemIcon>
            <ListItemText> Customer Success</ListItemText>
          </MenuItem>
          {}
          {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' && (
            <MenuItem
              sx={Style.Sidebar.sidebarMenuButton}
              onClick={() => routeChange(PageRouteConstants.UpGuard)}
              selected={selectedItem === PageRouteConstants.UpGuard}>
              <ListItemIcon sx={Style.Sidebar.sidebarMenuIcon}>
                <AttckSurafaceIcon />
              </ListItemIcon>
              <ListItemText> Attack Surface Management</ListItemText>
            </MenuItem>
          )}

          <MenuItem onClick={menuClick} sx={Style.Sidebar.sidebarMenuButton}>
            <ListItemIcon sx={Style.Sidebar.sidebarMenuIcon}>
              <Settings />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
            {menuOpen ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={menuOpen} timeout="auto" unmountOnExit>
            <MenuList disablePadding sx={{ pl: '37px' }}>
              <MenuItem
                onClick={() => routeChange(PageRouteConstants.MyAccount)}
                selected={selectedItem === PageRouteConstants.MyAccount}
                sx={Style.Sidebar.sidebarMenuButton}>
                <ListItemText>My Account</ListItemText>
              </MenuItem>

              <MenuItem
                onClick={() => routeChange(PageRouteConstants.ManageUsers)}
                selected={selectedItem === PageRouteConstants.ManageUsers}
                sx={Style.Sidebar.sidebarMenuButton}>
                <ListItemText>Company Information</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => routeChange(PageRouteConstants.Applications)}
                selected={selectedItem === PageRouteConstants.Applications}
                sx={Style.Sidebar.sidebarMenuButton}>
                <ListItemText>Technical Information</ListItemText>
              </MenuItem>
            </MenuList>
          </Collapse>
        </MenuList>

        <Box sx={{ height: '50px' }}></Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
