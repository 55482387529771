import HttpService from '@jibin/common/services/http/HttpService';

export const VulranbilityApi = {
 
  VulnerabilityStats(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/vulnerabilities/stats`);
  },
  // Remediations(userId: string, companyId: string) {
  //   //return HttpService.getAll("todos/1");
  //   return HttpService.getAll(`companies/${companyId}/users/${userId}/remediations`);
  // },

}