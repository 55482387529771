import SuccessConfirmationUpadated from '@jibin/common/components/ConformationModel/SuccessConfirmationUpadated';

import CrossCancle from '@jibin/common/icons/CrossCancle/CrossCancle';
import ExpandMoreIcon from '@jibin/common/icons/ExpandMore/ExpandMore';

import Shareicon from '@jibin/common/icons/Shareicon/Shareicon';
import LinkIcon from '@jibin/common/icons/LinkIcon';

import Style from '@jibin/common/style/Styles';

import {
  Box,
  List,
  Typography,
  Tabs,
  Tab,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Divider,
  ListItem,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListSubheader,
  IconButton,
  Autocomplete,
  InputAdornment
} from '@mui/material';
import { resolve } from 'dns';
import moment from 'moment';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { RemediationApi } from 'src/Pages/Remediation/Remediation.Api';
import { useFormik } from 'formik';
import { EndpointProtectionApi } from '../EndpointProtection.Api';
import { EllipsisContent } from '@jibin/common/components/EllipsisContent';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { useNavigate } from 'react-router-dom';
import { notifySuccess } from '@jibin/common/services/http/http-common';
import { LoadingButton } from '@mui/lab';
const momentTimezone = require('moment-timezone');
var debounce = require('lodash.debounce');

const EndPointProtectionSideBarEngineer: FC<{
  rowData: any;
  assignees: any;
  isCreateRemediation: boolean;
  onClose(event: React.KeyboardEvent | React.MouseEvent): void;
}> = (prop) => {
  const [create, setCreate] = useState(false);
  const [compalate, setComplate] = useState(true);
  const [remediationData, setRemediationData] = useState<any>({});
  const [datasaved, setDataSaved] = useState(false);
  const [option, setOption] = useState([]);
  const [skeleton, setSkeleton] = useState(false);
  const [isRemediationButtonLoading, setIsRemediationButtonLoading] = useState(false);

  const navigate = useNavigate();
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [deviceData, setDeviceData] = useState<any>({});

  const validate = (value: any) => {
    const errors: any = {};

    if (!value.issue_name || !value.description || !value.recommendation || value.assign == null) {
      setComplate(true);
    } else {
      setComplate(false);
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: { issue_name: '', assign: null, description: '', recommendation: '' },
    validate,
    onSubmit: useCallback(
      debounce(
        async () => {
          try {
            setIsRemediationButtonLoading(true);
            const data = {
              issue_name: formik.values.issue_name,
              description: formik.values.description,
              recommendation: formik.values.recommendation,
              severity: '',
              status: '',
              owner_uuid: formik.values.assign.user_uuid
            };
            const res = await EndpointProtectionApi.PostRemediationData(
              commonDetails.data.user_uuid,
              commonDetails.data.company.company_uuid,
              deviceData.device_id,
              data
            );
            getDeviceDetails(deviceData.device_id, false);

            setDataSaved(true);
          } catch (error) {
          } finally {
            setTimeout(() => {
              setIsRemediationButtonLoading(false);
            }, 1000);
          }
        },
        500,
        true
      ),
      [compalate, deviceData, commonDetails]
    )
  });

  const renderOption = (assignees) => {
    const options = assignees.data.map((option) => {
      const firstLetter = option.company_name.toUpperCase();

      const data = option?.users.map((el: any) => {
        return { firstLetter: firstLetter, ...el };
      });
      return data;
    });

    const optionarray = options.concat.apply([], options).flat();
    setOption(optionarray);
  };

  const getDeviceDetails = useCallback(
    async (device_id: string, skeleton: boolean) => {
      setSkeleton(skeleton);
      const res = await EndpointProtectionApi.GetAllDeviceDetails(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid,
        device_id
      );
      if (res.data.data.remediations.length != 0) {
        const sortRemediation = res.data.data.remediations.sort((a: any, b: any) => {
          return a?.created_at < b?.created_at ? 1 : -1;
        });
        if (sortRemediation[0].status == 'resolved') {
          setRemediationData({});
        } else {
          setRemediationData(sortRemediation[0]);
        }
      }
      setSkeleton(false);
      setDeviceData(res.data.data);
    },
    [commonDetails]
  );
  useEffect(() => {
    if (prop.rowData.device_id) {
      setRemediationData({});
      setDataSaved(false);
      getDeviceDetails(prop.rowData.device_id, true);
      renderOption(prop.assignees);
    }
    if (prop.isCreateRemediation) {
      handleCreate();
      setDataSaved(false);
    } else {
      setCreate(false);
    }
  }, [prop]);

  const renderColorBackGround = (severity: any = 'active') => {
    // Active = Green
    // Bypass = Red
    // Deregistered = Yellow
    // Errors = Red
    // Inactive = Red
    // Pending Install = Red
    // Pending Update = Yellow
    // Quarantine = Red
    // Sensor Out of Date = Red
    var color = '#27AE60';
    switch (severity.toLowerCase()) {
      case 'active':
        color = '#27AE60';
        break;

      case 'bypass':
      case 'errors':
      case 'inactive':
      case 'pending install':
      case 'quarantine':
      case 'sensor out of date':
        color = '#EF4444';
        break;

      case 'deregistered':
      case 'pending update':
        color = '#FCD34D';
        break;
    }

    return color;
  };
  const handleCreate = () => {
    if (!formik.errors.flag && create) {
      setDataSaved(true);
    }
    setCreate(true);
  };
  if (skeleton) {
    return <Box></Box>;
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          width: { md: 520, xs: 300 },
          minHeight: !create ? '100vh' : 'auto',
          position: 'relative'
        }}
        role="presentation">
        <List sx={{ p: 0 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: 1,
              justifyContent: 'space-between',
              backgroundColor: '#003260',
              px: 2.5,
              alignItems: 'center',
              py: 1
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',

                width: 1,
                alignItems: 'center'
              }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700, color: 'white', my: 1.25 }}>
                {deviceData?.device_name}
              </Typography>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    notifySuccess('Copied');
                  }}>
                  <Shareicon />
                </Box>
                <Box
                  onClick={(event) => {
                    prop.onClose(event);
                  }}>
                  <CrossCancle
                    sx={{ color: 'white', height: '16px', width: '16px', cursor: 'pointer' }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </List>
        <Box sx={{ p: 3 }}>
          <List>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600 }}>
                Endpoint Status
              </Typography>
              <Box
                sx={{
                  py: 1,
                  px: 2,
                  border: '1px solid #EBEBEB',
                  borderRadius: '100px'
                }}>
                {' '}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box
                    sx={{
                      width: '14px',
                      height: '14px',
                      backgroundColor: renderColorBackGround(deviceData?.status),
                      borderRadius: '50%'
                    }}></Box>
                  <Typography variant="caption">{deviceData?.status}</Typography>
                </Box>
              </Box>
            </Box>
          </List>
          <List>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600 }}>
                Endpoint Details
              </Typography>
            </Box>
            <Box sx={Style.EndPointProtection.EndPointDataTable}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ minWidth: 200 }}>
                  <Typography variant="body2" sx={{ color: '#696969' }}>
                    User
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ color: '#696969' }}>
                    {deviceData?.user_name}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ minWidth: 200 }}>
                  <Typography variant="body2" sx={{ color: '#696969' }}>
                    Os
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ color: '#696969' }}>
                    {deviceData?.os}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ minWidth: 200 }}>
                  <Typography variant="body2" sx={{ color: '#696969' }}>
                    Sensor
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ color: '#696969' }}>
                    {deviceData?.sensor}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ minWidth: 200 }}>
                  <Typography variant="body2" sx={{ color: '#696969' }}>
                    Last Checking
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ color: '#696969' }}>
                    {momentTimezone(deviceData?.last_check_in)
                      .tz('utc')
                      .format('DD MMM YYYY hh:mm A')
                      .toUpperCase()}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </List>
          <List>
            {' '}
            <Accordion
              sx={{
                boxShadow: 'none',
                '.MuiButtonBase-root,.MuiAccordionSummary-root.Mui-expanded': {
                  minHeight: '46.22px'
                }
              }}>
              <AccordionSummary
                sx={{
                  border: '1px solid #EBEBEB',
                  borderRadius: '4px',

                  '.Mui-expanded': {
                    my: 0,
                    minHeight: 0
                  }
                }}
                // sx={{
                //   p: 3,
                //   '.MuiAccordionSummary-content': {
                //     m: 0
                //   }
                // }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header">
                <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600 }}>
                  Additional Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 0 }}>
                <Box sx={Style.EndPointProtection.EndPointDataTable}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Device ID
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        {deviceData?.device_id}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Internal IP
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        {deviceData?.internal_ip}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        External IP
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        {deviceData?.external_ip}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Registered
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        {momentTimezone(deviceData?.registered)
                          .tz('utc')
                          .format('DD MMM YYYY hh:mm A')
                          .toUpperCase()}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Installed By
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        {deviceData?.installed_by}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Scan Engine
                      </Typography>
                    </Box>
                    <Box>
                      <EllipsisContent
                        sx={{
                          fontSize: 14,
                          color: '#696969',
                          width: 'max-content',
                          maxWidth: {
                            xxs: 200,
                            md: 200
                          }
                        }}
                        text={deviceData?.scan_engine}
                        maxTextWidth={200}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Uninstall Code
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        {deviceData?.installed_by}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </List>
          {create && !datasaved && !remediationData?.remediation_uuid && (
            <List sx={{ py: 0 }}>
              <Box>
                <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600, py: 2 }}>
                  Issue Name
                </Typography>
                <TextField
                  autoComplete={'none'}
                  size={'small'}
                  value={formik.values.issue_name}
                  onChange={formik.handleChange}
                  name="issue_name"
                  rows={7}
                  sx={{
                    fontSize: '14px',

                    border: 'none',
                    textAlign: 'justify',
                    width: 1,
                    backgroundColor: 'white',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CCCCCC'
                    }
                  }}
                  placeholder="Type here..."
                />
              </Box>
              <Box>
                <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600, py: 2 }}>
                  Remediation Description
                </Typography>
                <TextField
                  autoComplete={'none'}
                  multiline
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  name="description"
                  rows={7}
                  sx={{
                    fontSize: '14px',

                    border: 'none',
                    textAlign: 'justify',
                    width: 1,
                    backgroundColor: 'white',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CCCCCC'
                    }
                  }}
                  placeholder="Type your answer here..."
                />
              </Box>
              <Box>
                <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600, py: 2 }}>
                  Recommendation
                </Typography>
                <TextField
                  autoComplete={'none'}
                  multiline
                  value={formik.values.recommendation}
                  onChange={formik.handleChange}
                  name="recommendation"
                  rows={7}
                  sx={{
                    fontSize: '14px',

                    border: 'none',
                    textAlign: 'justify',
                    width: 1,
                    backgroundColor: 'white',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CCCCCC'
                    }
                  }}
                  placeholder="Type your answer here..."
                />
              </Box>
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2" sx={{ fontWeight: 600, color: '#696969', my: 2 }}>
                    Remediation Owner
                  </Typography>

                  <Typography
                    onClick={() =>
                      formik.setFieldValue('assign', {
                        first_name: commonDetails.data.first_name,
                        last_name: commonDetails.data.last_name,
                        user_uuid: commonDetails.data.user_uuid,
                        ico: commonDetails.data.ico
                      })
                    }
                    variant="body2"
                    sx={{
                      fontWeight: 600,
                      color: '#436AF3',
                      my: 2,
                      cursor: 'pointer'
                    }}>
                    {'Assign to me'}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1.5 }}>
                  <FormControl sx={{ width: '100%' }}>
                    <Autocomplete
                      id="grouped-demo"
                      size="small"
                      value={formik.values.assign}
                      fullWidth={true}
                      options={option}
                      onChange={(e, value) => formik.setFieldValue('assign', value)}
                      groupBy={(option: any) => option.firstLetter}
                      getOptionLabel={(option: any) => {
                        return `${option.first_name} ${option.last_name}`;
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ fontSize: '14px' }}
                          {...params}
                          placeholder="Assign to"
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: formik.values.assign ? (
                              <InputAdornment position="start">
                                <Box>
                                  {formik.values.assign.ico ? (
                                    <Box
                                      sx={{
                                        ...Style.versionsCard.infoRound,
                                        width: '24px',
                                        height: '24px',
                                        fontSize: '14px'
                                      }}>
                                      <img
                                        width={'100%'}
                                        height="100%"
                                        style={{
                                          objectFit: 'cover',
                                          borderRadius: '50%'
                                        }}
                                        src={`${process.env.REACT_APP_API_HOST}/${formik.values.assign.ico}`}
                                        alt=""
                                      />
                                    </Box>
                                  ) : (
                                    <Typography
                                      sx={{
                                        ...Style.versionsCard.infoRound,
                                        width: '24px',
                                        height: '24px',
                                        fontSize: '14px'
                                      }}>
                                      {formik.values.assign?.first_name?.charAt(0) +
                                        '' +
                                        formik.values.assign?.last_name?.charAt(0)}
                                    </Typography>
                                  )}
                                </Box>
                              </InputAdornment>
                            ) : (
                              ''
                            )
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          <Box>
                            {option.ico ? (
                              <Box
                                sx={{
                                  ...Style.versionsCard.infoRound,
                                  width: '24px',
                                  height: '24px',
                                  fontSize: '14px'
                                }}>
                                <img
                                  width={'100%'}
                                  height="100%"
                                  style={{
                                    objectFit: 'cover',
                                    borderRadius: '50%'
                                  }}
                                  src={`${process.env.REACT_APP_API_HOST}/${option.ico}`}
                                  alt=""
                                />
                              </Box>
                            ) : (
                              <Typography
                                sx={{
                                  ...Style.versionsCard.infoRound,
                                  width: '24px',
                                  height: '24px',
                                  fontSize: '14px'
                                }}>
                                {' '}
                                {option.first_name.charAt(0) + '' + option.last_name.charAt(0)}
                              </Typography>
                            )}
                          </Box>
                          <Typography variant="body2">
                            {' '}
                            {option.first_name} {option.last_name}
                          </Typography>
                        </Box>
                      )}
                    />
                  </FormControl>
                </Box>
              </Box>
            </List>
          )}
          {remediationData?.remediation_uuid && (
            <Accordion
              sx={{
                boxShadow: 'none',
                '.MuiButtonBase-root,.MuiAccordionSummary-root.Mui-expanded': {
                  minHeight: '46.22px'
                }
              }}>
              <AccordionSummary
                sx={{
                  border: '1px solid #EBEBEB',
                  borderRadius: '4px',

                  '.Mui-expanded': {
                    my: 0,
                    minHeight: 0
                  }
                }}
                // sx={{
                //   p: 3,
                //   '.MuiAccordionSummary-content': {
                //     m: 0
                //   }
                // }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header">
                <Typography variant="body2" sx={{ color: '#696969', fontWeight: 600 }}>
                  Remediation History
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 0 }}>
                <Box sx={Style.EndPointProtection.EndPointDataTable}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Issue Name
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        {remediationData?.issue_name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Assignee
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        {remediationData?.owner?.first_name} {remediationData?.owner?.last_name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Status
                      </Typography>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          py: '6px',

                          borderRadius: '100px'
                        }}>
                        <Typography variant="body2" sx={{ color: '#696969' }}>
                          {' '}
                          {remediationData?.status == 'in_progress' ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Box
                                sx={{
                                  width: '14px',
                                  height: '14px',
                                  backgroundColor: '#FBBF24',
                                  borderRadius: '50%'
                                }}></Box>
                              <Typography variant="body2" sx={{ color: '#696969' }}>
                                In Progress
                              </Typography>
                            </Box>
                          ) : remediationData?.status == 'not_started' ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Box
                                sx={{
                                  width: '14px',
                                  height: '14px',
                                  backgroundColor: '#EF4444',
                                  borderRadius: '50%'
                                }}></Box>
                              <Typography variant="body2" sx={{ color: '#696969' }}>
                                Not Started
                              </Typography>
                            </Box>
                          ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Box
                                sx={{
                                  width: '14px',
                                  height: '14px',
                                  backgroundColor: '#10B981',
                                  borderRadius: '50%'
                                }}></Box>
                              <Typography variant="body2" sx={{ color: '#696969' }}>
                                {remediationData?.status.charAt(0).toUpperCase() +
                                  '' +
                                  remediationData?.status.slice(1)}
                              </Typography>
                            </Box>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Date
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        {momentTimezone(deviceData?.created_at)
                          .tz('utc')
                          .format('DD MMM YYYY')
                          .toUpperCase()}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Reported by
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        {remediationData?.created_by?.first_name}{' '}
                        {remediationData?.created_by?.last_name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <Box sx={{ minWidth: 200 }}>
                      <Typography variant="body2" sx={{ color: '#696969' }}>
                        Remediation History
                      </Typography>
                    </Box>
                    <Box
                      onClick={() =>
                        navigate(
                          `${PageRouteConstants.Remediation}?remediation_uuid=${remediationData.remediation_uuid}`
                        )
                      }
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography variant="body2" sx={{ color: '#436AF3' }}>
                        Track Remediation{' '}
                      </Typography>
                      <Box>
                        <LinkIcon />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
        <Box
          sx={{
            px: 3,
            py: 1,
            textAlign: 'end',
            bottom: 0,
            right: 0,
            position: !create ? 'absolute' : 'relative'
          }}>
          {create || remediationData?.remediation_uuid ? (
            remediationData?.remediation_uuid ? (
              <Box></Box>
            ) : (
              <LoadingButton
                loading={isRemediationButtonLoading}
                disabled={
                  (create && compalate) || datasaved || remediationData?.remediation_uuid
                    ? true
                    : false
                }
                variant="contained"
                sx={{ minWidth: 160 }}
                type="submit">
                Save
              </LoadingButton>
            )
          ) : (
            <Button
              disabled={(create && compalate) || datasaved ? true : false}
              variant="contained"
              sx={{ minWidth: 160 }}
              onClick={handleCreate}>
              {'Create Remediation'}
            </Button>
          )}
        </Box>
      </Box>
    </form>
  );
};
export default EndPointProtectionSideBarEngineer;
