import CloseIcon from '@jibin/common/icons/Close/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { AddTechEnv } from '../Models/AddTechEnv.model';
import AddCircleIcon from '@jibin/common/icons/AddCircle/AddCircle';
import { useFormik } from 'formik';
import { Env } from '../Models/Env.model';
import EditIcon from '@jibin/common/icons/Edit/Edit';
import { shallowEqual, useSelector } from 'react-redux';
import { TechinicalInformation } from '../../TechinicalInformation.Api';
import { idText } from 'typescript';
import * as Yup from 'yup';
import { FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { display } from '@mui/system';
import { LoadingButton } from '@mui/lab';

var debounce = require('lodash.debounce');
const EnvironmentTypes = [
  { key: 'Production' },
  { key: 'Staging' },
  { key: 'Testing' },
  { key: 'Development' }
];

const HostingProvider = [
  { key: 'Amazon Web Services' },
  { key: 'Google Cloud Platform' },
  { key: 'Microsoft Azure' },
  { key: 'CoreWeave' }
];

const MFA = [
  { key: 'Duo Mobile' },
  { key: 'Google Authenticator' },
  { key: 'Last Pass Authenticator' },
  { key: 'Microsoft Authenticator' },
  { key: 'Okta Verify' }
];

const IDS_IPS_solutions = [
  { key: 'Zscaler' },
  { key: 'CrowdStrike' },
  { key: 'Cisco' },
  { key: 'Fortigate' },
  { key: 'Splunk' },
  { key: 'Zeek' },
  { key: 'SolarWinds' },
  { key: 'Fidelis' },
  { key: 'Snort' },
  { key: 'BlueVector' }
];

export const AddTechEnvModel: FC<AddTechEnv> = (prop) => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [address, setTextfeild] = React.useState({ hosting_provider: '', mfa: '', ids_ips: '' });
  const [adressError, setAdressError] = React.useState({
    hosting_provider: false,
    mfa: false,
    ids_ips: false
  });
  const [urlError, setUrlError] = React.useState('');
  const [ipError, setIpError] = useState('');
  const [isEnvButtonLoading, setIsEnvButtonLoading] = useState(false);

  const handleOnBlur = (event: any) => {
    if (event.target.value) {
      let urlReg = new RegExp(
        '^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$'
      );
      if (!urlReg.test(event.target.value.toLowerCase())) {
        setUrlError('Enter valid url');
      }
    }
  };
  const handleTextfeildChange = (event: any) => {
    setTextfeild({ ...address, [event.target.name]: event.target.value });
    if (
      (event.target.name == 'hosting_provider' && event.target.value.length > 100) ||
      (event.target.name == 'mfa' && event.target.value.length > 100) ||
      (event.target.name == 'ids_ips' && event.target.value.length > 100)
    ) {
      setAdressError({ ...adressError, [event.target.name]: true });
    } else {
      setAdressError({ ...adressError, [event.target.name]: false });
    }
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formik.resetForm();
    setIsRangeIp('singleIp');
    setIpRange({ start: [], end: 0 });
    setOpen(false);

    setUrlError('');
    setAdressError({
      hosting_provider: false,
      mfa: false,
      ids_ips: false
    });
    prop.handleClose();
  };
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [ipRange, setIpRange] = React.useState({ start: [], end: 0 });

  const handleIpchange = (index: any, e: any) => {
    formik.errors.ip_address = '';
    if (Math.floor(+e.target.value) <= 255) {
      ipRange.start[index] = Math.floor(+e.target.value);
      setIpRange({ ...ipRange, start: ipRange.start });
      formik.setFieldValue('ip_address', ipRange.start.join('.'));
    } else {
      ipRange.start[index] = Math.floor(+e.target.value.substring(0, 2));
      setIpRange({ ...ipRange, start: ipRange.start });
      formik.setFieldValue('ip_address', ipRange.start.join('.'));
    }
  };

  const [isRangeIp, setIsRangeIp] = React.useState('singleIp');

  useEffect(() => {
    if (prop.envData && prop.envData.ip_size > 0) {
      setIsRangeIp('rangeIP');
      var startIp = prop.envData.ip_address.split('.');
      setIpRange({
        start: startIp,
        end: Math.floor(+(parseInt(startIp[3]) + prop.envData.ip_size))
      });
    } else {
      if (prop.envData) {
        setIsRangeIp('singleIp');
      }
    }
  }, [prop]);

  const validate = (values: Env) => {
    const errors = {};
    // const errorData = ipRange.start.filter((el, index) => !el);
    // console.log(ipRange);
    // console.log(errorData);

    if (!values.name) {
      errors['name'] = 'This field is Required';
    }

    const ipReg = new RegExp(
      /^((([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5]))$/
    );

    if (!values.ip_address) {
      errors['ip_address'] = 'This field is Required';
    } else if (
      !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        values.ip_address
      )
    ) {
      errors['ip_address'] = 'Enter valid IP Address';
    } else if (values.ip_address.length > 21) {
      errors['ip_address'] = 'IP Range must be less than 21 characters long';
    }

    if (values.hosting_provider_type == 'Other') {
      if (!values.hosting_provider) {
        errors['hosting_provider'] = 'This field is Required';
      }
    }
    if (!values.hosting_provider_type) {
      errors['hosting_provider_type'] = 'This field is Required';
    } else if (values.hosting_provider_type == '-1') {
      errors['hosting_provider_type'] = 'This field is Required';
    }

    if (values.ids_ips_type == 'Other') {
      if (!values.ids_ips) {
        errors['ids_ips'] = 'This field is Required';
      }
    }

    if (!values.ids_ips_type) {
      errors['ids_ips_type'] = 'This field is Required';
    } else if (values.ids_ips_type == '-1') {
      errors['ids_ips_type'] = 'This field is Required';
    }

    if (values.mfa_type == 'Other') {
      if (!values.mfa) {
        errors['mfa'] = 'This field is Required';
      }
    }

    if (!values.mfa_type) {
      errors['mfa_type'] = 'This field is Required';
    } else if (values.mfa_type == '-1') {
      errors['mfa_type'] = 'This field is Required';
    }

    if (!values.type) {
      errors['type'] = 'This field is Required';
    } else if (values.type == '-1') {
      errors['type'] = 'This field is Required';
    }

    if (!values.url) {
      errors['url'] = 'This field is Required';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: new Env(prop.envData),
    validateOnChange: false,
    validate,
    onSubmit: useCallback(
      debounce(
        (values) => {
          try {
            setIsEnvButtonLoading(true);
            delete values.ipRange;
            if (prop.envData.application_env_uuid) {
              TechinicalInformation.putEnv(
                commonDetails.data.user_uuid,
                commonDetails.data.company.company_uuid,
                prop.applicationId,
                prop.envData.application_env_uuid,
                values
              ).then(
                (q: any) => {
                  handleClose();
                  prop.handleSubmit();
                  formik.resetForm();
                },
                (err) => {}
              );
            } else {
              delete values.application_env_uuid;
              TechinicalInformation.postEnv(
                commonDetails.data.user_uuid,
                commonDetails.data.company.company_uuid,
                prop.applicationId,
                values
              ).then(
                (q: any) => {
                  handleClose();
                  prop.handleSubmit();
                  formik.resetForm();
                },
                (err) => {}
              );
            }
          } catch (error) {
          } finally {
            setTimeout(() => {
              setIsEnvButtonLoading(false);
            }, 1000);
          }
        },
        500,
        true
      ),
      [open]
    )
  });

  const updateValue = (e: any, formProp: any) => {
    formik.setFieldValue(e.target.name, e.target.value);

    if (e.target.value != 'Other') {
      formik.setFieldValue(formProp, e.target.value);
    } else {
      formik.setFieldValue(formProp, '');
    }
  };

  return (
    <>
      {prop.type == 1 && (
        <Box sx={{ ml: 1 }}>
          <Button
            disabled={prop.applicationStatus == 'Inactive'}
            onClick={handleClickOpen}
            sx={{
              fontSize: '12px',
              cursor: 'pointer',
              lineHeight: 1.66,
              color: '#436AF3',
              letterSpacing: ' 0.03333em'
            }}>
            <Typography variant="caption"> Add new environment...</Typography>
          </Button>
        </Box>
      )}
      {prop.type == 2 && (
        <Button
          sx={{ p: 0, minWidth: 0 }}
          disabled={prop.applicationStatus == 'Inactive'}
          onClick={handleClickOpen}>
          <AddCircleIcon />
        </Button>
      )}
      {prop.type == 3 && (
        <Box>
          <MenuItem onClick={handleClickOpen}>
            <EditIcon sx={{ mr: 4, color: 'primary.main' }} /> Edit
          </MenuItem>
        </Box>
      )}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{ display: 'flex', justifyContent: 'space-between', p: { xs: 1, md: 2 } }}>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: '600',
              maxWidth: 'calc(100% - 40px)',
              p: 1,
              color: 'text.primary'
            }}>
            Application
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ px: { xs: 2, md: 3 }, pt: 0 }}>
            <Box id="alert-dialog-description">
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Url">Environment Type </label>
                    </Box>
                    <Select
                      tabIndex={1}
                      size="small"
                      displayEmpty
                      id="type"
                      name="type"
                      value={formik.values.type}
                      error={formik.errors.type ? true : false}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.errors.type = '';
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}>
                      <MenuItem sx={{ display: 'none' }} key="-1" value="-1" disabled>
                        Select
                      </MenuItem>
                      {EnvironmentTypes.sort((a: any, b: any) => {
                        return a.key > b.key ? 1 : -1;
                      }).map((currItem, index) => (
                        <MenuItem key={index} value={currItem.key}>
                          {currItem.key}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText sx={{ color: '#d50000' }}> {formik.errors.type}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Email">Environment Name</label>
                    </Box>
                    <TextField
                      tabIndex={2}
                      size="small"
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.errors.name = '';
                      }}
                      helperText={formik.errors.name}
                      error={formik.errors.name ? true : false}
                      placeholder="Ex.: intqa1, west region"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Email">Environment URL </label>
                    </Box>
                    <TextField
                      tabIndex={3}
                      size="small"
                      id="url"
                      name="url"
                      value={formik.values.url}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.errors.url = '';
                        setUrlError('');
                      }}
                      onBlur={handleOnBlur}
                      helperText={
                        formik.errors.url ? formik.errors.url : '' || urlError ? urlError : ''
                      }
                      error={formik.errors.url ? true : false || urlError ? true : false}
                      placeholder="Ex.: https://test.com"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Url">3rd Party Hosted</label>
                    </Box>
                    <Select
                      tabIndex={4}
                      size="small"
                      displayEmpty
                      id="hosting_provider_type"
                      name="hosting_provider_type"
                      value={formik.values.hosting_provider_type}
                      error={formik.errors.hosting_provider_type ? true : false}
                      onChange={(e) => {
                        updateValue(e, 'hosting_provider');
                        formik.handleChange(e);

                        formik.errors.hosting_provider_type = '';
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}>
                      <MenuItem sx={{ display: 'none' }} key="-1" value="-1" disabled>
                        Select
                      </MenuItem>
                      {HostingProvider.sort((a: any, b: any) => {
                        return a.key > b.key ? 1 : -1;
                      }).map((currItem, index) => (
                        <MenuItem key={index} value={currItem.key}>
                          {currItem.key}
                        </MenuItem>
                      ))}

                      <MenuItem value={'Other'}>{'Other'}</MenuItem>
                    </Select>
                    <FormHelperText sx={{ color: '#d50000' }}>
                      {' '}
                      {formik.errors.hosting_provider_type}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Email">3rd Party Hosting Provider</label>
                    </Box>
                    <TextField
                      tabIndex={5}
                      size="small"
                      id="hosting_provider"
                      name="hosting_provider"
                      disabled={formik.values.hosting_provider_type != 'Other'}
                      value={formik.values.hosting_provider}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleTextfeildChange(e);
                        formik.errors.hosting_provider = '';
                      }}
                      helperText={
                        formik.errors.hosting_provider
                          ? formik.errors.hosting_provider
                          : '' || adressError.hosting_provider
                          ? '3rd Party Hosting Provider should be less than 100 characters'
                          : ''
                      }
                      error={
                        formik.errors.hosting_provider
                          ? true
                          : false || adressError.hosting_provider
                          ? true
                          : false
                      }
                      placeholder="Ex.: AWS"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ mt: 3 }}>
                    <Box sx={{ fontWeight: '600' }} component="span">
                      IP Address
                    </Box>{' '}
                    (Multi Factor Authorization Method )
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    tabIndex={6}
                    aria-label="gender"
                    name="ipRange"
                    sx={{
                      width: 1,
                      flexDirection: { xxs: 'column', md: 'row' },
                      gap: 2,
                      flexWrap: 'nowrap'
                    }}
                    value={isRangeIp}
                    onChange={(e) => {
                      setIsRangeIp(e.target.value);
                      formik.setFieldValue('ip_size', 0);
                    }}>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                        <Box sx={{ mb: 1 }}>
                          <label htmlFor="Url">IP Addresses</label>
                        </Box>
                        <Box sx={{ border: '1px solid #9D9D9D', borderRadius: '4px', px: 1.5 }}>
                          <FormControlLabel
                            tabIndex={7}
                            value="singleIp"
                            sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                            control={<Radio />}
                            label="Single IP Address"
                          />
                        </Box>
                        <FormHelperText sx={{ color: '#d50000' }}>
                          {' '}
                          {formik.errors.ipRange}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                        <Box sx={{ mb: 1, visibility: 'hidden' }}>
                          <label htmlFor="Url">IP Addresses </label>
                        </Box>
                        <Box sx={{ border: '1px solid #9D9D9D', borderRadius: '4px', px: 1.5 }}>
                          <FormControlLabel
                            tabIndex={8}
                            sx={{ '.MuiTypography-root': { fontSize: '14px' } }}
                            value="rangeIP"
                            control={<Radio />}
                            label="Range of IP Addresses"
                          />
                        </Box>
                        <FormHelperText sx={{ color: '#d50000' }}>
                          {' '}
                          {formik.errors.ipRange}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </RadioGroup>
                </Grid>
                {isRangeIp == 'singleIp' ? (
                  <Grid item xs={12} md={12}>
                    <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                      <Box sx={{ mb: 1 }}>
                        <label htmlFor="Email">IP Address</label>
                      </Box>
                      <TextField
                        tabIndex={9}
                        size="small"
                        placeholder="Enter valid IP Address"
                        id="ip_address"
                        name="ip_address"
                        value={formik.values.ip_address}
                        error={formik.errors.ip_address ? true : false}
                        helperText={formik.errors.ip_address ? formik.errors.ip_address : ''}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.errors.ip_address = '';
                        }}
                        //onBlur={()=>{ formik.validateField('ip_address')}}
                      />
                    </FormControl>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                        <Box sx={{ mb: 1 }}>
                          <label htmlFor="Email">Start of IP Range</label>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-end' }}>
                          <TextField
                            tabIndex={10}
                            error={formik.errors.ip_address ? true : false}
                            value={ipRange.start[0]}
                            onChange={(e) => handleIpchange(0, e)}
                            inputProps={{ sx: { padding: '8px' } }}
                          />
                          <Typography>.</Typography>
                          <TextField
                            tabIndex={11}
                            error={formik.errors.ip_address ? true : false}
                            value={ipRange.start[1]}
                            onChange={(e) => handleIpchange(1, e)}
                            inputProps={{ sx: { padding: '8px' } }}
                          />
                          <Typography>.</Typography>
                          <TextField
                            tabIndex={12}
                            error={formik.errors.ip_address ? true : false}
                            value={ipRange.start[2]}
                            onChange={(e) => handleIpchange(2, e)}
                            inputProps={{ sx: { padding: '8px' } }}
                          />
                          <Typography>.</Typography>
                          <TextField
                            tabIndex={13}
                            error={formik.errors.ip_address ? true : false}
                            value={ipRange.start[3]}
                            onChange={(e) => handleIpchange(3, e)}
                            inputProps={{ sx: { padding: '8px' } }}
                          />
                        </Box>
                        <FormHelperText sx={{ color: '#d50000' }}>
                          {formik.errors.ip_address ? formik.errors.ip_address : ''}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                        <Box sx={{ mb: 1 }}>
                          <label htmlFor="Email">End of IP Range</label>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-end' }}>
                          <TextField
                            error={formik.errors.ip_address ? true : false}
                            value={ipRange.start[0]}
                            disabled
                            inputProps={{ sx: { padding: '8px', background: '#F9F8F6;' } }}
                          />
                          <Typography>.</Typography>
                          <TextField
                            error={formik.errors.ip_address ? true : false}
                            value={ipRange.start[1]}
                            disabled
                            inputProps={{ sx: { padding: '8px', background: '#F9F8F6;' } }}
                          />
                          <Typography>.</Typography>
                          <TextField
                            error={formik.errors.ip_address ? true : false}
                            value={ipRange.start[2]}
                            disabled
                            inputProps={{ sx: { padding: '8px', background: '#F9F8F6;' } }}
                          />
                          <Typography>.</Typography>
                          <TextField
                            value={ipRange.end}
                            onChange={(e) => {
                              setIpRange({ ...ipRange, end: Math.floor(+e.target.value) });
                              formik.setFieldValue(
                                'ip_size',
                                Math.floor(+e.target.value) - Math.floor(+ipRange.start[3])
                              );
                            }}
                            inputProps={{ sx: { padding: '8px' } }}
                          />
                        </Box>
                      </FormControl>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} md={12}>
                  <FormControl variant="outlined" fullWidth>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Url">External / Internal? </label>
                    </Box>
                    <Select
                      size="small"
                      displayEmpty
                      id="is_external"
                      name="is_external"
                      value={formik.values.is_external}
                      error={formik.errors.is_external ? true : false}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.errors.is_external = '';
                      }}>
                      <MenuItem disabled selected sx={{ display: 'none' }}>
                        Select
                      </MenuItem>
                      <MenuItem value={true as any}>External</MenuItem>
                      <MenuItem value={false as any}>Internal</MenuItem>
                    </Select>
                    <FormHelperText sx={{ color: '#d50000' }}>
                      {' '}
                      {formik.errors.is_external}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ mt: 3 }}>
                    <Box sx={{ fontWeight: '600' }} component="span">
                      MFA
                    </Box>{' '}
                    (Multi Factor Authorization Method )
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Url">MFA</label>
                    </Box>
                    <Select
                      size="small"
                      displayEmpty
                      id="mfa_type"
                      name="mfa_type"
                      value={formik.values.mfa_type}
                      error={formik.errors.mfa_type ? true : false}
                      onChange={(e) => {
                        updateValue(e, 'mfa');
                        formik.handleChange(e);
                        formik.errors.mfa_type = '';
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}>
                      <MenuItem sx={{ display: 'none' }} key="-1" value="-1" disabled>
                        Select
                      </MenuItem>
                      {MFA.sort((a: any, b: any) => {
                        return a.key > b.key ? 1 : -1;
                      }).map((currItem, index) => (
                        <MenuItem key={index} value={currItem.key}>
                          {currItem.key}
                        </MenuItem>
                      ))}{' '}
                      <MenuItem value={'Other'}>{'Other'}</MenuItem>
                    </Select>
                    <FormHelperText sx={{ color: '#d50000' }}>
                      {' '}
                      {formik.errors.mfa_type}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Email">Multi Factor Method</label>
                    </Box>
                    <TextField
                      size="small"
                      id="mfa"
                      name="mfa"
                      value={formik.values.mfa}
                      disabled={formik.values.mfa_type != 'Other'}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleTextfeildChange(e);
                        formik.errors.mfa = '';
                      }}
                      helperText={
                        formik.errors.mfa
                          ? formik.errors.mfa
                          : '' || adressError.mfa
                          ? 'Multi Factor Method should be less than 100 characters'
                          : ''
                      }
                      error={formik.errors.mfa ? true : false || adressError.mfa ? true : false}
                      placeholder="Ex.: Microsoft"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ mt: 3 }}>
                    <Box sx={{ fontWeight: '600' }} component="span">
                      IDS/IPS
                    </Box>
                    (Intrusion Detection System/ Intrusion Prevention System){' '}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Url">IDS/IPS</label>
                    </Box>
                    <Select
                      size="small"
                      displayEmpty
                      id="ids_ips_type"
                      name="ids_ips_type"
                      value={formik.values.ids_ips_type}
                      error={formik.errors.ids_ips_type ? true : false}
                      onChange={(e) => {
                        updateValue(e, 'ids_ips');
                        formik.handleChange(e);
                        formik.errors.ids_ips_type = '';
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}>
                      <MenuItem sx={{ display: 'none' }} key="-1" value="-1" disabled>
                        Select
                      </MenuItem>
                      {IDS_IPS_solutions.sort((a: any, b: any) => {
                        return a.key > b.key ? 1 : -1;
                      }).map((currItem, index) => (
                        <MenuItem key={index} value={currItem.key}>
                          {currItem.key}
                        </MenuItem>
                      ))}{' '}
                      <MenuItem value={'Other'}>{'Other'}</MenuItem>
                    </Select>
                    <FormHelperText sx={{ color: '#d50000' }}>
                      {' '}
                      {formik.errors.ids_ips_type}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                    <Box sx={{ mb: 1 }}>
                      <label htmlFor="Email">IDS/IPS Solution</label>
                    </Box>
                    <TextField
                      size="small"
                      id="ids_ips"
                      name="ids_ips"
                      disabled={formik.values.ids_ips_type != 'Other'}
                      value={formik.values.ids_ips}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleTextfeildChange(e);
                        formik.errors.mfa = '';
                      }}
                      helperText={
                        formik.errors.ids_ips
                          ? formik.errors.ids_ips
                          : '' || adressError.ids_ips
                          ? 'IDS/IPS Solution should be less than 100 characters'
                          : ''
                      }
                      error={
                        formik.errors.ids_ips ? true : false || adressError.ids_ips ? true : false
                      }
                      placeholder="Ex.: Fortigate"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ px: { xs: 2, md: 3 }, pb: { xs: 2, md: 3 }, pt: 0, flexWrap: 'wrap' }}>
            <Button variant="outlined" sx={{ minWidth: 100 }} color="error" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              loading={isEnvButtonLoading}
              variant="contained"
              sx={{ minWidth: 100 }}
              type="submit">
              Save
            </LoadingButton>
            {/* <Typography variant="caption" component="div" sx={{ width: 1, textAlign: "right", mt: 1.5, color: "common.green" }}>Aplication activated!</Typography> */}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default AddTechEnvModel;
