import SvgIcon from '@mui/material/SvgIcon';

export default function HumanHead(props) {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        d="M10.8333 2.5C14.0667 2.5 16.6667 5.11667 16.6667 8.33333C16.6667 10.6667 15.3083 12.6583 13.3333 13.5917V17.5H7.50001V15H6.66668C5.74168 15 5.00001 14.2583 5.00001 13.3333V10.8333H3.75001C3.40001 10.8333 3.20001 10.4167 3.40001 10.1583L5.00001 8.05C5.15835 4.95833 7.69168 2.5 10.8333 2.5ZM10.8333 0.833332C7.00835 0.833332 3.84168 3.68333 3.38335 7.41667L2.08335 9.16667H2.05835L2.04168 9.19167C1.58335 9.825 1.52501 10.6583 1.88335 11.35C2.18335 11.925 2.71668 12.325 3.33335 12.45V13.3333C3.33335 14.875 4.40001 16.1833 5.83335 16.5583V19.1667H15V14.5833C17.0833 13.1917 18.3333 10.8833 18.3333 8.33333C18.3333 4.19167 14.9667 0.833332 10.8333 0.833332ZM14.4417 7.75L12.8083 8.175L14.0083 9.39167C14.3 9.675 14.3 10.1583 14.0083 10.45C13.7167 10.7417 13.2333 10.7417 12.95 10.45L11.7417 9.25L11.3083 10.8833C11.2083 11.2917 10.8 11.5167 10.4167 11.4167C10 11.3083 9.76668 10.9 9.86668 10.4917L10.3083 8.85833L8.67501 9.3C8.26668 9.4 7.85835 9.16667 7.75001 8.75C7.65001 8.375 7.88335 7.95833 8.28335 7.85833L9.91668 7.425L8.71668 6.21667C8.42501 5.93333 8.42501 5.45833 8.71668 5.15833C9.00835 4.86667 9.49168 4.86667 9.77501 5.15833L10.9917 6.35833L11.4167 4.725C11.5167 4.31667 11.9333 4.08333 12.325 4.19167C12.7333 4.3 12.9667 4.70833 12.8583 5.10833L12.4167 6.75L14.0583 6.30833C14.4583 6.2 14.8667 6.43333 14.975 6.84167C15.0833 7.23333 14.85 7.65 14.4417 7.75Z"
        fill="white"
      />
    </SvgIcon>
  );
}
