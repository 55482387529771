import HttpService from '@jibin/common/services/http/HttpService';

export const CompanyManagementApi = {
  // User section
  // PenetrationTests(userId: string, companyId: string) {
  //   //return HttpService.getAll("todos/1");
  //   return HttpService.getAll(`companies/${companyId}/users/${userId}/settings/penetration/tests`);
  // },
  GetCompany(company_id: string, user_id: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${company_id}/users/${user_id}/managed-companies`);
  }
};
