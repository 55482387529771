import Style from '@jibin/common/style/Styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { Skeleton } from '@mui/material';

export const CompanyManagementContactTable = () => {
  const [isSkeleton, setIsSkeleton] = useState(false);

  const [isRefresh, setIsRefresh] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const nevigate = useNavigate();
  const renderColorBackGround = (severity: any = 'active') => {
    // Active = Green
    // Bypass = Red
    // Deregistered = Yellow
    // Errors = Red
    // Inactive = Red
    // Pending Install = Red
    // Pending Update = Yellow
    // Quarantine = Red
    // Sensor Out of Date = Red
    var color = '#27AE60';
    switch (severity.toLowerCase()) {
      case 'active':
        color = '#27AE60';
        break;

      case 'not invited':
        color = '#B7B7B7';
        break;

      case 'deregistered':
      case 'pending':
        color = '#FCD34D';
        break;
    }

    return color;
  };

  return (
    <Box sx={Style.PoliciesAndProcedures.PoliciesTableWrapper}>
      <Typography variant="subtitle2" sx={{ fontSize: '18px', fontWeight: 600, mb: 1.5 }}>
        Amazon LTDA{' '}
      </Typography>
      {!isSkeleton ? (
        <Table sx={{ minWidth: 650 }} aria-label="site list table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body2">Contact Name</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Email</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Job Title</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Status</Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Box sx={Style.Common.Divider}></Box>
            <TableRow
              onClick={() => nevigate(PageRouteConstants.CompanyManagementAccount)}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                cursor: 'pointer',

                borderColor: '#CCCCCC'
              }}>
              <TableCell component="th" scope="row" sx={{ minWidth: 200 }}>
                <Typography variant="caption"> Veronica Carraro</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption"> email@email.com</Typography>{' '}
              </TableCell>
              <TableCell>
                <Typography variant="caption"> CEO</Typography>
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box
                    sx={{
                      width: '14px',
                      height: '14px',
                      backgroundColor: renderColorBackGround('Pending'),
                      borderRadius: '50%'
                    }}
                  />
                  <Typography variant="caption"> Pending</Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Button variant="contained">Resend Invite</Button>
                {/* <Typography
                  variant="caption"
                  sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  {loadMedal('gold')} Gold
                </Typography> */}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <Table
          sx={{ minWidth: 650, borderSpacing: '0px 10px', borderCollapse: 'separate' }}
          aria-label="site list table">
          <TableHead>
            <TableRow>
              <TableCell>Contact Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Job Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row" sx={{ minWidth: 200 }}>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell>
                {' '}
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                </Typography>
              </TableCell>
              <TableCell>
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default CompanyManagementContactTable;
