import { createSlice } from '@reduxjs/toolkit';

const initialStateObj = {
  authDetails: {}
};

export const authDetailSlice = createSlice({
  name: 'authDetails',
  initialState: initialStateObj,
  reducers: {
    setAuthDetails: (state, { payload }) => {
      state.authDetails = payload;
    },
    removeAuthDetails: (state) => {
      state.authDetails = {};
    }
  }
});

export const { setAuthDetails, removeAuthDetails } = authDetailSlice.actions;

//it behave like connector (old redux)
export const nftDetailSelector = (state: any) => state.nftDetail;

export default authDetailSlice.reducer;
