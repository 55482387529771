import { combineReducers } from '@reduxjs/toolkit';
import authDetailReducer from './authReducer';
import commonDetailReducer from './commonReducer';
import subscriptionPlanReducer from './subscriptionPlanReducer';

const RootReducer = combineReducers({
  authData: authDetailReducer,
  commonDetails : commonDetailReducer.reducer,
  subscriptionPlan : subscriptionPlanReducer.reducer,

});

export default RootReducer;
