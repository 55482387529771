import CloseIcon from '@jibin/common/icons/Close/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { FC, useEffect } from 'react';
import { InlineWidget } from 'react-calendly';
import { shallowEqual, useSelector } from 'react-redux';

export interface Meetingdata {
  Meeting: any;
  isReschedule: boolean;
  handleRefresh(): void;
}

export const ScheduleMeetingsModel: FC<Meetingdata> = (prop) => {
  const [open, setOpen] = React.useState(false);
  const [prefill, setPrefill] = React.useState({ name: '', email: '' });
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  useEffect(() => {
    // let utm_content =
    // utm.utm_content=utm_content;
    setPrefill({
      ...prefill,
      name: commonDetails.data.first_name + ' ' + commonDetails.data.last_name,
      email: commonDetails.data.email
    });
  }, [commonDetails]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    prop.handleRefresh();
    setOpen(false);
  };

  return (
    <>
      {/* {prop.isReschedule ? (
        <Button variant="outlined" onClick={handleClickOpen} sx={{ minWidth: 100 }}>
          Reschedule
        </Button>
      ) : (
        <Button variant="contained" onClick={handleClickOpen} sx={{ minWidth: 140 }}>
          Schedule
        </Button>
      )} */}
      <Button variant="contained" onClick={handleClickOpen} sx={{ minWidth: 140 }}>
        Schedule
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          id="alert-dialog-title"
          sx={{ px: { xs: 1, md: 2 }, py: { xs: 0.5, md: 1 }, bgcolor: 'error.red100' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="subtitle2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'text.primary',
                fontWeight: '600',
                maxWidth: 'calc(100% - 40px)',
                p: 1
              }}>
              Schedule Meeting
            </Typography>
            <IconButton sx={{ color: 'text.primary' }} aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ px: { xs: 2, md: 3 } }}>
          <InlineWidget
            url={prop.Meeting.data.scheduling_url}
            prefill={prefill}
            utm={{
              utmCampaign: 'NA',
              utmContent: `company_uuid:${commonDetails.data.company.company_uuid};user_uuid:${commonDetails.data.user_uuid}`,
              utmMedium: 'NA',
              utmSource: 'NA',
              utmTerm: 'NA'
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ScheduleMeetingsModel;
