import { FC, useCallback, useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import { RemediationApi } from './Remediation.Api';
import { shallowEqual, useSelector } from 'react-redux';
import { cp } from 'fs/promises';
import RemidiationPopUpList from '@jibin/common/components/RemidiationPopUp/RemidiationPopupList';
import ProfileIcon from '@jibin/common/icons/ProfileIcon/ProfileIcon';
import { SourceTypeEnums } from '@jibin/common/utils/enums/SourceTypeEnums';
import Style from '@jibin/common/style/Styles';
import SearchIcon from '@jibin/common/icons/Search/Search';
import FlagIcon from '@jibin/common/icons/FlagIcon/FlagIcon';
import { useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';

type Anchor = 'top' | 'left' | 'bottom' | 'right';
export const Remediation: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [useParams, setUseParam] = useSearchParams();
  const [rowData, setRowData] = useState({} as any);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [assignees, setAssignees] = useState({});
  const [click, setclick] = useState(false);
  const [state, setState] = useState({
    right: false
  });
  const [remediationFilterform, setRemediationFilterform] = useState({
    keyword: '',
    severity: '',
    status: '',
    source: ''
  });
  const location = useLocation();

  const toggleDrawer = (anchor: Anchor, open: boolean, row: any) => {
    setclick(!click);

    if (!open) {
      useParams.delete('remediation_uuid');
      setUseParam(useParams);
    } else {
      setParams(row.remediation_uuid);
    }
    setRowData(row);
    setState({ ...state, [anchor]: open });
  };
  const setParams = (index) => {
    const params = {
      remediation_uuid: index
    };
    setUseParam(params);
  };
  const [remediations, setRemediations] = useState({} as any);
  const GetRemediations = useCallback(() => {
    RemediationApi.Remediations(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid,
      new URLSearchParams(remediationFilterform).toString()
    ).then(
      (q: any) => {
        if (useParams.get('remediation_uuid') && !click) {
          const filterdData = q.data.data.filter(
            (el, index) => el.remediation_uuid == useParams.get('remediation_uuid')
          );

          setTimeout(() => {
            const element = document.getElementById(filterdData[0].remediation_uuid);
            if (element) {
              var headerOffset = 90;
              var elementPosition = element.getBoundingClientRect().top;
              var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
              });
              element.classList.add('flash');
              setTimeout(() => {
                element.classList.remove('flash');
              }, 1000);
            }
          }, 1);

          toggleDrawer('right', true, filterdData[0]);
          setRowData(filterdData[0]);
        }
        setRemediations(q.data);
        setIsSkeleton(false);
      },
      (err: any) => {}
    );
  }, [commonDetails, remediationFilterform, isRefresh, location.search]);

  const GetAssignees = useCallback(async () => {
    try {
      const res = await RemediationApi.GetAssignees(
        commonDetails.data.user_uuid,
        commonDetails.data.company.company_uuid
      );
      setAssignees(res.data);
    } catch (err) {
      console.log(err);
    }
  }, [commonDetails, isRefresh]);
  // const GetRemidiationDetails = useCallback(
  //   async (row: any) => {
  //     try {
  //       const res = await RemediationApi.GetRemediatios(
  //         commonDetails.data.user_uuid,
  //         commonDetails.data.company.company_uuid,
  //         row.remediation_uuid || ''
  //       );
  //       setRowData(res.data);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   },
  //   [commonDetails,isRefresh]
  // );

  const GetRemidiationDetails = (row: any) => {
    setRowData(row);
  };

  useEffect(() => {
    if (commonDetails.data.group != 'superadmin' && commonDetails.data.group != 'csc') {
      if (engineer == null) {
        if (commonDetails.data.group == 'engineer') {
          setEngineer(true);
        } else {
          setEngineer(false);
        }
      }
    }
    if (!click) {
      GetRemediations();
    }
  }, [GetRemediations]);

  const renderColor = (severity: any) => {
    var color = '#F87171';
    if (severity == 'critical') {
      color = '#F87171';
    } else if (severity == 'high') {
      color = '#F87171';
    } else if (severity == 'medium') {
      color = '#FBBF24';
    } else if (severity == 'low') {
      color = '#436AF3';
    }
    return color;
  };
  const renderColorBackGround = (severity: any = 'critical') => {
    var color = '#FFE6E2';
    if (severity == 'critical') {
      color = '#FFE6E2';
    } else if (severity == 'high') {
      color = '#FFE6E2';
    } else if (severity == 'medium') {
      color = '#FEF3C7';
    } else if (severity == 'low') {
      color = '#D0DAFC';
    }
    return color;
  };

  const getSource = (source: any) => {
    let sourceLabel = '';
    switch (source) {
      case SourceTypeEnums.gap:
        sourceLabel = 'GAP';
        break;
      case SourceTypeEnums.vuln:
        sourceLabel = 'VUL';
        break;
      case SourceTypeEnums.pen:
        sourceLabel = 'PEN';
        break;
      case SourceTypeEnums.endpoint:
        sourceLabel = 'ENDPOINT';
        break;
    }
    return sourceLabel;
  };

  const [engineer, setEngineer] = useState(null);

  const handleRadioChange = (e: any) => {
    if (e.target.value == 'engineer') {
      setEngineer(true);
    } else {
      setEngineer(false);
    }
  };

  const handleSearch = (e) => {
    setRemediationFilterform({ ...remediationFilterform, [e.target.name]: e.target.value });
  };
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          backgroundColor: '#FFFFFF',
          p: 4,
          gap: 1
        }}>
        <Typography variant={'subtitle1'}>Remediation Tracker</Typography>
        <Typography variant={'subtitle2'}>
          Prioritized list of cybersecurity remediations
        </Typography>
        {commonDetails.data.group == 'superadmin' && (
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="user"
            name="radio-buttons-group"
            onChange={(e) => {
              handleRadioChange(e);
            }}
            row>
            <FormControlLabel value="engineer" control={<Radio />} label="Engineer" />
            <FormControlLabel value="user" control={<Radio />} label="User" />
          </RadioGroup>
        )}
      </Box>
      {isSkeleton ? (
        <Box sx={{ px: 4, py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : (
        <Box sx={{ px: 4, py: 1, backgroundColor: '#FBFBFF', width: 1 }}>
          <Box sx={{ py: 2, display: 'flex', gap: 3 }}>
            <TextField
              placeholder="Search"
              hiddenLabel
              size="small"
              name="keyword"
              value={remediationFilterform.keyword}
              onChange={handleSearch}
              sx={{ backgroundColor: '#FFFFFF' }}
              inputProps={{ sx: { fontSize: '14px', color: '#696969' } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            <Select
              size="small"
              displayEmpty
              id="type"
              name="severity"
              value={remediationFilterform.severity}
              onChange={handleSearch}
              sx={{
                fontSize: '14px',
                color: '#696969',
                minWidth: { xxs: 0, md: '188px' },
                backgroundColor: '#FFFFFF'
              }}
              inputProps={{ 'aria-label': 'Without label' }}>
              <MenuItem sx={{ fontSize: '14px' }} value="critical">
                Critical
              </MenuItem>
              <MenuItem sx={{ fontSize: '14px' }} value="high">
                High
              </MenuItem>
              <MenuItem sx={{ fontSize: '14px' }} value="medium">
                Medium
              </MenuItem>
              <MenuItem sx={{ fontSize: '14px' }} value="low">
                Low
              </MenuItem>
              <MenuItem sx={{ fontSize: '14px' }} key="-1" value="">
                All Severities
              </MenuItem>
            </Select>
            <Select
              size="small"
              displayEmpty
              id="type"
              sx={{
                fontSize: '14px',
                color: '#696969',
                minWidth: { xxs: 0, md: '188px' },
                backgroundColor: '#FFFFFF'
              }}
              name="source"
              value={remediationFilterform.source}
              onChange={handleSearch}
              inputProps={{ 'aria-label': 'Without label' }}>
              <MenuItem sx={{ fontSize: '14px' }} value="vuln">
                Vulnerability
              </MenuItem>
              <MenuItem sx={{ fontSize: '14px' }} value="pen">
                Penetration Test
              </MenuItem>
              <MenuItem sx={{ fontSize: '14px' }} value="gap">
                Gap Analysis
              </MenuItem>
              <MenuItem sx={{ fontSize: '14px' }} value="endpoint">
                Endpoint
              </MenuItem>
              <MenuItem sx={{ fontSize: '14px' }} key="-1" value="">
                All Sources
              </MenuItem>
            </Select>

            <Select
              size="small"
              displayEmpty
              id="type"
              sx={{
                fontSize: '14px',
                color: '#696969',
                minWidth: { xxs: 0, md: '188px' },
                backgroundColor: '#FFFFFF'
              }}
              name="status"
              value={remediationFilterform.status}
              onChange={handleSearch}
              inputProps={{ 'aria-label': 'Without label' }}>
              <MenuItem sx={{ fontSize: '14px' }} value="not_started">
                Not Started
              </MenuItem>
              <MenuItem sx={{ fontSize: '14px' }} value="in_progress">
                In Progress
              </MenuItem>
              <MenuItem sx={{ fontSize: '14px' }} value="resolved">
                Resolved
              </MenuItem>
              <MenuItem sx={{ fontSize: '14px' }} key="-1" value="">
                All Remediations
              </MenuItem>
            </Select>
          </Box>
          {remediations?.data?.length == 0 ? (
            <Box
              sx={{ display: 'flex', justifyContent: 'center', width: '100%', m: 'auto', mt: 5 }}>
              <Box sx={{ textAlign: 'center' }}>
                <img width={'50%'} src="/images/NoDataFound.png" />
                <Typography
                  sx={{ mt: '10px', textAlign: 'center', color: '#696969' }}
                  variant="body2">
                  The Remediation Tracker page will be populated once the first remediation is
                  discovered.
                </Typography>
              </Box>
            </Box>
          ) : (
            <TableContainer component={Paper} sx={{ border: 'none', boxShadow: 'none' }}>
              <Table
                sx={{
                  minWidth: 650,
                  borderCollapse: 'separate',
                  boxShadow: 'none',
                  borderSpacing: '0px 16px',
                  border: 'none'
                }}
                aria-label="site list table">
                <TableHead>
                  <TableRow sx={{ '.MuiTableCell-root': { border: 'none' } }}>
                    <TableCell>
                      <Typography variant="body2">Severity </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">Date Discovered</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2"> Source</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2"> Issue name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2"> Owner</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2"> Status</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {remediations.data ? (
                    remediations.data?.map((row, index) => (
                      <TableRow
                        id={row.remediation_uuid}
                        key={index}
                        onClick={() => {
                          toggleDrawer('right', true, row);
                          // GetRemidiationDetails(row);
                          // GetAssignees();
                          // setParams(index);
                        }}
                        sx={{
                          cursor: 'pointer',
                          '.MuiTableCell-root': {
                            px: '20px',

                            borderBottom: '1px solid  #CCCCCC',
                            borderTopRadius: '4px',
                            borderTop: '1px solid  #CCCCCC',
                            '&:last-child': {
                              borderRight: '1px solid  #CCCCCC',
                              borderTopRightRadius: '4px',
                              borderBottomRightRadius: '4px'
                            },
                            '&:first-child': {
                              borderLeft: '1px solid  #CCCCCC',
                              borderTopLeftRadius: '4px',
                              borderBottomLeftRadius: '4px'
                            }
                          }
                        }}>
                        <TableCell component="th" scope="row">
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box
                              sx={{
                                width: '24px',
                                height: '24px',
                                backgroundColor: renderColorBackGround(row.severity),
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '50%'
                              }}>
                              <FlagIcon
                                sx={{
                                  color: renderColor(row.severity),
                                  width: '12px'
                                }}
                              />
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="caption">
                            {' '}
                            {moment(row.created_at).format('DD MMM YYYY').toUpperCase()}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="caption">{getSource(row.source)}</Typography>
                        </TableCell>
                        <TableCell sx={{ maxWidth: 250 }} align="left">
                          <Typography variant="caption">{row.issue_name}</Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box>
                              {row.owner.ico ? (
                                <Box
                                  sx={{
                                    ...Style.versionsCard.infoRound,
                                    width: '32px',
                                    height: '32px'
                                  }}>
                                  <img
                                    width={'100%'}
                                    height="100%"
                                    style={{ objectFit: 'cover', borderRadius: '50%' }}
                                    src={`${process.env.REACT_APP_API_HOST}/${row.owner.ico}`}
                                    alt=""
                                  />
                                </Box>
                              ) : (
                                <Typography
                                  sx={{
                                    ...Style.versionsCard.infoRound,
                                    width: '32px',
                                    height: '32px'
                                  }}>
                                  {' '}
                                  {row.owner.first_name
                                    ? row.owner.first_name.charAt(0) +
                                      '' +
                                      row.owner.last_name.charAt(0)
                                    : '-'}
                                </Typography>
                              )}
                            </Box>
                            {row.owner.first_name && (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between'
                                }}>
                                <Typography variant="caption" sx={{ fontWeight: 600 }}>
                                  {row.owner.first_name + ' ' + row.owner.last_name}
                                </Typography>
                                <Typography variant="caption">{row.owner?.company.name}</Typography>
                              </Box>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="caption">
                            {' '}
                            {row.status == 'in_progress' ? (
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: '#FBBF24',
                                    borderRadius: '50%'
                                  }}></Box>
                                <Typography variant="caption">In Progress</Typography>
                              </Box>
                            ) : row.status == 'not_started' ? (
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: '#EF4444',
                                    borderRadius: '50%'
                                  }}></Box>
                                <Typography variant="caption">Not Started</Typography>
                              </Box>
                            ) : (
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box
                                  sx={{
                                    width: '14px',
                                    height: '14px',
                                    backgroundColor: '#10B981',
                                    borderRadius: '50%'
                                  }}></Box>
                                <Typography variant="caption">
                                  {row.status.charAt(0).toUpperCase() + '' + row.status.slice(1)}
                                </Typography>
                              </Box>
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <>
                      <TableRow
                        sx={{
                          cursor: 'pointer',
                          '.MuiTableCell-root': {
                            backgroundColor: '#FFFFFF ',
                            px: '20px',
                            py: '30px',

                            borderBottom: '1px solid  #CCCCCC',
                            borderTopRadius: '4px',
                            borderTop: '1px solid  #CCCCCC',
                            '&:last-child': {
                              borderRight: '1px solid  #CCCCCC',
                              borderTopRightRadius: '4px',
                              borderBottomRightRadius: '4px'
                            },
                            '&:first-child': {
                              borderLeft: '1px solid  #CCCCCC',
                              borderTopLeftRadius: '4px',
                              borderBottomLeftRadius: '4px'
                            }
                          }
                        }}>
                        <TableCell align="left">
                          <Typography variant="caption">
                            {' '}
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="caption">
                            {' '}
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="caption">
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="caption">
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="caption">
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
              <SwipeableDrawer
                BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.4 } }}
                sx={{
                  '.MuiDrawer-paper': {
                    boxShadow: 'none'
                  }
                }}
                anchor={'right'}
                open={state['right']}
                onClose={() => toggleDrawer('right', false, {})}
                onOpen={() => toggleDrawer('right', true, rowData)}>
                {!isSkeleton && (
                  <RemidiationPopUpList
                    el={rowData}
                    remediation_uuid={rowData.remediation_uuid}
                    closePopup={() => toggleDrawer('right', false, {})}
                    isEngineer={engineer}
                    setIsRefresh={setIsRefresh}
                    isRefresh={isRefresh}
                  />
                )}
              </SwipeableDrawer>
            </TableContainer>
          )}
        </Box>
      )}
    </Box>
  );
};
