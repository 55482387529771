
import React, { FC, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Link from '@mui/material/Link';
import Style from '@jibin/common/style/Styles';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants';
import { FrameworksApi } from './Frameworks.Api';
import { shallowEqual, useSelector } from 'react-redux';
import CISTab from './FrameworkControl';
import FrameworkControl from './FrameworkControl';
import { Skeleton } from '@mui/material';


// const MenuItems = [
//   'Company Profile', 'Manage Users', 'Meetings', 'Authorizations', 'Plan and Billing'
// ]


// const MenuItems = [
//   {name :'MPA', class:'',route: PageRouteConstants.FrameworksMPA}, 
//   {name :'CIS', class:'',route: PageRouteConstants.FrameworksCIS}, 
 
//   ]

export const FrameWorksTemplate: FC = (props) => {
  const navigate = useNavigate();
  
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );

  
  const [frameworksList, setFrameworksList] = useState({} as any);
  const [framework, setFramework] = useState({} as any);
  const [frameworkId, setFrameworkId] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  //const [useParams, setUseParam] = useSearchParams();

  
  const selectFramework = (id)=>{    
    debugger
    let data = frameworksList.data?.filter(q=> q?.frameworks_uuid == id)[0]
    setFramework(data);
    setFrameworkId(data?.frameworks_uuid)
    const params = {
      frameworks_uuid: id
    };
    setSearchParams(params);
    return data
  }

  const getFrameworkList = useCallback(() => {
    FrameworksApi.FrameworksList(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {                        
        setFrameworksList(q.data);
        if(searchParams.get("frameworks_uuid")){
          setFrameworkId(searchParams.get("frameworks_uuid"))
          var frameworkData = q.data.data.filter(q=> q.frameworks_uuid ==searchParams.get("frameworks_uuid"))[0];
          setFramework(frameworkData)
        }else{                 
          setFrameworkId(q.data.data[0]?.frameworks_uuid)
          setFramework(q.data.data[0])
        }
        
      },
      (err: any) => {}
    );
  }, [commonDetails]);

  useEffect(() => {      
    getFrameworkList();
  }, [getFrameworkList]);


  return (

    <>
      <Box sx={Style.Common.pageHead}>
        <Typography variant="subtitle1" data-testid="lbl_onboard"  sx={{ mb: 2 }}>Frameworks </Typography>
        <Typography variant="subtitle2">
        Automated compliance reporting against cybersecurity frameworks
          </Typography>
      </Box>
      
      <List sx={Style.CompanySetting.MenuList}>

        {
          frameworksList.data ? 
          frameworksList.data?.map((currenElement, index) => (
            <ListItem disablePadding key={index} sx={{ width: 'auto' }}>
              {/* navigate(PageRouteConstants.FrameworkControl+currenElement.frameworks_uuid) */}
              <Link onClick={()=>{selectFramework(currenElement?.frameworks_uuid)}} style={{cursor: 'pointer'}}  className={frameworkId === currenElement?.frameworks_uuid ? 'active':'' } sx={Style.CompanySetting.MenuListItem}>{currenElement.name}</Link>
            </ListItem>
          ))
         
          :
          <>
          <ListItem disablePadding sx={{ width: 'auto' }}>          
          <Link ><Skeleton variant="text" sx={{ fontSize: '1rem',width:100 }} /></Link>
          </ListItem>
           <ListItem disablePadding sx={{ width: 'auto' }}>          
           <Link ><Skeleton variant="text" sx={{ fontSize: '1rem',width:100 }} /></Link>
           </ListItem>
          </>
        }

      </List>
      {/* {props.children} */}
      {frameworksList.data?.length > 0 &&
      <FrameworkControl el={framework} />
      }
    </>
  );
};

export default FrameWorksTemplate;
