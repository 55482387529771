import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';

import React, { FC, useEffect, useRef, useState } from 'react';

import Header from '../Header';
import Sidebar from '../../../app/src/Pages/Common/Sidebar';
import Style from '@jibin/common/style/Styles';
import CloseIcon from '@jibin/common/icons/Close/Close';
import ClockIcon from '@jibin/common/icons/ClockIcon/ClockIcon';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { shallowEqual, useSelector } from 'react-redux';
import UserPool from '@jibin/common/utils/UserPool';
import Divider from '@mui/material/Divider';

export const MainContainer: FC<{ children: React.ReactNode | React.ReactNode[] }> = (props) => {
  let [count, setCount] = useState(60 * parseInt(process.env.REACT_APP_MINS_TO_LOGOUT));
  const [open, setOpen] = useState(false);
  let ref = useRef(null);
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const handleOpen = () => {
    setOpen(true);
  };

  const startTimeOut = () => {
    if (ref.current) {
      return;
    }

    ref.current = setInterval(() => {
      setCount(count--);

      if (count == 60 * parseInt(process.env.REACT_APP_MINS_TO_SHOW_POPUP)) {
        setOpen(true);
      } else if (count == 0) {
        handleSignout(true);
      }
    }, 1000);
  };

  const handleSignout = (inActivity: boolean) => {
    localStorage.clear();
    sessionStorage.setItem("IsSessionActive",'false')

    const user = new CognitoUser({
      Username: commonDetails.data.email,
      Pool: UserPool
    });
    user.signOut();

    handleClose();
    window.location.href = inActivity ? '/login?inactivity=true' : '';
  };
  const stopTimeOut = () => {
    clearInterval(ref.current);
    ref.current = null;

    setCount(60 * parseInt(process.env.REACT_APP_MINS_TO_LOGOUT));
    startTimeOut();
  };
  const handleClose = () => {
    stopTimeOut();
    setOpen(false);
  };
  const converTime = (counter) => {
    var minutes = Math.floor(counter / 60);
    var secondsRemaining = counter % 60;
    return minutes + ' min ' + secondsRemaining.toString().padStart(2, '0') + ' sec ';
  };
  useEffect(() => {
    startTimeOut();
        
    if(!sessionStorage.getItem("IsSessionActive")){
      handleSignout(true)
    }



    return () => {
      clearInterval(ref.current);
      ref.current = null;
    };
  }, []);

  return (
    <Box
      onMouseMove={() => {
        !open && stopTimeOut();
      }}
      onKeyDown={() => {
        !open && stopTimeOut();
      }}
      onClick={() => {
        !open && stopTimeOut();
      }}
      onTouchStart={() => {
        !open && stopTimeOut();
      }}>
      <Header showMenu={true} />
      <Box sx={Style.Layout.MainLayout}>
        <Sidebar />
        <Box component="main" className="main-content" sx={Style.Layout.MainContent}>
          {props.children}
        </Box>
      </Box>
      <Box>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              px: { xs: 1, md: 2 },
              py: { xs: 0.5, md: 1 },
              bgcolor: 'error.red100',
              mb: 1
            }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ClockIcon sx={{ mr: 1 }} />
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#2D2D2D',
                    fontWeight: '600',

                    p: 1
                  }}>
                  Session Timeout
                </Typography>
              </Box>
              <IconButton sx={{ color: 'text.primary' }} aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ p: { xs: 2, md: 3 } }}>
            <DialogContentText id="alert-dialog-description" sx={{ color: 'text.primary' }}>
              <Box sx={{ textAlign: 'center', width: 1 }}>
                <Typography variant="body2" sx={{ mb: 1.5 }}>
                  Your session will expire in:
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 600, mb: 2.5 }}>
                  {converTime(count)}
                </Typography>
                <Box sx={{ width: 0.6, m: 'auto', display: 'flex' }}>
                  <Typography variant="caption">
                    Please click “Continue” to keep working or click “Log Off” to end your session
                    now.
                  </Typography>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{ px: { xs: 2, md: 3 }, pb: { xs: 1, md: 2 }, pt: 0, justifyContent: 'center' }}>
            <Button
              variant="outlined"
              onClick={() => handleSignout(false)}
              sx={{ minWidth: 100, color: '#9D9D9D', borderColor: '#9D9D9D' }}>
              Log Off
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{ minWidth: 100, color: 'Primary/500' }}>
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default MainContainer;
