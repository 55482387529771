import HttpService from '@jibin/common/services/http/HttpService';
export const ServiceReviewApi = {
  PostDocument(service_review_uuid: string, user_id: string, company_id: string, data: any) {
    //return HttpService.getAll("todos/1");

    return HttpService.post(
      `companies/${company_id}/users/${user_id}/services-review/${service_review_uuid}/evidence/reports`,
      data
    );
  },
  PatchApprovel(company_id: string, user_id: string, evidence_id: string, data: any) {
    //return HttpService.getAll("todos/1");

    return HttpService.patchData(
      `companies/${company_id}/users/${user_id}/services-review/${evidence_id}/acknowledge`,
      data
    );
  },
  GetFile(service_name: string, company_id: string, file_path: string) {
    file_path = file_path.slice(1);
    return HttpService.getDocument(`${file_path}`);
  },
  GetAllServices(company_id: string, user_id: string) {
    return HttpService.getAll(`companies/${company_id}/users/${user_id}/services-review`);
  }
};
