import HttpService from '@jibin/common/services/http/HttpService';

export const SecurityAwarenessApi = {
  // User section
  training(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/training`);
  },
  phishing(userId: string, companyId: string) {
    //return HttpService.getAll("todos/1");
    return HttpService.getAll(`companies/${companyId}/users/${userId}/phishing`);
  },

}