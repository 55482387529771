import Style from '@jibin/common/style/Styles';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { FC, useState } from 'react';
import GoldIcon from '@jibin/common/icons/MedalIcon/GoldIcon.svg';
import SilverIcon from '@jibin/common/icons/MedalIcon/SilverIcon.svg';
import BronzeIcon from '@jibin/common/icons/MedalIcon/BronzeIcon.svg';
import PlatniumIcon from '@jibin/common/icons/MedalIcon/PlatniumIcon.svg';
import CloseIcon from '@jibin/common/icons/Close/Close';
import WarningIcon from '@jibin/common/icons/Warning/Warning';
import ChipBox from './ChipBox';
const CompanyManagementAccount: FC = () => {
  const loadMedal = (type) => {
    switch (type) {
      case 'gold':
      case 'Gold':
        return <img width={'16px'} src={GoldIcon} />;

      case 'silver':
      case 'Silver':
        return <img width={'16px'} src={SilverIcon} />;
      case 'bronze':
      case 'Bronze':
        return <img width={'16px'} src={BronzeIcon} />;
      case 'platinum':
      case 'Platinum':
        return <img width={'16px'} src={PlatniumIcon} />;
    }
  };
  const Framework = [
    { name: 'CIS', id: 1 },
    { name: 'MPA', id: 2 },
    { name: 'Framework 3', id: 3 },
    { name: 'Framework 4', id: 4 }
  ];
  const Service = [
    { name: 'Gap Analysis', id: 1 },
    { name: 'Polices & Procedures', id: 2 },
    { name: 'Security Awareness', id: 3 },
    { name: 'Vulnerability Management', id: 4 },
    { name: 'Penetration Test', id: 5 },
    { name: 'Remediation Tracker', id: 6 },
    { name: 'Endpoint Protection ', id: 7 },
    { name: 'Email Protection', id: 8 },
    { name: 'Domain Monitoring', id: 9 }
  ];
  const [open, setOpen] = useState(false);
  const [values, setValue] = useState<any>({ Framework: [], Service: [] });
  const [country, setCountry] = useState('US');
  const [address, setAddress] = useState({ address1: '', address2: '' });
  const [adressError, setAdressError] = useState({ address1: false, address2: false });
  const handleChangeCountry = (event: any) => {
    setCountry(event.target.value);
  };

  const [autocompleteInputRef, setautocompleteInputRef] = useState({} as any);

  const handleChangeAddress = (event: any) => {
    setAddress({ ...address, [event.target.name]: event.target.value });
    if (
      (event.target.name == 'address1' && event.target.value.length > 100) ||
      (event.target.name == 'address2' && event.target.value.length > 100)
    ) {
      setAdressError({ ...adressError, [event.target.name]: true });
    } else {
      setAdressError({ ...adressError, [event.target.name]: false });
    }
  };

  const [setState, setSetState] = useState('');

  return (
    <Box>
      <Box sx={{ py: 1.5 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
          Company Account
        </Typography>
      </Box>
      <Box sx={Style.CompanyManagement.CommonBoxes}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                Company:
              </Typography>
              <Typography variant="body1" sx={{ color: '#696969' }}>
                Amazon
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                Company:
              </Typography>
              <Typography variant="body1" sx={{ color: '#696969' }}>
                Amazon
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                Industry:
              </Typography>
              <Typography variant="body1" sx={{ color: '#696969' }}>
                Industry Type
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                Website:
              </Typography>
              <Typography variant="body1" sx={{ color: '#696969' }}>
                www.site.com
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body1" sx={{ fontWeight: 600, minWidth: '90px' }}>
                Onboarding:
              </Typography>
              <Box
                sx={{
                  px: 1,
                  py: '2px',
                  bgcolor: 'rgba(120, 120, 120, 0.1)',
                  borderRadius: '100px'
                }}>
                <Typography variant="caption">Not Onboarded</Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography
                variant="body1"
                sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontWeight: 600 }}>
                {loadMedal('platinum')} Subscription (Platinum)
              </Typography>
              <Box
                sx={{
                  px: 1,
                  py: '2px',
                  bgcolor: 'rgba(16, 185, 129, 0.1);',
                  borderRadius: '100px'
                }}>
                <Typography variant="caption" sx={{ color: '#27AE60' }}>
                  Active
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={Style.CompanyManagement.CommonBoxes}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 600, py: 1.5, color: '#696969' }}>
              Framework
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                multiple
                id="tags-filled"
                options={Framework.sort((a: any, b: any) => {
                  return a?.name > b?.name ? 1 : -1;
                }).map((option) => option.name)}
                freeSolo
                value={values.Framework}
                renderTags={(value, getTagProps) => {
                  return value.map((option, index) => (
                    <Box>
                      <ChipBox
                        tag={'Framework'}
                        values={values}
                        option={option}
                        getTagProps={getTagProps}
                        index={index}
                        value={value}
                        setValue={setValue}
                      />
                    </Box>
                  ));
                }}
                onChange={(event, value) => {
                  const common = Framework.filter((obj) => value.includes(obj.name)).map(
                    (obj) => obj.name
                  );

                  setValue({ ...values, Framework: common });
                }}
                renderInput={(params) => (
                  <TextField size="small" {...params} placeholder="Add Frameworks" />
                )}
                sx={{
                  '&.MuiAutocomplete-root': {
                    '.MuiOutlinedInput-root': {
                      p: 0,
                      pl: 1
                    },
                    '.MuiAutocomplete-tag': {
                      m: 1,
                      bgcolor: 'common.lightBlue',
                      border: 'none'
                    }
                  }
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 600, py: 1.5, color: '#696969' }}>
              Services
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                multiple
                id="tags-filled"
                options={Service.sort((a: any, b: any) => {
                  return a?.name > b?.name ? 1 : -1;
                }).map((option) => option.name)}
                freeSolo
                value={values.Service}
                renderTags={(value, getTagProps) => {
                  return value.map((option, index) => (
                    <Box>
                      <ChipBox
                        tag={'Service'}
                        title={''}
                        values={values}
                        option={option}
                        getTagProps={getTagProps}
                        index={index}
                        value={value}
                        setValue={setValue}
                      />
                    </Box>
                  ));
                }}
                onChange={(event, value) => {
                  console.log(value);
                  const common = Service.filter((obj) => value.includes(obj.name)).map(
                    (obj) => obj.name
                  );
                  console.log(common);
                  setValue({ ...values, Service: common });
                }}
                renderInput={(params) => (
                  <TextField size="small" {...params} placeholder="Add Services" />
                )}
                sx={{
                  '&.MuiAutocomplete-root': {
                    '.MuiOutlinedInput-root': {
                      p: 0,
                      pl: 1
                    },
                    '.MuiAutocomplete-tag': {
                      m: 1,
                      bgcolor: 'common.lightBlue',
                      border: 'none'
                    }
                  }
                }}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box sx={{ py: 1.5 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
          Tool Settings
        </Typography>
      </Box>
      <Box sx={Style.CompanyManagement.CommonBoxes}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 600, py: 1.5, color: '#696969' }}>
              KnowBe4 Token Access Token
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <TextField placeholder="Enter Token" variant="outlined" size="small" fullWidth />
            </FormControl>
          </Box>
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 600, py: 1.5, color: '#696969' }}>
              Rapid7 InsightVM Site ID
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <TextField placeholder="Enter Jira Epic" variant="outlined" size="small" fullWidth />
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box sx={{ py: 1.5 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
          Tool Settings
        </Typography>
      </Box>
      <Box sx={Style.CompanyManagement.CommonBoxes}>
        <FormGroup>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                sx={{ '.MuiFormControlLabel-label': { fontSize: '14px' } }}
                control={<Checkbox />}
                label="Schedule Meeting"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                sx={{ '.MuiFormControlLabel-label': { fontSize: '14px' } }}
                control={<Checkbox />}
                label="Create Policies & Procedures"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                sx={{ '.MuiFormControlLabel-label': { fontSize: '14px' } }}
                control={<Checkbox />}
                label="Add Users"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                sx={{ '.MuiFormControlLabel-label': { fontSize: '14px' } }}
                control={<Checkbox />}
                label="Invite Users to Security Awareness"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                sx={{ '.MuiFormControlLabel-label': { fontSize: '14px' } }}
                control={<Checkbox />}
                label="Set Technical Information"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                sx={{ '.MuiFormControlLabel-label': { fontSize: '14px' } }}
                control={<Checkbox />}
                label="Complete Company Information"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                sx={{ '.MuiFormControlLabel-label': { fontSize: '14px' } }}
                control={<Checkbox />}
                label="Sign Authorizations"
              />
            </Grid>
          </Grid>
        </FormGroup>
      </Box>
      <Box sx={{ py: 2 }}>
        <Box sx={{ display: 'flex', gap: 1.5, justifyContent: 'flex-end' }}>
          <Button variant="outlined" sx={{ minWidth: '160px' }}>
            Save
          </Button>
          <Button variant="contained" sx={{ minWidth: '160px' }}>
            Onboard
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default CompanyManagementAccount;
