import React, { FC } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import { NotFound } from './Pages/NotFound';
import { Login } from './Pages/Login';

import { PageRouteConstants } from '@jibin/common/utils/constants/PageRouteConstants'
import PrivateRoutes from '@jibin/common/utils/RouteAccessor/PrivateRoutes'
import PublicRoutes from '@jibin/common/utils/RouteAccessor/PublicRoutes'
import Onboarding from './Pages/Onboarding';
import { Signup } from './Pages/Signup';
import { Welcome } from './Pages/Welcome';
import { ForgotPassword } from './Pages/ForgotPassword';
import CompanySettings, { CustomerSuccessTemplate, FrameWorksTemplate, TechnicalInformation } from '@jibin/common/templates'
import { AuthNonLayoutContainer } from '@jibin/common/components/AuthContainer';
import CompanyProfile from './Pages/CompanySettings/CompanyProfile';
import Applications from './Pages/TechinicalInformation/Applications';
import ExternalInfrastructureAssessment from './Pages/TechinicalInformation/ExternalInfrastructureAssessment';
import WirelessAssessment from './Pages/TechinicalInformation/WirelessAssessment';
import ManageUsers from './Pages/CompanySettings/ManageUsers';
import Meetings from './Pages/CompanySettings/Meetings';
import Authorizations from './Pages/CompanySettings/Authorizations';
import PlanAndBilling from './Pages/CompanySettings/PlanAndBilling';
import PoliciesAndProcedures from './Pages/PoliciesAndProcedures';
import PoliciesAndProceduresTemplate from './Pages/PoliciesAndProcedures/Template';
import { SecurityAwarness } from './Pages/SecurityAwarnes';
import { Mfa } from './Pages/MFA';
import { Vulnerability } from './Pages/Vulranbility';

import { Remediation } from './Pages/Remediation';

import { Panetration } from './Pages/Panetration';
import GapAnalysis from './Pages/GapAnalysis';
import { DashBoard } from './Pages/DashBoard';
import ServicesReview from './Pages/CustomerSuccess/ServicesReview';
import EngineeringConsulting from './Pages/CustomerSuccess/EngineeringConsulting';
import { SignupCreate } from './Pages/SignupCreate';
import MyAccount from './Pages/MyAccount';
import { RemediationOld } from './Pages/Remediation-old';
import EndpointProtection from './Pages/EndpointProtection/EndpointProtection';
import EmailProtection from './Pages/EmailProtection/EmailProtection';
import UpGuard from './Pages/UpGuard';
import CompanyManagement from './Pages/CompanyManagement';
import ComapnyManagementTemplate from './Pages/CompanyManagement/CompanyManagementTemplate';
import CompanyManagementAccount from './Pages/CompanyManagement/CompanyManagementTemplate/CompanyManagementAccount';
import CompanyManagementContacts from './Pages/CompanyManagement/CompanyManagementTemplate/CompanyManagementContacts';
import { PanetrationOld } from './Pages/Panetration/penetration-old';

const App: FC = () => {
  return (

    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path={PageRouteConstants.Login} element={<PublicRoutes><Login /></PublicRoutes>} />
        <Route path={PageRouteConstants.Home} element={<PublicRoutes><Login /></PublicRoutes>} />
        <Route path={PageRouteConstants.Mfa} element={<PublicRoutes><Mfa /></PublicRoutes>} />
        <Route path={PageRouteConstants.Signup} element={<PublicRoutes><Signup /></PublicRoutes>} />
        <Route path={PageRouteConstants.SignupCreate} element={<PublicRoutes><SignupCreate /></PublicRoutes>} />
        <Route path={PageRouteConstants.ForgotPassword} element={<PublicRoutes><ForgotPassword /></PublicRoutes>} />

        {/* Private Routes */}
        <Route path={PageRouteConstants.OnBoarding} element={<PrivateRoutes><Onboarding /></PrivateRoutes>}></Route>
        <Route path={PageRouteConstants.Any} element={<PrivateRoutes><NotFound /></PrivateRoutes>} />

        <Route path={PageRouteConstants.MyAccount} element={<PrivateRoutes><MyAccount /></PrivateRoutes>}></Route>
 
      
        <Route path={PageRouteConstants.CompanyManagement} element={<PrivateRoutes><CompanyManagement /></PrivateRoutes>}></Route> 
 
        <Route path={PageRouteConstants.CompanyManagementAccount} element={<PrivateRoutes><ComapnyManagementTemplate  ><CompanyManagementAccount/></ComapnyManagementTemplate  ></PrivateRoutes>}></Route>
         
        <Route path={PageRouteConstants.CompanyManagementContact} element={<PrivateRoutes><ComapnyManagementTemplate  ><CompanyManagementContacts/></ComapnyManagementTemplate  ></PrivateRoutes>}></Route>
 
   
        <Route path={PageRouteConstants.CompanyManagement} element={<PrivateRoutes><CompanyManagement /></PrivateRoutes>}></Route>
        {/* Commenting as this is descoped
        <Route path={PageRouteConstants.Notification} element={<PrivateRoutes><NotificationCenter /></PrivateRoutes>}></Route> */}

        <Route path={PageRouteConstants.CompanyProfile} element={<PrivateRoutes><CompanySettings><CompanyProfile /></CompanySettings></PrivateRoutes>}></Route>
        <Route path={PageRouteConstants.ManageUsers} element={<PrivateRoutes><CompanySettings><ManageUsers /></CompanySettings></PrivateRoutes>}></Route>
        <Route path={PageRouteConstants.Meetings} element={<PrivateRoutes><CompanySettings><Meetings /></CompanySettings></PrivateRoutes>}></Route>
        <Route path={PageRouteConstants.Authorizations} element={<PrivateRoutes><CompanySettings><Authorizations /></CompanySettings></PrivateRoutes>}></Route>
        <Route path={PageRouteConstants.PlanAndBilling} element={<PrivateRoutes><CompanySettings><PlanAndBilling /></CompanySettings></PrivateRoutes>}></Route>
        
        <Route path={PageRouteConstants.EndpointProtection} element={<PrivateRoutes><EndpointProtection /></PrivateRoutes>}></Route>

        <Route path={PageRouteConstants.Applications} element={<PrivateRoutes><TechnicalInformation><Applications /></TechnicalInformation></PrivateRoutes>} />
        <Route path={PageRouteConstants.ExternalInfrastructureAssessment} element={<PrivateRoutes><TechnicalInformation><ExternalInfrastructureAssessment /></TechnicalInformation></PrivateRoutes>} />
        <Route path={PageRouteConstants.WirelessAssessment} element={<PrivateRoutes><TechnicalInformation><WirelessAssessment /></TechnicalInformation></PrivateRoutes>} />

        <Route path={PageRouteConstants.PoliciesAndProcedures} element={<PrivateRoutes><PoliciesAndProcedures /></PrivateRoutes>} />
        <Route path={PageRouteConstants.PoliciesAndProceduresTemplate + ':id'} element={<PrivateRoutes><PoliciesAndProceduresTemplate /></PrivateRoutes>} />

        <Route path={PageRouteConstants.SecurityAwareness} element={<PrivateRoutes><SecurityAwarness /></PrivateRoutes>}></Route>
        <Route path={PageRouteConstants.Vulnerability} element={<PrivateRoutes><Vulnerability /></PrivateRoutes>}></Route>
        <Route path={PageRouteConstants.Remediation} element={<PrivateRoutes><Remediation /></PrivateRoutes>}></Route>

        {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' &&
          <Route path={PageRouteConstants.Penetration} element={<PrivateRoutes><Panetration /></PrivateRoutes>}></Route>
        }
        {process.env.REACT_APP_SHOW_PARTIAL_FEATURES !== 'true' &&
          <Route path={PageRouteConstants.Penetration} element={<PrivateRoutes><PanetrationOld /></PrivateRoutes>}></Route>
        }

        {/* <Route path={PageRouteConstants.FrameworksCIS} element={<PrivateRoutes><FrameWorksTemplate><CISTab /></FrameWorksTemplate></PrivateRoutes>}/> */}
        <Route path={PageRouteConstants.Frameworks} element={<PrivateRoutes><FrameWorksTemplate /></PrivateRoutes>} />

        <Route path={PageRouteConstants.GapAnalysis} element={<PrivateRoutes><GapAnalysis /></PrivateRoutes>} />
        <Route path={PageRouteConstants.DashBoard} element={<PrivateRoutes><DashBoard /></PrivateRoutes>} />
  
        {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' &&
        <Route path={PageRouteConstants.UpGuard} element={<PrivateRoutes><UpGuard /></PrivateRoutes>} />}
        
 
        {process.env.REACT_APP_SHOW_PARTIAL_FEATURES === 'true' &&
          <Route path={PageRouteConstants.EmailProtection} element={<PrivateRoutes><EmailProtection /></PrivateRoutes>} />
        }



        <Route path={PageRouteConstants.ServicesReview} element={<PrivateRoutes><CustomerSuccessTemplate><ServicesReview /></CustomerSuccessTemplate></PrivateRoutes>} />
        <Route path={PageRouteConstants.EngineeringConsulting} element={<PrivateRoutes><CustomerSuccessTemplate><EngineeringConsulting /></CustomerSuccessTemplate></PrivateRoutes>} />



        {/* Non layout comtainer */}
        <Route path={PageRouteConstants.Welcome} element={<AuthNonLayoutContainer><Welcome /></AuthNonLayoutContainer>}></Route>







      </Routes>

    </Router>
  );
}

export default App;
