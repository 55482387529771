export class Wireless {
  constructor(ips?: Wireless) {
    let properties = [
      'ssid',
      'description',
      'protocol_type',
      'protocol',
      'security_type',
      'security',
      'facility',
      'company_facility_uuid',
      'tech_info_wireless_uuid'
    ];
    for (let property of properties)
      if (ips && (ips[property] || ips[property] === 0)) this[property] = ips[property];
  }
  ssid: string;
  description: string;
  protocol_type: string = '';
  protocol: string = '';
  security_type: string = '';
  security: string = '';
  facility: Facility = new Facility();
  company_facility_uuid: string;
  tech_info_wireless_uuid: string;
}
export class Facility {
  constructor(facility?: Facility) {
    let properties = ['company_facility_uuid', 'name', 'address'];
    for (let property of properties)
      if (facility && (facility[property] || facility[property] === 0))
        this[property] = facility[property];
  }
  company_facility_uuid: string;
  name: string;
  address: string;
}
