import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { NotFound } from 'src/Pages/NotFound';
import MainContainer from '../../components/Container';
import { PageRouteConstants } from '../constants/PageRouteConstants';

function ProtectedRoute({ children }: { children: React.ReactElement }): React.ReactElement {
  let location = useLocation();
  const navigate = useNavigate();
  const superadminpath = [
    PageRouteConstants.ManageUsers,
    PageRouteConstants.MyAccount,
    PageRouteConstants.CompanyManagementAccount,
    PageRouteConstants.CompanyManagement,
    PageRouteConstants.CompanyManagementContact
  ];
  const engineeringPath = [PageRouteConstants.MyAccount];
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const [isAllowerd, setIsAllowed] = useState(false);
  useEffect(() => {
    if (commonDetails?.data?.group.toUpperCase() == 'SUPERADMIN') {
      if (superadminpath.includes(location.pathname)) {
        setIsAllowed(true);
      } else {
        navigate(PageRouteConstants.MyAccount);
      }
    } else {
      if (engineeringPath.includes(location.pathname)) {
        setIsAllowed(true);
      } else {
        navigate(PageRouteConstants.MyAccount);
      }
    }
  }, [location.pathname]);
  //return children;
  if (!isAllowerd) {
    return (
      <MainContainer>
        <NotFound />
      </MainContainer>
    );
  }
  return <>{children}</>;
}

export default ProtectedRoute;
