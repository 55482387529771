import { Box } from '@mui/material';

import React, { FC } from 'react';
import Style from '@jibin/common/style/Styles';

export const AuthNonLayoutContainer: FC<{ children: React.ReactNode | React.ReactNode[] }> = (
  props
) => {
  return (
    <>
      <Box sx={Style.Layout.MainLayout}>
        <Box
          component="main"
          className="main-content"
          sx={{ ...Style.Layout.MainContent, marginLeft: '0px' }}>
          {props.children}
        </Box>
      </Box>
    </>
  );
};

export default AuthNonLayoutContainer;
