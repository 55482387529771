import SvgIcon from '@mui/material/SvgIcon';

export default function DropDown(props) {
  return (
    <SvgIcon viewBox="0 0 8 9" sx={{ width: '12px', ml: 1 }} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0457749 1.7144C0.0153751 1.7728 0.000174999 1.8364 0.000174999 1.9C0.000174999 1.98 0.0241747 2.0592 0.0713749 2.1276L3.67138 7.3276C3.74618 7.4356 3.86898 7.5 4.00017 7.5C4.13138 7.5 4.25418 7.4356 4.32898 7.3276L7.92898 2.1276C8.01338 2.0056 8.02338 1.846 7.95458 1.7144C7.88538 1.5824 7.74898 1.5 7.60018 1.5L0.400175 1.5C0.251375 1.5 0.114975 1.5824 0.0457749 1.7144Z"
        fill="white"
      />
    </SvgIcon>
  );
}
