import { FC, useCallback, useEffect, useState } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';

import UpArrow from '@jibin/common/icons/UpArrow/UpArrow';
import DownArrow from '@jibin/common/icons/DownArrow/DownArrow';
import CalenderIcon from '@jibin/common/icons/CalenderIcon/CalenderIcon';


import {
  ResponsiveContainer,
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from 'recharts';

import { shallowEqual, useSelector } from 'react-redux';
import { VulranbilityApi } from './Vulranbility.Api';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { TopRemediations } from '../Common/TopRemediations/TopRemediations';

export const Vulnerability: FC = () => {
  const commonDetails = useSelector(
    ({ commonDetails }: any) => commonDetails.commonDetails?.data,
    shallowEqual
  );
  const navigate = useNavigate();

  const [vulnerabilities, setVulnerabilities] = useState({} as any);
  const [vulnerabilityStats, setVulnerabilityStats] = useState({} as any);
  const [remediations, setRemediations] = useState({} as any);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const args = {
    gradientColor: 'blue',
    areaStrokeColor: 'cyan',
    // tickFormatter: null,
    uniqueId: 2
  };

  // const Getvulnerabilities = useCallback(() => {
  //   VulranbilityApi.vulnerabilities(
  //     commonDetails.data.user_uuid,
  //     commonDetails.data.company.company_uuid
  //   ).then(
  //     (q: any) => {
  //       setVulnerabilities(q.data);
  //     },
  //     (err: any) => {}
  //   );
  // }, [commonDetails]);

  const GetVulnerabilityStats = useCallback(() => {
    setIsSkeleton(true);
    VulranbilityApi.VulnerabilityStats(
      commonDetails.data.user_uuid,
      commonDetails.data.company.company_uuid
    ).then(
      (q: any) => {
        setVulnerabilityStats(q.data);
        setIsSkeleton(false);
      },
      (err: any) => { }
    );
  }, [commonDetails]);



  const momentTimezone = require('moment-timezone');

  useEffect(() => {
    GetVulnerabilityStats();

   
  }, [GetVulnerabilityStats]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          backgroundColor: '#FFFFFF',
          p: 4,
          gap: 1
        }}>
        <Typography variant={'subtitle1'}>Vulnerability Management</Typography>
        <Typography variant={'subtitle2'}>
          Proactive scanning to detect & remediate cybersecurity vulnerabilities
        </Typography>
      </Box>
      {isSkeleton ? (
        <Box sx={{ px: 4, py: 3 }}>
          <Skeleton sx={{ p: 5 }} />
        </Box>
      ) : vulnerabilityStats.data?.scan.current == 'Not set' ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', m: 'auto', mt: 5 }}>
          <Box sx={{ textAlign: 'center' }}>
            <img width={'50%'} src="/images/NoDataFound.png" />
            <Typography sx={{ mt: '10px', textAlign: 'center', color: '#696969' }} variant="body2">
              Please go to Technical Information and setup your environment
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box sx={{ py: 3, px: 4, backgroundColor: '#FBFBFF', width: 1 }}>
          <Box sx={{ color: 'rgba(70, 70, 70, 1)', width: 1 }}>
            <Box>
              <Box
                sx={{
                  border: '1px solid #CCCCCC',
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderRadius: '8px',
                  p: 3,
                  gap: 1,
                  pr: 17,
                  flexWrap: 'wrap',
                  backgroundColor: '#FFFFFF'
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1
                  }}>
                  <Typography sx={{ fontSize: '19px', fontWeight: 600 }}>
                    Vulnerability Management
                  </Typography>
                  <Typography variant="body2">
                    Minimizing Vulnerabilities for Improved Security
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 1
                  }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CalenderIcon sx={{ color: '#436AF3' }} />
                    <Typography variant="body2">Last Vulnerability Scan</Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">
                      {momentTimezone(vulnerabilityStats.data?.scan.current)
                        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                        .format('DD MMM YYYY')
                        .toUpperCase()}{' '}
                      at{' '}
                      {momentTimezone(vulnerabilityStats.data?.scan.current)
                        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                        .format('hh:mm A zz')}
                    </Typography>
                  </Box>
                </Box>
                {moment(vulnerabilityStats.data?.scan.next).isValid() &&
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: 1
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <CalenderIcon sx={{ color: '#9D9D9D' }} />
                      <Typography variant="body2">Next Vulnerability Scan</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2">
                        {moment(vulnerabilityStats.data?.scan.next).isValid()
                          ? momentTimezone(vulnerabilityStats.data?.scan.next)
                            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                            .format('DD MMM YYYY hh:mm A zz')
                          : vulnerabilityStats.data?.scan.next}
                      </Typography>
                    </Box>
                  </Box>
                }
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: {
                    xs: 'repeat( 1,1fr)',
                    sm: 'repeat( 2,1fr)',
                    md: 'repeat( 3,1fr)'
                  },
                  width: 1,
                  gap: 2,
                  mt: 3
                }}>
                <Box
                  sx={{
                    border: '1px solid #CCCCCC',
                    width: 1,

                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px'
                  }}>
                  <Box sx={{ p: 3, minHeight: 300 }}>
                    <Box sx={{ mb: 4 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: 1
                        }}>
                        <Typography sx={{ fontWeight: 600 }}>
                          Vulnerabilities by Criticality
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Typography variant="caption">
                          From {vulnerabilityStats.data?.count.total} Total Vulnerabilities
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          border: '1px solid #EAECF0',
                          borderRadius: '8px',
                          p: '14px'
                        }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Box
                            sx={{
                              width: '16px',
                              height: '16px',
                              backgroundColor: '#F87171',
                              borderRadius: '50%'
                            }}></Box>
                          <Typography variant={'body1'} sx={{ fontWeight: 600, color: '#696969' }}>
                            Critical
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                            {vulnerabilityStats.data?.count.critical
                              ? vulnerabilityStats.data?.count.critical
                              : 0}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          border: '1px solid #EAECF0',
                          borderRadius: '8px',
                          p: '14px'
                        }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Box
                            sx={{
                              width: '16px',
                              height: '16px',
                              backgroundColor: '#F59E0B',
                              borderRadius: '50%'
                            }}></Box>
                          <Typography variant={'body1'} sx={{ fontWeight: 600, color: '#696969' }}>
                            High
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                            {vulnerabilityStats.data?.count.severe
                              ? vulnerabilityStats.data?.count.severe
                              : 0}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          border: '1px solid #EAECF0',
                          borderRadius: '8px',
                          p: '14px'
                        }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Box
                            sx={{
                              width: '16px',
                              height: '16px',
                              backgroundColor: '#FCD34D',
                              borderRadius: '50%'
                            }}></Box>
                          <Typography variant={'body1'} sx={{ fontWeight: 600, color: '#696969' }}>
                            Medium
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{ fontWeight: 600, fontSize: '28px', lineHeight: '34px' }}>
                            {vulnerabilityStats.data?.count.moderate
                              ? vulnerabilityStats.data?.count.moderate
                              : 0}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    minHeight: 300,
                    width: 1,
                    display: 'flex',
                    gap: 3,
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}>
                  <Box
                    sx={{
                      border: '1px solid #CCCCCC',
                      width: 1,

                      backgroundColor: '#FFFFFF',
                      borderRadius: '8px'
                    }}>
                    <Box sx={{ p: 3 }}>
                      <Box sx={{ mb: 4 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 1
                          }}>
                          <Typography sx={{ fontWeight: 600 }}>
                            Critical Vulnerabilities Trend
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                          <Typography variant="caption">
                            {momentTimezone(vulnerabilityStats.data?.scan.current)
                              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                              .format('DD MMM YYYY')
                              .toUpperCase()}{' '}
                            at{' '}
                            {momentTimezone(vulnerabilityStats.data?.scan.current)
                              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                              .format('hh:mm A zz')}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: 3
                          }}>
                          <Typography sx={{ fontSize: '40px', fontWeight: 600 }}>
                            {vulnerabilityStats.data?.critical.current
                              ? vulnerabilityStats.data?.critical.current
                              : 0}
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {vulnerabilityStats.data?.critical.performance > 0 ? (
                              <DownArrow />
                            ) : (
                              <UpArrow />
                            )}

                            <Typography sx={{ fontWeight: 600 }} variant="body2">
                              {vulnerabilityStats.data?.critical.performance > 0 && (
                                <span style={{ color: '#059669' }}>
                                  {Math.abs(
                                    vulnerabilityStats.data?.critical.performance
                                      ? vulnerabilityStats.data?.critical.performance
                                      : 0
                                  )}
                                  %{' '}
                                </span>
                              )}
                              {vulnerabilityStats.data?.critical.performance <= 0 && (
                                <span style={{ color: '#F96363' }}>
                                  {Math.abs(
                                    vulnerabilityStats.data?.critical.performance
                                      ? vulnerabilityStats.data?.critical.performance
                                      : 0
                                  )}
                                  %{' '}
                                </span>
                              )}
                              vs. Last Scan
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          {/* <GreenChart /> */}

                          <AreaChart
                            width={170}
                            height={70}
                            data={vulnerabilityStats.data?.critical.stats}>
                            <defs>
                              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop
                                  offset="5%"
                                  stopColor={
                                    vulnerabilityStats.data?.critical.performance > 0
                                      ? '#059669'
                                      : '#F96363'
                                  }
                                  stopOpacity={0.8}
                                />
                                <stop
                                  offset="95%"
                                  stopColor={
                                    vulnerabilityStats.data?.critical.performance > 0
                                      ? '#059669'
                                      : '#F96363'
                                  }
                                  stopOpacity={0}
                                />
                              </linearGradient>
                            </defs>
                            <XAxis tick={false} hide axisLine={false} dataKey="date" />
                            <YAxis tick={false} hide axisLine={false} />
                            <Area
                              type="monotone"
                              dataKey="value"
                              stroke={
                                vulnerabilityStats.data?.critical.performance > 0
                                  ? '#059669'
                                  : '#F96363'
                              }
                              fillOpacity={1}
                              fill="url(#colorUv)"
                            />
                          </AreaChart>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      border: '1px solid #CCCCCC',
                      width: 1,
                      backgroundColor: '#FFFFFF',
                      height: 1 / 2,
                      borderRadius: '8px'
                    }}>
                    <Box sx={{ p: 3 }}>
                      <Box sx={{ mb: 4 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 1
                          }}>
                          <Typography sx={{ fontWeight: 600 }}>
                            Total Vulnerabilities Trend
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                          <Typography variant="caption">
                            {momentTimezone(vulnerabilityStats.data?.scan.current)
                              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                              .format('DD MMM YYYY')
                              .toUpperCase()}{' '}
                            at{' '}
                            {momentTimezone(vulnerabilityStats.data?.scan.current)
                              .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                              .format('hh:mm A zz')}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: 3
                          }}>
                          <Typography sx={{ fontSize: '40px', fontWeight: 600 }}>
                            {vulnerabilityStats.data?.total.current
                              ? vulnerabilityStats.data?.total.current
                              : 0}
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {vulnerabilityStats.data?.total.performance > 0 ? (
                              <DownArrow />
                            ) : (
                              <UpArrow />
                            )}

                            <Typography sx={{ fontWeight: 600 }} variant="body2">
                              {vulnerabilityStats.data?.total.performance > 0 && (
                                <span style={{ color: '#059669' }}>
                                  {Math.abs(
                                    vulnerabilityStats.data?.total.performance
                                      ? vulnerabilityStats.data?.total.performance
                                      : 0
                                  )}
                                  %{' '}
                                </span>
                              )}
                              {vulnerabilityStats.data?.total.performance <= 0 && (
                                <span style={{ color: '#F96363' }}>
                                  {Math.abs(
                                    vulnerabilityStats.data?.total.performance
                                      ? vulnerabilityStats.data?.total.performance
                                      : 0
                                  )}
                                  %{' '}
                                </span>
                              )}
                              vs. Last Scan
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <AreaChart
                            width={170}
                            height={70}
                            data={vulnerabilityStats.data?.total.stats}>
                            <defs>
                              <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                                <stop
                                  offset="5%"
                                  stopColor={
                                    vulnerabilityStats.data?.total.performance > 0
                                      ? '#059669'
                                      : '#F96363'
                                  }
                                  stopOpacity={0.8}
                                />
                                <stop
                                  offset="95%"
                                  stopColor={
                                    vulnerabilityStats.data?.total.performance > 0
                                      ? '#059669'
                                      : '#F96363'
                                  }
                                  stopOpacity={0}
                                />
                              </linearGradient>
                            </defs>
                            <XAxis tick={false} hide axisLine={false} dataKey="date" />
                            <YAxis tick={false} hide axisLine={false} />
                            <Area
                              type="monotone"
                              dataKey="value"
                              stroke={
                                vulnerabilityStats.data?.total.performance > 0
                                  ? '#059669'
                                  : '#F96363'
                              }
                              fillOpacity={1}
                              fill="url(#colorUv2)"
                            />
                          </AreaChart>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    border: '1px solid #CCCCCC',
                    width: 1,
                    backgroundColor: '#FFFFFF',
                    borderRadius: '8px'
                  }}>
                  <TopRemediations
                    size={10}
                    source='vuln'
                    title='Top Remediation Tasks'
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
